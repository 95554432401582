import * as React from "react"
import { AbstractLabelFactory,  DefaultLabelWidget, } from 'storm-react-diagrams'

import { DecisionLabelModel } from '../models'

export class LabelFactory extends AbstractLabelFactory {
	constructor() {
		super("label");
	}

	generateReactWidget(diagramEngine, label){
		return <DefaultLabelWidget model={label} />;
	}

	getNewInstance(initialConfig) {
		return new DecisionLabelModel();
	}
}

	