import { lighten } from "@material-ui/core/styles/colorManipulator";

export const drawerWidth = 240;
export const detailsDrawerWidth = 500;
export const componentPaletteWidth = 150;
export const solutionDiagramMinHeight = 200;
export const executionContextMinHeight = 150;

const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: "#e6e6e6",
    // overflow: "hidden",
    height: "-webkit-fill-available"
  },

  cursorWait: {
    cursor: "wait"
  },

  cursorDefault: {
    cursor: "default"
  },


  /** dashboard */

  graphOverlay :{
    position: 'absolute',
    textAlign: '-webkit-center'
  },

  itemPaper :{
    padding: "40px 20px 20px 20px",
    borderRadius:'10px 10px 0px 0px'
  },


  itemHeader : {
    borderRadius:'10px 10px 0px 0px',
    position: "absolute",
    top: 0,
    left: 0,
    right:0,
    // width:'100%',
    display:"flex",
    margin:'0px',
    backgroundColor:theme.palette.secondary.main,
  },

  itemHeaderButton : {
    padding:'3px',
  },

  /**
   * DeployedUsageStats
   */
  deployedUsageStatsSelectionArea :{
    display:'flex',
    margin : '10px 0 20px 0',
  },
    deployedUsageStatsSelection :{
    minWidth :'50%',
    // backgroundColor :'green',
  },
  deployedUsageStatsOverlayText :{
    top: 'calc(50% - 20px)', 
    width:'100%',

    position: 'absolute'

   },
   deployedUsageStatsSpinner :{
    top: 'calc(50% + 40px)', 
    position: 'absolute'
   },

   deployedUsageStatsToggleButtons :{
    borderStyle :'none',
    boxShadow : 'none',
    margin:'10px 0 5px 0',
    textAlign :'right',
   },

   deployedUsageStatsToggleButtonSelected :{
    background  :'white !important',
    borderBottomWidth: '2px',
    borderBottomColor: 'orange',
    borderBottomStyle: 'solid'
   },
   deployedUsageStatsToggleButtonRoot :{
   },
   deployedUsageStatsDiagram :{
     borderWidth : '1px',
     borderStyle :'solid',
     borderColor : '#d6d6d6',
     backgroundColor :'#f6f6f6',
     padding :'15px 15px 5px 5px',
  },

 /**
   * Admin
   * Table Styles
   */

  adminTablePaper: {
    padding: "20px 20px 20px 20px",
    margin: theme.spacing.unit * 3,
    maxHeight: "80%",
    overflow: "auto"
  },
  adminTableAddButton: {
    position: "relative",
    // top :'5px',
    marginBottom: "10px",
    left: "10px",
    float: "left",
    zIndex: 2
  },

  toolBarHighlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  toolBarSpacer: {
    flex: "1 1 100%"
  },
  toolBarActions: {
    color: theme.palette.text.secondary
  },
  toolBarTitle: {
    flex: "0 0 auto"
  },

  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },

  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  close: {
    padding: theme.spacing.unit / 2
  },

  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },

  backButton: {
    minWidth: theme.spacing.unit * 6,
    color: "white",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: theme.spacing.unit * -2
  },

  refreshIconSmall: {
    top: theme.spacing.unit * -1,
    padding: "0px"
  },

  /**
   *  Runtime info panel
   *
   */

  executionTestView: {
    overflow: "auto",
    height: "87%"
  },


  spinBlock : {
    zIndex: 1000,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',    
  },

  /**
   * ItemsSelector ListItems
   */

   noPaddingListItem :{
     padding : '0px',
   },

  /**
   * Component Details
   *
   */

  componentDetailsHeader: {
    display: "flex"
  },
  componentDetailsArea: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "25px",
    height: "87%"
  },
  componentDetailsButtons: {
    display: "flex",
    marginLeft: "auto",
    justifyContent: "flex-end"
  },
  componentDetailsButton: {
    margin: theme.spacing.unit,
    top: "-20px"
  },

  componentDetailsDivider: {
    marginBottom: "25px"
  },

  componentDetailsTextField: {
    margin: "0px 0px 25px 0px"
  },

  componentDetailsCodeEditor: {
    height: 150,
    margin: "50px 0px 25px 0px"
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },

  contextMenuButton: {
    height: "30px",
    width: "31px",
    margin: "5px",
    minWidth: "unset"
  },

  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  loginPaper: {
    backgroundColor: "#e6e6e6"
  },

  /** Typography variants */

  typoBoldCentered: {
    textAlign: "center",
    fontWeight: 'bold',
  },
  /**
   * App Bar
   * Management
   */

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  /**
   * Navigation
   * Drawer Styles
   */

  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9
    }
  },

  detailsListItem: {
    marginLeft: "0px",
    marginRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "0px",
    paddingLeft: "5px",
    color: "#1890ff",
    width: "auto",
    "& div": {
      margin: "5px 10px 5px 10px"
    }
  },

  zoomButton: {
    top: "-100px",
    margin: "5px",
    opacity: "0.2",
    "&:hover": {
      backgroundColor: "#1890ff",
      opacity: "0.8"
    }
  },

  opaqueButton: {
    opacity: "0.2",
    "&:hover": {
      opacity: "0.7"
    }
  },
  addToListButton: {
    marginBottom: "25px"
  },

  /**
   * Experiments Config page
   */

  experimentConfigTab :{
    maxHeight: `calc(100% - ${theme.mixins.toolbar.minHeight+77}px)`,
    overflowY:'auto',
  },

  /**
   * Experiments visualiser
   */
  visualiserPaper : {
    padding : theme.spacing.unit,
    maxHeight: '500px',
  },
  axisCard : {
    margin : theme.spacing.unit,
    minHeight:'300px',
    maxHeight:'400px',
  },
  axisCardHeader : {
    backgroundColor: theme.palette.primary.main,
  },

  axisCardHeaderText : {
    color:'white',
  },
  chip : {
    margin: '5px',
  },

  hiddenChip : {
    visibility: 'hidden',
    opacity: 0,
    transition: {
      visibility : '0s 2s', 
      opacity : '2s linear',
    }
  },

  chipLandingPlace : {
    borderRadius: '20px',
    borderWidth:'3px',  
    borderStyle:'dashed', 
    borderColor:'#e6e6e6',
    minHeight:'48px',
    minWidth : '150px',
  },

  chipLandingPlaceText :{
    fontSize :'14px',
    color :'#e6e6e6',
    margin :'10px 20px 5px 20px',
    verticalAlign:'middle',
  },
  /**
   * Component Details
   * drawer styles
   */

  gridButton: {
    "&:hover": {
      color: "#f26430 "
    }
  },

  detailsDrawerMarker: {
    borderRight: "2px solid",
    borderColor: "#1890ff",
    width: "0px",
    position: "absolute",
    left: theme.spacing.unit * 6,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    willChange: "top, height"
  },

  detailsItemIsActive: {
    "& div": {
      color: "#1890ff"
    }
  },

  detailsDrawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: detailsDrawerWidth,
    maxHeight: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  detailsDrawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7
    }
  },

  detailsDrawerGrid: {
    width: detailsDrawerWidth - 1,
    overflow: "hidden",
    height: "100%"
  },
  detailsOuterArea: {
    width: "87%"
  },
  detailsTitle: {
    margin: "10px 0px 10px 0px"
  },

  detailsPaper: {
    margin: "10px 10px 10px 0px",
    padding: "10px 10px 10px 10px"
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },

  adminContent: {
    flexGrow: 1,
    height: "86vh",
    width: "86vw",
  },
  solutonEditorContent: {
    height: "100vh",
    marginRight: "5px"
  },

  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  h5: {
    marginBottom: theme.spacing.unit * 2
  },
  "@font-face": {
    fontFamily: "Lovelo",
    src: "url(/Lovelo%20Line%20Light.otf)"
  },
  logo: {
    fontFamily: "Lovelo, Arial, sans-serif",
    fontSize: "3em",
    fontWeight: "bold",
    color: "#1890ff"
  },
  bigAvatar: {
    width: 60,
    height: 60
  },
  loginButton: {
    display: "inline-block",
    background: "transparent",
    width: 190,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 30,
    border: "6px solid",
    borderColor: "#1890ff",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Roboto"
  },

  calculationSummmaryRoot: {
    minHeight: "20px",
    backgroundColor: "#e6e6e6"
  },

  calculationSummmaryContent: {
    minHeight: "20px",
    fontSize: theme.typography.pxToRem(14),
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    width: "100%",
    margin: "5px 0 !important",
    backgroundColor: "#e6e6e6"
  },
  calculationSummmaryExpanded: {
    minHeight: "20px",
    backgroundColor: "#e6e6e6"
  },

  calculationItem: {
    backgroundColor: "#e6e6e6"
  },

  calcOperatorRoot: {
    fontSize: "25px"
  },
  calcOperatorInput: {
    paddingBottom: "0px"
  },
  calcOperatorIcon: {
    left: "15px",
    maxWidth: "15px",
    top: "10px"
  },

  fieldSelectorSummmaryRoot: {
    margin: "0px 0",
    borderBottom: "rgb(230, 230, 230)",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },

  fieldSelectorSummmaryContent: {
    fontSize: theme.typography.pxToRem(14),
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    width: "100%",
    margin: "5px 0 !important"
  },

  fieldSelectorSummmaryExpanded: {
    backgroundColor: "rgb(230, 230, 230)"
  },
  fieldSelectorValue: {
    minWidth: "40px",
    marginBottom: "2px",
    lineHeight: "0.9",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },

  fieldSelectorItem: {
    backgroundColor: "rgb(230, 230, 230)"
  },
  componentPalette: {
    width: componentPaletteWidth
  },

  solutionEditorIconButton: {
    minWidth: "unset"
  },

  solutionEditorContent: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0
  },
  solutionEditorContentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },

  solutionDiagramColumn: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height:"100%",
  },

  solutionDiagramPanelTop: {
    minHeight: solutionDiagramMinHeight
  },

  solutionDiagramPanelBottom: {
    minHeight: executionContextMinHeight,
    bottom: 0
  },

  solutionDiagramPaper: {
    margin: "10px",
    padding: "20px",
    width :'100%'
  },
  solutionEditorRuntimePaper: {
    margin: "10px",
    padding: "20px",

  },

  // Two styles for the Range Score Card properties table
  scoreCardCellRoot: {
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
    borderBottom: "0px !important"
  },

  scoreCardNarrowSelect: {
    paddingRight: "15px !important",
    paddingLeft: "10px !important"
  },

  tableWrapper: {
    height: "87%",
    overflow: "auto"
  },
  tableRowSelected: {
    background: "lightGrey !important"
  },

  componentPaletteLayout: {
    margin: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap"
  },

  componentPaletteItem: {
    padding: "0px",
    margin: "5px",
    fontSize: "13.5px !important",
    cursor: "move"
  },

  componentPaletteItemLabel: {
    textAlign: "center",
    margin: "10px"
  },

  // offline page

  loginLayout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },

  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },

  // display: "block", // Fix IE11 issue.
  // [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //   width: 400,

  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  toolbarTitle: {
    flex: 1
  },
  mainFeaturedPost: {
    backgroundColor: "#1890ff",
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3
  },
  card: {
    display: "flex",
    cursor: "pointer",
    height: "100%"
  },
  cardDetailButton: {
    textTransform: "initial",
    textAlign: "left"
  },
  cardMedia: {
    minWidth: 160,
    minHeight: 200
  },

  // Drop Zone for Key file on the Organisation Certificate Dialog
  keyFileDropZone: {
    backgroundColor :'green',
    minHeight:'100px',
  },
  keyFileDropZoneParagraph: {
    font :theme.typography.font,
    fontSize: theme.typography.pxToRem(14),

  }


});
export default styles;
