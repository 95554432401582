import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { EnhancedTable } from '../../../components/table'

import * as actions from './actions'
import * as versionActions from '../Solutions/actions'
import * as p from '../../../actions/permissions'
import {isoToPrettyDate} from '../../../functions'

import _ from 'lodash'

export class ExecutionsTable extends React.Component {
    state = {
        showAll: false,
        versionIds : []
    }


    componentWillReceiveProps (props, state )  {
        let newIds = []
        props.versionIds.forEach( selectedId =>{
            if (!this.props.executions[selectedId] ) {
                newIds.push(selectedId)
            }
        })
        if (props.versionIds !== this.props.versionIds && newIds !== [] ) {
            this.loadExecutions(newIds)
        }
        
    }

    loadExecutions = (versionIds) => {
        if (p.permission(this.props.user, p.LIST_EXECUTIONS)) {
            versionIds.forEach(versionId=> this.props.getExecutionsForVersion(this.props.user._links[p.VERSION_EXECUTIONS].href, versionId))
            
        }
    }

    render() {
        let datasource = []
        this.props.versionIds.forEach( selectedId =>{
            const newExecutions = this.props.executions[selectedId]? _.map(this.props.executions[selectedId], (k)=>k ) : []
            datasource.push (...newExecutions)
        })
        
        const columns = [
            {
                title: 'Started',
                key: 'started',
                dataIndex: '_embedded.started',
                render: (text, record) => { return isoToPrettyDate(text)}
            }, 
            {
                title: 'Completed',
                key: 'completed',
                dataIndex: '_embedded.ended',
                render: (text, record) => { return isoToPrettyDate(text)}
            },
            {
                title :'Duration (ms)',
                key:'duration',
                dataIndex: '_embedded.durationMillis',
            },
        ]

        return (
            <div className={this.props.className} >
                <EnhancedTable
                    dataSource={datasource}
                    rowKey={ record => record._links.self.href }
                    columns={columns}
                    pagination
                />
            </div >
        )
    }
}

ExecutionsTable = connect(
    state => ({
        executions: state.executions,
        user: state.user.profile,

    }),
    dispatch => (
        bindActionCreators({ ...versionActions, ...actions }, dispatch)
    )
)(ExecutionsTable)