import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { EditInput } from '../../../components/table'
import { TextField, Dialog, Button, DialogTitle, DialogActions, DialogContent, Tooltip, IconButton, Icon, } from '@material-ui/core'

import { EnhancedTable } from '../../../components/table'
import { withStyles } from '@material-ui/core/styles'

import * as experimentsActions from './actions'
import * as p from '../../../actions/permissions'
import styles from '../../../styles'

import _ from 'lodash'

export class ExperimentsTable extends React.Component {

    state = {
        newExperimentConfigVisible: false,
        newExperimentConfigName: '',
        newExperimentError : false,
    }
    componentWillMount() {
        if (p.permission(this.props.user, p.LIST_EXPERIMENTS)) {
            this.props.getExperimentConfigs(this.props.user._links[p.EXPERIMENTS].href)
        }
    }

    resetState = ()=> {
        this.setState({
            newExperimentConfigVisible: false,
            newExperimentConfigName: '',
            newExperimentError : false})
    }

    createExperimentConfig = () => {
        if (this.state.newExperimentConfigName ==='')  {
            this.setState({newExperimentError:true})
        }
        else {
            this.props.createExperimentConfig(this.props.user._links[p.EXPERIMENTS].href, { name: this.state.newExperimentConfigName })
            this.resetState()
        }
    }

    handleNewExperimentConfigNameChange = (e) => {

        this.setState({ newExperimentConfigName: e.target.value,
            newExperimentError : e.target.value ===''})
    }

    openNewPCModal = () => {
        this.setState({
            newExperimentConfigVisible: true,
        })
    }

    closeNewPCModal = () => {
        this.resetState()
    }

    render() {
        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_EXPERIMENTS)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_EXPERIMENT)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_EXPERIMENT)
        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    return canEdit && record._embedded.code ?
                        <EditInput
                            onChange={value => (
                                this.props.updateExperimentConfig(record._links.self.href, { name: value, description: record._embedded.description }))
                            }
                            value={text}
                            key={record._embedded.webSafeString} >
                            {text}
                        </EditInput>
                        : text
                }
            }, {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return <React.Fragment>
                        {
                            canEdit ?
                            <Tooltip title="Edit Experiment" >
                            <IconButton aria-label="Edit Experiment" onClick={e => { e.preventDefault(); this.props.push(`configs/${record._embedded.webSafeString}`) }}><Icon>build</Icon></IconButton>
                            </Tooltip> : null
                        }
                        {
                            canDelete ?
                            <Tooltip title="Delete Experiment" >
                            <IconButton aria-label="Delete Experiment" onClick={e => { e.preventDefault(); this.props.deleteExperimentConfig(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                            </Tooltip> : null
                        }</React.Fragment>
                }
            }]
        
        const { items } = this.props
        return (
            <React.Fragment>
                <Dialog
                    title='Create a new experiment'
                    open={this.state.newExperimentConfigVisible}
                    onClose={this.closeNewPCModal}
                    aria-labelledby="dialog-title"
                >
                    <DialogTitle id="dialog-title">New Experiment</DialogTitle>
                    <DialogContent>
                        <form className={this.props.classes.container} autoComplete="off">
                            <TextField
                            label = "Name"
                                value={this.state.newExperimentConfigName}
                                onChange={this.handleNewExperimentConfigNameChange}
                                className={this.props.classes.textField}
                                required = {true}
                                error = {this.state.newExperimentConfigError}
                            />
                            <br />
                            <br />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNewPCModal} color="primary">Cancel</Button>
                        <Button onClick={this.createExperimentConfig} color="primary" autoFocus>OK</Button>
                    </DialogActions>

                </Dialog>
                <EnhancedTable
                    dataSource={items}
                    columns={columns}
                    rowKey={record => record._links.self.href}
                    canDelete={canDelete}
                    onDelete={e => this.props.deleteExperimentConfig(e._links.self.href)}
                    canCreate={canAdd}
                    onCreate={this.openNewPCModal}
                    pagination
                />
            </React.Fragment>
        )
    }
}

ExperimentsTable = connect(
    state => ({
        items: Object.values(state.experiments.items),
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators({ ...experimentsActions, push }, dispatch)

    )
)(withStyles(styles, { withTheme: true })(ExperimentsTable))
