import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { DataSourcesList } from './DataSourcesList'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { OperationFieldList } from './OperationFieldList'

import { OutputsList } from './OutputsList'

import { ActionScheduler } from './ActionScheduler'
import { FieldSelector } from './FieldSelector';
import { ScoreTable } from './ScoreTable';
import { Calculator} from './Calculator'
import { ListScoreTable } from './ListScoreTable';
import { PredictionConfigFieldMapper } from './PredictionConfigFieldMapper';
import { KVSelectList} from './KVSelectList'
import { Editor } from '../Editor'
import { PredictionConfigSelector } from '../Predictions'
import styles from '../../../styles'

import {isInteger, isAlphaNumUnderscore} from '../../../functions'
import {FieldNamesTextField}  from './FieldNamesTextField';

import { ActionCreators }   from 'redux-undo';
import { bindActionCreators } from 'redux'

export class ComponentDetailsEditor extends React.Component {

    state = {
        deleteDialogOpen: false,
    }

    deleteNode(node) {
        this.props.onDelete(node)

    }

    onChange(node, field, value) {
        this.props.onUpdate({
            id: node.id, 
            selected: true,
            type: node.type,
            [field]: value
        })
    }

    getField(node, item, index) {
        const { classes } = this.props
        switch (item.type) {
            case 'datasourceSelector' :{
                return <DataSourcesList 
                    key={`${item.field}.datasourceSelector`}
                    value={node[item.field]}
                    onChange={value => this.onChange(node, item.field, value)}
                    label = {item.label}
                    />
            }
            case 'list': {
                const selectItems = item.values.map((listItem, index) => {
                    return (
                        <MenuItem 
                            key={item.field + '_' + index} value={listItem}>{listItem}
                        </MenuItem>
                    )
                })

                return (
                    <div
                    key={`${item.field}.dv`}
                    >
                    <FormControl className={classes.formControl}
                        style={{ width: item.width ? item.width : '100%' }}
                        key={`${item.field}.formControl`}>
                        <InputLabel
                            className={classes.componentDetailsTextField}
                            htmlFor={`${item.field}.formControl`}>{item.label}</InputLabel>
                        <Select
                            className={classes.componentDetailsTextField}
                            value={node[item.field] || ''}
                            key={`${item.field}.textfield`}
                            onChange={e => this.onChange(node, item.field, e.target.value)}
                            inputProps={{
                                name: item.label,
                                id: `${item.field}.formControl`,
                            }}
                        >
                            {selectItems}
                        </Select>
                    </FormControl>
                    </div>
                )
            }
            case 'kvSelectList' :{
                return (
                 <KVSelectList
                    key={`${item.field}.kvSelectList`}
                    values={node[item.field] ||[]}
                    classes={classes}
                    vItems = {item.vItems}
                    kType = {item.kType}
                    kLabel = {item.kLabel}
                    vLabel = {item.vLabel}

                    onChange={value => this.onChange(node, item.field, value)}
                    label = {item.label}
                 />   
                )
            }
            case 'editor': {
                return (
                    <FormControl className={classes.formControl}
                        key={`${item.field}.formControl`}
                        style={{ width: item.width ? item.width : '100%' }}
                    >
                        <InputLabel
                            className={classes.componentDetailsTextField}
                            htmlFor={`${item.field}.formControl`}>
                            {item.label}
                        </InputLabel>
                        <div className={classes.componentDetailsCodeEditor}>
                            <Editor
                                id={`${item.field}.formControl`}
                                key={`${item.field}.editor`}
                                value={node[item.field] || ''}
                                //addonBefore={item.label}
                                language='json'
                                label={item.field}
                                fieldname={item.field}
                                onChange={e => this.onChange(node, item.field, e)}
                            />
                        </div>
                    </FormControl>
                )
            }
            case 'linkCondition': {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]
                return (
                    <div
                        style={{ width: item.width ? item.width : '100%' }}
                        key={item.field + 'div'}>
                        <Typography variant="body1">{item.label}</Typography>
                        <OperationFieldList
                            classes={classes}
                            id={item.field}
                            values={node[item.field] || []}
                            node={node}
                            listOps={[
                                {key:'&&', value:'&&', label:'AND'}, 
                                {key:'||', value:'||', label:'OR'}
                            ]}
                            ops={[
                                {key:'==', value:'==', label:'=='},
                                {key:'!=', value:'!=', label:'!='},
                                {key:'>', value: '>', label:'>'},
                                {key:'<', value: '<', label:'<'},
                                {key:'>=', value: '>=', label:'>='},
                                {key:'<=', value: '<=', label:'<='}
                            ]}
                            leftHandDatasources={datasources}
                            rightHandDatasources={[...datasources, { name: 'Literal', datasource: null }]}
                            onChange={newValue => this.onChange(node, item.field, newValue)} />
                    </div>)
            }
            case 'runtimeFieldSetter': {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]
                return (
                    <div
                        style={{ width: item.width ? item.width : '100%' }}
                        key={item.field + 'div'}>
                        <Typography variant="body1">{item.label}</Typography>
                        <OperationFieldList
                            classes={classes}
                            id={item.field}
                            values={node[item.field] || []}
                            node={node}
                            ops={[
                                {key:'=', value:'=', label:'='},
                                {key:'+=', value:'+=', label:'+='},
                                {key:'-=', value:'-=', label:'-='},
                            ]}
                            leftHandDatasources={[{ name: 'Runtime', datasource: this.props.runtimeDatasetFields }]}
                            rightHandDatasources={[...datasources, { name: 'Literal', datasource: null }]}
                            onChange={newValue => this.onChange(node, item.field, newValue)}
                        />
                    </div>)

            }
            case 'runtimeFieldSelector': {
                return (<FieldSelector
                    derivedFromProps
                    key={item.field + '.fieldSelector'}
                    form={this.props.form}
                    classes={classes}
                    fieldname={item.field}
                    value={node[item.field] || ''}
                    hideSourceSelector
                    inputLabel={item.label}
                    datasources={[{ name: 'Runtime', datasource: this.props.runtimeDatasetFields }]}
                    solutionId={this.props.id}
                    onChange={newValue => this.onChange(node, item.field, newValue)}
                />)
            }
            case 'fieldSelector': {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]
                return (<FieldSelector
                    derivedFromProps
                    key={item.field + '.fieldSelector'}
                    form={this.props.form}
                    classes={classes}
                    value={node[item.field] || ''}
                    fieldname={item.field}
                    label={item.label}
                    solutionId={this.props.id}
                    datasources={datasources}
                    onChange={newValue => this.onChange(node, item.field, newValue)}
                />)
            }
            case 'outputsList': {
                return (
                    <OutputsList
                        style={{ width: item.width ? item.width : '100%' }}
                        key={item.field + '.outputsList'}
                        classes={classes}
                        values={node[item.field] || []}
                        fieldname={item.field}
                        runtimeDatasetFields={this.props.runtimeDatasetFields}
                        label={item.label}
                        onChange={newValue => this.onChange(node, item.field, newValue)}
                    />
                )
            }
            case 'scoreTable': {
                return (<ScoreTable
                    key={item.field + 'scoreTable'}
                    classes={classes}
                    values={node[item.field] || []}
                    fieldname={item.field}
                    onChange={newValue => this.onChange(node, item.field, newValue)}
                />)
            }
            case 'listScoreTable': {
                return (<ListScoreTable
                    key={item.field + 'scoreTable'}
                    classes={classes}
                    values={node[item.field] || []}
                    fieldname={item.field}
                    onChange={newValue => this.onChange(node, item.field, newValue)}
                />)
            }
            case 'predictionConfigSelector': {
                return (<div key={item.field + 'card'} >
                    <PredictionConfigSelector
                        label={item.label}
                        classes={classes}
                        value={node[item.field] || ''}
                        onChange={newValue => this.onChange(node, item.field, newValue)}
                    />
                </div>)

            }
            case 'calculator' : {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]

                return (<div key={item.field + 'calculator'} >
                    <Calculator
                        label={item.label}
                        classes={classes}
                        values={node[item.field]}
                        onChange={newValue => this.onChange(node, item.field, newValue)}
                        datasources = {datasources}

                    />
                </div>)
            }
            case 'scheduler': {
                return (<div key={item.field + 'scheduler'} >
                    <ActionScheduler
                        label={item.label}
                        classes={classes}
                        value={node[item.field] || ''}
                        onChange={newValue => this.onChange(node, item.field, newValue)}
                    />
                </div>)
            }
            case 'predictionConfigFieldMapper': {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]
                return (<PredictionConfigFieldMapper
                    datasources={datasources}
                    key={item.field + '.predictionConfigFieldMapper'}
                    values={node[item.field] || []}
                    predictionConfig={node[item['predictionConfig']]}
                    classes={classes}
                    onChange={newValue => this.onChange(node, item.field, newValue)}
                />)
            }
            case 'inputWithFieldSelector': {
                const datasources = [{ name: 'Application', datasource: this.props.applicationDatasetFields }, { name: 'Runtime', datasource: this.props.runtimeDatasetFields }]

                return (
                    <FieldNamesTextField
                        width={item.width}
                        classes={classes}
                        label={item.label}
                        value={node[item.field] || ''}
                        onChange={newValue => this.onChange(node, item.field, newValue)}
                        key={`${item.field}.FieldNamesTextField`}
                        multiline={item.multiline}
                        datasources={datasources}
                    />)
            }
            case 'infoText': {
                return <div key={`${item.field}.div`}
                style={{ width: item.width ? item.width : '100%', whiteSpace: 'normal' }}>
                   <Typography variant="caption" gutterBottom>{item.label}</Typography>
                </div>

            }
            case 'divider': {
                return <Divider key={index}
                    className={classes.componentDetailsDivider} />
            }
            case 'checkBox': {
                return   <FormControlLabel key={`${item.field}.checkBox`}
                control={
                  <Checkbox
                    checked={node[item.field] || false}
                    onChange={e => this.onChange(node, item.field, e.target.checked)}
                  />
                }
                label={item.label}
                />
            }
            case 'integerInput' :{
                return (
                    <div key={`${item.field}.div`}
                        style={{ width: item.width ? item.width : '100%' }}
                    >
                    <TextField
                        label={item.label}
                        className={classes.componentDetailsTextField}
                        style={{ width: item.width ? item.width : '100%' }}
                        fieldname={item.field}
                        value={node[item.field] || ''}
                        key={`${item.field}.textfield-${index}`}
                        multiline = {item.multiline}
                        onChange={e => {
                            if (isInteger(e.target.value)) {
                                this.onChange(node, item.field, e.target.value)
                            }
                        }} />
                        </div>
                )

            }
            case 'alphaNumUnderscoreInput' :{
                return (
                    <div key={`${item.field}.div`}
                        style={{ width: item.width ? item.width : '100%' }}
                    >
                    <TextField
                        label={item.label}
                        className={classes.componentDetailsTextField}
                        style={{ width: item.width ? item.width : '100%' }}
                        fieldname={item.field}
                        value={node[item.field] || ''}
                        key={`${item.field}.textfield-${index}`}
                        multiline = {item.multiline}
                        onChange={e => {
                            if (isAlphaNumUnderscore(e.target.value)) {
                                this.onChange(node, item.field, e.target.value)
                            }
                        }} />
                        </div>
                )

            }

            default: {
                return (
                    <div key={`${item.field}.div`}
                        style={{ width: item.width ? item.width : '100%' }}

                    >
                    <TextField
                        label={item.label}
                        className={classes.componentDetailsTextField}
                        style={{ width: item.width ? item.width : '100%' }}
                        fieldname={item.field}
                        value={node[item.field] || ''}
                        key={`${item.field}.textfield-${index}`}
                        multiline = {item.multiline}

                        onChange={e => this.onChange(node, item.field, e.target.value)} />
                        </div>
                )
            }
        }
    }

    nodeDetails(node) {
        if (node && node.nodeProps) {
            const nodeProps = node.nodeProps
            const formItems = nodeProps.map((item, index) => {
                return (
                    this.getField(node, item, index)
                )
            })
            return formItems
        }
    }


	handleKeyDown = (event) =>{
		if (event.ctrlKey && event.key === 'z') {
			event.stopPropagation() 
			event.preventDefault()
			this.props.undo()
		}
		if (event.ctrlKey && event.key === 'y') {
			event.stopPropagation() 
			event.preventDefault()
			this.props.redo()
		}
	}

    render() {
        const { selected, classes, solution } = this.props
        const numSelected = selected ? Object.values(selected).length : 0
        const node = numSelected === 1 &&  solution && [...solution.nodes, ...solution.links].find( n => n.id === Object.keys(selected)[0] )
        return (
            <React.Fragment >
                {
                    numSelected <= 1 ?
                        node ?
                            <React.Fragment>
                                <Divider />
                                <div className={classes.componentDetailsHeader}>
                                    <Tooltip title={node.id}>
                                        <Typography variant='h6'
                                            gutterBottom
                                            noWrap
                                            color='primary'>
                                            {node.typeLabel?node.typeLabel : 'Link'}
                                        </Typography>
                                    </Tooltip>
                                    <div className={classes.componentDetailsButtons}>
                                        <Tooltip title="Delete the selected component">
                                            <div>
                                                <Fab
                                                    color="secondary"
                                                    className={classNames([classes.button, classes.componentDetailsButton])}
                                                    onClick={() => this.setState({ deleteDialogOpen: true, })}
                                                    disabled={node.name === 'Start'}>
                                                    <Icon>delete</Icon>
                                                </Fab>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={classes.componentDetailsArea}
                                	onKeyDown={this.handleKeyDown}
                                    >
                                    {this.nodeDetails(node)}
                                </div>
                                <Dialog
                                    open={this.state.deleteDialogOpen}
                                    onClose={() => this.setState({ deleteDialogOpen: false, })}
                                    aria-labelledby="responsive-dialog-title">
                                    <DialogTitle id="responsive-dialog-title">Delete node?</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Are you sure you want to delete {node.typeLabel} ({node.label})?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.setState({ deleteDialogOpen: false })} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {
                                            this.deleteNode(node)
                                            this.setState({ deleteDialogOpen: false })
                                        }} color="primary" autoFocus>
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                            : <React.Fragment>
                                <Divider />
                                <Typography variant='h6'
                                            gutterBottom
                                            noWrap
                                            color='primary'>
                                            Nothing selected
                                </Typography>
                              </React.Fragment>
                        : <React.Fragment>{numSelected} items selected</React.Fragment>
                }
            </React.Fragment>
        )
    }
}

ComponentDetailsEditor = connect(
    (state, props) => ({
        selected: state.diagrams.instances[props.id] && state.diagrams.instances[props.id].selected,
        solution: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.solutionDefinition,
        applicationDatasetFields: (state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.applicationDatasetFields) || [],
        runtimeDatasetFields: (state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.runtimeDatasetFields) || [],
    }),
	dispatch => (
		bindActionCreators({ ...ActionCreators }, dispatch)
	),
)(withStyles(styles, { withTheme: true })(ComponentDetailsEditor))

