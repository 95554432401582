import { arrayToObject, versionsExecutionsToObject} from '../../../functions'
import * as actions from './actions'
import { createActionTypeReducer} from '../../../middleware'

const initialState = {
    items: {}
}

export const executionsReducer = createActionTypeReducer(initialState, {
    [actions.GET_EXECUTIONS_SUCCESS]: (state, action) => ({ ...state, items: arrayToObject(action.payload.items) }),
    [actions.GET_EXECUTION_STATS_FOR_VERSION_REQUEST]: (state, action) => {
        let newStats = state.stats ||{}
        newStats[new URLSearchParams(action.meta.endpoint).get('id')] = null
        return { ...state, stats:newStats}
    },
    [actions.GET_EXECUTION_STATS_FOR_VERSION_SUCCESS]: (state, action) => {
        let newStats = state.stats ||{}
        newStats[action.payload.requestId] = action.payload.data 
        return { ...state, stats:newStats}
    },
    [actions.GET_EXECUTIONS_FOR_VERSION_SUCCESS]: (state, action) => ({ ...versionsExecutionsToObject(state, action.payload.items) }),
})

