import React from 'react'
import { RolesTable } from './RolesTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'

export class RolesPage extends React.Component {
    render() {
        return (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
                <RolesTable classes={this.props.classes} />
            </Paper>
        )
    }
}

RolesPage = withStyles(styles, {withTheme: true})(RolesPage)