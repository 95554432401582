import { createBrowserHistory } from 'history'
import { reducers } from './reducers'

import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'

import { sagas } from './sagas'
// import { reducers } from './reducers'

import * as Constants from '../constants'

const logger = createLogger({
    // predicate,          // if specified this function will be called before each action is processed with this middleware. 
    collapsed: true,     // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise. 
    duration: false,    //: Boolean, // print the duration of each action? 
    timestamp: true,   //: Boolean, // print the timestamp with each action? 
   
    level: 'log',         // : 'log' | 'console' | 'warn' | 'error' | 'info', // console's level 
    //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18 
    //titleFormatter,   // Format the title used when logging actions. 
   
    //stateTransformer,   // Transform state before print. Eg. convert Immutable object to plain JSON. 
    //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON. 
    //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON. 
   
    logger: console,  //: LoggerObject, // implementation of the `console` API. 
    logErrors: true, //: Boolean, // should the logger catch, log, and re-throw errors? 
   
    diff: false,    //: Boolean, // (alpha) show diff between states? 
    //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate` 
  })  

export const history = createBrowserHistory()

export function configureStore(initialState) { 
    const sagaMiddleware = createSagaMiddleware()
    const middlewares = [
        apiMiddleware, 
        routerMiddleware(history), 
        sagaMiddleware,
    ]
    // this goes last in the chain
    if ( Constants.DEBUG ) {
        middlewares.push(logger);
    }

    // install compostion for redux dev tools
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // Note: passing middleware as the last argument to createStore requires redux@>=3.1.0
    // const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)))
    
    const store = createStore(
        reducers(history), // root reducer with router state
        initialState,
        composeEnhancers(
          applyMiddleware(...middlewares ),
        ),
    )
    sagaMiddleware.run(sagas)
    return store
  }

  export function createActionTypeReducer ( initialState, handlers, fallback = (state, action) => (state) ){
    return function reducer( state = initialState, action ){
        if( handlers.hasOwnProperty( action.type )) {
            return handlers[action.type](state, action)
        } else {
            return fallback(state, action)
        }
    }
}
