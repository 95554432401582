import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'

import {Tooltip, Icon,Fab,Dialog , DialogContent,DialogTitle, Button, DialogActions, DialogContentText} from '@material-ui/core'

import styles from '../../../styles'

import { AddRemoveLayout } from '../../../components/layout'

import * as actions from './actions'
import { uuid } from '../../../functions'

/**
 * Home is the home page for user
 */


const defaultLayout = [0].map((item, key) => ({ i: uuid(), x: key, y: Infinity, w: 10, h: 5, config: {type: 'DeployedUsageStats'} }))

export class Home extends React.Component {
    state = {
        dirty: false,
        newItemOpen: false
    }

    componentDidMount() {
        // this.props.homeInit()
        if (this.props.user._embedded.dashboard) {
            this.setState({layout : this.props.user._embedded.dashboard, dirty : false })
        }
        else {
            this.setState({layout : defaultLayout, 
            dirty : false})
        }
    }

    componentWillUnmount() {
        if (this.state.dirty) {
            this.props.updateDashboard(this.state.layout)
        }

    }
    
    handleCreate =() =>{
        this.setState({newItemOpen:true})
    }

    handleLayoutChange=(newLayout)=> {
        if (this.state.layout && newLayout) {
            let {layout} = this.state
            
            _.forEach(newLayout, (item, index)=>{
                layout[index].x = item.x ||0
                layout[index].y = item.y ||0
                layout[index].w = item.w ||2
                layout[index].h = item.h ||2

            })
            this.setState({
                dirty: true,
                layout: layout,
            })
        }
    }

    handleNewItemClose =()=> {
        this.setState({newItemOpen:false})
    }

    handleAddItem=(type) =>{
        const prev = this.state.layout[this.state.layout.length-1]
        let nextX = prev.x + prev.w
        let nextY = prev.y
        if (nextX + 2>10) {
            nextX = 0
            nextY = nextY +1
        }
        const layout = this.state.layout.concat(
            {i: uuid(),
              x: nextX,
              y: nextY,
              h: 2,
              w: 3,
              title : "New ... ",
            config: {type: type || 'DeployedUsageStats'}
            }
          )
        this.setState({
          layout: layout,
          newItemOpen:false
        })
      }
    

    handleChange=(change)=> {
        if (change) {
            if (change !== this.state.layout) {
                this.setState({
                dirty: true,
                layout: change,
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <AddRemoveLayout
                    className="layout"
                    layout={this.state.layout }
                    onLayoutChange={this.handleLayoutChange} 
                    onChange={this.handleChange} 
                    classes = {this.props.classes}
                />
                <Tooltip title="Add">
                <Fab
                  
                  color="primary"
                  className={this.props.classes.adminTableAddButton}
                  aria-label="Add"
                  onClick={this.handleCreate}>
                  <Icon>add</Icon>
                </Fab>
              </Tooltip>
              <Dialog
                    open={this.state.newItemOpen}
                    onClose={this.handleNewItemClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='xs'
                >
                    <DialogTitle id="alert-dialog-title">Add a dashboard item</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Select the dashboard item you would like to add
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleNewItemClose} color="primary">Cancel</Button>
                        <Button onClick={()=>this.handleAddItem('DeployedUsageStats')} color="primary" autoFocus>OK</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

Home = connect(
    state => ({
        user: state.user.profile
    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(withStyles(styles, {withTheme: true})(Home))