import { get, post, put, del } from '../../../actions'

export const GET_GROUPS_REQUEST = '@@groups/GET_GROUPS_REQUEST'
export const GET_GROUPS_SUCCESS = '@@groups/GET_GROUPS_SUCCESS' 
export const GET_GROUPS_FAILURE = '@@groups/GET_GROUPS_FAILURE'
export const getGroups = ( endpoint ) =>(
    get({
        displayName: 'get groups',
        endpoint: endpoint,
        types: [
            GET_GROUPS_REQUEST, 
            GET_GROUPS_SUCCESS, 
            GET_GROUPS_FAILURE
        ]
    })
)


const defaultGroup = {
    name:'New Group',
    description : '',
    
}

export const CREATE_GROUP_REQUEST = '@@groups/CREATE_GROUP_REQUEST' 
export const CREATE_GROUP_SUCCESS = '@@groups/CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAILURE = '@@groups/CREATE_GROUP_FAILURE'
export const createGroup = ( endpoint ) => (
    post({
        displayName: 'create group',
        endpoint: endpoint,
        types: [
            CREATE_GROUP_REQUEST,
            CREATE_GROUP_SUCCESS,
            CREATE_GROUP_FAILURE,
        ],
        body: JSON.stringify(defaultGroup)
    })
)


export const UPDATE_GROUP_REQUEST = '@@groups/UPDATE_GROUP_REQUEST' 
export const UPDATE_GROUP_SUCCESS = '@@groups/UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAILURE = '@@groups/UPDATE_GROUP_FAILURE'
export const updateGroup = (endpoint, group) => (
    put({
        displayName: 'update group',
        endpoint: endpoint,
        types: [
            UPDATE_GROUP_REQUEST,
            UPDATE_GROUP_SUCCESS,
            UPDATE_GROUP_FAILURE,
        ],
        body: JSON.stringify(group)
    })
)

export const DELETE_GROUP_REQUEST = '@@groups/DELETE_GROUP_REQUEST' 
export const DELETE_GROUP_SUCCESS = '@@groups/DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAILURE = '@@groups/DELETE_GROUP_FAILURE'
export const deleteGroup = (endpoint) => (
    del({
        displayName: 'delete group',
        endpoint: endpoint,
        types: [
            DELETE_GROUP_REQUEST,
            DELETE_GROUP_SUCCESS,
            DELETE_GROUP_FAILURE,
        ],
    })
)



