import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {isAlphaNumUnderscore} from '../../../functions'

export class KVSelectItem extends React.Component {

    constructor(props) {
        super(props)
        this.valueSelectItems = props.vItems ? props.vItems.map((item, index) => {
            return (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
            )
        }) : []
    }

    onChange = (isKey, value) => {
        if (this.props.onChange) {
            let newValue = {}
            if (isKey) {
                if (this.props.kType === 'alphaNumUnderscoreInput') {
                    if (isAlphaNumUnderscore(value)) {
                        newValue = { key: value, value: this.props.vValue }
                    }
                    else {
                        return
                    }
                }
                else {
                    newValue = { key: value, value: this.props.vValue }
                }
            
            }
            else {
                newValue = { key: this.props.kValue, value: value }
            }
            this.props.onChange(newValue)
        }
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    style={{ width: '40%', marginRight :'10px' }}
                    label= {this.props.kLabel || 'key'}
                    onChange={e => this.onChange(true, e.target.value)}
                    value={this.props.kValue || ''}
            />
                    <FormControl
                        style={{ width: '40%' }}
                        className={this.props.classes.formControl}
                        key={this.props}>
                        <InputLabel htmlFor={`.formControl`}>
                        {this.props.vLabel || 'value'}</InputLabel>
                        <Select
                            value={this.props.vValue || ''}
                            key={`.textfield`}
                            onChange={e => this.onChange(false, e.target.value)}

                            inputProps={{
                                name: 'item.label',
                                id: `.formControl`,
                            }}
                        >
                            {this.valueSelectItems}
                        </Select>
                    </FormControl>
                    <IconButton onClick={() => this.props.delete(this.props.index)}
                        className={`${this.props.classes.button} ${this.props.classes.gridButton}`}>
                        <Icon>delete</Icon>
                    </IconButton>
            </div>
        )

    }

}

