import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { EditInput } from '../../../components/table'
import { TextField, Dialog, Button, DialogTitle, DialogActions, DialogContent, IconButton, Icon, Tooltip, } from '@material-ui/core'

import { EnhancedTable } from '../../../components/table'
import { withStyles } from '@material-ui/core/styles'

import * as predictionConfigActions from './actions'
import * as p from '../../../actions/permissions'
import styles from '../../../styles'

import _ from 'lodash'

export class PredictionConfigsTable extends React.Component {

    state = {
        newPredictionConfigVisible: false,
        newPredictionConfigType: 'standard',
        newPredictionConfigName: '',
        newPredictionConfigError : false,
    }
    componentWillMount() {
        if (p.permission(this.props.user, p.LIST_PREDICTIONS)) {
            this.props.getPredictionConfigs(this.props.user._links[p.PREDICTIONS].href)
        }
    }

    resetState = ()=> {
        this.setState({
            newPredictionConfigVisible: false,
            newPredictionConfigType: 'standard',
            newPredictionConfigName: '',
            newPredictionConfigError : false})
    }

    createPredictionConfig = () => {
        if (this.state.newPredictionConfigName ==='')  {
            this.setState({newPredictionConfigError:true})
        }
        else {
            this.props.createPredictionConfig(this.props.user._links[p.PREDICTIONS].href, { name: this.state.newPredictionConfigName, type: this.state.newPredictionConfigType })
            this.resetState()
        }
    }

    handleNewPredictionConfigNameChange = (e) => {

        this.setState({ newPredictionConfigName: e.target.value,
            newPredictionConfigError : e.target.value ===''})
    }

    handleNewPredictionConfigTypeChange = (e) => {
        this.setState({ newPredictionConfigType: e.target.value })
    }

    openNewPCModal = () => {
        this.setState({
            newPredictionConfigVisible: true,
        })
    }

    closeNewPCModal = () => {
        this.resetState()
    }

    render() {

        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_PREDICTION)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_PREDICTION)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_PREDICTION)
        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    return canEdit && record._embedded.code ?
                        <EditInput
                            onChange={value => (
                                this.props.updatePredictionConfig(record._links.self.href, { type :record._embedded.type, name: value, description: record._embedded.description }))
                            }
                            value={text}
                            key={record._embedded.webSafeString} >
                            {text}
                        </EditInput>
                        : text
                }
            }, {
                title: 'Type',
                dataIndex: '_embedded.type',
                key: 'type',
                render: (text) => {
                    return _.startCase(text)
                }
            }, {
                title: 'Status',
                dataIndex: '_embedded.statusMessage',
                key: 'status',
            }, {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return <React.Fragment>
                        {
                            canEdit ?
                            <Tooltip title="Edit this prediction configuration">
                                <IconButton onClick={e => {
                                    e.preventDefault()
                                    this.props.push(`configs/${record._embedded.webSafeString}`)
                                }}><Icon>create</Icon></IconButton></Tooltip>
                                : null
                        }
                        {
                            canDelete ?
                            <Tooltip title="Delete this prediction configuration">
                                <IconButton onClick={e => {
                                    e.preventDefault()
                                    this.props.deletePredictionConfig(record._links.self.href)
                                }}><Icon>delete</Icon></IconButton></Tooltip>
                                : null
                        }</React.Fragment>
                }
            }]

        const { items } = this.props
        return (
            <React.Fragment>
                <Dialog
                    title='Create a new prediction config'
                    open={this.state.newPredictionConfigVisible}
                    onClose={this.closeNewPCModal}
                    aria-labelledby="dialog-title"
                >
                    <DialogTitle id="dialog-title">New prediction config</DialogTitle>
                    <DialogContent>
                        <form className={this.props.classes.container} autoComplete="off">
                            <TextField
                            label = "Name"
                                value={this.state.newPredictionConfigName}
                                onChange={this.handleNewPredictionConfigNameChange}
                                className={this.props.classes.textField}
                                required = {true}
                                error = {this.state.newPredictionConfigError}
                            />
                            <br />
                            <br />
                            <br />

                            {/* <Typography variant="subtitle1">
                                Select the type of prediction config to be created
                    </Typography>
                            <RadioGroup
                                aria-label="Gender"
                                name="gender1"
                                className={this.props.classes.group}
                                value={this.state.newPredictionConfigType}
                                onChange={this.handleNewPredictionConfigTypeChange}
                            >
                                <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                                <FormControlLabel value="analyticsPrediction" control={<Radio />} label="Analytics Prediction" />
                            </RadioGroup> */}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNewPCModal} color="primary">Cancel</Button>
                        <Button onClick={this.createPredictionConfig} color="primary" autoFocus>OK</Button>
                    </DialogActions>

                </Dialog>
                <EnhancedTable
                    dataSource={items}
                    columns={columns}
                    rowKey={record => record._links.self.href}
                    canDelete={canDelete}
                    onDelete={e => this.props.deletePredictionConfig(e._links.self.href)}
                    canCreate={canAdd}
                    onCreate={this.openNewPCModal}
                    pagination
                />
            </React.Fragment>
        )
    }
}

PredictionConfigsTable = connect(
    state => ({
        items: Object.values(state.predictionConfigs.items),
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators({ ...predictionConfigActions, push }, dispatch)

    )
)(withStyles(styles, { withTheme: true })(PredictionConfigsTable))
