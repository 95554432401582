import React from 'react'
import { DeployedVersionsTable } from './DeployedVersionsTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import styles from '../../../styles'
import { ExecutionsTable } from './ExecutionsTable';



export class ExecutionsPage extends React.Component {

  state = {
    versionIds : []
  }
  selectedVersionsChanged = versionIds => {
    this.setState({versionIds : versionIds})
  }
  render() {
    return (
      <Paper className={this.props.classes.adminTablePaper} elevation={1}>
        <DeployedVersionsTable 
        selectionChanged = {this.selectedVersionsChanged}
        />
        <Typography variant="h5" gutterBottom component="h2" color="textSecondary">
          Version Executions
          </Typography>
          <ExecutionsTable versionIds = {this.state.versionIds}/>
      </Paper>
    )
  }
}

ExecutionsPage = withStyles(styles, { withTheme: true })(ExecutionsPage)