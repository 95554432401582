import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as experimentActions from "./actions";
import { updateAdminPageTitlePane } from "../../SolutionEditor/actions";
import styles from "../../../styles";
import { Link } from 'react-router-dom'
import { Tooltip, Button,  Paper,  Typography,  Tabs,  Tab,  FormControl,  InputLabel,  Select,  MenuItem,  Icon, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import _ from "lodash";
import { EXPERIMENTS, SOLUTIONS } from "../../../actions/permissions";
import { getSolutions } from "../Solutions/actions";
import { DataSourcesList } from "../../SolutionEditor/SolutionDiagramEditor/DataSourcesList";
import { ApplicationDataEditor } from "../../SolutionEditor/SolutionDiagramEditor/ApplicationDataEditor";
import Table from '../../../components/table/SimpleTable'
import  {isoToPrettyDate} from '../../../functions'
import { getSolutionDefinition} from '../Solutions/actions'
import * as p from '../../../actions/permissions.js'

export class ExperimentConfigEditor extends React.Component {
  state = {
    selectedTab: 0,
    fieldMapping: [],
    deleteConfirmOpen: false,
    selectedExperimentResultLocation: undefined,
  };

  getActionButtons(isValid) {
    return (
      <React.Fragment>
	  {this.state.selectedTab ===0?
        <Button
          onClick={this.save}
          className={this.props.classes.button}
          color="primary"
          key="Save"
        ><Icon>save</Icon> Save</Button>
		:<React.Fragment>
			<Button
				className={this.props.classes.button}
				disabled = {!isValid}
				onClick={ e=> {
					this.save(e)
					this.props.runExperiment(this.props.experiments[this.props.match.params.id]._links['results'].href)
				}
				}
          		color="primary"
          		key="execute"
			><Icon>slideshow</Icon>Save & Execute</Button>
			<Button
				className={this.props.classes.button}
				onClick={e=> this.props.getExperimentResults(this.props.experiments[this.props.match.params.id]._links['results'].href)}
          		color="primary"
          		key="refresh"
			><Icon>refresh</Icon> Refresh</Button>
		</React.Fragment>}
      </React.Fragment>
    );
  }

  save = () => {
    let { experimentConfig } = this.state;
    experimentConfig._embedded.solutionId = this.state.solutionId;
    experimentConfig._embedded.datasourceId = this.state.datasourceId;
    experimentConfig._embedded.fieldMapping = this.state.fieldMapping;

    this.props.updateExperimentConfig(
      experimentConfig._links.self.href,
      experimentConfig._embedded
    );
  };

  componentDidMount() {
    this.props.getSolutions(this.props.user._links[SOLUTIONS].href);
    this.props.getExperimentConfigById(
      `${this.props.user._links[EXPERIMENTS].href}/${
        this.props.match.params.id
      }`
    );
    
  }

  componentWillUnmount() {
    this.props.updateAdminPageTitlePane(null);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const experimentConfig = nextProps.experiments
      ? nextProps.experiments[this.props.match.params.id]
      : null;

    if (experimentConfig) {// && this.props.solutions[experimentConfig._embedded.solutionId]) {
      if (!this.state.experimentConfig) {
        this.setState({
          ...nextState,
          experimentConfig: experimentConfig,
          solutionId: experimentConfig._embedded.solutionId,
          datasourceId: experimentConfig._embedded.datasourceId,
          fieldMapping: experimentConfig._embedded.fieldMapping || []
        });
       // check if we have already selected a solution and then load the definition
        if (this.props.solutions[experimentConfig._embedded.solutionId]) {
          this.props.getSolutionDefinition(this.props.solutions[experimentConfig._embedded.solutionId]._links.definition.href)
        }
      return false;
      }
      const nextTitle = (
        <React.Fragment>
          <Button
            className={this.props.classes.backButton}
            onClick={() => this.props.push("/admin/experiments/configs")}
          >
            <i id="nodeIcon" className="material-icons">
              keyboard_arrow_left
            </i>
          </Button>
          {experimentConfig._embedded.name}
        </React.Fragment>
      );

      if (!nextProps.title) {
        this.props.updateAdminPageTitlePane(nextTitle);
      }
    }
    return true;
  }

  handleTabChange = (event, value) => {
	  if (value === 1) {
		this.props.getExperimentResults(this.props.experiments[this.props.match.params.id]._links['results'].href)
	  }
    this.setState({ selectedTab: value });
  };

  handleSolutionSelectionChange = e => {
    // we need to get the solution definition out too
    const solution = this.props.solutions[e.target.value]
    this.props.getSolutionDefinition(solution._links.definition.href)
    this.setState({ solutionId: e.target.value,
		  fieldMapping: [],
	  });
  };

  handleDatasourceSelectionChange = e => {
	this.setState({ datasourceId: e,
		fieldMapping: [],
 });
  };

  deleteDialogOpen = (selectedExperimentResultLocation) => {
    this.setState({
        deleteConfirmOpen: true,
        selectedExperimentResultLocation : selectedExperimentResultLocation,
    })
  }

  handleDeleteDialogClose = () => {
    this.setState({
        deleteConfirmOpen: false,
        selectedExperimentResultLocation: undefined,
    })
  }

  handleDeleteDatasource = () => {
    this.props.deleteExperimentResult(this.state.selectedExperimentResultLocation)
    this.handleDeleteDialogClose()
  }


  handleFieldUpdate = e => {

    const fieldMapping = e.map(field => {
      return {
        key: field.key,
        value: field.value,
        useDatasource: field.useDatasource
      };
    })
    this.setState({
      fieldMapping: fieldMapping
    });
  };

  render() {
    const {
      experimentConfig,
      selectedTab,
      solutionId,
      datasourceId,
      fieldMapping
    } = this.state;

    const dsFields = this.props.datasources[datasourceId]
      ? this.props.datasources[datasourceId]._embedded.selectedColumns
      : [];

    const solution = solutionId ? this.props.solutions[solutionId] : null;
    const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_EXPERIMENT_RESULT)
    const canReadResult = _.includes(this.props.user._embedded.permissions, p.READ_EXPERIMENT_RESULT)
    const solutionValues = _.map(this.props.solutions, i => (
      <MenuItem
        key={i._embedded.webSafeString}
        value={i._embedded.webSafeString}
      >
        {i._embedded.name}
      </MenuItem>
	));
	
    const isValid = solution && solution.definition && dsFields && fieldMapping && solution.definition.validRuntime   

    const renderConfig = () => {
      return (
        <div className = {this.props.classes.experimentConfigTab}>
          <Paper className={this.props.classes.adminTablePaper} elevation={1}>
            <Typography variant="h5" gutterBottom>
              Solution
            </Typography>
            <Typography variant="body1">
              Select a Solution to be used for this experiment.
            </Typography>
            <FormControl
              style={{ width: "100%" }}
              className={` ${this.props.classes.formControl} ${
                this.props.classes.componentDetailsTextField
              }`}
              key={this.props}
            >
              <InputLabel htmlFor={`.formControl`}>
                {this.props.label}
              </InputLabel>
              <Select
                value={solutionId || ""}
                key={`.textfield`}
                onChange={this.handleSolutionSelectionChange}
                inputProps={{
                  name: "item.label",
                  id: `.formControl`
                }}
              >
                {solutionValues}
              </Select>
              <br />
            </FormControl>
            {solution ? (
              isValid? (
                <Typography variant="body1">The solution definition is valid</Typography>
              ) : (
                <Typography variant="body1">
                  <b>The solution definition is not valid. </b>Only valid
                  solutions can be used for experiments
                </Typography>
              )
            ) : null}
          </Paper>
          <Paper className={this.props.classes.adminTablePaper} elevation={1}>
            <Typography variant="h5" gutterBottom>
              Datasource
            </Typography>
            <Typography variant="body1">
                Select the datasource that contains the application data for
                this experiment.
            </Typography>
            <DataSourcesList
              value={datasourceId}
              label={"Select a datasource"}
              onChange={this.handleDatasourceSelectionChange}
            />
            <Typography variant="body1">
            {datasourceId && this.props.datasources && this.props.datasources[datasourceId]?
            this.props.datasources[datasourceId]._embedded.recordCount>0 ? `This datasource contains ${this.props.datasources[datasourceId]._embedded.recordCount} records` :''
            :null}
            </Typography>

            <Typography variant="body1">
            {datasourceId && this.props.datasources && this.props.datasources[datasourceId] && this.props.datasources[datasourceId]._embedded.recordCount>500
             ? <b>Only the first 1000 records of this datasource will be used for the experiment</b> :null}
            
            </Typography>

          </Paper>
          {datasourceId && solutionId ? (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
              <Typography variant="h5" gutterBottom>
                Field mapping
              </Typography>
              <Typography variant="body1">
                  Each application data field of the solution need to be mapped
                  to a field in the datasource, or to a literal
              </Typography>
              <ApplicationDataEditor
                values={fieldMapping}
                id={solutionId}
                pagination={false}
                onUpdate={this.handleFieldUpdate}
                dsFields={dsFields}
              />
            </Paper>
          ) : null}
        </div>
      );
    };

    const renderExecutions = () => {
		const columns = [
			{
                title: 'Time',
                dataIndex: '_embedded.created',
				key: '_embedded.created',
				render : e=>{return isoToPrettyDate(e)}
            },
			{
                title: 'Status',
                dataIndex: '_embedded.status',
				key: '_embedded.status',
            },
			{
                title: 'Executions',
                dataIndex: '_embedded.resultsCount',
				key: '_embedded.resultsCount',
				align : 'right',
            },
			{
                title: 'Actions',
                dataIndex: 'actions',
				key: 'actions',
				render : (value, row) => {
          return (
          <React.Fragment>
          {canReadResult ?
            <Tooltip title="Open in experiment visualiser" >
            <IconButton>
              <Link aria-label="Open in experiment visualiser"
                    to={{ pathname: `/experiments/result/${row._embedded.webSafeString}` }}
                    target={row._embedded.webSafeString}>
                <Icon>assessment</Icon>
              </Link>
              </IconButton>
              </Tooltip>
           : null}
            {canDelete ?
              <Tooltip title="Delete results" >
                <IconButton aria-label="Delete results" onClick={()=>this.deleteDialogOpen(row._links.self.href)}><Icon>delete</Icon></IconButton>
              </Tooltip>
                : null}
          </React.Fragment>
        )
            },
      }
		]

      return (
        <Paper className={this.props.classes.adminTablePaper} elevation={1}>
          <Typography variant="h5" gutterBottom>
            Config 1
          </Typography>
		  <Table
                pagination={this.props.pagination}
				rowKey = {item=> item._embedded.webSafeString}
                className={this.props.className}
                dataSource={Object.values(this.props.experiments[this.props.match.params.id].results ||{})}
                columns={columns}/>
        </Paper>
      );
    };
    return (
      <React.Fragment>
                <Dialog
                    open={this.state.deleteConfirmOpen}
                    onClose={this.handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='xs'
                >
                    <DialogTitle id="alert-dialog-title">{"Delete experiment results?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Would you like to delete these experiment results?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteDialogClose} color="primary">Cancel</Button>
                        <Button onClick={this.handleDeleteDatasource} color="primary" autoFocus>OK</Button>
                    </DialogActions>
                </Dialog>



        {this.getActionButtons(isValid)}

        <Tabs
          value={selectedTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Configuration" />
          <Tab label="Execution" />
        </Tabs>
        {experimentConfig ? (
          <div style={{ height: "100%" }}>
            {selectedTab === 0 && renderConfig()}
            {selectedTab === 1 && renderExecutions()}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

ExperimentConfigEditor = connect(
  (state, props) => ({
    experiments: state.experiments.items,
    solutions: state.solutions.present.items || [],
    user: state.user.profile,
    datasources: state.datasources.items,
    title: state.adminPage.title
  }),
  dispatch =>
    bindActionCreators(
      { push, updateAdminPageTitlePane, ...experimentActions, getSolutions, getSolutionDefinition },
      dispatch
    )
)(withStyles(styles, { withTheme: true })(ExperimentConfigEditor));
