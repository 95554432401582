import React from 'react'
import { OutputsItem} from './OutputsItem'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import { Typography } from '@material-ui/core';

export class OutputsList extends React.Component {


    add = (e) =>{
        const newValues = [...this.props.values, {datasetType:'application', fieldName: '', param:''}]
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    delete = (index) =>{
        let newValues = this.props.values
        newValues.splice(index, 1)
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    onChange (index, field, value) {
        let newValues = this.props.values
        newValues[index]= {...this.props.values[index], [field] : value}
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    render() {
        return (
            <div> <Typography variant="body1">{this.props.label}</Typography>
            <div style = {{width: '100%', marginBottom :'5px'}}>
                {this.props.values.map((value, index) => {
                return (<OutputsItem 
                    key = {index}
                    style = {{width: '90%'}}
                    {...this.props}
                    index = {index}
                    value = {value}
                    delete = {this.delete}
                    onChange = {(field, value) =>this.onChange(index, field, value)}
                    />
                )  
                }) }

            </div>
                <Fab onClick={this.add} 
                className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                color="primary">
                <Icon>add</Icon>
                </Fab >
            </div>
        )
    }
}

