import React from 'react'
import { uuid, isInteger } from '../../../functions'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

export class ScoreTable extends React.Component {

    addScoreItem = (e) => {
        const values = this.props.values ? this.props.values : []
        const count =  values.length

        const rangeFrom = count > 0 ? values[count - 1].rangeTo : ''

        const newData = {
            rangeFromIncludeExclude: '[',
            rangeFrom: rangeFrom,
            rangeTo: '',
            rangeToIncludeExclude: ')',
            score: '',
            reasonCode: '',
            id: uuid(),
        };
        if (this.props.onChange) {
            this.props.onChange([...values, newData])
        }

    }

    removeScoreItem = (index) => {
        let values = this.props.values ? this.props.values : []
        values.splice(index, 1)
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }

    handleNumberChange = (e, index, field) => {
        const { value } = e.target;
        if (isInteger(value)) {
            this.handleValueChange(e, index, field)
        }

    }
    handleValueChange = (e, index, field) => {
        let values = this.props.values ? this.props.values : []
        values[index][field] = e.target.value
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }


    render() {
        const values = this.props.values ?this.props.values : []
        return (
            <div>
                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='none'></TableCell>
                            <TableCell padding='none'>From</TableCell>
                            <TableCell padding='none'>To</TableCell>
                            <TableCell padding='none'></TableCell>
                            <TableCell padding='none'>Points</TableCell>
                            {/* <TableCell padding='none'>Reason code</TableCell> */}
                            <TableCell padding='none'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((row, index) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row" padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Select classes={{ select: this.props.classes.scoreCardNarrowSelect }}
                                            style={{ width: '100%' }}
                                            key={`.textfield`}
                                            value={row.rangeFromIncludeExclude}
                                            onChange={(e) => this.handleValueChange(e, index, 'rangeFromIncludeExclude')}

                                            inputProps={{
                                                name: 'item.label',
                                                id: 'conditionalOperator',
                                            }}>
                                            <MenuItem classes={{ root: 'rootMenuItemTest' }} key={'['} value={'['}>[</MenuItem>
                                            <MenuItem classes={{ root: 'rootMenuItemTest', select: 'rootCellContentTest' }} key={'('} value={'('}>(</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align='right' padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Input value={row.rangeFrom} onChange={(e) => this.handleNumberChange(e, index, 'rangeFrom')} style={{ width: '100%' }} />
                                    </TableCell>
                                    <TableCell align='right' padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Input value={row.rangeTo} onChange={(e) => this.handleNumberChange(e, index, 'rangeTo')} style={{ width: '100%' }} />
                                    </TableCell>
                                    <TableCell component="th" scope="row" padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Select classes={{ select: this.props.classes.scoreCardNarrowSelect }}
                                            style={{ width: '100%' }}
                                            key={`.textfield`}
                                            value={row.rangeToIncludeExclude}
                                            onChange={(e) => this.handleValueChange(e, index, 'rangeToIncludeExclude')}

                                            inputProps={{
                                                name: 'item.label',
                                                id: 'conditionalOperator',
                                            }}>
                                            <MenuItem key={']'} value={']'}>]</MenuItem>
                                            <MenuItem key={')'} value={')'}>)</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align='right' padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Input value={row.score} onChange={(e) => this.handleNumberChange(e, index, 'score')} style={{ width: '100%' }} />
                                    </TableCell>
                                    {/* <TableCell padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <Input value={row.reasonCode} onChange={(e) => this.handleValueChange(e, index, 'reasonCode')} style={{ width: '100%' }} />
                                    </TableCell> */}
                                    <TableCell padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                        <IconButton onClick={() => this.removeScoreItem(index)}
                                            className={`${this.props.classes.button} ${this.props.classes.gridButton}`}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}


                    </TableBody>
                </Table>

                <Fab onClick={this.addScoreItem}
                    className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                    color="primary">
                    <Icon>add</Icon>
                </Fab >

            </div>
        )
    }
}