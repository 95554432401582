import React from 'react'
import { connect } from 'react-redux'
import { uuid } from '../../../functions'
import { Fab, Checkbox, Input, IconButton, Icon, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import { TypeSelect } from '../../../components/table/EditSelect'
import styles from '../../../styles'

export class DatafieldsTable extends React.Component {


    constructor(props) {
        super(props)
        const values = props.datasource || []
        this.state = { values: values }
    }

    removeItem(index) {
        let values = this.state.values
        values.splice(index, 1)
        this.setState({
            values: values,
        })
        if (this.props.onUpdate) {
            this.props.onUpdate(values)
        }

    }

    addItem =() => {
        const newItem = {
            name: '',
            type: 'String',
            required: true,
            key: uuid(),
        }

        const newValues = [...this.state.values, newItem]
        this.setState({
            values: newValues,
        })
        if (this.props.onUpdate) {
            this.props.onUpdate(newValues)
        }


    }
    handleValueChange = (newValue, index, field) => {
        let values = this.state.values
        values[index][field] = newValue
        this.setState({ values: values })
        if (this.props.onUpdate) {
            this.props.onUpdate(values)
        }
    }


    render() {
        const values = this.state.values
        return (
            <React.Fragment>
                <div style={this.props.style} className={this.props.className}>
                    <Table className={this.props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='none'>Name</TableCell>
                                <TableCell padding='none'>Field type</TableCell>
                                <TableCell padding='none'>Required</TableCell>
                                <TableCell padding='none' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.map((row, index) => {
                                return (
                                    <TableRow key={row.key}>
                                        <TableCell classes={{ root: this.props.classes.scoreCardCellRoot }} padding='none'>
                                            <Input value={row.name} onChange={(e) => this.handleValueChange(e.target.value, index, 'name')} style={{ width: '100%' }} />
                                        </TableCell>

                                        <TableCell classes={{ root: this.props.classes.scoreCardCellRoot }} padding='none'>
                                            <TypeSelect classes={{ select: this.props.classes.scoreCardNarrowSelect }} value={row.type} onChange={(newValue) => this.handleValueChange(newValue, index, 'type')} >

                                            </TypeSelect>

                                        </TableCell>
                                        <TableCell classes={{ root: this.props.classes.scoreCardCellRoot }} padding='none'>
                                            <Checkbox

                                                onChange={(e) => this.handleValueChange(e.target.checked, index, 'required')}
                                                checked={row.required === true}

                                            />
                                        </TableCell>
                                        <TableCell classes={{ root: this.props.classes.scoreCardCellRoot }} padding='none'>
                                            <IconButton onClick={() => this.removeItem(index)}
                                                className={`${this.props.classes.button} ${this.props.classes.gridButton}`}>
                                                <Icon>delete</Icon>
                                            </IconButton >
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>

                </div>
                <Fab onClick={this.addItem}
                    className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                    color="primary">
                    <Icon>add</Icon>
                </Fab >
            </React.Fragment>
        )
    }
}

export const ApplicationDatasetFields = connect(
    (state, props) => ({
        datasource: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.applicationDatasetFields
    })
)(withStyles(styles, { withTheme: true })(DatafieldsTable))

export const RuntimeDatasetFields = connect(
    (state, props) => ({
        datasource: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.runtimeDatasetFields
    })
)(withStyles(styles, { withTheme: true })(DatafieldsTable))

