import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import { DatasourceButtons } from './DatasourceButtons'

import _ from 'lodash'
import { isInteger, isFloat } from '../../../functions';
import { Typography } from '@material-ui/core';

const LEFT = 'leftHandFieldname'
const RIGHT = 'rightHandFieldname'

export class OperationFieldSelector extends React.Component {

    findDatasourceField(datsources, datasourceName, key) {
        const datasource = datsources.find(i => i.name === datasourceName)
        const field = datasource ? datasource.datasource.find(i => i.key === key) : null
        return field
    }

    onOptionChange(e, side) {
        const newValue = e.target.value
        const { value } = this.props
        side === RIGHT ?
            this.handleChange({
                rightHandDatasource: newValue,
                rightHandFieldname: newValue === 'Literal' ? '' : value.rightHandFieldname
            }) : this.handleChange({
                leftHandDatasource: newValue
            })
    }

    handleConditionalOperatorChange = (e) => {
        this.handleChange({
            conditionalOperator: e.target.value
        })
    }
    handleOperatorChange = (e) => {
        this.handleChange({
            operator: e.target.value
        })
    }

    handleFieldChange = (e, side) => {
        const key = e.target.value
        const { value } = this.props
        if (side === RIGHT) {
            this.handleChange({ 
                rightHandFieldname: key
            })
        }
        else {
            const newField = this.findDatasourceField(this.props.leftHandDatasources, value.leftHandDatasource, key)
            this.handleChange({
                leftHandType: newField.type,
                leftHandFieldname: key
            })
        }
    }

    handleLiteralChange = (type, e, side) => {
        const value = e.target.value
        if (type === 'String' || (type === 'Integer' && isInteger(value)) || (type === 'Float' && isFloat(value))) {
            this.handleFieldChange(e, side)
        }
    }


    handleChange = (change) => {
        const { onChange, value, leftHandDatasources, rightHandDatasources } = this.props
        const leftHandField = this.findDatasourceField(leftHandDatasources, value.leftHandDatasource, value.leftHandFieldname)
        const newValue = {
            conditionalOperator: value.conditionalOperator || '&&',
            leftHandDatasource: value.leftHandDatasource || leftHandDatasources[0].name,
            leftHandFieldname : value.leftHandFieldname ? value.leftHandFieldname : '',
            leftHandType: leftHandField ? leftHandField.type : 'String',
            operator: value.operator || '==',
            rightHandDatasource: value.rightHandDatasource || rightHandDatasources[0].name,
            rightHandFieldname: value.rightHandFieldname || '',
            ...change
        }
        onChange && onChange(this.props.index, newValue)
    }

    render() {
        const { classes, value, index, leftHandDatasources, rightHandDatasources, listOps } = this.props
        const leftHandField = this.findDatasourceField(leftHandDatasources, value.leftHandDatasource, value.leftHandFieldname)

        let rightHandFieldList = value.rightHandDatasource ?
            _.find(rightHandDatasources, { 'name': value.rightHandDatasource }).datasource
            : rightHandDatasources[0].datasource

        const leftHandFieldList = value.leftHandDatasource ?
            _.find(leftHandDatasources, { 'name': value.leftHandDatasource }).datasource
            : leftHandDatasources[0].datasource

        const conditionalOperator = value.conditionalOperator || '&&'
        const leftHandDatasource = value.leftHandDatasource || leftHandDatasources[0].name
        const leftHandFieldname = leftHandField ? leftHandField.name : ''
        const leftHandType = leftHandField ? leftHandField.type : 'String'
        const operator = value.operator || '=='

        const rightHandDatasource = value.rightHandDatasource || rightHandDatasources[0].name
        const rightHandField = rightHandDatasource.toLowerCase()!=='literal' ? 
            this.findDatasourceField(rightHandDatasources, value.rightHandDatasource, value.rightHandFieldname)
            : null


        
        if (rightHandDatasource.toLowerCase()!=='literal' && leftHandType && leftHandType === 'Integer') {
             rightHandFieldList = rightHandFieldList.filter(field=>field.type==='Integer')
        }
        if (rightHandDatasource.toLowerCase()!=='literal' && leftHandType && leftHandType === 'Float') {
            rightHandFieldList = rightHandFieldList.filter(field=>field.type!=='String')
        }

        const rightHandFieldname = rightHandDatasource.toLowerCase()!=='literal' ? rightHandField? rightHandField.name : '' : value.rightHandFieldname

        const tooltip = (<div >
            {index > 0 && listOps ? conditionalOperator === '&&' ? 'AND ' : 'OR ' : null}
            {leftHandFieldname ? `${leftHandDatasource}.${leftHandFieldname} ${operator}` : ''}
            {rightHandFieldname ? ` ${rightHandDatasource}.${rightHandFieldname}` : ''}
        </div>)

        const summaryValue = (<React.Fragment>
        
            {index > 0 && listOps ? conditionalOperator === '&&' ? 'AND ' : 'OR ' : null}
            {leftHandFieldname ? `${leftHandFieldname} ${operator}` : ''}
            {rightHandFieldname ? ` ${rightHandFieldname}` : ''}
        </React.Fragment>)

        let operators =  this.props.ops.map(o =>
            <MenuItem {...o}>{o.label}</MenuItem>)
        if (leftHandType.toLowerCase()==='string' ) {
            operators = this.props.ops.filter(oper=> {return oper.value==='==' || oper.value==='!=' || oper.value==='=' }).map(o =>
                <MenuItem {...o}>{o.label}</MenuItem>)
        }
    
        return (
            <div key={index} >
                <ExpansionPanel expanded={this.props.expanded === `panel.${index}`} onChange={this.props.onExpand(`panel.${index}`)}>
                    <ExpansionPanelSummary classes={
                        {
                            root: classes.fieldSelectorSummmaryRoot,
                            expanded: classes.fieldSelectorSummmaryExpanded,
                            content: classes.fieldSelectorSummmaryContent,
                        }}
                        expandIcon={<Icon>expand_more</Icon>}>
                        <Tooltip title={tooltip}> 
                            <Typography variant="body1" >{summaryValue}</Typography>
                        </Tooltip>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{ root: classes.fieldSelectorItem }}>
                        <Grid container spacing={8}>
                            {index > 0 && this.props.listOps ?
                                <Grid item xs={12}>
                                    <RadioGroup
                                        className='testNow'
                                        value={conditionalOperator}
                                        onChange={this.handleConditionalOperatorChange}
                                    >{this.props.listOps.map(l =>
                                        <FormControlLabel {...l} control={<Radio />} />
                                    )}
                                    </RadioGroup>
                                </Grid>
                                : null
                            }
                            <Grid item xs={5}>
                                <DatasourceButtons
                                    value={leftHandDatasource} classes={classes} onChange={e => this.onOptionChange(e, LEFT)} datasources={leftHandDatasources} />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={5}>
                                <DatasourceButtons
                                    value={rightHandDatasource} classes={classes} onChange={e => this.onOptionChange(e, RIGHT)} datasources={rightHandDatasources} />
                            </Grid>
                            <Grid item xs={1} >
                                <IconButton onClick={() => this.props.onDelete(this.props.index)}
                                    className={`${classes.button} ${classes.gridButton}`}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl className={classes.formControl}
                                    style={{ width: '100%' }}
                                >
                                    <InputLabel htmlFor='leftHandFieldname' />
                                    <Select
                                        style={{ width: '100%' }}
                                        value={value.leftHandFieldname ||''}
                                        key={`.textfield`}
                                        onChange={(e) => this.handleFieldChange(e, LEFT)}

                                        inputProps={{
                                            name: 'item.label',
                                            id: 'leftHandFieldname',
                                        }}
                                    >
                                        {leftHandFieldList.map(d => <MenuItem key={d.key} value={d.key}>{d.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                {this.props.ops && this.props.ops.length === 1 ?
                                    <FormControl className={this.props.classes.formControl}
                                        style={{ width: '100%' }}
                                    >
                                    <Typography variant="body1">{this.props.ops[0].label}</Typography>
                                        {/* <p style={{ marginTop: '20px', alignSelf: 'center' }}>{this.props.ops[0].label}</p> */}
                                    </FormControl>
                                    :
                                    <FormControl className={classes.formControl}
                                        style={{ width: '100%' }}
                                    >
                                        <InputLabel htmlFor='operator' />
                                        <Select
                                            style={{ width: '100%' }}
                                            value={operator}
                                            key={`.textfield`}
                                            onChange={this.handleOperatorChange}

                                            inputProps={{
                                                name: 'item.label',
                                                id: 'operator',
                                            }}
                                        >{operators}
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={5}>
                                {rightHandFieldList ?
                                    <FormControl className={classes.formControl}
                                        style={{ width: '100%' }}
                                    >
                                        <InputLabel htmlFor='rightHandFieldname' />
                                        <Select
                                            style={{ width: '100%' }}
                                            value={value.rightHandFieldname ||''}
                                            key={`.textfield`}
                                            onChange={(e) => this.handleFieldChange(e, RIGHT)}

                                            inputProps={{
                                                name: 'item.label',
                                                id: 'rightHandFieldname',
                                            }}
                                        >
                                            {rightHandFieldList.map(d => <MenuItem key={d.key} value={d.key}>{d.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    :
                                    <TextField
                                        label='Value'
                                        className={classes.formControl}
                                        style={{ width: '100%' }}
                                        value={value.rightHandFieldname}
                                        key={`.textfield`}
                                        onChange={(e) => this.handleLiteralChange(leftHandType, e, RIGHT)} />
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div >
        )
    }
}

