import { get, put, post, del } from '../../../actions'

export const GET_ROLES_REQUEST = '@@roles/GET_ROLES_REQUEST'
export const GET_ROLES_SUCCESS = '@@roles/GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = '@@roles/GET_ROLES_FAILURE'
export const getRoles = (endpoint) => (
    get({
        displayName: 'get roles',
        endpoint: endpoint,
        types: [
            GET_ROLES_REQUEST,
            GET_ROLES_SUCCESS,
            GET_ROLES_FAILURE],
    })
)

export const UPDATE_ROLE_REQUEST = '@@roles/UPDATE_ROLE_REQUEST'
export const UPDATE_ROLE_SUCCESS = '@@roles/UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILURE = '@@roles/UPDATE_ROLE_FAILURE'
export const updateRole = (endpoint, role) => (
    put({
        displayName: 'update role',
        endpoint: endpoint,
        types: [
            UPDATE_ROLE_REQUEST,
            UPDATE_ROLE_SUCCESS,
            UPDATE_ROLE_FAILURE,
        ],
        body: JSON.stringify(role)
    })
)

const defaultRole = {
    name: 'New Role',
    permissions: []
}

export const CREATE_ROLE_REQUEST = '@@roles/CREATE_ROLE_REQUEST'
export const CREATE_ROLE_SUCCESS = '@@roles/CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILURE = '@@roles/CREATE_ROLE_FAILURE'
export const createRole = (endpoint, role = defaultRole) => (
    post({
        displayName: 'create role',
        endpoint: endpoint,
        types: [
            CREATE_ROLE_REQUEST,
            CREATE_ROLE_SUCCESS,
            CREATE_ROLE_FAILURE,
        ],
        body: JSON.stringify(role)
    })
)

export const DELETE_ROLE_REQUEST = '@@roles/DELETE_ROLE_REQUEST'
export const DELETE_ROLE_SUCCESS = '@@roles/DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = '@@roles/DELETE_ROLE_FAILURE'
export const deleteRole = (endpoint) => (
    del({
        displayName: 'delete role',
        endpoint: endpoint,
        types: [
            DELETE_ROLE_REQUEST,
            DELETE_ROLE_SUCCESS,
            DELETE_ROLE_FAILURE,
        ],
    })
)

export const GET_PERMISSIONS_REQUEST = '@@permissions/GET_PERMISSIONS_REQUEST'
export const GET_PERMISSIONS_SUCCESS = '@@permissions/GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSIONS_FAILURE = '@@permissions/GET_PERMISSIONS_FAILURE'
export const getPermissions = (endpoint) => (
    get({
        displayName: 'get permissions',
        endpoint: endpoint,
        types: [
            GET_PERMISSIONS_REQUEST,
            GET_PERMISSIONS_SUCCESS,
            GET_PERMISSIONS_FAILURE
        ]
    })
)
