import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import withStyles from '@material-ui/core/styles/withStyles'
import { Tooltip, Button, Icon, Paper, Tab, Tabs } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { Typography } from '@material-ui/core'

import styles from '../../../styles'

import classNames from 'classnames'

import 'react-resizable/css/styles.css';

import { ComponentPalette } from './ComponentPalette'
import { ExecutionDataView } from './ExecutionDataView'
import { ApplicationDataEditor } from './ApplicationDataEditor'
import { RuntimeView } from './RuntimeView'

import * as actions from './actions'
import * as solutionActions from '../../Admin/Solutions'

import { updateAppDataFields } from '../../Admin/Solutions/actions'
import { notifyUserMessage} from '../../../actions/messages'
import { uuid } from '../../../functions'

import { ActionCreators }   from 'redux-undo';
import  SolutionDiagram from './Diagram/SolutionDiagram'
import _ from 'lodash'
/**
 * ComponentPalette
 * ApplicationDataEditor
 * SolutionDiagram
 * Tab
 * 	-> ComponentDetails
 * 	-> DataFields (App)
 * 	-> DataFields (runtime)
 */
export class SolutionDiagramEditor extends React.PureComponent {

	PASTE_OFFSET = 40

	constructor(props) {
		super(props)
		this.SOLUTION_DIAGRAM_REF = React.createRef()
		const { theme } = props

		const height = this.calcHeight(theme)

		this.state = {
			height: height,

			tab1Index: 0,
			buttonMenuVisible: false,
			deleteDialogOpen: false,
			deleteDialogText: [],
			pasteOffset: this.PASTE_OFFSET,
		}

	}

	componentDidMount() {
		this.props.initSolutionEditor(this.props.id)
	}

	componentWillUnmount() {
		this.props.quitSolutionEditor(this.props.id)
	}

	handleTab1Change = (e, v) => {
		this.setState({
			tab1Index: v
		})
	}

	calcHeight(theme) {
		return window.innerHeight - theme.spacing.unit * 20
	}

	handleSelectComponent = (componentId) => {
		this.SOLUTION_DIAGRAM_REF.current.props.selectNode(componentId)
	}
	handleMouseUp = (event) => {
		if (event.nativeEvent.button !== 2) {
			this.setState({
				buttonMenuVisible: false
			})
		}
	}

	handleContextMenu = (event) => {
		event.stopPropagation()
		event.preventDefault();
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenW = window.innerWidth;
		const screenH = window.innerHeight;
		const rootW = 0 //this.root.offsetWidth;
		const rootH = 0// this.root.offsetHeight;

		const right = (screenW - clickX) > rootW;
		const left = !right;
		const top = (screenH - clickY) > rootH;
		const bottom = !top;
		let buttonMenuLeft, buttonMenuTop = 0
		if (right) {
			buttonMenuLeft = `${clickX + 5}px`;
		}

		if (left) {
			buttonMenuLeft = `${clickX - rootW - 5}px`;
		}

		if (top) {
			buttonMenuTop = `${clickY + 5}px`;
		}

		if (bottom) {
			buttonMenuTop = `${clickY - rootH - 5}px`;
		}
		this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
		this.setState({
			buttonMenuVisible: true,
			buttonMenuTop: buttonMenuTop,
			buttonMenuLeft: buttonMenuLeft
		})
	}

	getNames = (items) => {
		let text = []
		items.nodes.forEach(item => {
			text.push(`Node:  ${item.name}:${item.label}`)
		})
		items.links.forEach(item => {
			text.push(`Link:  ${this.SOLUTION_DIAGRAM_REF.current.props.getNameForLink(item.id)}`)
		})
		return text
	}

	handleKeyDown = (event) =>{
		if (event.key === 'Delete') {
			event.stopPropagation() 
			event.preventDefault()
			if (event.getModifierState("Shift")) {
				this.handleContextMenuClick(event, "deleteNow")
			}
			else {
				this.handleContextMenuClick(event, "delete")
			}
		}

		if (event.ctrlKey && event.key === 'z') {
			event.stopPropagation() 
			event.preventDefault()
			this.handleContextMenuClick(event, "undo")
		}
		if (event.ctrlKey && event.key === 'y') {
			event.stopPropagation() 
			event.preventDefault()
			this.handleContextMenuClick(event, "redo")
		}
	}


	handleContextMenuClick(event, button) {
		switch (button) {
			case "cut":
				break
			case "undo":
				this.props.undo()
				break
			case "redo":
				this.props.redo()
				break
			case "copy":
				this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
				this.copyItems(this.SOLUTION_DIAGRAM_REF.current.props.getSelectedItems())
				break
			case "paste":
				this.pasteItems()
				break
			case "zoom_in":
				this.SOLUTION_DIAGRAM_REF.current.props.zoomIn()
				break;
			case "zoom_out":
				this.SOLUTION_DIAGRAM_REF.current.props.zoomOut()
				break;
			case "delete":
				this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
				const selectedItems = this.SOLUTION_DIAGRAM_REF.current.props.getSelectedItems()
				if ((selectedItems.links.length + selectedItems.nodes.length) > 0) {
					this.setState({
						deleteItems: selectedItems,
						deleteDialogOpen: true,
						deleteDialogText: this.getNames(selectedItems)

					})
				}
				break;
			case "deleteNow":
				this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
				this.deleteItems(this.SOLUTION_DIAGRAM_REF.current.props.getSelectedItems())
				break
			default:
		}

		this.setState({
			buttonMenuVisible: false,
		})
	}

	deleteItems = (items) => {
		items.nodes.forEach(item => {
			this.props.deleteComponent(this.props.id, item)
		})
		items.links.forEach(item => {
			this.props.deleteComponent(this.props.id, item)
		})
	}

	copyItems = (items) => {
		if ((items.links.length + items.nodes.length) > 0) {
			navigator.clipboard.writeText(JSON.stringify(items)).then((message, err) => {
				if (err) {
					this.props.notifyUserMessage('Oops, '`Can't copy to clipboard (${err}`)
				}
				else {
					this.props.notifyUserMessage("yeah", 'Selection copied to clipboard')
					this.setState({ pasteOffset: this.PASTE_OFFSET })
				}
			})
		}
	}

	replaceIds = (val) => {
		const offset = this.state.pasteOffset || this.PASTE_OFFSET
		const nodeIds = val.nodes.map(node => { return { oldId: node.id, newId: uuid() } })
		const linkIds = val.links.map(link => { return { oldId: link.id, newId: uuid() } })
		// port should only be included if they appear in both the link and the node
		const portIds = []
		val.nodes.forEach(node => {
			node.ports.forEach(port => portIds.push({ oldId: port.id, newId: uuid() }))
		})

		const findNewId = (mapping, oldId) => {
			const item = _.find(mapping, node => node.oldId === oldId)
			return item ? item.newId : null
		}
		// clean the links, 
		val.links = val.links.map(link => {
			link.source = findNewId(nodeIds, link.source)
			link.target = findNewId(nodeIds, link.target)
			link.sourcePort = findNewId(portIds, link.sourcePort)
			link.targetPort = findNewId(portIds, link.targetPort)
			link.id = findNewId(linkIds, link.id)
			link.points = link.points.map(point => {
				point.x = point.x + offset
				point.y = point.y + offset
				point.id = uuid()
				return point;
			})
			return link
		})

		// clean the nodes
		val.nodes = val.nodes.map(node => {
			node.id = findNewId(nodeIds, node.id)
			node.x = node.x + offset
			node.y = node.y + offset

			node.ports = node.ports.map(port => {
				port.id = findNewId(portIds, port.id)
				port.name = port.id
				port.parentNode = findNewId(nodeIds, port.parentNode)
				let newLinks = []
				port.links.forEach(linkId => {
					const newId = findNewId(linkIds, linkId)
					if (newId) {
						newLinks.push(newId)
					}
				})
				port.links = newLinks
				return port
			})
			return node
		})
		return val
	}
	pasteItems = async () => {
		try {
			// await this.checkClipBoardPermissions()
			navigator.clipboard.readText().then((text, err) => {
				if (err) {
					this.props.notifyUserMessage("Oops", "Unable to paste " +err)
				}
				else {
					try {
						let val = JSON.parse(text)
						// replace the Ids
						val = this.replaceIds(val)
						this.SOLUTION_DIAGRAM_REF.current.props.insertItems(val)
						this.setState({ pasteOffset: this.state.pasteOffset + this.PASTE_OFFSET })
					}
					catch (e) {
						this.props.notifyUserMessage("Oops", "No solution content to be pasted")
					}
				}
			})
		}
		catch (ex) {
			this.props.notifyUserMessage("Oops", "No access to the clipboard allowed.")
			console.log("We can't paste", ex)
		}
	}

	render() {
		const { tab1Index } = this.state
		const { classes } = this.props

        const canSave =  _.includes(this.props.permissions, 'solution.update')
        const canExecute =  _.includes(this.props.permissions, 'solution.execute')


		const selectedCount = (this.state.buttonMenuVisible ||this.state.deleteDialogOpen)?this.SOLUTION_DIAGRAM_REF.current.props.getSelectedItemCount():0
		const buttonMenu = <Paper className={classNames([classes.menuPaper])}
			style={{ display: this.state.buttonMenuVisible ? 'flex' : 'none', zIndex: 1300, position: 'absolute', top: this.state.buttonMenuTop, left: this.state.buttonMenuLeft }} >
			{/* <Button style={{ display: selectedCount > 0 ? 'flex' : 'none' }} className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "cut", selectedItems)}>
				<Tooltip title="Cut selection"><Icon className={classes.rightIcon}>content_cut</Icon></Tooltip>
			</Button> */}
			<Button style={{ display: selectedCount > 0 ? 'flex' : 'none' }} className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "copy")}>
				<Tooltip title="Copy selection"><Icon className={classes.rightIcon}>content_copy</Icon></Tooltip>
			</Button>
			<Button className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "paste")}>
				<Tooltip title="Paste items"><Icon className={classes.rightIcon}>content_paste</Icon></Tooltip>
			</Button>
			<Button className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "zoom_out")}>
				<Tooltip title="Zoom out"><Icon className={classes.rightIcon}>zoom_out</Icon></Tooltip>
			</Button>
			<Button className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "zoom_in")}>
				<Tooltip title="Zoom in"><Icon className={classes.rightIcon}>zoom_in</Icon></Tooltip>
			</Button>
			<Button style={{ display: selectedCount > 0 ? 'flex' : 'none' }} className={classes.contextMenuButton} onClick={e => this.handleContextMenuClick(e, "delete")}>
				<Tooltip title="Delete selection"><Icon className={classes.rightIcon}>delete</Icon></Tooltip>
			</Button>
		</Paper>
		return (
			<React.Fragment>
			{canSave? 
				<Button
					onClick={e => {
						this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
						this.props.saveDiagram(this.props.id) }
					}
					className={classes.button}
					color='primary'
				>
					Save
				</Button> :null}
				{canExecute ?
				<Button
					disabled={!this.props.canExec }
					onClick={e => {
						this.SOLUTION_DIAGRAM_REF.current.props.diagramChanged()
						this.props.executeDiagram(this.props.id) }
					}
					className={classes.button}
					color='primary'
				>
					Execute
				</Button> : null}
				<Button
					disabled={!this.props.canUndo}
					onClick={e => this.handleContextMenuClick(e, "undo")}
					className={classes.solutionEditorIconButton}
					color='primary'
				>
					<Tooltip title="Undo"><Icon>undo</Icon></Tooltip>
				</Button>
				<Button
					disabled={!this.props.canRedo}
					onClick={e => this.handleContextMenuClick(e, "redo")}
					className={classes.solutionEditorIconButton}
					color='primary'
				>
				   	<Tooltip title="Redo"><Icon>redo</Icon></Tooltip>
				</Button>
				<Button
					onClick={e => this.handleContextMenuClick(e, "zoom_in")}
					className={classes.solutionEditorIconButton}
					color='primary'
				>
				   	<Tooltip title="Zoom in"><Icon>zoom_in</Icon></Tooltip>
				</Button>
				<Button
					onClick={e => this.handleContextMenuClick(e, "zoom_out")}
					className={classes.solutionEditorIconButton}
					color='primary'
				>
				   	<Tooltip title="Zoom out"><Icon>zoom_out</Icon></Tooltip>
				</Button>
				<div 
					className={classes.root} 
					onKeyDown={this.handleKeyDown}
				>
					<div className={classes.componentPalette} >
						<ComponentPalette />
					</div>

					<div className={classes.solutionDiagramColumn} >
							<Paper tabIndex="0"
								onContextMenu={this.handleContextMenu}
								onClick={this.handleMouseUp}
								onCopy={e => this.handleContextMenuClick(e, "copy")}
								onPaste={e => this.handleContextMenuClick(e, "paste")}
								onKeyDown={this.handleKeyDown}
								className={classNames([classes.solutionDiagramPaper, classes.solutionDiagramPanelTop])}
								style={{ height: this.state.height}}
								>
								<SolutionDiagram
									ref={this.SOLUTION_DIAGRAM_REF}
									solutionId={this.props.id}
									datasource={this.props.diagram}
									onUpdate={diagram => {
										this.props.updateSolutionDiagram(this.props.id, diagram)
									}
									}// actual diagram passed
									onSelectionChanged={(e, diagram) => {
										e.isSelected ?
											this.props.selectedComponent(this.props.id, e.entity.serialize()) :
											this.props.deselectedComponent(this.props.id, e.entity.serialize(), diagram)
									}
									}
									selectComponent={e => {
										this.props.selectedComponent(this.props.id, e)
									}
									} // actual component passed
									onDelete={e => {
										this.props.deleteComponent(this.props.id, e.entity)
										this.setState({ buttonMenuVisible: false })
									}}
									zoomLevel={e => {
										this.props.zoomLevel(this.props.id, e)
										this.setState({ buttonMenuVisible: false })
									}
									} />
							</Paper>
						<Paper
							onContextMenu={this.handleContextMenu}
							className={classes.solutionEditorRuntimePaper}
							>
							<Tabs
							style= {{width:'450px'}}
								value={this.state.tab1Index}
								onChange={this.handleTab1Change}
								indicatorColor="primary"
								textColor="primary"
							>
								<Tab label='Solution messages' />
								<Tab label='Runtime verification' />
							</Tabs>
							{
								tab1Index === 0 &&
								<ExecutionDataView
									id={this.props.id}
									pagination={false}
									onComponentSelect={this.handleSelectComponent}
									execution={this.props.execution}
									solution={this.props.solution} />
							}{
								tab1Index === 1 &&
								<React.Fragment>
									<Typography
										component="h1"
										variant="h5"
										color="inherit"
										noWrap
										className={classes.detailsTitle}
									>Test data</Typography>
									<ApplicationDataEditor
										id={this.props.id}
										pagination={false}
										onUpdate={fields => this.props.updateAppDataFields(this.props.id, fields)}
									/>
									<br />
									<Typography
										component="h1"
										variant="h5"
										color="inherit"
										noWrap
										className={classes.detailsTitle}
									>Runtime results</Typography>
									<RuntimeView
										pagination={false}
										execution={this.props.execution}
									/>
								</React.Fragment>
							}
						</Paper>
					</div>
				</div>
				{buttonMenu}
				<Dialog
					open={this.state.deleteDialogOpen}
					onClose={() => this.setState({ deleteDialogOpen: false, })}
					aria-labelledby="responsive-dialog-title">
					<DialogTitle id="responsive-dialog-title">Delete {selectedCount} item{selectedCount > 1 ? 's' : null}?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete the following item{selectedCount > 1 ? 's' : null}?<br />
							<b>{this.state.deleteDialogText.map((item, count) => (<React.Fragment key={count} ><li>{item}</li></React.Fragment>))}</b>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.setState({ deleteDialogOpen: false })} color="primary">Cancel</Button>
						<Button onClick={() => {
							this.deleteItems(this.state.deleteItems)
							this.setState({
								deleteDialogOpen: false,
								deleteItems: null,
								deleteDialogText: []

							})
						}} color="primary" autoFocus>
							Delete </Button>
					</DialogActions>
				</Dialog>

			</React.Fragment>
		)
	}
}

SolutionDiagramEditor = connect(
	(state, props) => ({
		solution: state.solutions.present.items[props.id] && state.solutions.present.items[props.id]._embedded,
		diagram: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.solutionDefinition,
		canUndo : state.solutions.past.length > 0,
		canRedo : state.solutions.future.length > 0,
		solutionExec: state.solutions.present.items[props.id] && state.solutions.present.items[props.id]._links && state.solutions.present.items[props.id]._links.execute.href,
		execution: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].execution,
		canExec: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.validRuntime,
		permissions: state.user.profile._embedded.permissions,

	}),
	dispatch => (
		bindActionCreators({ ...actions, notifyUserMessage, updateAppDataFields, ...solutionActions, ...ActionCreators }, dispatch)
	),
)(withStyles(styles, { withTheme: true })(SolutionDiagramEditor))
