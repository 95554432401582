import React from 'react'
import { connect } from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper';

import { UserProfile } from './UserProfile'

import styles from '../../../styles'

export class UserProfilePage extends React.Component {
    render() {
        const { classes, userProfile } = this.props
        return (

            <Paper className={classes.adminTablePaper} elevation={1}>
                <UserProfile profile={userProfile} />
            </Paper>
        )
    }
}

UserProfilePage = connect(
    state => ({
        userProfile: state.user.profile
    })
)(withStyles(styles)(UserProfilePage))