import React from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { DatasourceButtons } from './DatasourceButtons'

import * as _ from "lodash"
import { Typography } from '@material-ui/core';

export class FieldSelector extends React.Component {

    // must have at least 1 datasource
    constructor(props) {
        super(props)
        const datasources = props.allowLiteral ? [...props.datasources, { name: 'Literal', datasource: null }] : props.datasources

        let datasourceFields = datasources[0]
        let selectedValue = ''
        if (props.value) {
            const parts = props.value.split('.')
            if (parts.length === 1) {
                selectedValue = parts[0]
            }
            else if (parts.length > 1) {
                selectedValue = parts[1]
                datasourceFields=  _.find(datasources, ['name', parts[0]]) || []
            }
        }
        this.state = {
            searchValue: '', // value of search field name 
            selectedValue: selectedValue , // value of selected field name
            datasourceFields: datasourceFields,
            newFieldModalvisible: false, // model visibility flag
            datasources: datasources,
        }
    }

    static getDerivedStateFromProps(props, state) {
        // TODO get rid of derivedFromProps and put a proper solution in place
        if (!props.derivedFromProps) {
            return state
        }
        if (props.value ) {
            let datasourceFields = props.datasources[0]
            let selectedValue = ''
            const datasources = props.allowLiteral ? [...props.datasources, { name: 'Literal', datasource: null }] : props.datasources

            const parts = props.value.split('.')
            if (parts.length === 1) {
                selectedValue = parts[0]
            }
            else if (parts.length > 1) {
                selectedValue = parts[1]
                datasourceFields=  _.find(datasources, ['name', parts[0]]) || []
            }
            return {...state, 
                value: props.value,
                datasourceFields: datasourceFields,
                selectedValue: selectedValue, // value of selected field name
            }
        }
        else {
            return {...state, 
                // value: props.value,
                // datasourceFields: datasourceFields,
                selectedValue: "", // value of selected field name
            }
        }
    }

    onDatasourceChange = (e) => {
        this.setState({
            datasourceFields: _.find(this.state.datasources, ['name', e.target.value]) || [],
            selectedValue: '',
        })
    }


    onFieldSelected = (e) => {
        const value = e.target.value
        this.setState({
            selectedValue: value,
        });

        if (this.props.onChange) {
            this.props.onChange(this.state.datasourceFields.name + "." + value);
        }
    }


    render() {


        const fieldsList = this.state.datasourceFields.datasource

        const datasourceFields = fieldsList ? fieldsList.map((item, index) => {
            return (
                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
            )
        }) : []

        return (
            <div style={{ width: '100%' }}>
                {this.props.label ? <Typography variant="body1">{this.props.label}</Typography> : null}
                {!this.props.hideSourceSelector?<DatasourceButtons
                    datasources={this.state.datasources}
                    onChange={this.onDatasourceChange}
                    value={this.state.datasourceFields.name}
                    classes={this.props.classes}
                />: null}
                {fieldsList ?
                    <FormControl className={this.props.classes.componentDetailsTextField}
                        key={this.props}
                        style={{ width: '100%' }}
                    >
                        <InputLabel htmlFor={`.formControl`}
                        >{this.props.inputLabel ? this.props.inputLabel : 'Select field'}</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            value={this.state.selectedValue}
                            key={`.textfield`}
                            onChange={this.onFieldSelected}
                            inputProps={{
                                name: 'item.label',
                                id: `.formControl`,
                            }}
                        >
                            {datasourceFields}
                        </Select>
                    </FormControl>
                    :
                    <TextField
                        label='Value'
                        
                        className={this.props.classes.componentDetailsTextField}
                        style={{ width: '100%' }}
                        value={this.state.selectedValue}
                        key={`.textfield`}
                        onChange={this.onFieldSelected} />
                }
            </div>
        )
    }
}
