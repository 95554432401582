import React from 'react';

import classNames from 'classnames';
import styles from '../styles'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';

import { handleAuthentication, validateUser }  from './actions'
import { Typography, Paper, } from '@material-ui/core'

export class Callback extends React.Component {

    componentWillMount() {
        const { handleAuthentication } = this.props
        handleAuthentication()
    }



    render() {
        const { isAuthenticated, validationStatus, classes } = this.props
        if ((isAuthenticated && validationStatus !=='inProgress') || validationStatus==='success') {
            return <Redirect to='/admin' />
        }
        else if ((isAuthenticated === false && validationStatus!== 'inProgress')  || validationStatus === 'failed') {
            return <Redirect to='/login' />
        }
        else {
            // otherwise it's undefined
            if (localStorage.getItem('validation') && localStorage.getItem('access_token')) {
                this.props.validateUser(localStorage.getItem('validation'))
                localStorage.removeItem('validation')
                return (<React.Fragment>
                    <main className={classes.loginLayout}>
                        <Paper className={classNames(classes.loginPaper, classes.paper)} elevation={15}>
                            <Typography>Making your account ready ...</Typography>
                        </Paper>
                    </main>
                </React.Fragment>
                )
            }
            return (<React.Fragment>
                <main className={classes.loginLayout}>
                    <Paper className={classNames(classes.loginPaper, classes.paper)} elevation={15}>
                        <Typography>Opening the airlocks...</Typography>
                    </Paper>
                </main>
            </React.Fragment>
            )
        }
    }
}

Callback = connect(
    state => ({
        isAuthenticated: state.auth.isAuthenticated,
        validationStatus : state.auth.validationState,
    }),
    dispatch => (
        bindActionCreators({validateUser, handleAuthentication }, dispatch)
    )
)(withStyles(styles)(Callback))