import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import _ from 'lodash'
import styles from '../../../styles'
import { withStyles } from '@material-ui/core/styles'

export class RuntimeView extends React.Component {

    getData = () => {
        const { execution } = this.props
        if (execution && execution._embedded && execution._embedded.runtimeData) {
            const runtimeValues = execution._embedded.runtimeData
            return _.map(runtimeValues, (value, key) => {
                return { key: key, value: value }
            })
        }
        else {
            return []
        }
    }

    render() {
        const data = this.getData()

    return (<Table>
            <TableHead>
                <TableRow>
                    <TableCell >Key</TableCell>
                    <TableCell >Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow key={row.key}>
                            <TableCell component="th" scope="row" >
                                {row.key}
                            </TableCell>
                            <TableCell >
                                {row.value}
                            </TableCell>

                        </TableRow>
                    )
                }
                )}
            </TableBody>
        </Table>)
    }
}

RuntimeView = withStyles(styles, { withTheme: true })(RuntimeView)
