import React from 'react'
import { Select, MenuItem, Typography } from '@material-ui/core';

export class DatasourceSelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value : props.value || ''

        }
    }

    handleChange = (e) => {
        const {value} = e.target
        this.setState ({
            value : value
        })
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            const value = nextProps.value
            this.setState({ value: value });
        }
    }


    render() {
        const datasources = this.props.datasources || []
        const items = datasources.map(item=> (<MenuItem key={item.key} value={item.key}>{item.title}</MenuItem>))
        return (
            <div>
                {this.props.label ? <Typography variant="body1">{this.props.label}</Typography> : <div />}
                <Select
                    style={{ width: 300 }}
                    value={this.state.value}
                    onChange={this.handleChange}>
                    {items}
                </Select>

            </div>
        );
    }
}
