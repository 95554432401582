import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

import {
	permission,
	PERMISSIONS_NONE,
} from '../../actions/permissions'

import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { SolutionDiagramEditor } from './SolutionDiagramEditor'
import {
	updateComponent,
	deleteComponent,
} from './SolutionDiagramEditor/actions'

import {
	updateApplicationFields,
	updateRuntimeFields,
} from '../Admin/Solutions/actions'

import { PredictionConfigEditor } from './Predictions'

import { ApplicationDatasetFields, RuntimeDatasetFields } from './SolutionDiagramEditor/DatafieldsTable'
import { ComponentDetailsEditor } from './SolutionDiagramEditor/ComponentDetailsEditor'

import { Home } from '../Admin/Home'

import styles from '../../styles'
import { DicidioAppBar } from '../DicidioAppBar';

/**
 * Tabs
 * TabPane
* 	1x Solution Diagram
 * 	Nx Prediction Editors
 * 	...
 */
const privateRoutes = [
	{ name: 'Dashboard', path: '/admin', exact: true, component: Home, className: 'logo menuItem', icon: 'dashboard', permission: PERMISSIONS_NONE },
]

export class SolutionEditor extends React.Component {

	COMPONENT_DETAILS = 'Properties'
	APPLICATION_DATA = 'Application data fields'
	RUNTIME_DATA = 'Runtime data fields'

	state = {
		selected: this.props.location.pathname,
		open: false,
		id: this.props.match.params.id,
		detailsView: this.COMPONENT_DETAILS,
		detailsDrawerOpen: true,
	}


	constructor(props) {
		super(props)
		this.COMPONENT_DETAILS_REF = React.createRef()
		this.APPLICATION_DATA_REF = React.createRef()
		this.RUNTIME_DATA_REF = React.createRef()
	}

	componentDidMount() {
		this.setState({
			detailsMarker: this.COMPONENT_DETAILS_REF.current.getClientRects()[0],
		})
	}

	handleDrawerOpen = () => {
		this.setState({
			open: true,
			detailsDrawerOpen: false,
		});
	}

	handleDrawerClose = () => {
		this.setState({ open: false });
	}

	handleItemClick = (e, path) => {
		e.preventDefault()
		this.setState({
			selected: path
		})
		this.props.push(path)
	}

	detailsDrawerToggle = () => {
		this.setState({
			detailsDrawerOpen: !this.state.detailsDrawerOpen,
			open: false,
		})
	}

	handleDetailsChange = (newViewRef, newView) => {
		this.setState({
			detailsView: newView,
			detailsDrawerOpen: true,
			open: false,
			detailsMarker: newViewRef.current.getClientRects()[0],
		})
	}

	render() {
		const detailsView = this.state.detailsView
		const classes = this.props.classes
		const selectedDetailsItemClass = `${classes.detailsItemIsActive} ${classes.detailsListItem}`

		const detailsDrawer = (id) => <Drawer
			variant="permanent"
			open={this.state.detailsDrawerOpen}
			anchor='right'
			classes={{
				paper: classNames(classes.detailsDrawerPaper, !this.state.detailsDrawerOpen && classes.detailsDrawerPaperClose),
			}}
		>
			<div className={classes.appBarSpacer} />
			<div className={classes.drawerHeader}>
				<IconButton onClick={this.detailsDrawerToggle}>
					{this.state.detailsDrawerOpen ? <Icon>chevron_right</Icon> : <Icon>chevron_left</Icon>}
				</IconButton>
			</div>
			<Divider />
			<Grid container className={classes.detailsDrawerGrid}>
				<Grid item xs={'auto'} >
					<div style={{ top: this.state.detailsMarker ? this.state.detailsMarker.top : 0, height: this.state.detailsMarker ? this.state.detailsMarker.height : 0 }} id='detailsDrawerMarker' className={classes.detailsDrawerMarker} />
					<div>
						<List >
							<div ref={this.COMPONENT_DETAILS_REF}>
								<ListItem button disableGutters={true} className={detailsView === this.COMPONENT_DETAILS ? selectedDetailsItemClass : classes.detailsListItem}
									onClick={() => { this.handleDetailsChange(this.COMPONENT_DETAILS_REF, this.COMPONENT_DETAILS) }}>
									<ListItemIcon>
										<Icon>settings</Icon>
									</ListItemIcon>
								</ListItem>
							</div>
							<div ref={this.APPLICATION_DATA_REF}>
								<ListItem button disableGutters={true} className={detailsView === this.APPLICATION_DATA ? selectedDetailsItemClass : classes.detailsListItem}
									onClick={() => { this.handleDetailsChange(this.APPLICATION_DATA_REF, this.APPLICATION_DATA) }}>
									<ListItemIcon>
										<Icon>how_to_vote</Icon>
									</ListItemIcon>
								</ListItem>
							</div>
							<div ref={this.RUNTIME_DATA_REF}>
								<ListItem button disableGutters={true} className={detailsView === this.RUNTIME_DATA ? selectedDetailsItemClass : classes.detailsListItem}
									onClick={() => { this.handleDetailsChange(this.RUNTIME_DATA_REF, this.RUNTIME_DATA) }}>
									<ListItemIcon>
										<Icon>attach_file</Icon>
									</ListItemIcon>
								</ListItem>
							</div>
						</List>
					</div>
				</Grid>
				<div className={classes.detailsOuterArea}>
					<Typography
						component="h1"
						variant="h5"
						color="inherit"
						noWrap
						className={classes.detailsTitle}
					>
						{detailsView}
					</Typography>
					{
						detailsView === this.COMPONENT_DETAILS &&
						<ComponentDetailsEditor
							classes={this.props.classes}
							className='se-editor-componentDetailsEditor'
							id={this.state.id}
							onDelete={e => this.props.deleteComponent(this.state.id, e)}
							onUpdate={e => this.props.updateComponent(this.state.id, e)}
						/>
					}{
						detailsView === this.APPLICATION_DATA &&
						<ApplicationDatasetFields
							className='se-editor-datafieldsTable'
							id={id}
							onUpdate={(fields) => this.props.updateApplicationFields(this.state.id, fields)}
						/>
					}{
						detailsView === this.RUNTIME_DATA &&
						<RuntimeDatasetFields
							className='se-editor-datafieldsTable'
							id={id}
							onUpdate={(fields) => this.props.updateRuntimeFields(this.state.id, fields)}
						/>
					}
				</div>
			</Grid>

		</Drawer>
		const title = `Solution Design - ${this.props.solution && this.props.solution._embedded?this.props.solution._embedded.name:''}`

		return (
			<div className={classes.root}>
				<DicidioAppBar menuOpen = {this.state.open}  onDrawerOpen = {this.handleDrawerOpen} title ={title}/>
				<Drawer
					variant="permanent"
					classes={{
						paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbarIcon}>
						<div className={classes.logo} >DICIDIO</div>
						<IconButton onClick={this.handleDrawerClose}>
							<Icon>chevron_left</Icon>
						</IconButton>
					</div>
					<Divider />
					<List>
						<div>
							{
								privateRoutes.map(i => (
									permission(this.props.user, i.permission) ?
										< ListItem key={i.name} button
											component={Link} to={i.path}
											onClick={e => this.handleItemClick(e, i.path)}
											selected={this.state.selected === i.path}>
											<ListItemIcon>
												<Icon>{i.icon}</Icon>
											</ListItemIcon>
											<ListItemText primary={i.name} />
										</ListItem>
										: null
								))
							}</div>
					</List>
					<Divider />
				</Drawer>
				<main className={classNames(classes.solutionEditorContent, {
					[classes.solutionEditorContentShift]: this.state.detailsDrawerOpen,
				})}>

					<div className={classes.appBarSpacer} />

					{
						this.props.match.url.split('/')[1] === 'solution' ?
							<SolutionDiagramEditor id={this.props.match.params.id} />
							: <PredictionConfigEditor id={this.props.match.params.id} />
					}
				</main>
				{detailsDrawer(this.props.match.params.id)}
			</div>
		)
	}
}

SolutionEditor = withRouter(connect(
    (state, props) => ({
		user: state.user.profile,
		solution : state.solutions.present.items[props.match.params.id],
		execution: state.solutions.present.items[props.match.params.id] && state.solutions.present.items[props.match.params.id].execution,

	}),
	dispatch => (
		bindActionCreators({
			updateComponent,
			deleteComponent,
			updateApplicationFields,
			updateRuntimeFields,
			push
		}, dispatch)
	)
)(withStyles(styles, { withTheme: true })(SolutionEditor)))



