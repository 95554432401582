import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'

import { PrivateRoute, SignIn, } from '../Auth'
import Notification from '../components/page/Notification'
import { AdminPage } from './Admin'

import { SolutionEditor } from './SolutionEditor'
import { Callback } from '../Auth/Callback'

import { PublicPage, HomePage } from './Public'

import { UserConfirmationPage } from './Admin/User'

import * as actions from './actions'

import CssBaseline from '@material-ui/core/CssBaseline'

import './App.css'
import { ExperimentVisualiser } from './ExperimentVisualiser/ExperimentVisualiser';

const routes = [
	{ key: 'Home', path: '/', component: HomePage, exact: true, private: false, },
	{ key: 'Login', path: '/login', component: SignIn, exact: true, private: false, },
	{ key: 'Callback', path: '/callback', component : Callback , exact: true, private: false, },
	{ key: 'Admin', path: '/admin', component: AdminPage, private: true, },
	{ key: 'Solution Editor', path: '/solution/:id', component: SolutionEditor, exact: true, private: true, },
	{ key: 'Experiments Visualiser', path: '/experiments/result/:id', component: ExperimentVisualiser, exact: true, private: true, },
	{ key: 'Prediction Editor', path: '/prediction/:id', component: SolutionEditor, exact: true, private: true, },
	{ key: 'Welcome', path: '/confirm/:key', component: UserConfirmationPage, exact: true, private: false },
	{ key: 'Welcome', path: '/confirm', component: UserConfirmationPage, exact: true, private: false },
	{ key: 'Offline', path:'/offline', component: PublicPage, private: false },
	{ key: '404',  component: PublicPage, private: false },
]

export class App extends React.Component {

	render() {
		const { application, hideNotification } = this.props
		return (
			<div style={{cursor:application.cursor}}>
				<CssBaseline />

				<Switch >
					{
						routes.map(route => route.private ?
							<PrivateRoute {...route} />
							: <Route {...route}/>)
					}
				</Switch>
				<Notification
					{...application.notification}
					onRequestClose={hideNotification}
				/> 
			</div>
		)
	}
}

App = withRouter(connect(
	state => ({
		isAuthenticated: state.auth.isAuthenticated,
		application: state.application,
	}),
	dispatch => (
		bindActionCreators(actions, dispatch)
	)
)(App))

