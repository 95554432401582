
import React from 'react'
import { DatasourceButtons } from './DatasourceButtons'
import { InputBase, TextField, Grid, Select, MenuItem, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Icon, Fab, IconButton, Typography } from '@material-ui/core'
import { isFloat} from '../../../functions'
import * as _ from "lodash"

export class Calculator extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleDsChange = (e, index) => {
        let values = this.props.values || this.defaultValue
        if (e.target.value !== values[index].datasource) {
            values[index].fieldKey = ''
        }
        values[index].datasource = e.target.value
        this.handleChange(values)
    }

    handleFieldChange = (e, index) => {
        let values = this.props.values || this.defaultValue
        if (values[index].datasource === 'Literal') {
            if (isFloat(e.target.value)) {
                values[index].fieldKey = e.target.value
            }
        }
        else {
            const key = e.target.value
            values[index].fieldKey = key
        }
        this.handleChange(values)
    }


    handleChange = (values) => {
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }

    handleOperatorChange = (e, index) => {
        let values = this.props.values || this.defaultValue
        values[index].operator = e.target.value
        this.handleChange(values)
    }

    findDatasourceField(datsources, datasourceName, key) {
        const datasource = datsources.find(i => i.name === datasourceName)
        return datasource ? datasource.datasource.find(i => i.key === key) : null
    }

    handleIndentChange = (index, direction) => {
        let values = this.props.values

        if (direction === 'add') {
            if (values[index].level < values.length - 2) {
                if (values[index].level !== values[index - 1].level) {
                    values[index].level = values[index].level + 1
                }
                else {
                    values[index].level = values[index].level + 1
                    values[index - 1].level = values[index - 1].level + 1
                }
                this.handleChange(values)
            }
        }
        else {
            if (values[index - 1].level > values[index].level) {
                values[index - 1].level = values[index - 1].level - 1
            }
            else if (values[index - 1].level < values[index].level) {
                values[index].level = values[index].level - 1
            }
            else {
                if (values[index].level > 0) {
                    values[index].level = values[index].level - 1
                }
                if (values[index - 1].level > 0) {
                    values[index - 1].level = values[index - 1].level - 1
                }
            }
            this.handleChange(values)
        }
    }
    handleAdd = () => {
        let values = this.props.values || this.defaultValue
        values.push({
            datasource: 'Application',
            fieldKey :'',
            operator: '+',
            level: 0,
        })
        this.handleChange(values)
    }

    handleDelete = (index) => {
        const values = [...this.props.values]
        values.splice(index, 1)
        this.handleChange(values)
    }

    calculationText = (values) => {
        const leftBrackets = (count) => {
            if (count > 0) {
                let returnValue = ''
                for (let i = 0; i < count; i++) {
                    returnValue = returnValue + "("
                }
                return returnValue
            }
            else {
                return ''
            }
        }

        const rightBrackets = (count) => {
            let returnValue = ''
            for (let i = 0; i < count; i++) {
                returnValue = returnValue + ")"
            }
            return returnValue
        }

        return values.map((item, index) => {
            let leftLevel = item.level
            let rightLevel = item.level
            if (index > 0) {
                if (item.level > values[index - 1].level) {
                    // we need more brackets 
                    leftLevel = item.level - values[index - 1].level
                }
                else {
                    leftLevel = 0
                }
            }
            if (index < values.length - 1) {
                if (item.level > values[index + 1].level) {
                    // we need more brackets 
                    rightLevel = item.level - values[index + 1].level
                }
                else {
                    rightLevel = 0
                }
            }

            if (index === 0) {
                return `${leftBrackets(leftLevel)} ${this.getFieldName(item.datasource, item.fieldKey)}`
            }
            else {

                return ` ${item.operator} ${leftBrackets(leftLevel)} ${this.getFieldName(item.datasource, item.fieldKey)} ${rightBrackets(rightLevel)}`
            }
        })
    }

    handleExpand = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        })
    }

    getFieldName = (datasource, value) => {
        if (!this.props.datasources) {
            return ''
        }
        if (datasource === 'Application' || datasource === 'Runtime') {
            const field =  _.find(_.find(this.props.datasources, { 'name': datasource }).datasource, { 'key': value })
            return field?field.name : ''
        }
        else {
            return value
        }
    }


    calcItem = (index, values) => {
        const { classes } = this.props
        const datasources = [...this.props.datasources, { name: 'Literal', datasource: null }]

        const fieldListA = values[index].datasource || values[index].datasource !== 'Literal' ?
            _.find(datasources, { 'name': values[index].datasource }).datasource
            : datasources[0].datasource
        const fieldList = _.filter(fieldListA, field=> {return field.type!=='String'})
        const summaryValue = this.getFieldName(values[index].datasource, values[index].fieldKey) || 'Select a field'

        return (
            <React.Fragment key={`fragment.${index}`}>

                {index > 0 ? (
                    <Grid container >
                        <Grid item xs={4} style={{ textAlign: 'center' }} />
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <Select 
                             classes ={{root : classes.calcOperatorRoot, select : classes.calcOperatorInput, icon : classes.calcOperatorIcon}}
                                value={values[index].operator} 
                                onChange={(e) => this.handleOperatorChange(e, index)}
                                input={
                                    <InputBase
                                      name="operator.Select"
                                      id="operator.Select"
                                    />
                                  }
                                >
                                <MenuItem key='plus' value='+'>+</MenuItem>
                                <MenuItem key='minus' value='-'>-</MenuItem>
                                <MenuItem key='divide' value='/'>/</MenuItem>
                                <MenuItem key='times' value='*'>*</MenuItem>
                                <MenuItem key='power' value='^'>^</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <IconButton style={{ padding: '4px' }} onClick={() => this.handleIndentChange(index, "add")}
                                className={`${classes.button} ${classes.gridButton}`}>
                                <Icon>link</Icon>
                            </IconButton>
                            <IconButton style={{ padding: '4px' }} onClick={() => this.handleIndentChange(index, "remove")}
                                className={`${classes.button} ${classes.gridButton}`}>
                                <Icon>link_off</Icon>
                            </IconButton>
                            {index > 1 ? (
                                <IconButton style={{ padding: '4px' }} onClick={() => this.handleDelete(index)}
                                    className={`${classes.button} ${classes.gridButton}`}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            ) : <React.Fragment />}
                        </Grid>
                    </Grid>
                ) : <React.Fragment />}
                <div style={{ display: 'inline-flex', width: '100%' }} >
                    <div style={{ width: `${values[index].level * 15}px` }} />
                    <ExpansionPanel 
                            expanded={this.state.expanded === `panel.${index}`} 
                            onChange={this.handleExpand(`panel.${index}`)}
                            style={{ width: '100%' }} elevation={0} >
                        <ExpansionPanelSummary classes={
                            {
                                root: classes.calculationSummmaryRoot,
                                expanded: classes.calculationSummmaryExpanded,
                                content: classes.calculationSummmaryContent,
                            }}
                            expandIcon={<Icon>expand_more</Icon>}>
                           <Typography variant='body1' >{summaryValue}</Typography> 
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.calculationItem }}>
                            <div style={{ width: '100%' }}>
                                <DatasourceButtons value={values[index].datasource} classes={classes} onChange={e => this.handleDsChange(e, index)} datasources={datasources} />
                                {values[index].datasource === 'Literal' ?
                                    <TextField
                                        style={{ width: '100%' }}
                                        value={values[index].fieldKey || ''}
                                        key={`.textfield.${index}`}
                                        onChange={(e) => this.handleFieldChange(e, index)} />

                                    :
                                    <Select
                                        style={{ width: '100%' }}
                                        value={values[index].fieldKey || ''}
                                        key={`.textfield.${index}`}
                                        onChange={(e) => this.handleFieldChange(e, index)}

                                        inputProps={{
                                            name: 'item.label',
                                            id: 'fieldname',
                                        }}
                                    >
                                        {fieldList.map(d => <MenuItem key={d.key} value={d.key}>{d.name}</MenuItem>)}
                                    </Select>
                                }
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <div style={{ width: `${values[index].level * 15}px` }} />
                </div>
            </React.Fragment>
        )
    }

    defaultValue = [
        {
            fieldKey :'',
            datasource: 'Application',
            level: 0,
        },
        {
            fieldKey :'',
            datasource: 'Application',
            operator: '+',
            level: 0,
        },
    ]

    render() {

        const values = this.props.values || this.defaultValue

        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ margin: '5px', whiteSpace: 'initial' }}>
                        <Typography variant='button' >  {this.calculationText(values)}</Typography>
                    </div>
                    {values.map((item, index) => { return this.calcItem(index, values) })}

                </div>

                <Fab onClick={this.handleAdd}
                    className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                    color="primary">
                    <Icon>add</Icon>
                </Fab>
            </div>
        )
    }
}
