import { get,} from '../../../actions'

export const GET_EXECUTIONS_REQUEST = '@@executions/GET_EXECUTIONS_REQUEST'
export const GET_EXECUTIONS_SUCCESS = '@@executions/GET_EXECUTIONS_SUCCESS' 
export const GET_EXECUTIONS_FAILURE = '@@executions/GET_EXECUTIONS_FAILURE'
export const getExecutions = (endpoint) =>(
    get({
        displayName: 'get executions',
        endpoint: endpoint,
        types: [
            GET_EXECUTIONS_REQUEST, 
            GET_EXECUTIONS_SUCCESS, 
            GET_EXECUTIONS_FAILURE
        ]
    })
)

export const GET_EXECUTIONS_FOR_VERSION_REQUEST = '@@executions/GET_EXECUTIONS_FOR_VERSION_REQUEST'
export const GET_EXECUTIONS_FOR_VERSION_SUCCESS = '@@executions/GET_EXECUTIONS_FOR_VERSION_SUCCESS' 
export const GET_EXECUTIONS_FOR_VERSION_FAILURE = '@@executions/GET_EXECUTIONS_FOR_VERSION_FAILURE'
export const getExecutionsForVersion = (endpoint, version) =>(
    get({
        displayName: 'get executions',
        endpoint: `${endpoint}/${version}`,
        types: [
            GET_EXECUTIONS_FOR_VERSION_REQUEST, 
            GET_EXECUTIONS_FOR_VERSION_SUCCESS, 
            GET_EXECUTIONS_FOR_VERSION_FAILURE
        ]
    })
)

export const GET_EXECUTION_STATS_FOR_VERSION_REQUEST = '@@executions/GET_EXECUTION_STATS_FOR_VERSION_REQUEST'
export const GET_EXECUTION_STATS_FOR_VERSION_SUCCESS = '@@executions/GET_EXECUTION_STATS_FOR_VERSION_SUCCESS' 
export const GET_EXECUTION_STATS_FOR_VERSION_FAILURE = '@@executions/GET_EXECUTION_STATS_FOR_VERSION_FAILURE'
export const getExecutionStatsForVersion = (endpoint, start, end, granularity, id) =>(
    get({
        displayName: 'get execution stats for a version',
        endpoint: `${endpoint}?start=${start||'-'}&end=${end||'-'}&gran=${granularity||'-'}&id=${id||'-'}`,
        types: [
            GET_EXECUTION_STATS_FOR_VERSION_REQUEST, 
            GET_EXECUTION_STATS_FOR_VERSION_SUCCESS, 
            GET_EXECUTION_STATS_FOR_VERSION_FAILURE
        ]
    })
)
