import { RSAA } from 'redux-api-middleware'
import * as Constants from '../constants'
import { uuid } from '../functions'

export const GET = 'GET'
export const PUT = 'PUT'
export const POST = 'POST'
export const DELETE = 'DELETE'

const DEFAULT_ENDPOINT = Constants.BASE_URL
export const REQUEST = 0
export const SUCCESS = 1
export const FAILURE = 2
export const REQUEST_ACTION = 'REQUEST'
export const SUCCESS_ACTION = 'SUCCESS'
export const FAILURE_ACTION = 'FAILURE'
const UNKNOWN_TYPES = [REQUEST_ACTION, SUCCESS_ACTION, FAILURE_ACTION]

/**
 * Construct types from name if types aren't supplied
 */
export const makeApiType = ( entity, action, scope = entity ) => ([
    `@@${scope.toLowerCase()}` + `/${action}_${entity}_${REQUEST_ACTION}`.toUpperCase(),
    `@@${scope.toLowerCase()}` + `/${action}_${entity}_${SUCCESS_ACTION}`.toUpperCase(),
    `@@${scope.toLowerCase()}` + `/${action}_${entity}_${FAILURE_ACTION}`.toUpperCase(),
]) 

export const get = (rest) => ApiAction({ method: GET, ...rest })
export const put = (rest) => ApiAction({ method: PUT, ...rest })
export const post = (rest) => ApiAction({ method: POST, ...rest })
export const del = (rest) => ApiAction({ method: DELETE, ...rest })
export function ApiAction({ method = GET, endpoint = DEFAULT_ENDPOINT, types = UNKNOWN_TYPES, options = { timeout: 30000 }, correlationId = uuid(), displayName = 'Unknown Origin', correlation, ...rest }) {
    let headers = {
        'Content-Type': 'application/json',
        'X-SQPM-CId': correlationId,
        'X-SQPM-ReqId': ''
    }
    if (localStorage['access_token']) {
        headers =  {...headers, 
            'Authorization': 'Bearer ' + localStorage['access_token'],
        }
    }
    return {
        [RSAA]: {
            endpoint: endpoint,
            method: method,
            options: options,
            types: [
                {
                    type: types[REQUEST],
                    meta: (action, state) => {
                        // set wait cursor
                        return {
                            endpoint: endpoint,
                            correlationId: correlationId,
                            displayName: displayName,
                            correlation : correlation,
                        }
                    }
                }, {
                    type: types[SUCCESS],
                    meta: (action, state, response) => {
                        // unset wait cursor
                        return {
                            endpoint: endpoint,
                            correlationId: (response ? response.headers.get('x-sqpm-cid') : ''),
                            requestId: (response ? response.headers.get('x-sqpm-reqid') : ''),
                            displayName: displayName,
                            correlation : correlation,
                        }
                    }
                }, {
                    type: types[FAILURE],
                    meta: (action, state) => {
                        return {
                            endpoint: endpoint,
                            correlationId: correlationId,
                            displayName: displayName,
                            correlation : correlation,
                            redo : {
                                displayName: displayName,
                                endpoint: endpoint,
                                method: method,
                                types:types,
                                ...rest,
                            }
                        }
                    }
                }
            ],
            headers: headers,
            ...rest
        }
    }
}


