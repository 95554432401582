import * as React from "react";
import { AbstractLinkFactory, } from 'storm-react-diagrams'

// models
import { DecisionLinkModel } from '../models'

// widgets
import { LinkWidget } from '../widgets'

export class LinkFactory extends AbstractLinkFactory {
	constructor(solutionId) {
		super("links")
		this.solutionId = solutionId
	}

	generateReactWidget(diagramEngine, link) {

		return <LinkWidget
			solutionId={this.solutionId}
			link={link}
			diagramEngine={diagramEngine} />
	}

	getNewInstance(initialConfig) {
		return new DecisionLinkModel();
	}

	generateLinkSegment(model, widget, selected, path, hasExecuted) {
		let className = ""

		if (selected) {
			className = widget.bem("--path-selected")
		}
		else if (hasExecuted && widget) {
			className = widget.bem("--path-executed")
		}
		return (
			<path
				className={className}
				strokeWidth={model.width}
				stroke={model.color}
				d={path}
			/>
		);
	}
}

