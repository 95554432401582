import React from 'react'
import { SolutionsTable } from './SolutionsTable'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'

export class SolutionsPage extends React.Component {
    render() {
        return (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
                <SolutionsTable  />
            </Paper>
        )
    }
}

SolutionsPage = withStyles( styles, { withTheme: true })(SolutionsPage)