import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import{Icon, IconButton, Input } from '@material-ui/core'

import styles from '../../styles'

export class EditInput extends React.Component {
    state = {
      value: this.props.value,
      editable: false,
    }
    handleChange = (e) => {
      const value = e.target.value;
      this.setState({ value });
    }
    check = () => {
      this.setState({ editable: false });
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    }
    cancel = () => {
      this.setState({ editable: false });
    }
    edit = () => {
      this.setState({ editable: true });
    }
    render() {
      const { value, editable } = this.state
      const { classes } = this.props
      return (
        <div >
          {
            editable ?
              <div >
                <Input className={classes.textField}
                  value={value}
                  onChange={this.handleChange}
                  required={true}
                />
                <IconButton className={classes.editIcon}
                                  color="inherit"
                                  aria-label="Enter value"
                                  onClick={this.check}
                              ><Icon>check</Icon>
                              </IconButton>
                
                              <IconButton className={classes.editIcon}
                                  color="inherit"
                                  aria-label="Cancel edit"
                                  onClick={this.cancel}
                              >
                                  <Icon>clear</Icon>
                              </IconButton>              
              </div>
              :
              <div >
                {value || ' '}
                              <IconButton className={classes.editIcon}
                                  color="inherit"
                                  aria-label="Cancel edit"
                                  onClick={this.edit}
                              >
                                  <Icon>create</Icon>
                              </IconButton> 
              </div>
          }
        </div>
      );
    }
  }

  EditInput = withStyles(styles, {withTheme: true})(EditInput)