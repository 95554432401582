import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

export class OutputsItem extends React.Component {

    constructor(props) {
        super(props)
        this.runtimeDatasetFields = props.runtimeDatasetFields.map((item, index) => {
            return (
                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
            )
        })
    }

    onChange = (field, value) => {
        if (this.props.onChange) {
            this.props.onChange(field, value)
        }
    }

    render() {
        const { value } = this.props;
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <FormControl
                        style={{ width: '90%' }}
                        className={this.props.classes.formControl}
                        key={this.props}>
                        <InputLabel htmlFor={`.formControl`}>Runtime field</InputLabel>
                        <Select
                            value={value.fieldName}
                            key={`.textfield`}
                            onChange={e => this.onChange('fieldName', e.target.value)}

                            inputProps={{
                                name: 'item.label',
                                id: `.formControl`,
                            }}
                        >
                            {this.runtimeDatasetFields}
                        </Select>
                    </FormControl>
                    <IconButton onClick={() => this.props.delete(this.props.index)}
                        className={`${this.props.classes.button} ${this.props.classes.gridButton}`}>
                        <Icon>delete</Icon>
                    </IconButton >

                </div>
                <TextField
                    style={{ width: '100%' }}
                    label='Json Query'
                    onChange={e => this.onChange('param', e.target.value)}
                    value={this.props.value.param || ''}
                />
            </div>
        )

    }

}

