import React, { Component } from 'react'
import { FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import * as p from '../../../actions/permissions'
import styles from '../../../styles'
import withStyles from '@material-ui/core/styles/withStyles'

import { bindActionCreators } from 'redux'
import { getExecutionStatsForVersion} from '../Executions/actions'
import { getDeployedVersions} from '../Solutions/actions'
import _ from 'lodash'
import VegaLite from 'react-vega-lite/lib/VegaLite';
import { Handler} from 'vega-tooltip';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';


export class DeployedUsageStats extends Component {


  constructor(props) {
    super(props)
    this.sizeRef = props.forwardedRef?props.forwardedRef: React.createRef();
    if (props.config) {
      if (!props.config.range){
        props.config.range = 'DAY'
      }
      if (!props.config.key){
        props.config.key = 'ALL'
      }
      this.state = {
        config: {...props.config, type :'DeployedUsageStats' },
        overlayText : "No data",
        loading: false,
        id : props.id,
        title : 'loading'//props.config.title,
      }
    }
    else {
      this.state = {
        config: {key:'ALL', range:'DAY', type :'DeployedUsageStats' },
        overlayText : "No data",
        loading: false,
        title : '-',
      }
    }
    
  }

  componentDidMount() {
    if (!this.props.versions) {
      this.handleLoad()    
    }
    const width = this.sizeRef.current?this.sizeRef.current.offsetWidth :null
    const height = this.sizeRef.current?this.sizeRef.current.offsetHeight:null
    const graphSize = {width:width, height:height }

    const { config} = this.state
    this.handlePeriodChange(null, config.range)
    if (config && config.location) {
      this.props.getExecutionStatsForVersion(config.location , config.start.toISOString(), config.end.toISOString(), config.granularity, this.props.id)
    }
    else {
      if (this.props.user._links.versionStats) {
        this.props.getExecutionStatsForVersion(this.props.user._links.versionStats.href , config.start.toISOString(), config.end.toISOString(), config.granularity, this.props.id)
      }
    }
    this.setState({loading: true, overlayText : "Loading data", graphSize:graphSize})

  }
  
  handleLoad =() => {
    if (p.permission(this.props.user, p.LIST_VERSIONS)) {
      this.props.getDeployedVersions(this.props.user._links[p.VERSIONS].href, 'ALL')
    }
  }

  refresh = ()=>{
    this.updateStats(this.state.config.key)
  }

  resize = ()=>{
    const width = this.sizeRef.current?this.sizeRef.current.offsetWidth :null
    const height = this.sizeRef.current?this.sizeRef.current.offsetHeight-100:null
    
    const graphSize = {width:width, height:height}
    if (height && width && (width !== this.state.graphSize.width || height !== this.state.graphSize.height)){
      this.setState({graphSize:graphSize})
    }
  }
  newItemValues = {

  }

  handleVersionSelected =(e, name)=>{
    const key = e.target.value
    if (key){
      this.updateStats(key)
    }
    if (name && name.props && name.props.children) {
      this.setState({config:{...this.state.config, title:name.props.children? name.props.children :'Solution Usage stats' }})
    }
  }

  handlePeriodChange = (e, range) => {
    if (range) {
      let end = new Date()
      let start = new Date()
      let granularity = 'DAY'
      
      switch (range) {
        case "HOUR":
          granularity = 'MINUTE'
          start.setMinutes(start.getMinutes() - 60)
          
          break;
        case "DAY":
          granularity = 'HOUR'
          start.setHours(start.getHours() - 24)
          break;
        case "WEEK":
        granularity = 'QUARTER_DAY'  
        start.setHours(start.getHours() - 24*7)
        break;
        case "CUSTOM":
          start.setHours(start.getHours() - 24*7)
          break;
        default:
          break;
      }
      this.updateStats(null, range, start, end, granularity)
    }
  }

  updateStats(key, range, start, end, granularity) {
    
    const item= _.find(this.props.versions, version=> version._embedded.webSafeString === key)
    let {config} = this.state

    let versionLocation = config.location
    if (item){
      versionLocation = item._links.stats.href
      config.location = versionLocation
    }
    else {
      
      versionLocation = this.props.user._links.versionStats?this.props.user._links.versionStats.href:null
      config.location = versionLocation
    }

    if (range) {
      config.range = range
    }
    if (key) {
      config.key = key
    }
    if (granularity) {
      config.granularity =  granularity
    }
    if (start) {
      config.start =  start
    }
    if (end) {
      config.end = end
    }
    if (versionLocation) {
      this.props.getExecutionStatsForVersion(versionLocation , config.start.toISOString(), config.end.toISOString(), config.granularity, this.props.id)
      this.props.onTitleChange && this.props.onTitleChange("Loading data")
      this.setState({
        loading:true,
        overlayText : "Loading data",
        config : config,
        versionLocation : versionLocation})
    }
    else {

      this.setState({
        config : config,
        versionLocation : versionLocation})
    }
    this.props.onChange && this.props.onChange(config)
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.data && prevProps.data && prevState.loading ===true) {
      const prevData = prevProps.data[this.props.id]
      const newData = this.props.data[this.props.id]
      if (prevData && newData ) {
        this.setState({
          loading:false, 
          overlayText : null})
        this.props.onTitleChange && this.props.onTitleChange(this.state.config.title)
      }
    }
    this.resize()
  }

  
  render() {
      let  values = [<MenuItem key={'allDeployedSolutions'} value={'ALL'}>All deployed solutions</MenuItem>]
      if (this.props.versions) {
        values.push(_.map(this.props.versions, version=> {
        const {webSafeString, deploymentStatus, versionName} = version._embedded
        return (
            <MenuItem key={webSafeString} value={webSafeString}>{`${versionName} (${deploymentStatus})`}</MenuItem>
        )
      }))
    }

    const data = this.props.data?_.map(this.props.data[this.props.id],(count,timestamp)=>({timestamp:timestamp, count: count})):[]
    const dataMax =  _.maxBy(data, 'count')
    const maxY = _.round(dataMax && dataMax.count > 9 ?dataMax.count:9 * 1.1)
    const {graphSize} = this.state
    const graphWidth = graphSize ? graphSize.width:440
    const graphHeight = graphSize? graphSize.height:190
    const spec = {
      "mark": {
        "type":"line", 
        stroke:'#2264b8',
        size:7,
        "point": {shape:"circle", size:200, filled:false, fill:'white', stroke:'#2264b8'},
      },
      "width" : graphWidth -40 -15 -15,
      "height" :graphHeight -80 ,
      "autosize": {
        "type": "fit",
        "resize" : true,
        "contains": "padding"
      },
      // "selection": {"grid": {"type": "interval", "bind": "scales"}},
       "encoding": {
        "x" : {
         "field" : "timestamp",
         "type" : "temporal",
         "timeUnit" : "monthdatehoursminutes"
        },
        "y" :{
          "field" : "count",
          "type" : "quantitative",
          "scale" : { "domain" :[0, maxY]}
        },
        "tooltip": [
          {"field": "count", "type": "quantitative"},
          {"field": "timestamp", "type": "temporal", "timeUnit" : "hoursminutes"}
        ],

      }
    }




    const {classes} = this.props

    const tooltipOptions = {
     
      theme :'custom',
      // styleId :  classes.vegaTooltip,
      // id :  classes.vegaTooltip,
      // disableDefaultStyle: true,
    }

    const tooltipHandler = new Handler(tooltipOptions).call

    return (
      <div>
        <div className={classes.deployedUsageStatsSelectionArea}>
        <FormControl
          className={`${classes.formControl} ${classes.deployedUsageStatsSelection}`}
          key={this.props}>
            <InputLabel htmlFor={`.formControl`}>Deployed solution</InputLabel>
              <Select
                value={this.state.config && this.state.config.key ? this.state.config.key : ''}
                key={`.textfield`}
                onChange={this.handleVersionSelected}

                inputProps={{
                  name: 'item.label',
                  id: `.formControl`,
                }}>
              {values}
              </Select>
        </FormControl>
        <div style={{width:'100%'}}>
        <ToggleButtonGroup classes={{root:classes.deployedUsageStatsToggleButtons}} exclusive size="large" value={this.state.config.range} onChange={this.handlePeriodChange}>
        <ToggleButton classes={{selected: classes.deployedUsageStatsToggleButtonSelected}} 
          value="HOUR">
        1 hour
        </ToggleButton>
        <ToggleButton classes={{selected: classes.deployedUsageStatsToggleButtonSelected}} 
          value="DAY">
        1 day
        </ToggleButton>
        <ToggleButton classes={{selected: classes.deployedUsageStatsToggleButtonSelected}} 
          value="WEEK">
        7 days
        </ToggleButton>
        </ToggleButtonGroup>
        </div>
      </div>
        <div ref={this.sizeRef} className={classes.graphOverlay} style={{
          borderRadius:'10px 10px 0px 0px',
          backgroundColor : this.state.overlayText? 'rgba(60, 60, 60, 0.15)' :null,
          width: '100%',
          height: '100%',
          left:0,
          bottom : 0,
          zIndex: this.state.overlayText?10:-10,
          }}>
          {this.state.overlayText?<Typography className={classes.deployedUsageStatsOverlayText} variant="h4">{this.state.overlayText}</Typography>:null}
          {this.state.loading?<CircularProgress className={classes.deployedUsageStatsSpinner} size={50} thickness ={3} color={'secondary'}/>:null} 
        </div>              
        <VegaLite spec={spec} data={{values:data}} tooltip={tooltipHandler}  className={classes.deployedUsageStatsDiagram} style={{ filter: this.state.overlayText?'blur(2px)':null }}
                autosize={{ type: "fit",   contains: "padding"}}/>
      </div>
    )
  }
}

DeployedUsageStats = connect(
  state => ({
    versions: state.solutions.present.versions.deployedVersions,
    user: state.user.profile,
    data: state.executions.stats,
  }),
  dispatch => (
    bindActionCreators({getExecutionStatsForVersion, getDeployedVersions }, dispatch)
  ),
  null,
  {forwardRef :true}
)(withStyles(styles, { withTheme: true })(DeployedUsageStats))
