import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline'
import {Typography, Icon, Avatar, Paper, Button} from '@material-ui/core'

import {login }  from './actions';
import { appInit} from '../App/actions'
import styles from '../styles'


export class SignIn extends React.Component {

	componentDidMount() {
    localStorage.removeItem('validation')
    this.props.appInit()
	}

  login = () => {
      this.props.login()
  }
  render() {
    const { classes, isAuthenticated } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } }
    if (isAuthenticated !== undefined) {
      if (isAuthenticated) {
        return <Redirect to={from} />;
      } else {
        return (
          <React.Fragment>
            <CssBaseline />
            
            <main className={classes.loginLayout}>
              <Paper className={classNames(classes.loginPaper, classes.paper)} elevation={15}>
                <Avatar className={classes.avatar}>
                  <Icon>lock</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign In
              </Typography>
                <img src="../images/Dicidio_Logo_Stacked_Blue.png" alt="Dicidio decisioning logo" style={{ maxWidth: '100%', maxHeight: '60%' }} />
                <div className="se-section-body se-centered" >
                  <br />
                  <Typography>
                    You will be redirected to Auth0 to log in. 
                  </Typography>
                  <br />
                </div>
                <br />

                <div className="se-section-body se-centered" >
                  <Button className={classes.loginButton}
                    onClick = {this.login}>Login</Button>
                  <br />
                  <br />
                </div>
              </Paper>
            </main>
          </React.Fragment>
        )
      }
    }
    else { return (<React.Fragment>
      <main className={classes.loginLayout}>
         <Paper className={classNames(classes.loginPaper, classes.paper)} elevation={15}>
         <Typography>Opening the airlocks...</Typography>
          </Paper>
          </main>  
    </React.Fragment>
    ) }
  }
}

SignIn = connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
	
  }),
  dispatch => (
    bindActionCreators( {login, appInit}, dispatch)
  )
)(withStyles(styles)(SignIn))