import * as _ from "lodash";
import { PortModel } from 'storm-react-diagrams'

//models
import { DecisionLinkModel} from './DecisionLinkModel'

export class DecisionPortModel extends PortModel {
	in
	label
	links

	constructor(isInput, name, label = null, id) {
		super(name, "links", id);
		this.in = isInput;
		this.label = label || name;
	}

	deSerialize(object, engine) {
		super.deSerialize(object, engine);
		this.in = object.in;
	}

	serialize() {
		return _.merge(super.serialize(), {
			in: this.in,
		});
	}

	link(port) {
		let link = this.createLinkModel();
		link.setSourcePort(this);
		link.setTargetPort(port);
		return link;
	}

	canLinkToPort(port)  {
		if (port instanceof DecisionPortModel) {
			return this.in !== port.in;
		}
		return true;
	}

	createLinkModel() {
		let link = super.createLinkModel();
		return link || new DecisionLinkModel();
	}
}
