import React from 'react'
import { List, ListItem, ListItemText, Checkbox, Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@material-ui/core';


export class ItemsSelector extends React.Component {
  
    state = {
      currentItems:  this.props.currentItems ||[],
      open : this.props.open || false,
      currentUser : this.props.selectedUser?this.props.selectedUser._embedded.code :''
    }
  
    shouldComponentUpdate(nextProps) {
      if (nextProps.selectedUser &&  (nextProps.selectedUser._embedded.code !== this.state.currentUser)) {
        this.setState ({
          currentItems:   nextProps.currentItems ||[],   
          currentUser : nextProps.selectedUser._embedded.code,
          })
          return false
      }
      return true
    }

    handleToggle = (value) =>{
      const { currentItems } = this.state;
      const currentIndex = currentItems.indexOf(value);
      const newChecked = [...currentItems];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      this.setState({
        currentItems: newChecked,
      });
      this.props.onChange && this.props.onChange(newChecked)
    }

    handleClose = (e, doSave) => {
      if (doSave)  {
        this.props.onOk && this.props.onOk()
        this.setState( {
          open:false,
        })
        }
      else {
         this.props.onCancel && this.props.onCancel()
         this.setState( {
          currentItems:   this.props.currentItems ||[],   
          open:false,
          currentUser: null,
        })
        }

    }

  
    render() {
      const {items, classes,  open, okButton } = this.props
      const listItems = items.map(item=> {
        return (<ListItem  classes ={{root:classes.noPaddingListItem}}key={item.key}  dense button onClick={() => this.handleToggle(item.key)}>
            <Checkbox
              checked={this.state.currentItems.indexOf(item.key) !== -1}
              tabIndex={-1}
              disableRipple
            />
              <ListItemText primary={item.title} />
        </ListItem>)
      })
      return (
          <Dialog
          onClose={ e=> this.handleClose(e, false)}
          aria-labelledby="dialog-title"
          open = {open}
        >
            <DialogTitle id="dialog-title">{this.props.title||'Dialog'}</DialogTitle>
            <DialogContent>
            <List >
          {listItems}
         </List>
         </DialogContent>
         <DialogActions>
         {okButton && !okButton.disabled? 
         <Button onClick={ e =>this.handleClose(e, false)} color="primary">
              Cancel
            </Button>
            :null}
            <Button onClick={ e =>this.handleClose(e, true)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }