import {isLocalhost} from './registerServiceWorker'

export const DEBUG = isLocalhost
const {hostname} = window.location
const apiHostname =  hostname.replace('apps', 'api') 
// export const DEBUG = process.env.NODE_ENV === 'development' ? true : false
export const BASE_URL  = DEBUG ? 'http://localhost:8080' : `https://${apiHostname}`
export const WEB_BASE_URL = DEBUG ? 'http://localhost:3000' :  `https://${hostname}` // apps.dicidio.com
export const PROJECT_ID = DEBUG ? 'dme-stage' : hostname === 'apps.dicidio.com'? 'decisioning-made-easy': 'dme-stage'
export const VERSION = process.env.REACT_APP_VERSION
export const APPNAME = process.env.REACT_APP_NAME
export const GOOGLE_CLIENT_ID = "163017945869-o002j56ulhdhvbnp0i1if0bs4on9mger.apps.googleusercontent.com"