import React, { Component } from "react";
import { FieldSelectionModal } from "./FieldSelectionModal";
import { TextField } from "@material-ui/core";
import _ from 'lodash'

export class FieldNamesTextField extends Component {

    handleTextChange=(e)=> {
        // now we need to combine the current value with the change and preserve the UUIDs
        this.props.onChange && this.props.onChange(this.translateMessage(e.target.value, 'toUuid'))
    }

    translateMessage = (message, direction) => {
        const matchUUID = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g;
        const matchFieldName = /\$\{(application|runtime)+\.[0-9a-zA-Z\s]+}/g;
        if (this.props.datasources) {
            
            const fields  = this.props.datasources.map(datasource=>{
                return datasource.datasource.map(ds=>{return {...ds, datasource:datasource.name.toLowerCase()}})
            })
            const allFields =  _.concat(...fields)

            if (direction === 'toUuid') {
                return message.replace(matchFieldName, found => {

                    const indexOfDot = found.indexOf('.')
                    const fieldName = found.substring(indexOfDot +1 , found.length-1)
                    const datasourceName = found.substring(2, indexOfDot )
                    var it = allFields.find(node => node.name === fieldName && node.datasource === datasourceName );
                    return it ? `\${${datasourceName}.${it.key}}` : `\${${datasourceName}.unknown}`;
                  })
            }
            else {
                // we're translation from UUID to normal text
                return message.replace(matchUUID, id => {
                    var it = allFields.find(node => node.key === id);
                    return it ? it.name : 'unknown';
                  })
            }
    
        }
        else  {
            return message
        }
        
    };

  render() {

    const onFieldSelected = (fieldName, editFieldRef) => {
      const oldValue = editFieldRef.current.value;
      const position = editFieldRef.current.selectionStart || 0;
      const newValue = [
        oldValue.slice(0, position),
        fieldName,
        oldValue.slice(position)
      ].join("");
      this.props.onChange(newValue);
    };
    let editFieldRef = React.createRef();
    // The ref (to the underlying edit box) is passed on to the modal dialog and passed back to
    // the onFieldSelected function (above) so that we have a handle to the edit field and
    // we can insert the field name in the right location within that edit box

    const {label, multiline, width, classes, datasources, value} = this.props
    return (
      <div>
        <TextField
          className={classes.componentDetailsTextField}
          label={label}
          style={{ width: width ? width : "90%", marginRight : '5px' }}
          value={value ? this.translateMessage(value) : ""}
          onChange={this.handleTextChange}
          key={'TextField'}
          inputRef={editFieldRef}
          multiline={multiline}
        />
       <FieldSelectionModal
          style={{ width: width ? width : "10%"}}
          onFieldSelected={onFieldSelected}
          id={'selectionModal'}
          classes={classes}
          editFieldRef={editFieldRef}
          datasources={datasources}
        />
      </div>
    );
  }
}
