import React, { lazy, Suspense } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../../styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {default as diagram} from "./diagramdef"

class PublicPage extends React.Component {
  render() {
		const SolutionDiagram = lazy( ()=> import('../SolutionEditor/SolutionDiagramEditor/Diagram/SolutionDiagram'))
    const PublicLinks = lazy( ()=> import('./PublicLinks'))
    const { classes } = this.props;

    const offline = this.props.match.path ==='/offline'
    if (offline) {
      document.title = "Dicidio Offline";
    }


    let zoom = (window.innerWidth-50) /30
      zoom = Math.min(zoom, 38)
    let offsetX = 120
    let height = 300
    if (zoom< 30) {
      offsetX = 80 
      height = 220
    }
    if (zoom< 15) {
      offsetX = 20
      height = 140
    }
    
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.layout}>
          <Toolbar className={classes.toolbarMain}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              align="center"
              noWrap
              className={classes.toolbarTitle}
            >
              <div className={classes.logo}>DICIDIO</div>
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={e => (window.location.href = "/login")}
            >
              Log In
            </Button>
          </Toolbar>
          <main>
            {!offline?
            <Paper className={classes.solutionDiagramPaper}
                                style={{height:height, marginLeft: 0, marginRight: 0, marginBottom:32}}>
            
            <Suspense fallback = {<div>loading diagran</div>} >

            <SolutionDiagram 
                      solutionId={404}
									    datasource={{...diagram, zoom:zoom, offsetX:offsetX}}
                    />
              </Suspense>
            </Paper> 
            : null}
            <Paper className={classes.mainFeaturedPost}>
              <Grid container>
                <Grid item md={6}>
                  <div className={classes.mainFeaturedPostContent}>
                    <Typography
                      component="h1"
                      variant="h3"
                      color="inherit"
                      gutterBottom
                    >
                      {offline?'Dicidio is currently offline' :null}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                    {offline?'We\'re working to get Dicidio back online as soon as possible': 'We can\'t find what you\'re after'}
                    </Typography>
                    <Typography variant="subtitle1" color="inherit" paragraph>
                     {offline? 'Until then, you can explore the documentation or read therecent news on Dicidio\'s updates.' :null}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Suspense fallback = {<div>loading...</div>} >
              <PublicLinks classes={classes}/>
            </Suspense>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PublicPage);
