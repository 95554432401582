export const APP_INIT = "@@app/INIT"
export const appInit = () => ({
    type: APP_INIT
})
export const APP_CLOSE = "@@app/CLOSE"
export const appClose = () => ({
    type: APP_CLOSE
})
export const APP_LOGIN = "@@app/LOGIN"
export const appLogin = () => ({
    type: APP_LOGIN
})

export const APP_UPDATE_STATUS = '@@app/UPDATE_STATUS'
export const appStatus = ( { description, message, code } ) => ({
    type: APP_UPDATE_STATUS,
    description: description, 
    message: message,
    code: code,
    visible: true
})



export const APP_CLEAR_STATUS = '@@app/CLEAR_APP_STATUS'
export const clearAppStatus = () => ({
    type: APP_CLEAR_STATUS,
    visible: false
})

export const APP_SHOW_NOTIFICATION = '@@app/APP_SHOW_NOTIFICATION'
export const showNotification = ({ description, message, code }) => ({
    type: APP_SHOW_NOTIFICATION,
    description: description,
    message: message,
    code: code
})

export const APP_HIDE_NOTIFICATION = '@@app/APP_HIDE_NOTIFICATION'
export const hideNotification = () => ({
    type: APP_HIDE_NOTIFICATION
})

export const APP_ASYNC_WAIT = '@@app/APP_ASYNC_WAIT'
export const startAsyncWait = () => ({
    type: APP_ASYNC_WAIT,
})

export const APP_ASYNC_DONE = '@@app/APP_ASYNC_DONE'
export const finishAsyncWait = () => ({
    type: APP_ASYNC_DONE,
})


export const APP_NEW_VERSION = '@@app/APP_NEW_VERSION'
export const newAppVersion = () => ({
    type: APP_NEW_VERSION,
    message:'A new version of Dicidio is now available',
    description: 'refresh the page to start using it',
})
