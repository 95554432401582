import { get, put, post, del } from '../../../actions'

/**
 * API Actions
 */


/**
 * Solutions
 */
export const GET_SOLUTIONS_REQUEST = '@@solutions/GET_SOLUTIONS_REQUEST'
export const GET_SOLUTIONS_SUCCESS = '@@solutions/GET_SOLUTIONS_SUCCESS'
export const GET_SOLUTIONS_FAILURE = '@@solutions/GET_SOLUTIONS_FAILURE'
export const getSolutions = (endpoint) => (
    get({
        displayName: 'get solutions',
        endpoint: endpoint,
        types: [
            GET_SOLUTIONS_REQUEST,
            GET_SOLUTIONS_SUCCESS,
            GET_SOLUTIONS_FAILURE]
    })
)

/**
 * Solution
 */
export const GET_SOLUTION_REQUEST = '@@solutions/GET_SOLUTION_REQUEST'
export const GET_SOLUTION_SUCCESS = '@@solutions/GET_SOLUTION_SUCCESS'
export const GET_SOLUTION_FAILURE = '@@solutions/GET_SOLUTION_FAILURE'
export const getSolution = (endpoint) => (
    get({
        displayName: 'get solution',
        endpoint: endpoint,
        types: [
            GET_SOLUTION_REQUEST,
            GET_SOLUTION_SUCCESS,
            GET_SOLUTION_FAILURE]
    })
)

export const GET_SOLUTION_DEF_REQUEST = '@@solutions/GET_SOLUTION_DEF_REQUEST'
export const GET_SOLUTION_DEF_SUCCESS = '@@solutions/GET_SOLUTION_DEF_SUCCESS'
export const GET_SOLUTION_DEF_FAILURE = '@@solutions/GET_SOLUTION_DEF_FAILURE'
export const getSolutionDefinition = (endpoint) => (
    get({
        displayName: 'get solution definition',
        endpoint: endpoint,
        types: [
            GET_SOLUTION_DEF_REQUEST,
            GET_SOLUTION_DEF_SUCCESS,
            GET_SOLUTION_DEF_FAILURE]
    })
)

export const UPDATE_SOLUTION_DEF_REQUEST = '@@solutions/UPDATE_SOLUTION_DEF_REQUEST'
export const UPDATE_SOLUTION_DEF_SUCCESS = '@@solutions/UPDATE_SOLUTION_DEF_SUCCESS'
export const UPDATE_SOLUTION_DEF_FAILURE = '@@solutions/UPDATE_SOLUTION_DEF_FAILURE'
export const updateSolutionDefinition = (endpoint, body) => (
    put({
        displayName: 'update solution definition',
        endpoint: endpoint,
        types: [
            UPDATE_SOLUTION_DEF_REQUEST,
            UPDATE_SOLUTION_DEF_SUCCESS,
            UPDATE_SOLUTION_DEF_FAILURE],
        body: JSON.stringify(body)
    })
)

const defaultSolution = {
    name: 'New Solution',
    description: 'New solution description'
}

export const CREATE_SOLUTION_REQUEST = '@@solutions/CREATE_SOLUTION_REQUEST'
export const CREATE_SOLUTION_SUCCESS = '@@solutions/CREATE_SOLUTION_SUCCESS'
export const CREATE_SOLUTION_FAILURE = '@@solutions/CREATE_SOLUTION_FAILURE'
export const createSolution = (endpoint, body = defaultSolution) => (
    post({
        displayName: 'create solution',
        endpoint: endpoint,
        types: [
            CREATE_SOLUTION_REQUEST,
            CREATE_SOLUTION_SUCCESS,
            CREATE_SOLUTION_FAILURE],
        body: JSON.stringify(body)
    })
)

export const UPDATE_SOLUTION_REQUEST = '@@solutions/UPDATE_SOLUTION_REQUEST'
export const UPDATE_SOLUTION_SUCCESS = '@@solutions/UPDATE_SOLUTION_SUCCESS'
export const UPDATE_SOLUTION_FAILURE = '@@solutions/UPDATE_SOLUTION_FAILURE'
export const updateSolution = (endpoint, body) => (
    put({
        displayName: 'update solution',
        endpoint: endpoint,
        types: [
            UPDATE_SOLUTION_REQUEST,
            UPDATE_SOLUTION_SUCCESS,
            UPDATE_SOLUTION_FAILURE],
        body: JSON.stringify(body)
    })
)

export const DELETE_SOLUTION_REQUEST = '@@solutions/DELETE_SOLUTION_REQUEST'
export const DELETE_SOLUTION_SUCCESS = '@@solutions/DELETE_SOLUTION_SUCCESS'
export const DELETE_SOLUTION_FAILURE = '@@solutions/DELETE_SOLUTION_FAILURE'
export const deleteSolution = (endpoint) => (
    del({
        displayName: 'delete solution',
        endpoint: endpoint,
        types: [
            DELETE_SOLUTION_REQUEST,
            DELETE_SOLUTION_SUCCESS,
            DELETE_SOLUTION_FAILURE]
    })
)

/* Versions */
export const GET_SOLUTION_VERSIONS_REQUEST = '@@solutions/GET_SOLUTION_VERSIONS_REQUEST'
export const GET_SOLUTION_VERSIONS_SUCCESS = '@@solutions/GET_SOLUTION_VERSIONS_SUCCESS'
export const GET_SOLUTION_VERSIONS_FAILURE = '@@solutions/GET_SOLUTION_VERSIONS_FAILURE'
export const getVersions = (endpoint) => (
    get({
        displayName: 'get versions',
        endpoint: endpoint,
        types: [
            GET_SOLUTION_VERSIONS_REQUEST,
            GET_SOLUTION_VERSIONS_SUCCESS,
            GET_SOLUTION_VERSIONS_FAILURE]
    })
)

export const GET_SOLUTION_VERSION_SOLUTION_REQUEST = '@@solutions/GET_SOLUTION_VERSION_SOLUTION_REQUEST'
export const GET_SOLUTION_VERSION_SOLUTION_SUCCESS = '@@solutions/GET_SOLUTION_VERSION_SOLUTION_SUCCESS'
export const GET_SOLUTION_VERSION_SOLUTION_FAILURE = '@@solutions/GET_SOLUTION_VERSION_SOLUTION_FAILURE'
export const getVersionSolution = (endpoint, versionWss) => (
    get({
        displayName: 'get version solution',
        endpoint: endpoint,
        types: [
            GET_SOLUTION_VERSION_SOLUTION_REQUEST,
            GET_SOLUTION_VERSION_SOLUTION_SUCCESS,
            GET_SOLUTION_VERSION_SOLUTION_FAILURE],
        correlation : {versionWss: versionWss}, 

    })
)

export const PUT_SOLUTION_VERSIONS_REQUEST = '@@solutions/PUT_SOLUTION_VERSIONS_REQUEST'
export const PUT_SOLUTION_VERSIONS_SUCCESS = '@@solutions/PUT_SOLUTION_VERSIONS_SUCCESS'
export const PUT_SOLUTION_VERSIONS_FAILURE = '@@solutions/PUT_SOLUTION_VERSIONS_FAILURE'
export const updateVersion = (endpoint, body) => (
    put({
        displayName: 'update version',
        endpoint: endpoint,
        types: [
            PUT_SOLUTION_VERSIONS_REQUEST,
            PUT_SOLUTION_VERSIONS_SUCCESS,
            PUT_SOLUTION_VERSIONS_FAILURE],
        body: JSON.stringify(body)
    })
)

export const GET_DEPLOYED_VERSIONS_REQUEST = '@@executions/GET_DEPLOYED_VERSIONS_REQUEST'
export const GET_DEPLOYED_VERSIONS_SUCCESS = '@@executions/GET_DEPLOYED_VERSIONS_SUCCESS' 
export const GET_DEPLOYED_VERSIONS_FAILURE = '@@executions/GET_DEPLOYED_VERSIONS_FAILURE'
export const getDeployedVersions = (endpoint, filter) =>(
    get({
        displayName: 'get deployed versions',
        endpoint: `${endpoint}/${filter}`,
        types: [
            GET_DEPLOYED_VERSIONS_REQUEST, 
            GET_DEPLOYED_VERSIONS_SUCCESS, 
            GET_DEPLOYED_VERSIONS_FAILURE
        ]
    })
)



export const CREATE_SOLUTION_VERSION_REQUEST = '@@solutions/CREATE_SOLUTION_VERSION_REQUEST'
export const CREATE_SOLUTION_VERSION_SUCCESS = '@@solutions/CREATE_SOLUTION_VERSION_SUCCESS'
export const CREATE_SOLUTION_VERSION_FAILURE = '@@solutions/CREATE_SOLUTION_VERSION_FAILURE'
export const createVersion = (endpoint) => (
    post({
        displayName: 'create version',
        endpoint: endpoint,
        types: [
            CREATE_SOLUTION_VERSION_REQUEST,
            CREATE_SOLUTION_VERSION_SUCCESS,
            CREATE_SOLUTION_VERSION_FAILURE]
    })
)

export const DEPLOY_SOLUTION_VERSION_REQUEST = '@@solutions/DEPLOY_SOLUTION_VERSION_REQUEST'
export const DEPLOY_SOLUTION_VERSION_SUCCESS = '@@solutions/DEPLOY_SOLUTION_VERSION_SUCCESS'
export const DEPLOY_SOLUTION_VERSION_FAILURE = '@@solutions/DEPLOY_SOLUTION_VERSION_FAILURE'
export const deployVersion = (endpoint) => (
    put({
        displayName: 'deploy version',
        endpoint: endpoint,
        types: [
            DEPLOY_SOLUTION_VERSION_REQUEST,
            DEPLOY_SOLUTION_VERSION_SUCCESS,
            DEPLOY_SOLUTION_VERSION_FAILURE]
    })
)


export const STOP_SOLUTION_VERSION_REQUEST = '@@solutions/STOP_SOLUTION_VERSION_REQUEST'
export const STOP_SOLUTION_VERSION_SUCCESS = '@@solutions/STOP_SOLUTION_VERSION_SUCCESS'
export const STOP_SOLUTION_VERSION_FAILURE = '@@solutions/STOP_SOLUTION_VERSION_FAILURE'
export const stopVersion = (endpoint) => (
    put({
        displayName: 'stop version',
        endpoint: endpoint,
        types: [
            STOP_SOLUTION_VERSION_REQUEST,
            STOP_SOLUTION_VERSION_SUCCESS,
            STOP_SOLUTION_VERSION_FAILURE]
    })
)

export const DELETE_SOLUTION_VERSION_REQUEST = '@@solutions/DELETE_SOLUTION_VERSION_REQUEST'
export const DELETE_SOLUTION_VERSION_SUCCESS = '@@solutions/DELETE_SOLUTION_VERSION_SUCCESS'
export const DELETE_SOLUTION_VERSION_FAILURE = '@@solutions/DELETE_SOLUTION_VERSION_FAILURE'
export const deleteVersion = (endpoint) => (
    del({
        displayName: 'delete version',
        endpoint: endpoint,
        types: [
            DELETE_SOLUTION_VERSION_REQUEST,
            DELETE_SOLUTION_VERSION_SUCCESS,
            DELETE_SOLUTION_VERSION_FAILURE]
    })
)

export const EXECUTE_SOLUTION_REQUEST = '@@solutions/EXECUTE_SOLUTION_REQUEST'
export const EXECUTE_SOLUTION_SUCCESS = '@@solutions/EXECUTE_SOLUTION_SUCCESS'
export const EXECUTE_SOLUTION_FAILURE = '@@solutions/EXECUTE_SOLUTION_FAILURE'
export const executeSolution = (endpoint, body) => (
    post({
        displayName: 'execute solution',
        endpoint: endpoint,
        types: [
            EXECUTE_SOLUTION_REQUEST,
            EXECUTE_SOLUTION_SUCCESS,
            EXECUTE_SOLUTION_FAILURE],
        body: JSON.stringify(body)
    })
)

/**
 * State Management for
 * Solution 
 */

export const CLEAR_EXECUTION  = '@@sde/CLEAR_EXECUTION'
export const clearExecution = ( id ) => ({
    type: CLEAR_EXECUTION,
    solutionId: id
})

export const UPDATE_DATAFIELDS = '@@sde/UPDATE_FIELDS'
export const updateFields = (id, dataset, fields) => {
    switch (dataset.name) {
        case 'Application':
            return updateApplicationFields(id, fields)
        case 'Runtime':
            return updateRuntimeFields(id, fields)
        default:
            return null
    }
}

export const UPDATE_SOLUTION_APPLICATION_FIELDS = '@@sde/UPDATE_SOLUTION_APPLICATION_FIELDS'
export const updateApplicationFields = (id, fields) => ({
    displayName: 'update application fields',
    type: UPDATE_SOLUTION_APPLICATION_FIELDS,
    solutionId: id,
    fields: fields,
})

export const UPDATE_SOLUTION_RUNTIME_FIELDS = '@@sde/UPDATE_SOLUTION_RUNTIME_FIELDS'
export const updateRuntimeFields = (id, fields) => ({
    displayName: 'update run time fields',
    type: UPDATE_SOLUTION_RUNTIME_FIELDS,
    solutionId: id,
    fields: fields,
})

export const UPDATE_SOLUTION_APPDATA_FIELDS = '@@sde/UPDATE_SOLUTION_APPDATA_FIELDS'
export const updateAppDataFields = (id, fields) => ({
    displayName: 'update application data fields',
    type: UPDATE_SOLUTION_APPDATA_FIELDS,
    solutionId: id,
    fields: fields,
})


/**
 * State Management for
 * Solution Definition
 */

export const UPDATE_SOLUTION_DIAGRAM = '@@solutions/UPDATE_SOLUTION_DIAGRAM'
export const updateSolutionDiagram = (id, diagram) => ({
    displayName: 'update solution diagram',
    type: UPDATE_SOLUTION_DIAGRAM,
    solutionId: id,
    diagram: diagram,
    undoable: true,
})

export const ADD_LINK = '@@diagram/ADD_LINK'
export const addLink = (id, link) => ({
    displayName: 'add link',
    type: ADD_LINK,
    solutionId: id,
    link: link
})

export const DELETE_LINK = '@@diagram/DELETE_LINK'
export const deleteLink = (id, link) => ({
    displayName: 'delete link',
    type: DELETE_LINK,
    solutionId: id,
    link: link
})
export const UPDATE_LINK = '@@diagram/UPDATE_LINK'
export const updateLink = (id, link) => ({
    displayName: 'update link',
    type: UPDATE_LINK,
    solutionId: id,
    link: link,
    undoable: true,
})

export const ADD_NODE = '@@diagram/ADD_NODE'
export const addNode = (id, node) => ({
    displayName: 'add node',
    type: ADD_NODE,
    solutionId: id,
    node: node
})
export const DELETE_NODE = '@@diagram/DELETE_NODE'
export const deleteNode = (id, node) => ({
    displayName: 'delete node',
    type: DELETE_NODE,
    solutionId: id,
    node: node
})
export const UPDATE_NODE = '@@diagram/UPDATE_NODE'
export const updateNode = (id, node) => ({
    displayName: 'update node',
    type: UPDATE_NODE,
    solutionId: id,
    node: node,
    undoable: true,
})

export const ZOOM_LEVEL = '@@diagram/ZOOM_LEVEL'
export const zoomLevel = ( id, zoom ) => ({
    type: ZOOM_LEVEL,
    solutionId: id,
    zoom: zoom,
    undoable: true,
})