import React from 'react'
import { GroupsTable } from './GroupsTable'
import  withStyles  from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'


export class GroupsPage extends React.Component {
    render() {
        return (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
                <GroupsTable />
            </Paper>
        )
    }
}

GroupsPage = withStyles( styles, { withTheme: true })(GroupsPage)