import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { EnhancedTable } from '../../../components/table'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel  from '@material-ui/core/FormControlLabel';

import * as actions from './actions'
import * as versionActions from '../Solutions/actions'
import * as p from '../../../actions/permissions'

export class DeployedVersionsTable extends React.Component {
    state = {
        showAll: false,
    }

    componentWillMount() {
        this.loadVersions(this.state.showAll)
    }

    loadVersions = (filter) => {
        if (p.permission(this.props.user, p.LIST_VERSIONS)) {
            this.props.getDeployedVersions(this.props.user._links[p.VERSIONS].href, filter)
        }
    }


    handleFilterChange = event => {
        this.setState({ showAll: event.target.checked });
        if (event.target.checked) {
            this.loadVersions("all") // we had already loaded the serving ones before
        }
    }

    render() {
        const versions = this.props.versions
            .filter( i => this.state.showAll || i._embedded.deploymentStatus === 'serving' ) 
        
        const columns = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: '_embedded.versionName'
            }, 
            {
                title: 'Description',
                key: 'description',
                dataIndex: '_embedded.versionDescription'
            },            
            {
                title :'Status',
                key:'status',
                dataIndex: '_embedded.deploymentStatus'
            },
        ]

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                if (this.props.selectionChanged) {
                    this.props.selectionChanged(selectedRowKeys)
                }
            },
        }
        return (
            <div className={this.props.className} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.showAll}
                            onChange={this.handleFilterChange} />
                    }
                    label="Show stopped versions"
                />

                <EnhancedTable
                    dataSource={versions}
                    rowKey={ record => record._embedded.webSafeString}
                    columns={columns}
                    pagination
                    rowSelection = {rowSelection}
                />
            </div>
        )
    }
}

DeployedVersionsTable = connect(
    state => ({
        versions: state.solutions.present.versions.deployedVersions ||[],
        user: state.user.profile,

    }),
    dispatch => (
        bindActionCreators({ ...versionActions, ...actions }, dispatch)
    )
)(DeployedVersionsTable)