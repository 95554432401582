import React from 'react'
import { isoToPrettyDate } from '../../../functions'
import _ from 'lodash'
import { SimpleTable } from '../../../components/table';
export class TrainingJobsTable extends React.Component {


    render() {

        const { jobs } = this.props
        const columns = [{
            title: 'Job Id',
            dataIndex: 'jobId',
            key: 'jobId',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                return _.capitalize(value)
            }
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (value) => {
                return isoToPrettyDate(value)
            }
        },
        {
            title: 'Completed',
            dataIndex: 'ended',
            key: 'ended',
            render: (value) => {
                return isoToPrettyDate(value)
            },
        }
        ]

        return (
            <div className={this.props.className}>
                <SimpleTable
                    dataSource={jobs}
                    columns={columns}
                    rowKey={record => record.jobId}
                />
            </div>
        )
    }
}
