import { arrayToObject, addUpdateItem, removeItem } from '../../../functions'
import * as actions from './actions'
import { createActionTypeReducer} from '../../../middleware'

const initialState = {
    items: {}
}

export const roleReducer = createActionTypeReducer({}, {
    [actions.CREATE_ROLE_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.UPDATE_ROLE_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem( state, action) }),
    [actions.DELETE_ROLE_SUCCESS]: (state, action) => ({ ...removeItem( state, action ) }),
})

export const rolesReducer = createActionTypeReducer( initialState, {
    [actions.GET_ROLES_SUCCESS]: (state, action) => ({ ...state, items: arrayToObject(action.payload.items) }),
},
    (state, action) => {
        return {
            ...state,
            items: roleReducer(state.items, action)
        }
    }
)
export const permissionsReducer = createActionTypeReducer(initialState, {
    [actions.GET_PERMISSIONS_SUCCESS]: (state, action) => ({ ...state, items: action.payload.items }),
},
    (state, action) => {
        return state
    }
)

