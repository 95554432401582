import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {Icon, Tooltip, IconButton} from '@material-ui/core'
import { EnhancedTable } from '../../../components/table'

import * as groupsActions from './actions'
import * as p from '../../../actions/permissions'
import * as organisationsActions from '../Organisations/actions'
import { EditInput, EditSelect } from '../../../components/table'
import _ from 'lodash'


export class GroupsTable extends React.Component {
    componentWillMount() {
        if (this.props.user._embedded) {
            if (p.permission(this.props.user, p.LIST_GROUPS)) {
                this.props.getGroups(this.props.user._links[p.GROUPS].href)
            }
            if (p.permission(this.props.user, p.LIST_ORGANISATIONS)) {
                this.props.getOrganisations(this.props.user._links[p.ORGANISATIONS].href)
            }
        }
    }

    render() {
        const { items, updateGroup, createGroup, deleteGroup } = this.props
        const orgs = _.map(this.props.organisations, (value, key) => {
            return { value: value._embedded.webSafeString, label: `${value._embedded.name} (${value._embedded.code})` }
        })

        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_GROUPS)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_GROUP)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_GROUP)

        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    if (record._embedded.code === 'ADMI') {
                        return (<React.Fragment>
                            {text}
                        </React.Fragment>)
                    }
                    else {
                        return canEdit ?
                            <EditInput
                                onChange={value =>
                                    updateGroup(record._links.self.href, { ...record._embedded, name: value })
                                }
                                value={text}
                                key={record._embedded.webSafeString} >
                                {text}
                            </EditInput>
                            : text
                    }
                }
            },
            {
                title: 'Description',
                dataIndex: '_embedded.description',
                key: 'description',
                render: (text, record) => {
                    if (record._embedded.code !== 'ADMI') {
                        return canEdit ?
                            <EditInput
                                onChange={value =>
                                    updateGroup(record._links.self.href, { ...record._embedded, description: value })
                                }
                                value={text}
                                key={record._embedded.webSafeString} >
                                {text}
                            </EditInput>
                            : text
                    }
                }
            },
            {
                title: 'Organisation',
                dataIndex: '_embedded.organisationWss',
                key: 'organisationWss',
                render: (text, record) => {
                    if (record._embedded.code !== 'ADMI') {
                        return canEdit ?
                            <EditSelect
                                values={orgs}
                                value={text}
                                onChange={value =>
                                    updateGroup(record._links.self.href, { ...record._embedded, organisationWss: value })
                                }

                            />
                            : text
                    }
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return canDelete ?
                        record._embedded.code !== 'ADMI' ?
                            <Tooltip title="Delete group" >
                                <IconButton aria-label="Delete group" onClick={e => { e.preventDefault(); deleteGroup(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                            </Tooltip> : null
                        : null
                }

            }
        ]

        return (
            <div className={this.props.className} >
                <EnhancedTable
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record._links.self.href}
                    canDelete={canDelete}
                    onDelete={e => deleteGroup(e._links.self.href)}
                    canCreate={canAdd}
                    onCreate={e => createGroup(this.props.user._links[p.GROUPS].href)}
                    pagination
                />
            </div >
        )
    }
}


GroupsTable = connect(
    state => ({ // Solutions are mapped in the model, but the UI needs an array
        items: Object.values(state.groups.items),
        organisations: state.organisations.items,
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators({ ...organisationsActions, ...groupsActions }, dispatch)
    )
)(GroupsTable)




