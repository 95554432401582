import React from 'react'
import { uuid } from '../../../functions'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import {isInteger} from '../../../functions'

export class ListScoreTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: props.values ? props.values : [],
        }
    }

    addScoreItem = (e) => {
        const newData = {
            value: '',
            score: '',
            reasonCode: '',
            id: uuid(),
        }
        this.updateValues([...this.state.values, newData])
    }

    removeScoreItem = (index) => {
        let values = this.state.values
        values.splice(index, 1)
        this.updateValues(values)
    }

    valueChanged = (e, index) => {
        let values = this.state.values
        values[index].value = e.target.value
        this.updateValues(values)
    }

    reasonCodeChanged = (e, index) => {
        let values = this.state.values
        values[index].reasonCode = e.target.value
        this.updateValues(values)
    }

    scoreChanged = (e, index) => {
        const { value } = e.target;
        if (isInteger(value)) {
            let values = this.state.values
            values[index].score = value
            this.updateValues(values)
        }
    }

    updateValues = (newValues) => {
        this.setState({ values: newValues })
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    render() {

        return (
            <div>

                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Value</TableCell>
                            <TableCell >Score</TableCell>
                            {/* <TableCell >Reason code</TableCell> */}
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.values.map((row, index) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row"
                                        padding='dense'
                                    >
                                        <Input value={row.value} onChange={(e) => this.valueChanged(e, index)} style={{ width: '100%' }} />
                                    </TableCell>
                                    <TableCell align='right' padding='dense'>
                                        <Input value={row.score} onChange={(e) => this.scoreChanged(e, index)} style={{ width: '100%' }} />
                                    </TableCell>
                                    {/* <TableCell padding='dense'>
                                        <Input value={row.reasonCode} onChange={(e) => this.reasonCodeChanged(e, index)} style={{ width: '100%' }} />
                                    </TableCell> */}
                                    <TableCell padding='dense'>
                                        <IconButton onClick={() => this.removeScoreItem(index)}
                                            className={`${this.props.classes.button} ${this.props.classes.gridButton}`}>
                                            <Icon>delete</Icon>
                                        </IconButton >
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Fab onClick={this.addScoreItem}
                    className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                    color="primary">
                    <Icon>add</Icon>
                </Fab >

            </div>
        )
    }
}