import * as actions from './actions'
import { AUTH_SUCCESS }  from '../../../Auth/actions'
import { arrayToObject, addUpdateItem, removeItem } from '../../../functions'
import { createActionTypeReducer} from '../../../middleware'

const initialState = {
    profile:{}
}

export const userReducer = ( state = initialState, action ) => {
    switch( action.type ){

        case actions.GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                profile: action.payload
            }
        }
        case AUTH_SUCCESS: {
            return {
                ...state,
                profile: action.payload,
            }
        }

        // case actions.UPDATE_USER_DASHBOARD:{
        //     let newProfile = {...state.profile}
        //     newProfile._embedded = {...state.profile._embedded }
        //     newProfile._embedded.dashboard = [...action.dashboard]
        //     return {
        //         ...state,
        //         profile: newProfile
        //     }
        // }

        default: {
            return state
        }
    }
}

export const userOneReducer = createActionTypeReducer({}, {
    [actions.CREATE_USER_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.UPDATE_USER_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.ADD_ROLE_TO_USER_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.REMOVE_ROLE_FROM_USER_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.DELETE_USER_SUCCESS]: (state, action) => ({ ...removeItem( state, action ) }),
})

export const usersReducer = createActionTypeReducer(initialState, {
    [actions.GET_USERS_SUCCESS]: (state, action) => ({ ...state, items: arrayToObject(action.payload.items) }),
    },
    (state, action) => {
        return {
            ...state,
            items: userOneReducer(state.items, action)
        }
    }
)