import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import {
    getPredictionConfigs,
} from '../../Admin/PredictionConfigs/actions'

import {
    permission,
    PREDICTIONS,
    LIST_PREDICTIONS,
} from '../../../actions/permissions'

import * as solutionVersionEditorActions from './../actions'

export class PredictionConfigSelector extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            value: props.value || { name: '', key: '' }
        }
    }

    onFieldSelected = (e, node) => {
        const key = e.target.value
        const item = this.props.predictionConfigs.items[key]
        const name = `${item._embedded.name} (${item._embedded.code})`
        this.setState({
            value: { name: name, key: key }
        })


        if (this.props.onChange) {
            this.props.onChange({ name: name, key: key })
        }
    }

    componentDidMount() {
        if (permission(this.props.user, LIST_PREDICTIONS)) {
            this.props.getPredictionConfigs(this.props.user._links[PREDICTIONS].href)
        }
        else {
            console.log("Don't have permission to get the list")
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({ value: nextProps.value });
        }
    }

    render() {
        const values = this.props.predictionConfigs.items ? Object.values(this.props.predictionConfigs.items).map((item, index) => {
            const key = item._embedded.webSafeString
            const name = `${item._embedded.name} (${item._embedded.code})`
            return (
                <MenuItem key={key} value={key}>{name}</MenuItem>
            )
        }) : []

        return (
            <div>
                <FormControl
                    style={{ width: '100%' }}
                    className={` ${this.props.classes.formControl} ${this.props.classes.componentDetailsTextField}`}
                    key={this.props}>
                    <InputLabel htmlFor={`.formControl`}>{this.props.label}</InputLabel>
                    <Select
                        value={this.state.value ? this.state.value.key : ''}
                        key={`.textfield`}
                        onChange={this.onFieldSelected}

                        inputProps={{
                            name: 'item.label',
                            id: `.formControl`,
                        }}
                    >
                        {values}
                    </Select>
                </FormControl>

            </div>
        );
    }
}

PredictionConfigSelector = connect(
    (state, props) => ({
        predictionConfigs: state.predictionConfigs,
        user: state.user.profile
    }),
    dispatch => (
        bindActionCreators({ getPredictionConfigs, ...solutionVersionEditorActions }, dispatch)
    )
)(PredictionConfigSelector)