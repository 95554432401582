import { AbstractPortFactory,  } from 'storm-react-diagrams'

// models
import { DecisionPortModel} from "../models"

export class PortFactory extends AbstractPortFactory {
		constructor() {
			super("links");
		}
	
		getNewInstance(initialConfig) {
			return new DecisionPortModel(true, "link");
		}
	}
	