export const SOLUTION_DIAGRAM = 'Solution Diagram'
// used by host editor
export const solutionPane = (id, component) => ({
    id: id,
    name: SOLUTION_DIAGRAM,
    documentType: SOLUTION_DIAGRAM,
    component: component,
})


export const INIT_SOLUTION_EDITOR = '@@sde/INIT_SOLUTION_EDITOR'
export const initSolutionEditor = ( id, payload ) => ({
    type: INIT_SOLUTION_EDITOR,
    documentId: id,
    payload: payload
})

export const QUIT_SOLUTION_EDITOR = '@@sde/QUIT_SOLUTION_EDITOR'
export const quitSolutionEditor = ( id ) => ({
    type: QUIT_SOLUTION_EDITOR,
    documentId: id,
})

export const SAVE_DIAGRAM = '@@diagram/SAVE_SOLUTION_DIAGRAM'
export const saveDiagram = ( id ) => ({
    type: SAVE_DIAGRAM,
    documentId: id,
})

export const EXECUTE_DIAGRAM = '@@sde/EXECUTE_DIAGRAM'
export const executeDiagram = (id) =>({
    type: EXECUTE_DIAGRAM,
    documentId: id,
})

/**
 *Execution handling
 */


export const LOAD_DIAGRAM = '@@sde/LOAD_DIAGRAM'
export const loadDiagram = (id) => ({
    type: LOAD_DIAGRAM,
    documentId: id,
})

/**
 * Editor actions directly on Document
 */
export const UPDATE_DOCUMENT = '@@sde/UPDATE_DOCUMENT'
export const updateDocument = ( id, document ) => ({
    type: UPDATE_DOCUMENT,
    documentId: id,
    document: document,
})

export const ADD_COMPONENT = '@@diagram/ADD_COMPONENT'
export const addComponent = ( id, component ) => ({
    type: ADD_COMPONENT,
    documentId: id,
    component: component
})

export const UPDATE_COMPONENT = '@@diagram/UPDATE_COMPONENT'
export const updateComponent = ( id, component ) => ({
    type: UPDATE_COMPONENT,
    documentId: id,
    component: component,
})

export const DELETE_COMPONENT = '@@diagram/DELETE_COMPONENT'
export const deleteComponent = ( id, component ) => ({
    type: DELETE_COMPONENT,
    documentId: id,
    component: component,
    undoable: true,
})

export const SELECTED_COMPONENT  = '@@diagram/SELECTED_COMPONENT'
export const selectedComponent = ( id, component) => ({
    type: SELECTED_COMPONENT,
    documentId: id,
    component: component,
})

export const DESELECTED_COMPONENT = '@@diagram/DESELECTED_COMPONENT'
export const deselectedComponent = ( id, component, diagram ) => ({
    type: DESELECTED_COMPONENT,
    documentId: id,
    component: component,
    diagram : diagram,
})
