import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export class EditSelect extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        value: this.props.value,
      }
    
    }
  
    handleChange = (event) => {
      this.setState({ value: event.target.value });
      if (this.props.onChange) {
        this.props.onChange(event.target.value);
      }
    }
  
  
    options = (values, parentKey) => {
      return values.map((item) => <MenuItem value={item.value} key={parentKey +"." +item.value}>{item.label}</MenuItem>)
    }
  
    render() {
      return (
            <Select 
              key={this.props.mkey}
              onChange={this.handleChange}
              value={this.state.value}
              style = {{width :"100%", }} 
            >
              {this.options(this.props.values, this.props.mkey)}
            </Select>
      );
    }
  }

  export class TypeSelect extends EditSelect {

    
    options = (values, parentKey) => {
      values = [
        {value:'String', label:'String'},
        {value:'Float', label:'Float'},
        {value:'Integer', label:'Integer'},
      
      ]
        return values.map((item) => <MenuItem value={item.value} key={parentKey +"." +item.value}>{item.label}</MenuItem>)
    }

  }