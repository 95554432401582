// map each array item to a dictionary uysing key

//selectors - action.?
const key = (k) => k._embedded.webSafeString

export const arrayToObject = (array) =>
  array.reduce(
    (obj, item) => ({
      ...obj, [key(item)]: item
    }), {}
  )


export const versionsResponseToObject = (state, versions) => {
  const solutionWss = versions[0] ? versions[0]._embedded.entityWss : undefined
  if (solutionWss) {
    state[solutionWss] = arrayToObject(versions)
  }
  return state
}

export const versionsExecutionsToObject = (state, versions) => {
  const solutionWss = versions[0] ? versions[0]._embedded.versionWss : undefined
  if (solutionWss) {
    state[solutionWss] = arrayToObject(versions)
  }
  return state
}


// adds the item keyed by key(), returns collection
export const addUpdateItem = (coll, action) => ({
  ...coll,
  [key(action.payload)]: { ...coll[key(action.payload)], ...action.payload},
})

// removes the item keyed by wss, returns collection
export const removeItem = (coll, action) => {
  const { [key(action.payload)]: value, ...rest } = coll
  return { ...rest }
}

/**
 * UUID
 */
const b = (a) => { return a ? ((a ^ Math.random() * 16) >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b) }
export { b as uuid }

/**
 * Pretty Date
 */
function convertISO8601toDate(dtstr) {

  if (!dtstr) {
    return '-'
  }
  // replace anything but numbers by spaces
  dtstr = dtstr.replace(/\D/g, " ");
  // trim any hanging white space
  dtstr = dtstr.replace(/\s+$/, "");
  // split on space
  var dtcomps = dtstr.split(" ");
  // not all ISO 8601 dates can convert, as is
  // unless month and date specified, invalid
  if (dtcomps.length < 3) return "invalid date";
  // if time not provided, set to zero
  if (dtcomps.length < 4) {
    dtcomps[3] = 0;
    dtcomps[4] = 0;
    dtcomps[5] = 0;
  }
  // modify month between 1 based ISO 8601 and zero based Date
  dtcomps[1]--;
  var convdt = new Date(dtcomps[0], dtcomps[1], dtcomps[2], dtcomps[3], dtcomps[4], dtcomps[5]);
  return convdt.toLocaleString();
}

export function firstLetterUpper(theString) {
  var newString = theString.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) { return c.toUpperCase() });
  return newString;
}

export function zeroFill(number, width) {
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
  }
  return number + ""; // always return a string
}


export function isInteger(value) {
  const reg = /^-?(0*|[0-9][0-9]*)?$/;
  // const reg = /^-?(0|[1-9][0-9]*)?$/;
  return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') 
}

export function isFloat(value) {
  const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
  return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') 
}

export function isAlphaNumUnderscore(value) {
  const reg = /^[a-zA-Z0-9_]+$/;
  return (reg.test(value) || value === '' ) 
}

export function isValidEmail(value) {
  const reg =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(value)  
}
export function isValidProjectId(value) {
  const reg =  /^\s*[a-z0-9-]+\s*$/
  return reg.test(value)  
}

export { convertISO8601toDate as isoToPrettyDate }
