import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export class DatasourceButtons extends React.PureComponent {
    render() {

        const formControlLabels = this.props.datasources.map(i => (
            <FormControlLabel key={i.name} value={i.name} control={<Radio />} label={i.name} />))

        return (
            <RadioGroup
                className={this.props.classes.group}
                value={this.props.value}
                onChange={this.props.onChange}
            >
                {formControlLabels}
            </RadioGroup>
        )
    }
}
