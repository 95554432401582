import "babel-polyfill";
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, history } from './middleware'
import ErrorBoundary from './components/ErrorBoundary'

import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

import { App } from './App'
import { newAppVersion }  from './App/actions'
import registerServiceWorker from './registerServiceWorker';

// pull all the css in at the beginning
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './index.css'
import Auth from './Auth/Auth';

const initialState = {}

const store = configureStore(initialState)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1890ff',
    },
    secondary: {
      main: '#f26430',
    },
  },
  typography: {
    useNextVariants: true,
  },

})

const auth = new Auth()

const onUpdate = () => {
  store.dispatch(newAppVersion())
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history} >
        <MuiThemeProvider theme={theme}>
          <App auth={auth}/>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary> 
  , document.getElementById('root'));
registerServiceWorker(onUpdate);
