import React from 'react'
import get from 'lodash/get'

import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'

import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import styles from '../../styles'

/**
 * SimpleTable - simple tabular data display
 * Header row, sorted columns, column render 
 *  
 * 
 */

function desc(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

class SimpleTable extends React.Component {

  state = {
    order: 'asc',
    orderBy: '',
    selectedRowKeys: [],
  }

  rowKey = (n) => n.key

  createSortHandler = property => event => {
    this.handleRequestSort(event, property)
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  canSelect =(rowId) => {
    return (this.props.canSelect && this.props.canSelect(this.props.dataSource[rowId]))
  }

  handleRowSelect = (rowId, event, ) => {

    let currentSelection = this.state.selectedRowKeys

    if (!this.canSelect(rowId)) {
      return
       
    }
    if (currentSelection.includes(rowId)) {
      currentSelection.splice(currentSelection.indexOf(rowId), 1)

    }
    else {
      // add it
      if (this.props.multiSelect) {
        currentSelection.push(rowId)
      }
      else {
        currentSelection = [rowId]
      }
    }
    this.setState({
      selectedRowKeys: currentSelection
    })
    if (this.props.onSelect) {
      this.props.onSelect(currentSelection)
    }
    if (this.props.onRowSelect)   {
      this.props.onRowSelect(rowId, event)
    }
  
  }

  render() {
    const { order, orderBy } = this.state
    const { classes } = this.props
    const dataSource = this.props.dataSource || []
    const columns  = this.props.columns || []
    const { showHeader } = this.props
    const rowKey = this.props.rowKey ? this.props.rowKey : this.rowKey

    const selectedRows = this.props.selectedRowKeys || this.state.selectedRowKeys
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle"
        >
        {showHeader!==false?
          <TableHead>
            <TableRow >

              {columns.map((row) => {
                return (
                  <TableCell
                    key={row.key}
                    align={row.numeric?'right':'left'}
                    sortDirection={orderBy === row.key ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === row.key}
                        direction={order}
                        onClick={this.createSortHandler(row.dataIndex)}
                      >
                        {row.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )
              }, this)}
            </TableRow>
          </TableHead>
           : null}
          <TableBody >
            {
              stableSort(dataSource, getSorting(order, orderBy))
                .map((n, index) => {
                  const rowId = rowKey(n)
                  const selected = selectedRows.includes(rowId)
                  return (
                    <TableRow
                      style = {{ cursor: this.canSelect(rowId)?'pointer':'default'}}
                      classes={{ selected: classes.tableRowSelected }}
                      onClick={(e) => { this.handleRowSelect(rowId, e) }}
                      tabIndex={-1}
                      key={rowId}
                      selected={selected}

                    >
                      {
                        columns.map((c, i) => {
                          return (
                            <TableCell component="th" scope="row" key={i} align={c.align||'left'}>
                              {c.render ? c.render(get(n, c.dataIndex), n, index) : get(n, c.dataIndex)}
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                }
                )
            }
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(SimpleTable)