import React from 'react'
import { Toolbar, AppBar, IconButton, Typography, Button, Menu, MenuItem, Icon, Avatar } from '@material-ui/core';
import classNames from 'classnames'
import { connect } from 'react-redux'
import { logout } from '../Auth/actions'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'

export class DicidioAppBar extends React.PureComponent {
    state = {
		anchorEl: null,
    }

    handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleClose = () => {
		this.setState({ anchorEl: null });
	}

    render () {

        const {classes, menuOpen} = this.props
		const { anchorEl, } = this.state

        return (
            <AppBar
            position="absolute"
            className={classNames(classes.appBar, menuOpen && classes.appBarShift)}
        >
            <Toolbar disableGutters={!menuOpen} className={classes.toolbar}>
            {!this.props.hideMenuIcon?
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.onDrawerOpen}
                    className={classNames(
                        classes.menuButton,
                        menuOpen && classes.menuButtonHidden,
                    )}
                >
                    <Icon>menu</Icon>
                </IconButton> 
            :<IconButton/>}
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                >
                    {this.props.title}
                </Typography>

                <div>
                    <Button
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <Avatar className={classes.avatar}>{this.props.user._embedded.firstName[0] + this.props.user._embedded.lastName[0]}</Avatar>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                        <MenuItem onClick={this.handleClose}>My account</MenuItem>
                        <MenuItem><Button onClick={this.props.logout}>Logout</Button></MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>

        )
    }
}

DicidioAppBar = withRouter(connect(
    (state, props) => ({
		user: state.user.profile,
	}),
	dispatch => (
		bindActionCreators({
			logout,
		}, dispatch)
	)
)(withStyles(styles, { withTheme: true })(DicidioAppBar)))




