import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as dsActions from '../../Admin/Datasources/actions'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import MenuItem from '@material-ui/core/MenuItem'
import styles from '../../../styles'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'


export class DataSourcesList extends React.Component {

    state = {value :''}

    componentDidMount() {
        this.props.getDatasources(this.props.user._links['datasources'].href)
        this.setState ({value: this.props.value})
    }

    onChange =(value) => {
        this.setState ({value: value})
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
    render() {
        const items = this.props.datasources ? this.props.datasources : []
        const selectItems = _.map(items, ((listItem, index) => {
            return (
                <MenuItem
                    key={'datasource_' + index} value={listItem._embedded.webSafeString}>{listItem._embedded.name}
                </MenuItem>
            )
        }))
        const { classes } = this.props;

        return (
            <div
                key={`datasource.div`}
            >
                <FormControl className={classes.formControl}
                    style={{ width: this.props.width ? this.props.width : '100%' }}
                    key={`datasource.formControl`}>
                    <InputLabel
                        className={classes.componentDetailsTextField}
                        htmlFor={`datasource.formControl`}>{this.props.label}</InputLabel>
                    <Select
                        className={classes.componentDetailsTextField}
                        value={this.state.value||''}
                        key={`datasource.textfield`}
                        onChange={e => this.onChange(e.target.value)}
                        inputProps={{
                            name: 'datasource.label',
                            id: `datasource.formControl`,
                        }}
                    >
                        {selectItems}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

DataSourcesList = connect(
    (state, props) => ({
        datasources: state.datasources.items,
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators({ ...dsActions }, dispatch)
    )
)(withStyles(styles, { withTheme: true })(DataSourcesList))

