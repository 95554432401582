import { createActionTypeReducer } from '../../middleware'
import reduceReducers from 'reduce-reducers'
import { solutionSelectionReducer } from './SolutionDiagramEditor/reducers'
import { predictionConfigEditorReducer } from './Predictions/reducers'

const initialState = {
    instances: {
        /** 
         * [id]: solutionVersion
         */
    },
}

const diagramReducers = reduceReducers(
    solutionSelectionReducer,
    predictionConfigEditorReducer,
)

/** 
 * reducer for diagram instances
 */

const diagramEditorReducer = createActionTypeReducer({}, {

}, (state, action) => {
    return action.documentId ? {
        ...state,
        [action.documentId]: { ...diagramReducers(state[action.documentId], action) }
    }
        : state
}
)


export const solutionEditorReducer = createActionTypeReducer(initialState, {
    
}, (state, action) => {
    return {
        ...state,
        instances: diagramEditorReducer(state.instances, action),
    }
})





