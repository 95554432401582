import { arrayToObject, addUpdateItem, removeItem} from '../../../functions'
import * as actions from './actions'
//import { createActionTypeReducer } from '../../../middleware';

const initialState = {
    items: {}
}

export function predictionConfigsReducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_PREDICTION_CONFIGS_SUCCESS: {
            return {
                ...state,
                items: arrayToObject(action.payload.items)
            }
        }

        // update
        case actions.CANCEL_PREDICTION_CONFIG_TRAINING_SUCCESS:
        case actions.START_PREDICTION_CONFIG_TRAINING_SUCCESS:
        case actions.UPDATE_PREDICTION_CONFIG_SUCCESS:
        case actions.CREATE_PREDICTION_CONFIG_SUCCESS:
        case actions.GET_PREDICTION_CONFIG_SUCCESS: {
            return {
                ...state,
                items: addUpdateItem(state.items, action)
            }
        }

        case actions.DELETE_PREDICTION_CONFIG_SUCCESS: {
            return {
                ...state,
                items: removeItem( state.items, action )
            }
        } 

        case actions.GET_PREDICTION_CONFIG_TRAINING_STATUS_SUCCESS: {
            return {
                ...state,
                trainingJob: {...action.payload, lastStatusUpdate : new Date()}
            }
        }

        case actions.GET_PREDICTION_CONFIG_TRAINING_LOGS_SUCCESS: {
            return {
                ...state,
                trainingLogs: {...action.payload}
            }
        }

        // update the training datasource reference
        case actions.UPDATE_PREDICTION_CONFIG_TRAINING_DATASOURCE: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.predictionId]: {
                        ...state.items[action.predictionId],
                        _embedded: {
                            ...state.items[action.predictionId]._embedded,
                            trainingDatasource: action.datasource,
                        }
                    }
                }
            }
        }

        case actions.UPDATE_PREDICTION_CONFIG_TEST_DATASOURCE: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.predictionId]: {
                        ...state.items[action.predictionId],
                        _embedded: {
                            ...state.items[action.predictionId]._embedded,
                            testDatasource: action.datasource,
                        }
                    }
                }
            }
        }

        case actions.UPDATE_PREDICTION_CONFIG_EVAL_DATASOURCE: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.predictionId]: {
                        ...state.items[action.predictionId],
                        _embedded: {
                            ...state.items[action.predictionId]._embedded,
                            evaluationDatasource: action.datasource,
                        }
                    }
                }
            }
        }

        case actions.UPDATE_PREDICTION_CONFIG_MAPPING: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.predictionId]: {
                        ...state.items[action.predictionId],
                        _embedded: {
                            ...state.items[action.predictionId]._embedded,
                            mapping: {
                                ...state.items[action.predictionId]._embedded.mapping,
                                ...action.mapping
                            },
                        }
                    }
                }
            }
        }

        
        // update the entire document
        case actions.UPDATE_PREDICTION_CONFIG_DOCUMENT: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.predictionId]: {
                        ...state.items[action.predictionId],
                        _embedded: {
                            ...state.items[action.predictionId]._embedded,
                            ...action.document,
                        }
                    }
                }
            }
        }


        default: return state
    }
}

/* 
const predictionsReducer = createActionTypeReducer( initialState, {
// [COLLECTION] : (state, action) => ({...state}),
},
(state, action)=>{
    return {
        ...state,
        items: predictionReducer(state, action)
    }
})

const predictionReducer = createActionTypeReducer( {}, {

}) */