import { get, put, post, del } from './methods'
import { makeApiType } from './methods'

/* standard method names */

export const LIST = 'list'
export const CREATE = 'create'
export const READ = 'read'
export const UPDATE = 'update'
export const DELETE = 'delete'

export const makeApiActionType = (entity, action) => ({[action.toLowerCase()]:makeApiType(entity, action)})

/** 
 * 
 * types[LIST][SUCCESS]
 */
export const makeApiActionTypes = (entity, actions = [LIST, CREATE, READ, UPDATE, DELETE]) => actions.reduce((acc, a) =>({...acc, ...makeApiActionType(entity, a)}), {})

const bodyTemplate = (entity) => ({
    name: `New ${entity}`,
    description: `A new ${entity}`
})

export const links = (entity) => (user) => {
    return user &&
    user._links &&
    user._links[`${entity}s`.toLowerCase()].href
}

/**
 * 
 * @param  entity Constant describing the system entity, i.e. 'SOLUTION'  
 * @param  types an array of types to use with each action (use makeApiActionTypes)
 * @param  newEntity The new body template to use in CREATE
 * @param  rest {...any, additional actions to add to action collection} 
 */

export const makeApiActions = (entity, types, actions, newEntity = bodyTemplate) => ({
    entity: entity,
    [LIST]: (endpoint) => (
        get({
            displayName: `${LIST} ${entity}s`,
            endpoint: endpoint,
            types: types[LIST]
        })
    ),
    [CREATE]: (endpoint) => (
        post({
            displayName: `${CREATE} ${entity}`,
            endpoint: endpoint,
            types: types[CREATE],
            body: JSON.stringify(newEntity(entity))
        })
    ),
    [READ]: (endpoint) => (
        get({
            displayName: `${READ} ${entity}`,
            endpoint: endpoint,
            types: types[READ],
        })
    ),
    [UPDATE]: (endpoint, organisation) => (
        put({
            displayName: `${UPDATE} ${entity}`,
            endpoint: endpoint,
            types: types[UPDATE],
            body: JSON.stringify(organisation)
        })
    ),
    [DELETE]: (endpoint) => (
        del({
            displayName: `${DELETE} ${entity}`,
            endpoint: endpoint,
            types: types[DELETE],
        })
    ),
    ...actions // other action creators 
})

