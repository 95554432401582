import React from 'react'
import { DatasourcesTable } from './DatasourcesTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'

export class DatasourcesPage extends React.Component {
    render(){
        return (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
                <DatasourcesTable  classes = {this.props.classes}/>
            </Paper>
        )
    }
}

DatasourcesPage = withStyles( styles, { withTheme: true})(DatasourcesPage)