import { createActionTypeReducer } from '../../middleware'

import * as actions from '../SolutionEditor/actions'

const initialState = {
}

export const adminPageReducer = createActionTypeReducer( initialState, {
    [actions.UPDATE_ADMIN_PAGE_TITLE]: (state, action) => ({ ...state, title : action.title }),
})

