import * as _ from "lodash"
import { NodeModel } from 'storm-react-diagrams'
import { DecisionPortModel } from "./DecisionPortModel"
import { uuid } from '../../../../../functions'


export class DecisionNodeModel extends NodeModel {

	constructor({
		type = 'Default',
		typeLabel,
		name = 'Untitled',
		label = typeLabel,
		color = "rgb(0,192,255)",
		icon,
		changeIcon,
		nodeProps = [
			{ field: 'label', label: 'Name' },
			{ field: 'color', label: 'color' },
		],
		ports = [
			{'in': []},
			{'out': []}
		]}) {
		// object factory identification
		super(type);
		// the visible name of this component type
		this.typeLabel = typeLabel
		// component properties
		/* fundamental properties of a DecisionNode */
		// user assignable name
		this.name = name;
		// distinguishing color
		this.color = color;
		// how the server identifies this component
		this.label = label;
		// the icon to use in compoent rendering
		this.icon = icon
		// do we show the change icon to indicate values have changed?
		this.changeIcon = changeIcon
		// the custom property types and edit control schema
		this.nodeProps = [...nodeProps]

		if( ports ){
			ports.in && ports.in.forEach( p => this.addInPort(p))
			ports.out && ports.out.forEach(p => this.addOutPort(p))
		}
		else {
			this.addInPort("in")
			this.addOutPort("out")
		}
	}

	addInPort(label) {
		return this.addPort(new DecisionPortModel(true, uuid(), label));
	}

	addOutPort(label) {
		return this.addPort(new DecisionPortModel(false, uuid(), label));
	}

	deSerialize(object, engine) {
		super.deSerialize(object, engine);
		this.name = object.name
		this.color = object.color
		this.label = object.label
		this.icon = object.icon
		this.changeIcon = object.changeIcon
		this.typeLabel = object.typeLabel
		// map the object props
		this.nodeProps = object.nodeProps
		this.nodeProps &&
			this.nodeProps.forEach(item => this[item.field] = object[item.field])
	}

	/**
	 * Never insert complex object heirarchies into nodeProps
	 * (unless you provide a serialization method for them)
	 * Serialization shallow copies the fundamental properties
	 * and then injects the enumerated NodeProps
	 */
	serialize() {
		// map the field names to object properties
		const props = this.nodeProps ?
			this.nodeProps.reduce((a, i) => ({ ...a, [i.field]: this[i.field] }), {})
			: null
		return _.merge(super.serialize(), {
			name: this.name,
			color: this.color,
			label: this.label,
			icon: this.icon,
			changeIcon : this.changeIcon,
			typeLabel: this.typeLabel,
			nodeProps: this.nodeProps,
			...props,
		});
	}

	getInPorts() {
		return _.filter(this.ports, portModel => {
			return portModel.in;
		});
	}

	getOutPorts() {
		return _.filter(this.ports, portModel => {
			return !portModel.in;
		});
	}
}
