import * as _ from "lodash";
import { LabelModel, } from 'storm-react-diagrams'


export class DecisionLabelModel extends LabelModel {
	label

	constructor() {
		super("label");
		this.offsetY = -23;
	}

	setLabel(label) {
		this.label = label;
	}

	deSerialize(ob, engine) {
		super.deSerialize(ob, engine);
		this.label = ob.label;
	}

	serialize() {
		return _.merge(super.serialize(), {
			label: this.label
		});
	}
}
