import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NewDatasourceDialog } from './NewDatasourceDialog'
import { EditInput } from '../../../components/table'

import { EnhancedTable } from '../../../components/table'

import Tooltip from '@material-ui/core/Tooltip'
import { Icon, IconButton, CircularProgress} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as actions from './actions'
import * as p from '../../../actions/permissions'

import _ from 'lodash'

export class DatasourcesTable extends React.Component {

    state = {
        deleteConfirmOpen: false,
        deleteBigQueryTable: false,
        selectedDatasourceLocation: undefined,
        new: false,
    }
    editDatasource = (id) => {
        this.setState({
            editId: id
        })
    }

    closeEditDatasource = (e) => {
        this.setState({
            editId: undefined
        })
    }

    createDatasource = () => {
        this.setState({
            new: true
        })
    }

    closeNewDatasourceDialog = () => {
        this.setState({
            new: false
        })
    }
    componentWillMount() {
        if (p.permission(this.props.user, p.LIST_DATASOURCES)) {
            this.props.getDatasources(this.props.user._links[p.DATASOURCES].href)
        }
    }

    deleteDialogOpen = (datasourceLocation) => {
        this.setState({
            deleteConfirmOpen: true,
            selectedDatasourceLocation : datasourceLocation,
            deleteBigQueryTable : false,
        })
    }

    handleDeleteDialogClose = () => {
        this.setState({
            deleteConfirmOpen: false,
            selectedDatasourceLocation: undefined,
            deleteBigQueryTable : false,

        })
    }

    handleDeleteDatasource = () => {
        this.props.deleteDatasource(this.state.selectedDatasourceLocation, this.state.deleteBigQueryTable)
        this.setState({ 
            deleteConfirmOpen: false, 
            selectedDatasourceLocation: undefined,
            deleteBigQueryTable: false })
    }

    handleUpdateRecordCount= (location, wss)=> {
        this.props.updateDatasourceRecordCount(location, wss)
    }

    render() {
        const { updateDatasource, updating } = this.props
        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_DATASOURCES)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_DATASOURCE)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_DATASOURCE)

        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    return canEdit ?
                        <EditInput
                            onChange={value => (
                                updateDatasource(record._links.self.href, { ...record._embedded, name: value }))
                            }
                            value={text}
                            key={record._embedded.webSafeString} >
                            {text}
                        </EditInput>
                        : { text }
                }

            },
            {
                title: 'Type',
                dataIndex: '_embedded.type',
                key: 'type',
                render: (text, record) => (
                    <React.Fragment>{`${record._embedded.type} ${record._embedded.tableType?`(${record._embedded.tableType})`:''}`}</React.Fragment>
                ),
            },
            {
                title: 'Table name',
                dataIndex: '_embedded.tableName',
                key: 'tableName'
            },
            {
                title: 'Record count',
                dataIndex: '_embedded.recordCount',
                key: 'recordCount',
                render: (text, record) => {
                    return (<React.Fragment>
                        {text>=0? text:'-'}

                        <IconButton onClick={()=>this.handleUpdateRecordCount(record._links.count.href,  record._embedded.webSafeString)}>
								<Icon>refresh</Icon>
							</IconButton>

                    <CircularProgress style = {{ display: updating.includes(record._embedded.webSafeString)?'':'none', verticalAlign:"middle"}}size={20}/>
                    </React.Fragment>)
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    canDelete ?
                        <Tooltip title="Delete datasource" >
                            <IconButton aria-label="Delete datasource" onClick={()=>this.deleteDialogOpen(record._links.self.href)}><Icon>delete</Icon></IconButton>
                        </Tooltip>
                        : null
                ),

            }]

        return (
            <div className={this.props.className}>
                <EnhancedTable
                    dataSource={this.props.items}
                    columns={columns}
                    rowKey={record => record._links.self.href}
                    canCreate={canAdd}
                    onCreate={this.createDatasource}
                    pagination
                />
                <NewDatasourceDialog
                    title="Create a new datasource"
                    onCancelComplete={this.closeNewDatasourceDialog}
                    onSaveComplete={this.closeNewDatasourceDialog}
                    open={this.state.new}
                    classes = {this.props.classes}
                />

                <Dialog
                    open={this.state.deleteConfirmOpen}
                    onClose={this.handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='xs'
                >
                    <DialogTitle id="alert-dialog-title">{"Delete datasource?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Would you like to delete this datasource?
                            <br />
                            <br />
                            {this.state.deleteBigQueryTable ? "The Google BigQuery table will also be deleted, this will affect any other datasources that connect to the same table." : "The Google BigQuery table will not be deleted."}
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.deleteBigQueryTable}
                                        onChange={e => { this.setState({ deleteBigQueryTable: e.target.checked }) }} />
                                }
                                label="Also delete the BigQuery table this datasource is linked to"
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteDialogClose} color="primary">Cancel</Button>
                        <Button onClick={this.handleDeleteDatasource} color="primary" autoFocus>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

DatasourcesTable = connect(
    state => ({
        items: Object.values(state.datasources.items),
        user: state.user.profile,
        updating : state.datasources.updating || [],
    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(DatasourcesTable)
