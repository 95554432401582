import { select, takeLatest, put, all } from 'redux-saga/effects'
import * as userActions from './User/actions'
import * as dashActions from './Home/actions'


function* updateUserDashboard( action ){
    // yield put( userActions.updateUserDashboard( action.dashboard ) )
    let user = yield select ( state =>state.user.profile )
    user._embedded.dashboard = action.dashboard
    yield put( userActions.updateUserProfile( user) )
}

function* watchUpdateDashboard(){
    yield takeLatest( dashActions.UPDATE_DASHBOARD, updateUserDashboard )
}

export function* adminSagas(){
    yield all([
        watchUpdateDashboard(),
    ])
}