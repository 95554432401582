import React from 'react'
import { connect } from 'react-redux'
import { FieldSelector } from './FieldSelector'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'
import * as _ from "lodash"
import { Typography } from '@material-ui/core';

export class PredictionConfigFieldMapper extends React.Component {
    constructor(props) {
        super(props)
        //if (props.value){
        this.state = {
            values: props.values || [],
            expanded : null,
        }
        //}
    }


    componentWillReceiveProps(nextProps) {
        if ('predictionConfig' in nextProps) {
            if (!this.props.predictionFieldMapping && nextProps.predictionConfig) {
                const predictionConfig = nextProps.predictionConfigs.items[nextProps.predictionConfig.key]
                if (predictionConfig) {
                    const inputFields = predictionConfig._embedded.mapping.inputFields
                    // merge them into  predictionFieldMapping
                    const predictionFieldMapping = _.merge(inputFields, nextProps.values)
                    this.setState({
                        values: predictionFieldMapping
                    })
                }
            }
        }

    }
    handleExpand = panel =>  {
        this.setState({
            expanded: this.state.expanded !==panel? panel: null
        })
    }

    getFieldName = (datasource, fieldUuid) =>{
        console.log("Fett", datasource, fieldUuid)
        if (!datasource  || datasource === 'Literal') {
            return fieldUuid
        }
        const datasourceFields=  _.find(this.props.datasources, ['name', datasource]) || []
        if (!datasourceFields || datasourceFields===[]) {
            return ""
        }
        return _.find(datasourceFields.datasource, ['key', fieldUuid]).name
    }
    renderFieldSelectors = (fieldsList, index) => {
        const datasources = this.props.datasources
        if (fieldsList ) {
            return _.map(fieldsList, (field, index) => {
                const fieldValue = (field.dsName?field.dsName:'') + '.' +  (field.dsFieldName?field.dsFieldName:'')
                return (

                    <ExpansionPanel key ={`panel.${index}`}  expanded={this.state.expanded === `panel.${index}`} onChange={()=>this.handleExpand(`panel.${index}`)}>
                        <ExpansionPanelSummary
                            classes={
                                {
                                    root: this.props.classes.fieldSelectorSummmaryRoot,
                                    expanded: this.props.classes.fieldSelectorSummmaryExpanded,
                                    content: this.props.classes.fieldSelectorSummmaryContent,
                                }}
                            expandIcon={<Icon>expand_more</Icon>}>
                            <div style={{ width: '100%' }}>
                            <Typography variant="body1">
                                {field.name} : {field.dsFieldName ?   this.getFieldName(field.dsName , field.dsFieldName) : (<i>not selected</i>)}
                            </Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: this.props.classes.fieldSelectorItem }}>
                            <FieldSelector
                                onChange={(e) => this.handleFieldSelectorChange(e, field)}
                                key={'PredictionConfigFieldMapper.' + field.name}
                                datasources={datasources}
                                fieldname={field.name}
                                classes={this.props.classes}
                                datasource={field.applicationField === true ? 'Application' : 'Runtime'}
                                value={fieldValue}
                                allowLiteral
                                inputLabel = 'Select input field'
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                )
            })

        }
        else {
            return (<div>Prediction configuration does not have any input fields defined</div>)
        }
    }

    handleFieldSelectorChange(value, field) {

        // predictionFieldMapping['name'] = dsFieldName
        let predictionFieldMapping = this.state.values
        const index = _.findIndex(predictionFieldMapping, ['name', field.name])
        const dsName = value.split('.')[0]
        const dsFieldName = value.split('.')[1]
        predictionFieldMapping[index].dsName = dsName 
        predictionFieldMapping[index].dsFieldName = dsFieldName

        
        this.setState({
            values: predictionFieldMapping
        })
        if (this.props.onChange) {
            this.props.onChange(predictionFieldMapping)
        }
    }

    render() {
        return (
            <div>
                <Typography variant="body1">Input fields</Typography>

                <br />
                {(this.state.values || this.state.source) ? this.renderFieldSelectors(this.state.values) : 'Loading...'}
            </div>
        );
    }
}

PredictionConfigFieldMapper = connect(
    (state, props) => ({
        predictionConfigs: state.predictionConfigs,

    }),
)(PredictionConfigFieldMapper)
