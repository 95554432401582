import * as _ from "lodash";
import { LinkModel, LabelModel } from 'storm-react-diagrams'

// models
import { DecisionLabelModel } from './DecisionLabelModel'

export class DecisionLinkModel extends LinkModel {

	constructor(type = "links") {
		super(type);
		this.color = "rgba(255,255,255,0.5)";
		this.width = 3;
		this.curvyness = 50;
		this.nodeProps = [{ field: 'conditionFields', label: 'Condition', type: 'linkCondition' },]
	}

	serialize() {
		// map the field names to object properties
		const props = this.nodeProps ?
			this.nodeProps.reduce((a, i) => ({ ...a, [i.field]: this[i.field] }), {})
			: null

		return _.merge(super.serialize(), {
			width: this.width,
			color: this.color,
			curvyness: this.curvyness,
			conditionFields: this.conditionFields,
			nodeProps: this.nodeProps,
			...props,
		});
	}

	deSerialize(ob, engine) {
		super.deSerialize(ob, engine)
		this.color = ob.color;
		this.width = ob.width;
		this.curvyness = ob.curvyness

		this.nodeProps = ob.nodeProps
		this.nodeProps &&
			this.nodeProps.forEach(item => this[item.field] = ob[item.field])

		this.conditionFields = ob.conditionFields
	}

	addLabel(label) {
		if (label instanceof LabelModel) {
			return super.addLabel(label);
		}
		let labelOb = new DecisionLabelModel();
		labelOb.setLabel(label);
		return super.addLabel(labelOb);
	}

	setWidth(width) {
		this.width = width;
		this.iterateListeners((listener, event) => {
			if (listener.widthChanged) {
				listener.widthChanged({ ...event, width: width });
			}
		});
	}

	setColor(color) {
		this.color = color;
		this.iterateListeners((listener, event) => {
			if (listener.colorChanged) {
				listener.colorChanged({ ...event, color: color });
			}
		});
	}
}
