import { get, put, post, del } from '../../../actions'

export const GET_DATASOURCES_REQUEST = '@@datasources/GET_DATASOURCES_REQUEST'
export const GET_DATASOURCES_SUCCESS = '@@datasources/GET_DATASOURCES_SUCCESS'
export const GET_DATASOURCES_FAILURE = '@@datasources/GET_DATASOURCES_FAILURE'
export const getDatasources = (endpoint) => (
    get({
        displayName: 'get datasources',
        endpoint: endpoint,
        types: [
            GET_DATASOURCES_REQUEST, 
            GET_DATASOURCES_SUCCESS, 
            GET_DATASOURCES_FAILURE]
    })
)

export const GET_DATASOURCE_REQUEST = '@@datasources/GET_DATASOURCE_REQUEST'
export const GET_DATASOURCE_SUCCESS = '@@datasources/GET_DATASOURCE_SUCCESS'
export const GET_DATASOURCE_FAILURE = '@@datasources/GET_DATASOURCE_FAILURE'
export const getDatasource = ( datasource ) => (
    get({
        displayName: 'get datasource',
        endpoint: datasource,
        types: [
            GET_DATASOURCE_REQUEST,
            GET_DATASOURCE_SUCCESS,
            GET_DATASOURCE_FAILURE]
    })
)

export const GET_DATASOURCE_META_DATA_REQUEST = '@@datasources/GET_DATASOURCE_META_DATA_REQUEST'
export const GET_DATASOURCE_META_DATA_SUCCESS = '@@datasources/GET_DATASOURCE_META_DATA_SUCCESS'
export const GET_DATASOURCE_META_DATA_FAILURE = '@@datasources/GET_DATASOURCE_META_DATA_FAILURE'
export const getDatasourceMetadata = ( location ) => (
    get({
        displayName: 'get datasource metadata',
        endpoint: location,
        types: [
            GET_DATASOURCE_META_DATA_REQUEST,
            GET_DATASOURCE_META_DATA_SUCCESS,
            GET_DATASOURCE_META_DATA_FAILURE]
    })
)

export const UPDATE_DATASOURCE_RECORD_COUNT = '@@datasources/UPDATE_DATASOURCE_RECORD_COUNT'
export const updateDatasourceRecordCount = ( location, webSafeString ) => (
    {
        type: UPDATE_DATASOURCE_RECORD_COUNT,
        location : location,
        webSafeString : webSafeString
    }
)

export const ADD_COUNTING_WSS = '@@datasources/ADD_COUNTING_WSS'
export const addCountingWss =(webSafeString) => (
    {
        type : ADD_COUNTING_WSS,
        webSafeString : webSafeString
    }
)

export const REMOVE_COUNTING_WSS = '@@datasources/REMOVE_COUNTING_WSS'
export const removeCountingWss =(webSafeString) => (
    {
        type : REMOVE_COUNTING_WSS,
        webSafeString : webSafeString
    }
)

export const UPDATE_DATASOURCE_RECORD_COUNT_REQUEST = '@@datasources/UPDATE_DATASOURCE_RECORD_COUNT_REQUEST'
export const UPDATE_DATASOURCE_RECORD_COUNT_SUCCESS = '@@datasources/UPDATE_DATASOURCE_RECORD_COUNT_SUCCESS'
export const UPDATE_DATASOURCE_RECORD_COUNT_FAILURE = '@@datasources/UPDATE_DATASOURCE_RECORD_COUNT_FAILURE'
export const updateDatasourceRecordCountApiRequest = (location) => (
    put({
        displayName: 'update datasource record count',
        endpoint: location,
        types: [
            UPDATE_DATASOURCE_RECORD_COUNT_REQUEST,
            UPDATE_DATASOURCE_RECORD_COUNT_SUCCESS,
            UPDATE_DATASOURCE_RECORD_COUNT_FAILURE],
    })
)

export const GET_DATASOURCE_OBJECTS_REQUEST = '@@datasources/GET_DATASOURCE_OBJECTS_REQUEST'
export const GET_DATASOURCE_OBJECTS_SUCCESS = '@@datasources/GET_DATASOURCE_OBJECTS_SUCCESS'
export const GET_DATASOURCE_OBJECTS_FAILURE = '@@datasources/GET_DATASOURCE_OBJECTS_FAILURE'
export const getDatasourceObjects = ( location, count ) => (
    get({
        displayName: 'get datasource objects',
        endpoint: location + '/' + count,
        types: [
            GET_DATASOURCE_OBJECTS_REQUEST,
            GET_DATASOURCE_OBJECTS_SUCCESS,
            GET_DATASOURCE_OBJECTS_FAILURE]
    })
)

export const UPDATE_DATASOURCE_REQUEST = '@@datasources/UPDATE_DATASOURCE_REQUEST'
export const UPDATE_DATASOURCE_SUCCESS = '@@datasources/UPDATE_DATASOURCE_SUCCESS'
export const UPDATE_DATASOURCE_FAILURE = '@@datasources/UPDATE_DATASOURCE_FAILURE'
export const updateDatasource = ( datasource, body ) =>(
    put({
        displayName: 'update datasource',
        endpoint: datasource,
        types: [
            UPDATE_DATASOURCE_REQUEST,
            UPDATE_DATASOURCE_SUCCESS,
            UPDATE_DATASOURCE_FAILURE],
        body: JSON.stringify(body)
    })
)


export const DELETE_DATASOURCE_REQUEST = '@@datasources/DELETE_DATASOURCE_REQUEST'
export const DELETE_DATASOURCE_SUCCESS = '@@datasources/DELETE_DATASOURCE_SUCCESS'
export const DELETE_DATASOURCE_FAILURE = '@@datasources/DELETE_DATASOURCE_FAILURE'
export const deleteDatasource = ( solution, deleteData ) => (
    del({
        displayName: 'delete datasource',
        endpoint: solution,
        types: [
            DELETE_DATASOURCE_REQUEST,
            DELETE_DATASOURCE_SUCCESS,
            DELETE_DATASOURCE_FAILURE],
        body: JSON.stringify({
         deleteData : deleteData})
    })
)


export const GET_BUCKETS_REQUEST = '@@datasources/GET_BUCKETS_REQUEST'
export const GET_BUCKETS_SUCCESS = '@@datasources/GET_BUCKETS_SUCCESS'
export const GET_BUCKETS_FAILURE = '@@datasources/GET_BUCKETS_FAILURE'
export const getBuckets = (endpoint) => (
    get({
        displayName: 'get buckets',
        endpoint: endpoint,
        types: [
            GET_BUCKETS_REQUEST, 
            GET_BUCKETS_SUCCESS, 
            GET_BUCKETS_FAILURE]
    })
)

export const GET_BUCKET_FILES_REQUEST = '@@datasources/GET_BUCKET_FILES_REQUEST'
export const GET_BUCKET_FILES_SUCCESS = '@@datasources/GET_BUCKET_FILES_SUCCESS'
export const GET_BUCKET_FILES_FAILURE = '@@datasources/GET_BUCKET_FILES_FAILURE'
export const getBucketFiles = (url, projectId, bucketName,filter = 'csv') => (
    post({
        displayName: 'get bucket files',
        endpoint: `${url}/${filter}`,
        types: [
            GET_BUCKET_FILES_REQUEST, 
            GET_BUCKET_FILES_SUCCESS, 
            GET_BUCKET_FILES_FAILURE],
        body: JSON.stringify({
                bucket:bucketName,
                projectId : projectId,
        })
    })
)

export const GET_BUCKET_FILE_DETAILS_REQUEST = '@@datasources/GET_BUCKET_FILE_DETAILS_REQUEST'
export const GET_BUCKET_FILE_DETAILS_SUCCESS = '@@datasources/GET_BUCKET_FILE_DETAILS_SUCCESS'
export const GET_BUCKET_FILE_DETAILS_FAILURE = '@@datasources/GET_BUCKET_FILE_DETAILS_FAILURE'
export const getFileDefinition = (url, projectId, bucketName, fileName,) => (
    post({
        displayName: 'get file definition',
        endpoint: `${url}`,
        types: [
            GET_BUCKET_FILE_DETAILS_REQUEST, 
            GET_BUCKET_FILE_DETAILS_SUCCESS, 
            GET_BUCKET_FILE_DETAILS_FAILURE],
            body: JSON.stringify({
                bucket:bucketName,
                projectId : projectId,
                fileName : fileName,
        })

    })
)

export const CREATE_DATASOURCE_REQUEST = '@@datasources/CREATE_DATASOURCE_REQUEST'
export const CREATE_DATASOURCE_SUCCESS = '@@datasources/CREATE_DATASOURCE_SUCCESS'
export const CREATE_DATASOURCE_FAILURE = '@@datasources/CREATE_DATASOURCE_FAILURE'
export const createLinkedDatasoureFromBucketFile = (endpoint, name, projectId, fileName, columns, selectedColumns) => (
    post({
        displayName: 'create linked datasource from bucket',
        endpoint: endpoint,
        types: [
            CREATE_DATASOURCE_REQUEST, 
            CREATE_DATASOURCE_SUCCESS, 
            CREATE_DATASOURCE_FAILURE],
        body: JSON.stringify({
            name:name,
            projectId : projectId, 
            fileName: fileName,
            columns : columns,
            selectedColumns : selectedColumns,
            type : 'LinkedStorage',
        })
    })
)

export const createBigQueryDatasoure = (endpoint, name, tableName, tableType) => (
    post({
        displayName: 'create Big Query datasource',
        endpoint: endpoint,
        types: [
            CREATE_DATASOURCE_REQUEST, 
            CREATE_DATASOURCE_SUCCESS, 
            CREATE_DATASOURCE_FAILURE],
        body: JSON.stringify({
            name:name,
            tableName: tableName,
            tableType : tableType, 
            type : 'BigQuery',
        })
    })
)
