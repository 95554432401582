import { takeEvery, put, all, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { notifyUserWarning, logAppError } from '../actions/messages'
import {
    getAuthorization,
    LOGOUT, LOGIN, logoutSuccess,
    LOGIN_SUCCESS,
    AUTH_REQUEST,
    AUTH_FAILURE,
    HANDLE_AUTHENTICATION,
    loginSuccess,
} from './actions'

import Auth from '../Auth/Auth'
import { ApiAction } from '../actions/methods';

const auth = new Auth()

export function* authorize(action) {
    try {
        yield localStorage.setItem('access_token', action.payload.accessToken)
        yield localStorage.setItem('access_token_expity', action.payload.idTokenPayload.exp)
        yield put(getAuthorization())
    } catch (e) {
        logAppError('Auth', 'invalid payload at authorization')
    }
}

export function* handleAuthenticationAction() {
    try {
        const authResponse = yield call(auth.handleAuthentication)
        yield put(loginSuccess(authResponse))
    }
    catch (error) {
        yield deauthorize()
    }

}

export function* reauthorize(oldAction) {
    try {
        const authResult = yield auth.renewSession()
        yield localStorage.setItem('access_token', authResult.accessToken)
        yield localStorage.setItem('access_token_expity', authResult.idTokenPayload.exp)
        yield put(ApiAction(oldAction.meta.redo))
    }
    catch (error) {
        yield console.log("reauthorising failed", error)
        yield deauthorize()
    }

}
export function* deauthorize(action) {
    yield console.log("deauthorize action", action)
    yield localStorage.removeItem('access_token')
    yield localStorage.removeItem('access_token_expity')
    yield console.log("action.redirect", action && action.redirect)
    yield auth.logOut('/login') // redirect to the /login page after the logout completed
}

// LOGOUT -> DEAUTH
export function* watchLogout() {
    yield takeEvery(LOGOUT, deauthorize)

}

function* goOffline(action) {
    yield put(notifyUserWarning('Currently Offline', 'please try again soon.'))
    yield put(push('./offline'))
}

export function* watchLogin() {
    yield takeEvery(LOGIN, auth.login)
    yield takeEvery(LOGIN_SUCCESS, authorize)
    yield takeEvery(HANDLE_AUTHENTICATION, handleAuthenticationAction)
}

export function* relogin() {
    yield put(logoutSuccess()) // simulate successful logout
    yield put(push('/login'))
}

export function* watchAuthErrors() {

    yield takeEvery(action =>
        action.type === AUTH_REQUEST
        && action.error !== undefined
        && action.error === true
        , goOffline
    )

    yield takeEvery(action => (
        action.type !== AUTH_FAILURE
        && action.payload
        && action.payload.status
        && action.payload.status === 401)
        , reauthorize
    )
    yield takeEvery(action => (
        action.type === AUTH_FAILURE
        && action.payload
        && action.payload.status
        && action.payload.status === 404)
        , deauthorize
    )
    yield takeEvery(action =>
        action.payload &&
        action.payload.status === 403 &&
        (action.meta &&
            action.meta.displayName &&
            action.meta.displayName !== 'update profile')
        , relogin
    )
}


export function* authSagas() {
    yield all([
        watchLogin(),
        watchLogout(),
        watchAuthErrors(),
    ])
}