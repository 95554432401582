import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as _ from 'lodash'
import { DatasourceSelector } from './DatasourceSelector'
import { TrainingJobsTable } from './TrainingJobsTable'
import { PredictionColumnsEditor } from './PredictionColumnsEditor'
import {  uuid } from '../../../functions'

import * as pcActions from '../../Admin/PredictionConfigs/actions'
import * as dsActions from '../../Admin/Datasources/actions'
import * as solEditorActions from '../actions'
import * as messages from '../../../actions/messages'
import { isoToPrettyDate } from '../../../functions'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { Paper} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import * as p from '../../../actions/permissions'

import styles from '../../../styles'


export class PredictionConfigEditor extends React.Component {

	state = {
		selectedTab: 0,
		scheduleType: 'fixedTime',
		interval: 60,
		time : "00:00",
	}

	getInputFields = (predictionConfigMapping) => {
		if (predictionConfigMapping.selectedRowKeys) {
			const inputFields = predictionConfigMapping.selectedRowKeys.map(key => {
				const column = _.find(predictionConfigMapping.columns, { key: key })
				return column
			})
			return inputFields
		}
		else {
			return predictionConfigMapping.columns
		}
	}

	getActionButtons(currentStatus) {
		const predictionConfigType = this.props.predictionConfig ? this.props.predictionConfig._embedded.type : 'standard'
		const canRead = _.includes(this.props.user._embedded.permissions, p.READ_PREDICTION)
       	const canEdit = canRead && _.includes(this.props.user._embedded.permissions, p.UPDATE_PREDICTION)
		const canTrain = canEdit && _.includes(this.props.user._embedded.permissions, p.TRAIN_PREDICTION)
		const canDeploy = canEdit && _.includes(this.props.user._embedded.permissions, p.DEPLOY_PREDICTION)
		return (<React.Fragment>
			{canEdit?<Button
				onClick={this.save}
				className={this.props.classes.button}
				color='primary'
				key='Save'
			>Save</Button>:null}
			{predictionConfigType === 'standard' ?
				<React.Fragment>
				{canTrain?
					<React.Fragment>
					<Button
						onClick={() =>
							this.props.startPredictionConfigTraining(this.state.predictionConfig._links.train.href, this.state.predictionConfig._embedded)}
						className={this.props.classes.button}
						color='primary'
						key='Train'
						disabled={currentStatus === 'training'}

					>Train</Button>
					<Button
						onClick={() =>
							this.props.cancelPredictionConfigTraining(this.state.predictionConfig._links.cancelTraining.href)}
						className={this.props.classes.button}
						color='primary'
						key='Cancel training'
						disabled={currentStatus !== 'training'}
					>Cancel training</Button>
					</React.Fragment>
					:null}
					{canDeploy?
					<Button
						onClick={() =>
							this.props.deployPredictionConfig(this.state.predictionConfig._links.deploy.href)}
						className={this.props.classes.button}
						color='primary'
						key='Deploy'
						disabled={currentStatus !== 'trained'}
					>Deploy</Button>
					:null}

				</React.Fragment> :
				<React.Fragment>
					<Button
						onClick={() =>
							this.props.startPredictionConfigScheduler(this.state.predictionConfig._links.train.href, this.state.predictionConfig._embedded)}
						className={this.props.classes.button}
						color='primary'
						key='StartScheduler'
						disabled={currentStatus !== 'schedulerStopped'}
					>Start scheduler</Button>
					<Button
						onClick={() =>
							this.props.stopPredictionConfigScheduler(this.state.predictionConfig._links.train.href, this.state.predictionConfig._embedded)}
						className={this.props.classes.button}
						color='primary'
						key='StopScheduler'
						disabled={currentStatus !== 'schedulerRunning'}
					>Stop scheduler</Button>

				</React.Fragment>
			}

		</React.Fragment>
		)
	}


	save = () => {
		// first we need to organise the selected columns in the mapping
		let predictionConfig = this.state.predictionConfig
		const inputFields = this.getInputFields(predictionConfig._embedded.mapping)
		predictionConfig._embedded.trainingInputFields = inputFields

		// find the label column
		const labelColumn = _.find(predictionConfig._embedded.mapping.columns, { isLabelColumn: true })
		if (labelColumn) {
			// set the label column
			predictionConfig._embedded.mapping.labelField = labelColumn
		}
		if (_.includes(this.props.user._embedded.permissions, p.UPDATE_PREDICTION)) {
			this.props.updatePredictionConfig(predictionConfig._links.self.href, predictionConfig._embedded)
		}
	}

	componentDidMount() {
		if (this.props.user._links[p.DATASOURCES]) {
		this.props.getDatasources(this.props.user._links[p.DATASOURCES].href)
		}
		if (_.includes(this.props.user._embedded.permissions, p.READ_PREDICTION)) {
			this.props.getPredictionConfigById(`${this.props.user._links[p.PREDICTIONS].href}/${this.props.match.params.id}`)
		}
	}

	componentWillUnmount() {
		this.props.updateAdminPageTitlePane(null)
	}

	shouldComponentUpdate(nextProps, nextState) {
		const predictionConfig = nextProps.predictionConfigs ? nextProps.predictionConfigs[this.props.match.params.id] : null
		if (predictionConfig) {
			if (!this.state.predictionConfig) {
				if (_.includes(this.props.user._embedded.permissions, p.TRAIN_PREDICTION)) {
					this.props.getPredictionConfigTrainingJobStatus(predictionConfig._links.trainingStatus.href)
				}
				this.setState({
					...nextState,
					predictionConfig: predictionConfig
				})

				return false
			}
			const nextTitle = <React.Fragment>
				<Button className={this.props.classes.backButton} onClick={() => this.props.push('/admin/predictions/configs')}>

					<i id='nodeIcon' className='material-icons' >keyboard_arrow_left</i>
				</Button>
				{predictionConfig._embedded.name}
			</React.Fragment>

			if (!nextProps.title) {
				this.props.updateAdminPageTitlePane(nextTitle)
			}
		}
		return true
	}

	combineColumns(dataSource, mapping) {

		const newMapping = {
			columns: [],
			inputFields: []
		}

		const convertDsColumn = (dsColumnType) => {
			dsColumnType = _.upperCase(dsColumnType)
			switch (dsColumnType) {
				case 'INTEGER':
				case 'BOOLEAN':
				case 'DOUBLE':
				case 'STRING':
					return dsColumnType
				case 'REAL':
				case 'FLOAT':
					return 'FLOAT'
				default:
					console.log("can't convert " + dsColumnType)
					return 'STRING'
			}
		}

		if (!dataSource) {
			// no change
			mapping.columns = []
			mapping.inputFields = []
			return mapping
		}

		if (!mapping) {
			// no change
			mapping.columns = []
			mapping.inputFields = []
		}

		// update the list with all columns
		// remove any mapping.columns that aren't in the dataSource and add any that aren't yet in the mapping.columns

		if (mapping.columns === []) {
			newMapping.columns = { ...dataSource._embedded.columns }
		}

		// mapping.columns intersects datasource.allColumns [1,2].[2,3] = [2]
		const intersection = [..._.intersectionBy(mapping.columns, dataSource._embedded.columns, 'name')]

		// intersection union with datasource.allColumns [1,2].[2,3] = [1,2,3]
		newMapping.columns = [..._.unionBy(intersection, dataSource._embedded.columns, 'name')]

		// transform columns
		_.forEach(newMapping.columns, column => {
			// convert type string = STRING
			column.type = convertDsColumn(column.type)
			// update key - used for selected rows and other table operations
			if (!column.key) column.key = uuid()
		})

		// we might have selectedRowKeys from the onChange of the selected columns
		newMapping.selectedRowKeys = mapping.selectedRowKeys || []
		// mapping input fields override mapping selected rows
		if (mapping.inputFields && _.toArray(mapping.inputFields).length > 0) {

			_.forEach(mapping.columns, column => {
				if (_.find(mapping.inputFields, ['name', column.name]) !== undefined) {
					newMapping.selectedRowKeys.push(column.key)
				}
			})
		}

		// now find the label column and mark it in the dataset
		if (mapping.labelField && _.find(newMapping.columns, ['name', mapping.labelField.name])) {

			_.find(newMapping.columns, ['name', mapping.labelField.name]).isLabelColumn = true
		}
		return newMapping
	}

	onSubmit(e) {
		e.preventDefault();
		this.props.updatePredictionConfig(this.props.predictionConfig._links.self.href, this.getPredictionConfigValues())
	}

	handeDsChange = (source, newValue) => {
		let predictionConfig = this.state.predictionConfig
		switch (source) {
			case 'trainingDatasource':
				predictionConfig._embedded.trainingDatasource = newValue
				break
			case 'testDatasource':
				predictionConfig._embedded.testDatasource = newValue
				break
			case 'evaluationDatasource':
				predictionConfig._embedded.evaluationDatasource = newValue
				break
			case 'eventsDatasource':
				predictionConfig._embedded.eventsDatasource = newValue
				break
			default:
		}
		this.setState({
			predictionConfig: predictionConfig

		})
	}

	handleMappingChange = (mapping) => {
		let predictionConfig = this.state.predictionConfig

		predictionConfig._embedded.mapping = mapping
		this.setState({
			predictionConfig: predictionConfig

		})
	}

	handleTabChange = (event, value) => {
		this.setState({ selectedTab: value })
	}

	handleScheduleTypeChange = (event) => {
		this.setState({ scheduleType: event.target.value })
	}

	handleIntervalChange = (event) => {
		this.setState({ interval: event.target.value })
	}

	handleDayOfWeekChange = day => event => {
		this.setState({ [day]: event.target.checked })
	}

	handleTimeChange = e => {
		this.setState ({time: e.target.value})
	}

	render() {

		const predictionConfig = this.state.predictionConfig

		const { trainingJob } = this.props
		const { selectedTab } = this.state
		const predictionConfigType = predictionConfig ? predictionConfig._embedded.type : '-'

		const datasources = this.props.datasources || []
		const trainDatasource = predictionConfig ? datasources[predictionConfig._embedded.trainingDatasource] : {}
		const treeData = _.map(datasources, datasource => {
			return {
				value: datasource._embedded.webSafeString,
				key: datasource._embedded.webSafeString,
				title: datasource._embedded.name,
				type: datasource._embedded.type,
				tableType: datasource._embedded.tableType,
			}
		})

		const mapping = predictionConfig && trainDatasource ?
			this.combineColumns(trainDatasource, predictionConfig._embedded.mapping)
			: {}


		const jobStatus = () => {
			if (this.state.predictionConfig._embedded.statusMessage) {
				return _.capitalize(this.state.predictionConfig._embedded.statusMessage)
			}
			else {
				return "Configuring"
			}
		}

		const renderOverview = () => {
			return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
				<Typography variant='h5' gutterBottom>
					Prediction configuration ({_.startCase(predictionConfigType)})
					</Typography>
				<Typography variant='body1' gutterBottom>
				Status: {jobStatus()}
				</Typography>
				<br />

				<Typography variant='h5' gutterBottom>
					Training job <Tooltip title='Refresh training job status'><IconButton
						onClick={() => this.props.getPredictionConfigTrainingJobStatus(predictionConfig._links.trainingStatus.href)}
						classes={{ root: this.props.classes.refreshIconSmall }}
						aria-label='Refresh training job status'><Icon>autorenew</Icon></IconButton></Tooltip>
				</Typography>
				{trainingJob ?
					<div style = {{height :'100%'}}>
					<Typography variant='body1' gutterBottom>
						Prediction Config status: {_.capitalize(trainingJob.status)}
						<br/><br/>
						Job id: {trainingJob.jobId}
						<br/><br/>
						Started: {isoToPrettyDate(trainingJob.created)} 
						<br/><br/>
						Finished:{isoToPrettyDate(trainingJob.ended)}
						<br/><br/>
						{trainingJob.lastStatusUpdate ?
							`Last status update: ${trainingJob.lastStatusUpdate.toLocaleString()}`
							: null}
						<br/><br/>
						</Typography>
					</div>
					:
					<Typography variant='body1' gutterBottom>
					Prediction Config status: No training job has been started for this prediction configuration
					<br/><br/>
					</Typography>
				}
			</Paper>
		}
		const renderStandardDatasources = () => {
			return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
				<Typography variant='h5' gutterBottom>
					Datasources configuration
				</Typography>
				{(this.state.predictionConfig && this.state.predictionConfig._embedded) ?
					<div style = {{height :'100%'}}>
						<DatasourceSelector
							label='Training data'
							datasources={treeData}
							value={this.state.predictionConfig._embedded.trainingDatasource||''}
							onChange={(e) => this.handeDsChange('trainingDatasource', e)}
						/>
						<br/>
						{/* <DatasourceSelector
							label='Test data'
							datasources={treeData}
							value={this.state.predictionConfig._embedded.testDatasource||''}
							onChange={(e) => this.handeDsChange('testDatasource', e)}
						/>
						<br/> */}
						<DatasourceSelector
							label='Evaluation data'
							datasources={treeData}
							value={this.state.predictionConfig._embedded.evaluationDatasource||''}
							onChange={(e) => this.handeDsChange('evaluationDatasource', e)}
						/>
						<br/>
					</div> : null}
			</Paper>
		}

		const analyticsTreeData = treeData.filter(element => { return element.tableType === 'predictiveAnalytics' })



		const renderAnalyticsEventsConfig = () => {
			return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
				<Typography variant='h5' gutterBottom>
					Events datasource
				</Typography>
				{(predictionConfig && predictionConfig._embedded) ?
					<div style = {{height :'100%'}}>
						<DatasourceSelector
							// label='Training data'
							datasources={analyticsTreeData}
							value={predictionConfig._embedded.eventsDatasource}
							onChange={(e) => this.handeDsChange('eventsDatasource', e)}
						/>
						<dl><dt>(Only predictive analytics datasources can be selected)</dt></dl>

						<Typography variant='h5' gutterBottom>
							Event fields
						</Typography>
						<PredictionColumnsEditor
							mapping={predictionConfig._embedded.mapping}
							onChange={this.handleMappingChange}
							canAdd
							canDelete
							canEditName
						/>
					</div> : null}
			</Paper>
		}

		const renderFieldMappings = () => {
			return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
				<Typography variant='h5' gutterBottom>
					Datafields
				</Typography>
				<PredictionColumnsEditor
					mapping={mapping}
					onChange={this.handleMappingChange}
				/>
			</Paper>
		}

		const renderTrainingJobs = () => {
			return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
				<Typography variant='h5' gutterBottom>
					Machine Learning training jobs
				</Typography>
				<TrainingJobsTable jobs={this.state.predictionConfig._embedded.jobs||[]} />
			</Paper>
		}

		// const renderScheduleConfig = () => {
		// 	return <Paper className={this.props.classes.adminTablePaper} elevation={1}>
		// 	schedule goes here
		// 	</Paper>
		// }

		return (
			<React.Fragment>
				{this.getActionButtons(trainingJob ? trainingJob.status : null)}
				<Tabs 
					value={selectedTab}
					onChange={this.handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
				>

					<Tab label='Overview' />
					<Tab label='Datasources' />
					{/* <Tab label='Field mappings' />  */}
					{predictionConfigType === 'standard' ? <Tab label='Field mappings' /> : undefined}
					<Tab label='Jobs' />
				</Tabs>
				{predictionConfig ?
					<div style = {{height :'100%'}}>

						{selectedTab === 0 && renderOverview()}
						{selectedTab === 1 && predictionConfigType === 'standard' && renderStandardDatasources()}
						{selectedTab === 1 && predictionConfigType === 'analyticsPrediction' && renderAnalyticsEventsConfig()}
						{selectedTab === 2 && predictionConfigType === 'standard' && renderFieldMappings()}
						{/* {selectedTab === 2 && predictionConfigType === 'analyticsPrediction' && renderScheduleConfig()} */}
						{selectedTab === 3 && renderTrainingJobs()}
					</div>
					: null
				}
			</React.Fragment>
		)
	}

}

PredictionConfigEditor = connect(
	(state, props) => ({
		datasources: state.datasources.items,
		predictionConfigs: state.predictionConfigs.items,
		trainingJob: state.predictionConfigs.trainingJob,
		user: state.user.profile,
		title: state.adminPage.title,
	}),
	dispatch => (
		bindActionCreators({ push, ...solEditorActions, ...pcActions, ...dsActions, ...messages }, dispatch)
	)
)(withStyles(styles, { withTheme: true })(PredictionConfigEditor))

