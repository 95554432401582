import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import styles from '../../../styles'

const data = [
    {
        key: 'EasyRule',
        model: {
            typeLabel: 'Easy Rule',
            name: 'EasyRule',
            type: 'DecisionNode',
            color: "rgb(204, 0, 204)",
            icon: 'check_circle',
            changeIcon: true,
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'conditionFields', label: 'If', type: 'linkCondition' },
                { type: 'divider' },
                { field: 'thenFields', label: 'Then', type: 'runtimeFieldSetter' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: 'Calculation',
        model: {
            typeLabel: 'Calculation',
            name: 'Calculation',
            type: 'DecisionNode',
            icon: 'ballot',
            changeIcon: true,
            color: "rgb(124, 122, 155)",
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'info1Label0', label: 'Only Integer and Float fields can be used in calculations', type: 'infoText' },
                { field: 'calculations', label: 'Calculation', type: 'calculator', required: true },
                { field: 'resultField', label: 'Result field', type: 'runtimeFieldSelector' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: 'setState',
        model: {
            typeLabel: 'Set State',
            name: 'SetState',
            type: 'DecisionNode',
            icon: 'edit',
            changeIcon: true,
            color: "rgb(190, 200, 100)",
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'thenFields', label: 'Fields to set', type: 'runtimeFieldSetter' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },

    // {
    //     key: '10',
    //     model: {
    //         typeLabel: 'Capture Event',
    //         name: 'MassStorage',
    //         type: 'DecisionNode',
    //         icon: 'save',
    //         changeIcon: true,
    //         color: "rgb(124, 122, 155)",
    //         nodeProps: [
    //             { field: 'label', label: 'Name', type: 'text' },
    //             { field: 'datasource', label: 'Datasource', type: 'datasourceSelector',  source:'datasources', filter :'BigQuery' , required: true },
    //             { field: 'info1Label0', label : '(Only Prediction Analytics Datasource can be selected)', type :'infoText'},
    //         ],
    //         ports: {
    //             'in': ['in'],
    //             'out': ['out'],
    //         }
    //     }
    // },
    // {
    //     key: '11',
    //     model: {
    //         typeLabel: 'Analytics Transformation',
    //         name: 'BeamTransformation',
    //         type: 'DecisionNode',
    //         icon: 'functions',
    //         changeIcon: true,
    //         color: "rgb(90, 222, 55)",
    //         nodeProps: [
    //             { field: 'label', label: 'Name', type: 'text' },
    //             { field: 'pipeline', label: 'Beam pipeline', type: 'list', values: ['Analytics'], defaultValue: 'Analytics' },
    //             { field: 'inputDsWss', label: 'Input table', type: 'datasourceSelector',  source:'datasources', filter :'BigQuery.AnalyticsEvents' , required: true },
    //             { field: 'recordsCount', label: 'Number of analytics records to collate', type: 'integerInput', required: true },
    //             { field: 'startDateField', label: 'Start date field', type: 'fieldSelector', required: true },
    //             { field: 'endDateField', label: 'End date field', type: 'fieldSelector', required: true },
    //             { field: 'runOptionsList', label : 'Attached data fields to be extracted', kLabel : 'Field name', kType: 'alphaNumUnderscoreInput', vLabel : 'Type', type :'kvSelectList', required: true, vItems:[{label:'String', value:'String'},{label:'Integer', value:'Integer'},{label:'Float', value:'Float'},{label:'Boolean', value:'Boolean'}]},
    //             { field: 'info1Label0', label : 'Attached data field can only contain numbers, letter and underscores(_)', type :'infoText'},

    //             { field: 'outputTableName', label: 'Output table name', type: 'alphaNumUnderscoreInput',  source:'datasources', filter :'BigQuery.AnalyticsRecords' , required: true },
    //             { field: 'info1LabelTableName', label : 'A table name can only contain letters, numbers and underscores (_)', type :'infoText'},
    //             { field: 'info1Label1', label : 'A new BigQuery datasource with this name will be created for the output table if one doesn\'t exist yet.', type :'infoText'},
    //             { field: 'createTrainingData', label: 'Create training and test data', type: 'checkBox' },
    //             { field: 'info1Label2', label : 'New BigQuery datasources will be created for test and training data using the output table\'s name with the suffixes ".test" and ".training".', type :'infoText'},
    //         ],
    //         ports: {
    //             'in': ['in'],
    //             'out': ['out'],
    //         }
    //     }
    // },
    // {
    //     key: '12',
    //     model: {
    //         typeLabel: 'ML training',
    //         name: 'MLTraining',
    //         type: 'DecisionNode',
    //         icon: 'build',
    //         changeIcon: true,
    //         color: "rgb(190, 200, 100)",
    //         nodeProps: [
    //             { field: 'label', label: 'Name', type: 'text' },
    //             { field: 'predictionConfig', label: 'Prediction Configuration', type: 'predictionConfigSelector', },
    //             { field: 'schedule', label: 'Scheduling', type: 'scheduler', },
    //         ],
    //         ports: {
    //             'in': ['in'],
    //             'out': ['out'],
    //         }
    //     }
    // },
    {
        key: '5',
        model: {
            typeLabel: 'Range Score',
            name: 'DecisionTable',
            type: 'DecisionNode',
            color: "rgb(04, 222, 200)",
            icon: 'list',
            changeIcon: true,
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'criteriaField', label: 'Criteria field', type: 'fieldSelector' },
                { field: 'decisionTable', label: 'Scores', type: 'scoreTable' },
                { type: 'divider' },
                { field: 'scoreField', label: 'Score field', type: 'runtimeFieldSelector' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: '6',
        model: {
            typeLabel: 'List Score',
            name: 'ListDecisionTable',
            type: 'DecisionNode',
            icon: 'ballot',
            changeIcon: true,
            color: "rgb(04, 222, 100)",
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'criteriaField', label: 'Criteria field', type: 'fieldSelector' },
                { field: 'decisionTable', label: 'Scores', type: 'listScoreTable' },
                { type: 'divider' },
                { field: 'scoreField', label: 'Score field', type: 'runtimeFieldSelector' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: '4',
        model: {
            typeLabel: "Web call",
            name: 'Http',
            type: 'DecisionNode',
            icon: 'cloud_download',
            changeIcon: true,
            color: "rgb(144, 192, 0)",
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text', },
                { field: 'httpMethod', width: '40%', label: 'Request method', type: 'list', values: ['GET', 'POST', 'PUT', 'DELETE'], defaultValue: 'GET', required: false },
                { field: 'url', label: 'Request URL', type: 'inputWithFieldSelector', multiline: true, required: true },
                { field: 'body', label: 'Request content', type: 'editor' },
                { type: 'divider' },
                { field: 'outputs', label: 'Response mapping', type: 'outputsList' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: '8',
        model: {
            typeLabel: 'Prediction',
            name: 'Prediction',
            type: 'DecisionNode',
            color: "rgb(34, 123, 204)",
            icon: 'rounded_corner',
            changeIcon: true,
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
                { field: 'predictionConfig', label: 'Prediction Configuration', type: 'predictionConfigSelector', },
                { field: 'inputFields', label: 'Input fields', type: 'predictionConfigFieldMapper', predictionConfig: 'predictionConfig' },
                { type: 'divider' },
                { field: 'scoreField', label: 'Score field', type: 'runtimeFieldSelector' },
            ],
            ports: {
                'in': ['in'],
                'out': ['out'],
            }
        }
    },
    {
        key: '3',
        model: {
            typeLabel: 'End',
            name: 'End',
            type: "DecisionNode",
            icon: 'stop',
            changeIcon: false,
            color: "rgb(255,192,0)",
            nodeProps: [
                { field: 'label', label: 'Name', type: 'text' },
            ],
            ports: {
                'in': ['in'],
            }
        },
    },


]


export class ComponentPalette extends React.Component {

    render() {
        const { classes } = this.props
        return (
            <div className={classes.componentPaletteLayout} >
                {data.map((item) => (
                    <div
                        id={item.key}
                        key={item.key}
                        className={classNames([classes.componentPaletteItem, "srd-default-node"])}

                        style={{
                            backgroundColor: item.model.color,
                        }}

                        draggable={true}
                        onDragStart={event => {
                            item.model.label = item.model.typeName
                            event.dataTransfer.setData("diagram-node", JSON.stringify(item.model));
                        }}
                    >
                        <div className='srd-default-node__title ' >
                            <div className='srd-default-node__name '>
                                <i id='nodeIcon' className="material-icons">{item.model.icon}</i>
                            </div>
                        </div>
                        <div className={classes.componentPaletteItemLabel} >{item.model.typeLabel}</div>
                    </div>
                ))}
            </div>
        )
    }
}

ComponentPalette = withStyles(styles, { withTheme: true })(ComponentPalette)
