import { get, put, post, del } from '../../../actions'

// export const UPDATE_USER_DASHBOARD = '@@user/UPDATE_USER_DASHBOARD'
// export const updateUserDashboard = ( dashboard ) => ({
//     displayName: 'update Dashboard',
//     type: UPDATE_USER_DASHBOARD,
//     dashboard
// })

export const GET_USER_PROFILE_REQUEST = '@@user/GET_USER_PROFILE_REQUEST' 
export const GET_USER_PROFILE_SUCCESS = '@@user/GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILURE = '@@user/GET_USER_PROFILE_FAILURE'
export const getUserProfile = ( user ) =>( 
    get({
        displayName: 'get profile',
        endpoint: user._links.self.href,
        types: [
            GET_USER_PROFILE_REQUEST,
            GET_USER_PROFILE_SUCCESS,
            GET_USER_PROFILE_FAILURE,
        ],
    })
)

export const UPDATE_USER_PROFILE_REQUEST = '@@user/UPDATE_USER_PROFILE_REQUEST' 
export const UPDATE_USER_PROFILE_SUCCESS = '@@user/UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = '@@user/UPDATE_USER_PROFILE_FAILURE'
export const updateUserProfile = ( user ) =>( 
    put({
        displayName: 'update profile',
        endpoint: user._links.self.href,
        types: [
            UPDATE_USER_PROFILE_REQUEST,
            UPDATE_USER_PROFILE_SUCCESS,
            UPDATE_USER_PROFILE_FAILURE,
        ],
        body: JSON.stringify( user._embedded )
    })
)

export const GET_USERS_REQUEST = '@@user/GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = '@@user/GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = '@@user/GET_USERS_FAILURE'
export const getUsers = (endpoint) => (
    get({
        displayName: 'get users',
        endpoint: endpoint,
        types: [
            GET_USERS_REQUEST,
            GET_USERS_SUCCESS,
            GET_USERS_FAILURE,
        ],
    })
)

export const CREATE_USER_REQUEST = '@@user/CREATE_USER_REQUEST' 
export const CREATE_USER_SUCCESS = '@@user/CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = '@@user/CREATE_USER_FAILURE'
export const createNewUser = (endpoint, newUser) => (
    post({
        displayName: 'create new user',
        endpoint: endpoint,
        types: [
            CREATE_USER_REQUEST,
            CREATE_USER_SUCCESS,
            CREATE_USER_FAILURE,
        ],
        body: JSON.stringify(newUser)
    })
)


export const REINVITE_USER_REQUEST = '@@user/REINVITE_USER_REQUEST' 
export const REINVITE_USER_SUCCESS = '@@user/REINVITE_USER_SUCCESS'
export const REINVITE_USER_FAILURE = '@@user/REINVITE_USER_FAILURE'
export const reinviteUser = (endpoint) => (
    post({
        displayName: 'reinvite user',
        endpoint: endpoint,
        types: [
            REINVITE_USER_REQUEST,
            REINVITE_USER_SUCCESS,
            REINVITE_USER_FAILURE,
        ]
    })
)

export const UPDATE_USER_REQUEST = '@@user/UPDATE_USER_REQUEST' 
export const UPDATE_USER_SUCCESS = '@@user/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = '@@user/UPDATE_USER_FAILURE'
export const updateUser = ( endpoint, user ) => (
    put({
        displayName: 'update user',
        endpoint: endpoint,
        types: [
            UPDATE_USER_REQUEST,
            UPDATE_USER_SUCCESS,
            UPDATE_USER_FAILURE,
        ],
        body: JSON.stringify(user)
    })
)

export const DELETE_USER_REQUEST = '@@user/DELETE_USER_REQUEST' 
export const DELETE_USER_SUCCESS = '@@user/DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = '@@user/DELETE_USER_FAILURE'
export const deleteUser = ( endpoint ) => (
    del({
        displayName: 'delete user',
        endpoint: endpoint,
        types: [
            DELETE_USER_REQUEST,
            DELETE_USER_SUCCESS,
            DELETE_USER_FAILURE,
        ]
    })
)

export const ADD_ROLE_TO_USER_REQUEST = '@@user/ADD_ROLE_TO_USER_REQUEST' 
export const ADD_ROLE_TO_USER_SUCCESS = '@@user/ADD_ROLE_TO_USER_SUCCESS'
export const ADD_ROLE_TO_USER_FAILURE = '@@user/ADD_ROLE_TO_USER_FAILURE'
export const addRoleToUser = ( endpoint, roleWss ) => (
    post({
        displayName: 'add role to user',
        endpoint: `${endpoint}/${roleWss}`,
        types: [
            ADD_ROLE_TO_USER_REQUEST,
            ADD_ROLE_TO_USER_SUCCESS,
            ADD_ROLE_TO_USER_FAILURE,
        ]
    })
)

export const REMOVE_ROLE_FROM_USER_REQUEST = '@@user/REMOVE_ROLE_FROM_USER_REQUEST' 
export const REMOVE_ROLE_FROM_USER_SUCCESS = '@@user/REMOVE_ROLE_FROM_USER_SUCCESS'
export const REMOVE_ROLE_FROM_USER_FAILURE = '@@user/REMOVE_ROLE_FROM_USER_FAILURE'
export const removeRoleFromUser = ( endpoint, roleWss ) => (
    del({
        displayName: 'remove role from user',
        endpoint: `${endpoint}/${roleWss}`,
        types: [
            REMOVE_ROLE_FROM_USER_REQUEST,
            REMOVE_ROLE_FROM_USER_SUCCESS,
            REMOVE_ROLE_FROM_USER_FAILURE,
        ]
    })
)

