import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// App Reducers
import { loginReducer, authReducer } from "../Auth";
import { appReducer } from "../App";

// Admin Reducers
import { groupsReducer } from "../App/Admin/Groups";
import { rolesReducer, permissionsReducer } from "../App/Admin/Roles";
import { datasourcesReducer } from "../App/Admin/Datasources";
import { organisationsReducer } from "../App/Admin/Organisations";
import { solutionReducers } from "../App/Admin/Solutions/reducers";
import { userReducer, usersReducer } from "../App/Admin/User";
import { predictionConfigsReducer } from "../App/Admin/PredictionConfigs/reducers";
import { experimentConfigsReducer } from "../App/Admin/Experiments/reducers";
import { executionsReducer } from "../App/Admin/Executions";
import { adminPageReducer } from "../App/Admin";
// Design Reducers
import { solutionEditorReducer } from "../App/SolutionEditor/reducers";
import undoable from "redux-undo";

const undoConfig = {
  limit : 50,
  filter: function filterActions(action, currentState, previousHistory) {
    return action.undoable
  }
};


export const reducers = (history) => combineReducers({
  router: connectRouter(history),
  application: appReducer,
  datasources: datasourcesReducer,
  groups: groupsReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  login: loginReducer,
  solutions: undoable(solutionReducers, undoConfig),
  executions: executionsReducer,
  user: userReducer,
  users: usersReducer,
  organisations: organisationsReducer,
  adminPage: adminPageReducer,
  auth: authReducer,
  diagrams: solutionEditorReducer,
  predictionConfigs: predictionConfigsReducer,
  experiments : experimentConfigsReducer,
  // this needs to be last - dont ask
});

// selectors - up to, but not including payload._embedded/._links
// select(solution(id)) or state => solution: solution(id)(state)
export const user = _ => state => state.user.profile;
export const solution = id => state => state.solutions.present.items[id];
export const version = id => state => state.versions.items[id];
export const diagram = id => state => state.diagrams.instances[id];
export const role = id => state => state.diagram.roles[id];
export const menu = id => state => state.application.menus[id];
