import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import {Snackbar, IconButton, Icon}  from '@material-ui/core'

import { firstLetterUpper } from '../../functions'

import styles from '../../styles'

class SimpleSnackbar extends React.Component {

  render() {
    const { classes, message, description, onRequestClose }  = this.props;
    return  (
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={this.props.visible}
      autoHideDuration={this.props.duration || 6000}
      onClose={onRequestClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{firstLetterUpper(message+" - "+description)}</span>}
      action={[
//         <Button key="undo" color="secondary" size="small" onClick={this.props.onRequestClose}>
//          UNDO
//        </Button>,
         <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={this.props.onRequestClose}
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    />
    ) ;
  }
}

export default withStyles(styles)(SimpleSnackbar);