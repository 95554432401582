import React from 'react'
import { connect } from 'react-redux'
import styles from '../../../styles'
import { withStyles } from '@material-ui/core/styles'
import { isInteger, isFloat } from '../../../functions'
import _ from 'lodash'
import { Input, Table, TableHead, TableBody, TableRow, TableCell, Select, MenuItem, Checkbox } from '@material-ui/core'

export class ApplicationDataEditor extends React.Component {

  handleValueChange = (type, newValue, index, field) => {
    if (
      type &&
      ((type.toLowerCase() === "integer" && isInteger(newValue)) ||
        (type.toLowerCase() === "float" && isFloat(newValue)) ||
        type.toLowerCase() === "string")
    ) {
      let values = this.getValues();
      if (type.toLowerCase() === "integer") {
        values[index][field] = newValue
      } else if (type.toLowerCase() === "float") {
        values[index][field] = newValue
      } else {
        values[index][field] = newValue
      }

      if (this.props.onUpdate) {
        this.props.onUpdate(values)
      }
    } 
    else if (type === "checkbox") {
      let values = this.getValues()
      values[index][field] = newValue
      values[index]['value'] = null
      this.props.onUpdate && this.props.onUpdate(values)
    }
    else if (type ==='dsField') {
        let values = this.getValues();
        values[index][field] = newValue;
        this.props.onUpdate && this.props.onUpdate(values);
    }
  };

  getValues = () => {
    const { solution } = this.props;
    if (!solution) {
      return [];
    }
    const dataFields = solution.applicationDatasetFields;
    const data = this.props.values || solution.applicationTestData || [];
    let retFields = dataFields
      ? dataFields.map(item => {
          const field = _.find(data, ["key", item.key]);
          const value = field ? field.value : "";
          const useDatasource = field ? field.useDatasource === true : "false";
          return {
            ...item,
            value: value,
            useDatasource: useDatasource
          };
        })
      : [];
    return retFields;
  };

  render() {
    const values = this.getValues();
    const valueField = (row, index) => {
        const optionsList = (items) =>{
            return items.map( i => (
            <MenuItem
            key={i.key}
            value={i.key}
          >
            {`${i.name} (${i.type})` }
          </MenuItem>
        ))
      }
      const filterDsFields = (type) =>{
        if (type && type.toLowerCase() === 'integer') {
            return this.props.dsFields.filter(i=>{return i.type.toLowerCase()==='integer' })
        }
        else if (type && type.toLowerCase() === 'float') {
            return this.props.dsFields.filter(i=>{return i.type.toLowerCase()==='integer' || i.type.toLowerCase()==='real' })
        }
        else {
            return this.props.dsFields
        }
      }
      return (
          <React.Fragment>
            <TableCell
              padding="none"
              classes={{ root: this.props.classes.scoreCardCellRoot }}
            >
              <Checkbox
                onChange={e =>
                  this.handleValueChange(
                    "checkbox",
                    e.target.checked,
                    index,
                    "useDatasource"
                  )
                }
                checked={row.useDatasource === true}
              />
            </TableCell>
            <TableCell
              padding="none"
              classes={{ root: this.props.classes.scoreCardCellRoot }}
            >
              {row.useDatasource===true ? (
                    <Select
                    value={row.value ||''}
                    key={`${this.props}.textfield`}
                    onChange={e=> this.handleValueChange('dsField', e.target.value, index , 'value')}
                    inputProps={{
                      name: "item.label",
                      id: `.formControl`
                    }}
                    style={{ width: "100%" }}
                  >{optionsList(filterDsFields(row.type))}</Select>
              ) : (
                <Input
                  value={row.value || ""}
                  onChange={e =>
                    this.handleValueChange(
                      row.type,
                      e.target.value,
                      index,
                      "value"
                    )
                  }
                  style={{ width: "100%" }}
                />
              )}
            </TableCell>
          </React.Fragment>
      );
    }

    return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding='none'>Name</TableCell>
                        <TableCell padding='none'>Type</TableCell>
                        {this.props.dsFields?
                          <React.Fragment>
                            <TableCell padding='none'>Use datasource</TableCell>
                            <TableCell padding='none'>Value</TableCell>
                          </React.Fragment>
                        :
                        <TableCell padding='none'>Value</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {values.map((row, index) => {
                        return (
                            <TableRow key={row.key}>
                                <TableCell component="th" scope="row" padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                    {row.name}
                                </TableCell>
                                <TableCell padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                    {row.type}
                                </TableCell>
                                {this.props.dsFields?
                                  valueField(row, index) :
                                <TableCell padding='none' classes={{ root: this.props.classes.scoreCardCellRoot }}>
                                    <Input value={row.value} onChange={(e) => this.handleValueChange(row.type, e.target.value, index, 'value')} style={{ width: '100%' }} />
                                </TableCell>
                                }
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>)
    }
  }


ApplicationDataEditor = connect(
    (state, props) => ({
        solution: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition
    }),
    null
)(withStyles(styles, { withTheme: true })(ApplicationDataEditor))