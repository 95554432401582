import React from 'react'
import { UsersTable } from './UsersTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'

export class UsersAdminPage extends React.Component {
    
    render() {
        const {classes} = this.props
        return (
            <Paper className={classes.adminTablePaper} elevation={1}>
                <UsersTable  classes = {classes}/>
                </Paper>
        )
    }
}

UsersAdminPage = withStyles( styles, {withTheme: true} )(UsersAdminPage)