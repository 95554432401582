import React from 'react'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Button, CssBaseline, Paper, Typography} from '@material-ui/core'

import {login, logout }  from '../../../Auth/actions'
import styles from '../../../styles'

import '../Admin.css'

export class UserConfirmationPage extends React.Component {

    componentDidMount() {
        localStorage.removeItem('access_token')
        const validationKey = this.props.match.params.key
        if (validationKey) {
            localStorage.setItem('validation', validationKey)
            this.props.logout('/confirm')
        }
    }

    render() {
        // const { validationFailure,  } = this.props
        const key = this.props.match.params.key
        if (!key && !localStorage.getItem('validation')) {
            return <Redirect to='/login' />
        }
        const { classes } = this.props
        return (
            <React.Fragment>
            <CssBaseline />
            
            <main className={classes.loginLayout}>
              <Paper className={classNames(classes.loginPaper, classes.paper)} elevation={15}>
                <Typography component="h2" variant="h4">
                  Welcome to Dicidio
              </Typography>
                <img src="../images/Dicidio_Logo_Stacked_Blue.png" alt="Dicidio decisioning logo" style={{ maxWidth: '100%', maxHeight: '60%' }} />
                <div className="se-section-body se-centered" >
                  <br />
                        {/* Show when loading the page */}
                        <div style={{display: key ? '' : 'none' }} >
                            <div className ="se-section-body se-centered" > 
                            <Typography>
                            Now logging out any users that might have been logged in before, this will only take a second or two
                            </Typography>
                            </div>
                            <br/>
                        </div>
                        {/* Show after the logout returns here */}
                        <div style={{display: !key && this.props.validationState==='initial' ? '' : 'none' }} >
                            <div className ="se-section-body se-centered" > 
                            <Typography>
                            You will be redirected to Auth0 to where you can either create a new account, or use your Google account to login. You will then automatically return to Dicidio. 
                            </Typography>
                            <br/>
                            </div>
                            <br/>
                            <div className ="se-section-body se-centered" > 
                            <Button className={classes.loginButton} style = {{width:'250px'}}
                                    onClick={() => this.props.login(key)}>Continue to Auth0</Button>
                            <br/>
                            </div>
                        </div>
                        {/* Show on fail */}
                        <div style={{display: this.props.validationState==='failed' ? '' : 'none' }} >
                            <div className ="se-centered" > 
                                <h3>Oops</h3>
                            </div>
                            <div className ="se-section-body se-centered" > 
                            <br/>
                            <Typography>
                                Unfortunately we weren't able to activate your account. Please contact your organisation's Dicidio administrator who
                                can help you further.
                                </Typography>
                            </div>
                        </div>
                  <br />
                </div>
                <br />
              </Paper>
            </main>
			</React.Fragment>
        )
    }
}

UserConfirmationPage = connect(
    state => ({
        isAuthenticated: state.auth.isAuthenticated,
        validationState : state.auth.validationState || 'initial'
    }),
	dispatch => (
		bindActionCreators( {login, logout},  dispatch )
	)
)(withStyles(styles)(UserConfirmationPage))