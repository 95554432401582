import React from 'react'

import {  Icon, Radio, Tooltip, Fab, Select, Dialog, Switch, DialogTitle, Input, DialogContent, FormControl, FormLabel, MenuItem, Button, DialogActions, IconButton, Typography } from '@material-ui/core'
import styles from '../../../styles'
import withStyles from '@material-ui/core/styles/withStyles'
import {uuid, isInteger} from '../../../functions'

import * as _ from 'lodash'
import { SimpleTable, EnhancedTable } from '../../../components/table';

export class PredictionColumnsEditor extends React.Component {

    constructor(props) {
        super(props)
        // values for modal editing
        this.state = {
            editModalVisible: false,
            // the column currently editing
            editModalColumn: undefined,
            editModalColumnStringCountVisible: false,
        }
    }

    handleAdd=() =>{
        let newMapping =  this.props.mapping
        let columns = _.toArray(this.props.mapping.columns)
        columns.push({name:'new', type:'STRING', key : uuid()})
        newMapping.columns  =  columns
        this.onChange(newMapping)
        
    }
    handleRemove=(index) =>{
        if (this.props.canDelete) {
            let newMapping =  this.props.mapping
            let columns = _.toArray(this.props.mapping.columns)
            columns.splice(index,1 )
            newMapping.columns = columns
            this.onChange(newMapping)
        }
    }
    openDialog = (record) => {
        const column =  record
        const editModalColumnBoundaries = []
        if (column.boundaries) {
            _.forEach(column.boundaries, (item, index) => {
                editModalColumnBoundaries.push({ key: index, boundary: item })
            })
        }

        let editModalStringValues = []
        if (column.values) {
            _.forEach(column.values, (item, index) => {
                editModalStringValues.push({ key: index, stringValue: item })

            })
        }
        const editModalStringCount = column.valuesCount

        console.log("Column", column)
        this.setState({
            editModalVisible: true,
            editModalColumn: column,
            editModalColumnBoundaries: editModalColumnBoundaries,
            editModalColumnStringValues: editModalStringValues,
            editModalColumnStringCount: editModalStringCount
        })
    }

    editModalOk = () => {
        // this is where it gets difficult
        this.setState({
            editModalVisible: false,
            editModalColumn: undefined,
        })
        let newMapping = { ...this.props.mapping }// { ...this.props.value }
        let updatedColumn = { ...this.state.editModalColumn }
        // set the updated column
        if (updatedColumn.type === 'STRING') {
            // check if we need to update the string details
            updatedColumn.boundaries = undefined
            if (this.state.editModalColumnStringCountVisible) {
                //we need to set the string count
                updatedColumn.valuesCount = this.state.editModalColumnStringCount
                updatedColumn.values = undefined
            }
            else {
                updatedColumn.valuesCount = undefined
                updatedColumn.values = _.map(this.state.editModalColumnStringValues, 'stringValue')
            }
        }
        else {
            updatedColumn.valuesCount = undefined
            updatedColumn.values = undefined
            updatedColumn.boundaries = _.map(this.state.editModalColumnBoundaries, 'boundary')
        }

        const index = _.findIndex(newMapping.columns, {key :updatedColumn.key})
        newMapping.columns[index] = updatedColumn
        // const indexOfInputFields = _.findIndex(newMapping.inputFields, {'name' :updatedColumn.name})
        // newMapping.inputFields[indexOfInputFields] = updatedColumn
        this.onChange(newMapping)
    }

    onChange = (mapping) => {
        // update the form if this component is wrapped inside a form item (as it is in PredictionConfigEditor)

        // we first need to rework the selected columns, the mapping only has the rowkeys
        // mapping.inputFields = _.filter(mapping.columns, col => { 
            // return _.includes(mapping.selectedRowKeys,col.key) 
        // })
        // mapping.selectedRowKeys = null
        if (this.props.onChange) {
            this.props.onChange(mapping)
        }
    }

    editModalCancel = () => {
        this.setState({
            editModalVisible: false,
            editModalColumn: undefined
        })
    }

    handleAddBoundary = () => {

        const boundaries = this.state.editModalColumnBoundaries || []

        let maxValue = _.maxBy(boundaries, 'boundary')
        maxValue = maxValue ? Number(maxValue.boundary) + 1 : 0
        const newBoundary = {
            key: boundaries.length || 0,
            boundary: maxValue || 0
        }
        this.setState({
            editModalColumnBoundaries: [...boundaries, newBoundary]
        })
    }


    handleBoundaryChange = (e, index) => {
        let editModalColumnBoundaries = this.state.editModalColumnBoundaries
        editModalColumnBoundaries[index].boundary = e.target.value
        this.setState({
            editModalColumnBoundaries: editModalColumnBoundaries
        })
    }

    handleDeleteBoundary = (e, index) => {
        let editModalColumnBoundaries = this.state.editModalColumnBoundaries
        _.remove(editModalColumnBoundaries,
            boundary => { return boundary.key === index }
        );
        this.setState({
            editModalColumnBoundaries: editModalColumnBoundaries
        })
    }
    handleAddStringValue = () => {
        const stringValues = this.state.editModalColumnStringValues || []
        const newStringValue = {
            key: stringValues.length || 0,
            stringValue: ''
        }
        this.setState({
            editModalColumnStringValues: [...stringValues, newStringValue]
        })
    }

    handleStringValueChange = (value, index, another) => {
        let editModalColumnStringValues = this.state.editModalColumnStringValues
        editModalColumnStringValues[index].stringValue = value.target.value
        this.setState({
            editModalColumnStringValues: editModalColumnStringValues
        })
    }

    handleDeleteStringValue = (e, index) => {
        let editModalColumnStringValues = this.state.editModalColumnStringValues
        _.remove(editModalColumnStringValues,
            stringValue => { return stringValue.key === index }
        );
        this.setState({
            editModalColumnStringValues: editModalColumnStringValues
        })
    }


    handleStringSwitchChange = (e) => {
        this.setState({
            editModalColumnStringCountVisible: e.target.checked
        })
    }

    handleStringCountChange = (e) => {

        const {value } = e.target
        if (isInteger(value)) {
            this.setState({
                editModalColumnStringCount: value
            })
        }
    }

    handleTypeChange = (e) => {
        const {value } = e.target
        this.setState({
            editModalColumn: { ...this.state.editModalColumn, type: value }
        })
    }

    handleNameChange = (e) => {
        this.setState({
            editModalColumn: { ...this.state.editModalColumn, name: e.target.value }
        })
    }

    handleLabelColumnSelected = (key) => {
        let newMapping = { ...this.props.mapping }
        if (!newMapping) {
            return
        }
        _.forEach(newMapping.columns, col => {
            col.isLabelColumn = col.key === key
        })
        const indexOfColSel = _.indexOf(newMapping.selectedRowKeys,key)
        if (indexOfColSel>-1) {
            newMapping.selectedRowKeys.splice(indexOfColSel, 1)
        }
        this.onChange(newMapping)
    }

    renderModal() {
        const stringValues = _.forEach(this.state.editModalColumnStringValues, (value, index) => { return { stringValue: value, key: index } })
        const boundaryValues = _.toArray(this.state.editModalColumnBoundaries, (value, index) => { return { boundary: value, key: index } })
        const {classes} = this.props
        const valuesColumns = [{
            title : 'Values', 
            dataIndex :'stringValue', 
            key :'values',
            render: (text, record, index) => {
                return (<Input
                    key={record.key}
                    onChange={(e) => this.handleStringValueChange(e, record.key)}
                    value={record.stringValue}
                />)
            }}
            ,
             {title:'',
             key :'deleteButton',
             render :(text, record) => (
                <span>
                <IconButton onClick={(e) => this.handleDeleteStringValue(e, record.key)}><Icon>delete</Icon></IconButton>
                </span>
            )
        }]
        

        const boundaryColumns = [
            {title : 'Boundary',
             dataIndex :'boundary',
            key:'boundary',
            render: (text, record, index) => {
                    return (<Input
                        key={record.key}
                        onChange={ val => this.handleBoundaryChange(val, record.key)}
                        value={record.boundary}
                    />)
                }
            
        },
        
        {   title:'',
            key :'deleteButton',
            render :(text, record) => (
                <span>
                <IconButton onClick={ e => this.handleDeleteBoundary(e, record.key)}><Icon>delete</Icon></IconButton>
                </span>
            )
        }]

        return (
            <DialogContent>
                {this.props.canEditName?
                    <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Column name</FormLabel>
                        <Input
                            key={'name'}
                            value={this.state.editModalColumn.name}
                            onChange={this.handleNameChange}
                        />
                    </FormControl>
            : null}

            <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Column type</FormLabel>
                        <Select
                            key={this.props.key}
                            onChange={this.handleTypeChange}
                            value={this.state.editModalColumn.type}
                        >
                            <MenuItem key='STRING' value='STRING'>String</MenuItem>
                            <MenuItem key='INTEGER' value='INTEGER'>Integer</MenuItem>
                            <MenuItem key='FLOAT' value='FLOAT'>Float</MenuItem>
                        </Select>
                    </FormControl>
              
                {
                    this.state.editModalColumn.type === 'STRING' ?
                        <React.Fragment>
                        <br/>
                        <FormControl className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Use values count</FormLabel>
                                <Switch 
                                    onChange={this.handleStringSwitchChange}
                                    checked={this.state.editModalColumnStringCountVisible} />
                          </FormControl>
                            {
                                this.state.editModalColumnStringCountVisible ?
                                <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                                    <FormLabel component="legend">Number of possible values in columns</FormLabel>
                                        <Input
                                            key='modalColumnStringCount'
                                            onChange={this.handleStringCountChange}
                                            value={this.state.editModalColumnStringCount ||0}
                                        />

                                    </FormControl>

                                    :
                                    <React.Fragment>
                                            <Button
                                                onClick={this.handleAddStringValue}
                                                color="primary"
                                                variant="outlined"
                                                >
                                                Add a value 
                                            </Button>
                                            <SimpleTable
                                                showHeader={false}
                                                pagination={false}
                                                dataSource={stringValues}
                                                columns = {valuesColumns}
                                                rowKey={record => record.key}
                                            />
                                    </React.Fragment>
                            }
                        </React.Fragment>
                        : <React.Fragment>
                        <br/>
                        <br/>
                                <Button
                                    onClick={this.handleAddBoundary}
                                    color="primary"
                                    variant="outlined">
                                    Add a boundary
                                </Button>
                            <br/>
                            <SimpleTable
                                    showHeader={false}
                                    pagination={false}
                                    dataSource={boundaryValues}
                                    columns = {boundaryColumns}
                                    rowKey={record => record.key}
                                />
                        </React.Fragment>
                }
            </DialogContent>
        )
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            }, {
                title: 'Label',
                key: 'label',
                render: (text, record, index) => {
                    return (<Radio
                        checked={record.isLabelColumn || false}
                        onClick={() => this.handleLabelColumnSelected(record.key)}
                    />)
                }
            }, {
                title: 'Details',
                key: 'details',
                render: (text, record, index) => {
                    if (record.type === 'STRING') {
                        if (record.valuesCount > 0) {
                            return ("Buckets: " + record.valuesCount)
                        }
                        else if (record.values && record.values.length > 0) {
                            return ('Values: [' + _.join(record.values, ' ,') + ']')
                        }
                    }
                    else {
                        if (record.boundaries && record.boundaries.length > 0) {
                            return ('Boundaries: [' + _.join(record.boundaries, ' ,') + ']')

                        }
                    }
                }
            }, {
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: (item, record, index) => { return (<Icon onClick={() => this.openDialog(record)} >create</Icon> ) }
            }, {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                render: (item, record, index) => { return this.props.canDelete? ( <Icon onClick={() => this.handleRemove(index)}>delete</Icon>) : <React.Fragment/> }
            }
        ]

        if (this.props.mapping) {
            const dataSource = this.props.mapping.columns

            const selectedRowKeys= this.props.mapping.selectedRowKeys

            const rowSelection = {
                onChange: (selectedRowKeys, selectedRows) => {
                    let newMapping = this.props.mapping
                    newMapping.selectedRowKeys = selectedRowKeys
                    this.onChange(newMapping)
                },
                selectedRowKeys: selectedRowKeys ,
            }

            return (
                <div>
                    <EnhancedTable
                        rowSelection={rowSelection}
                        dataSource={_.toArray(dataSource)}
                        pagination
                        columns={columns}
                        rowKey={record => record.key}
                    />
                    {this.props.canAdd ? (
                        <Tooltip title="Add">
                        <Fab
                            color="primary"
                            className={this.props.classes.adminTableAddButton}
                            aria-label="Add"
                            onClick={this.handleAdd}>
                            <Icon>add</Icon>
                        </Fab>
                        </Tooltip>)
                    : (<React.Fragment />)}
      
                    {this.state.editModalColumn ? (
                        <Dialog
                            onClose={this.editModalCancel}
                            open = {this.state.editModalVisible}
                            aria-labelledby="dialog-title">
                            <DialogTitle id="dialog-title">Edit column details for {this.state.editModalColumn.name}</DialogTitle>
                            {this.renderModal()}
                            <DialogActions>
                                <Button onClick={this.editModalCancel} color="primary">Cancel</Button>
                                <Button onClick={this.editModalOk} color="primary" autoFocus>Update datafield</Button>
                            </DialogActions>
                        </Dialog>
                    ) : (<div/>)}
                </div>
            )
        }
        else {
            return (<Typography variant="body1"> Please select a valid training datasource first</Typography>)
        }
    }
}
PredictionColumnsEditor =  withStyles(styles, { withTheme: true })(PredictionColumnsEditor)