import { get, put, post, del} from '../../../actions'

export const GET_PREDICTION_CONFIGS_REQUEST = '@@predictionConfig/GET_PREDICTION_CONFIGS_REQUEST'
export const GET_PREDICTION_CONFIGS_SUCCESS = '@@predictionConfig/GET_PREDICTION_CONFIGS_SUCCESS'
export const GET_PREDICTION_CONFIGS_FAILURE = '@@predictionConfig/GET_PREDICTION_CONFIGS_FAILURE'
export const getPredictionConfigs = (endpoint) => (
    get({
        displayName: 'get prediction configurations',
        endpoint: endpoint,
        types: [
            GET_PREDICTION_CONFIGS_REQUEST,
            GET_PREDICTION_CONFIGS_SUCCESS,
            GET_PREDICTION_CONFIGS_FAILURE],
    })
)

export const GET_PREDICTION_CONFIG_REQUEST = '@@predictionConfig/GET_PREDICTION_CONFIG_REQUEST'
export const GET_PREDICTION_CONFIG_SUCCESS = '@@predictionConfig/GET_PREDICTION_CONFIG_SUCCESS'
export const GET_PREDICTION_CONFIG_FAILURE = '@@predictionConfig/GET_PREDICTION_CONFIG_FAILURE'
export const getPredictionConfigById = (endpoint) => (
    get({
        displayName: 'get prediction configuration',
        endpoint: endpoint,
        types: [
            GET_PREDICTION_CONFIG_REQUEST,
            GET_PREDICTION_CONFIG_SUCCESS,
            GET_PREDICTION_CONFIGS_FAILURE],
    })
)

export const UPDATE_PREDICTION_CONFIG_REQUEST = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_REQUEST' 
export const UPDATE_PREDICTION_CONFIG_SUCCESS = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_SUCCESS'
export const UPDATE_PREDICTION_CONFIG_FAILURE = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_FAILURE'
export const updatePredictionConfig = ( endpoint, predictionConfig ) => (
    put({
        displayName: 'update prediction configuration',
        endpoint: endpoint,
        types: [
            UPDATE_PREDICTION_CONFIG_REQUEST,
            UPDATE_PREDICTION_CONFIG_SUCCESS,
            UPDATE_PREDICTION_CONFIG_FAILURE,
        ],
        body: JSON.stringify(predictionConfig)
    })
)

export const CREATE_PREDICTION_CONFIG_REQUEST = '@@predictionConfig/CREATE_PREDICTION_CONFIG_REQUEST'
export const CREATE_PREDICTION_CONFIG_SUCCESS = '@@predictionConfig/CREATE_PREDICTION_CONFIG_SUCCESS'
export const CREATE_PREDICTION_CONFIG_FAILURE = '@@predictionConfig/CREATE_PREDICTION_CONFIG_FAILURE'
export const createPredictionConfig = (endpoint, newPredictionConfig) => (
    post({
        displayName: 'create prediction configuration',
        endpoint: endpoint,
        types: [
            CREATE_PREDICTION_CONFIG_REQUEST,
            CREATE_PREDICTION_CONFIG_SUCCESS,
            CREATE_PREDICTION_CONFIG_FAILURE,
        ],
        body: JSON.stringify(newPredictionConfig)
    })
)

export const DELETE_PREDICTION_CONFIG_REQUEST = '@@predictionConfig/DELETE_PREDICTION_CONFIG_REQUEST'
export const DELETE_PREDICTION_CONFIG_SUCCESS = '@@predictionConfig/DELETE_PREDICTION_CONFIG_SUCCESS'
export const DELETE_PREDICTION_CONFIG_FAILURE = '@@predictionConfig/DELETE_PREDICTION_CONFIG_FAILURE'
export const deletePredictionConfig = ( endpoint ) => (
    del({
        displayName: 'delete prediction configuration',
        endpoint: endpoint,
        types: [
            DELETE_PREDICTION_CONFIG_REQUEST,
            DELETE_PREDICTION_CONFIG_SUCCESS,
            DELETE_PREDICTION_CONFIG_FAILURE,
        ],
    })
) 

export const START_PREDICTION_CONFIG_TRAINING_REQUEST = '@@predictionConfig/START_PREDICTION_CONFIG_TRAINING_REQUEST'
export const START_PREDICTION_CONFIG_TRAINING_SUCCESS = '@@predictionConfig/START_PREDICTION_CONFIG_TRAINING_SUCCESS'
export const START_PREDICTION_CONFIG_TRAINING_FAILURE = '@@predictionConfig/START_PREDICTION_CONFIG_TRAINING_FAILURE'
export const startPredictionConfigTraining = ( endpoint, predictionConfig ) => (
    post({
        displayName: 'start prediction training',
        endpoint: endpoint,
        types: [
            START_PREDICTION_CONFIG_TRAINING_REQUEST,
            START_PREDICTION_CONFIG_TRAINING_SUCCESS,
            START_PREDICTION_CONFIG_TRAINING_FAILURE,
        ],
        body: JSON.stringify(predictionConfig)
    })
)

export const CANCEL_PREDICTION_CONFIG_TRAINING_REQUEST = '@@predictionConfig/CANCEL_PREDICTION_CONFIG_TRAINING_REQUEST'
export const CANCEL_PREDICTION_CONFIG_TRAINING_SUCCESS = '@@predictionConfig/CANCEL_PREDICTION_CONFIG_TRAINING_SUCCESS'
export const CANCEL_PREDICTION_CONFIG_TRAINING_FAILURE = '@@predictionConfig/CANCEL_PREDICTION_CONFIG_TRAINING_FAILURE'
export const cancelPredictionConfigTraining = ( endpoint ) => (
    post({
        displayName: 'cancel prediction training',
        endpoint: endpoint,
        types: [
            CANCEL_PREDICTION_CONFIG_TRAINING_REQUEST,
            CANCEL_PREDICTION_CONFIG_TRAINING_SUCCESS,
            CANCEL_PREDICTION_CONFIG_TRAINING_FAILURE,
        ],
    })
)

export const GET_PREDICTION_CONFIG_TRAINING_STATUS_REQUEST = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_STATUS_REQUEST'
export const GET_PREDICTION_CONFIG_TRAINING_STATUS_SUCCESS = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_STATUS_SUCCESS'
export const GET_PREDICTION_CONFIG_TRAINING_STATUS_FAILURE = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_STATUS_FAILURE'
export const getPredictionConfigTrainingJobStatus = ( endpoint ) => (
    get({
        displayName: 'get prediction training status',
        endpoint: endpoint,
        types: [
            GET_PREDICTION_CONFIG_TRAINING_STATUS_REQUEST,
            GET_PREDICTION_CONFIG_TRAINING_STATUS_SUCCESS,
            GET_PREDICTION_CONFIG_TRAINING_STATUS_FAILURE,
        ],
    })
    
)

export const GET_PREDICTION_CONFIG_TRAINING_LOGS_REQUEST = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_LOGS_REQUEST'
export const GET_PREDICTION_CONFIG_TRAINING_LOGS_SUCCESS = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_LOGS_SUCCESS'
export const GET_PREDICTION_CONFIG_TRAINING_LOGS_FAILURE = '@@predictionConfig/GET_PREDICTION_CONFIG_TRAINING_LOGS_FAILURE'
export const getPredictionConfigLogs = ( endpoint ) => (
    get({
        displayName: 'get prediction configuration logs',
        endpoint: endpoint,
        types: [
            GET_PREDICTION_CONFIG_TRAINING_LOGS_REQUEST,
            GET_PREDICTION_CONFIG_TRAINING_LOGS_SUCCESS,
            GET_PREDICTION_CONFIG_TRAINING_LOGS_FAILURE,
        ],
    })
    
)

export const DEPLOY_PREDICTION_CONFIG_REQUEST = '@@predictionConfig/DEPLOY_PREDICTION_CONFIG_REQUEST'
export const DEPLOY_PREDICTION_CONFIG_SUCCESS = '@@predictionConfig/DEPLOY_PREDICTION_CONFIG_SUCCESS'
export const DEPLOY_PREDICTION_CONFIG_FAILURE = '@@predictionConfig/DEPLOY_PREDICTION_CONFIG_FAILURE'
export const deployPredictionConfig = ( endpoint ) => (
    post({
        displayName: 'deploy prediction configuration',
        endpoint: endpoint,
        types: [
            DEPLOY_PREDICTION_CONFIG_REQUEST,
            DEPLOY_PREDICTION_CONFIG_SUCCESS,
            DEPLOY_PREDICTION_CONFIG_FAILURE,
        ],
    })
    
)

export const UPDATE_PREDICTION_CONFIG_DOCUMENT = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_DOCUMENT'
export const updatePredictionConfigDoc = (id, document) => ({
    displayName: 'update prediction configuration document',
    type: UPDATE_PREDICTION_CONFIG_DOCUMENT,
    predictionId: id,
    document: document,
})

export const UPDATE_PREDICTION_CONFIG_TRAINING_DATASOURCE = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_TRAINING_DATASOURCE'
export const updatePredictionConfigTrainingDatasource = (id, datasource) => ({
    displayName: 'update prediction configuration training datasource',
    type: UPDATE_PREDICTION_CONFIG_TRAINING_DATASOURCE, 
    predictionId: id,
    datasource: datasource
})

export const UPDATE_PREDICTION_CONFIG_TEST_DATASOURCE = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_TEST_DATASOURCE'
export const updatePredictionConfigTestDatasource = (id, datasource) => ({
    displayName: 'update prediction configuration test datasource',
    type: UPDATE_PREDICTION_CONFIG_TEST_DATASOURCE, 
    predictionId: id,
    datasource: datasource
})

export const UPDATE_PREDICTION_CONFIG_EVAL_DATASOURCE = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_EVAL_DATASOURCE'
export const updatePredictionConfigEvaluationDatasource = (id, datasource) => ({
    displayName: 'update prediction configuration evalusation datasource',
    type: UPDATE_PREDICTION_CONFIG_EVAL_DATASOURCE, 
    predictionId: id,
    datasource: datasource
})

export const UPDATE_PREDICTION_CONFIG_MAPPING = '@@predictionConfig/UPDATE_PREDICTION_CONFIG_MAPPING'
export const updatePredictionConfigMapping = (id, mapping) => ({
    displayName: 'update prediction configuration mapping',
    type: UPDATE_PREDICTION_CONFIG_MAPPING, 
    predictionId: id,
    mapping: mapping 
})