import { arrayToObject, addUpdateItem, removeItem } from '../../../functions'
import * as actions from './actions'
import { createActionTypeReducer} from '../../../middleware'

const initialState = {
    items: {}
}

export const organisationReducer = createActionTypeReducer({}, {
    [actions.CREATE_ORGANISATION_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.UPDATE_ORGANISATION_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem( state, action) }),
    [actions.SET_SERVICE_ACCOUNT_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem( state, action) }),
    [actions.SET_SERVICE_ACCOUNT_PROJECTIDS_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem( state, action) }),
    [actions.DELETE_ORGANISATION_SUCCESS]: (state, action) => ({ ...removeItem( state, action ) }),
})

export const serviceAccountReducer = createActionTypeReducer({}, {
    [actions.SET_SERVICE_ACCOUNT_FAILURE] : (state, action) => ({...state, inProgress:false, message: action.payload.response && action.payload.response.error ?action.payload.response.error.message:'Unable to set up the serice account'}),
    [actions.SET_SERVICE_ACCOUNT_SUCCESS] : (state, action) => ({...state, inProgress:false, message: "Service account setup completed successfully"}),
    [actions.SET_SERVICE_ACCOUNT_REQUEST] : (state, action) => ({...state, inProgress:true, message: null}),
    [actions.SET_SERVICE_ACCOUNT_PROJECTIDS_REQUEST]: (state, action) => ({...state, inProgress:true, message: null}),
    [actions.SET_SERVICE_ACCOUNT_PROJECTIDS_SUCCESS] : (state, action) => ({...state, inProgress:false, message: "Project IDs have been saved"}),
    [actions.CLEAR_SERVICE_ACCOUNT_PROGRESS] : (state, action) => ({...state, inProgress:false, message: null}),
})

export const organisationsReducer = createActionTypeReducer(initialState, {
    [actions.GET_ORGANISATIONS_SUCCESS]: (state, action) => ({ ...state, items: arrayToObject(action.payload.items) , serviceAccountStatus : {inProgress:false, message: null}}),
},
    (state, action) => {
        return {
            ...state,
            items: organisationReducer(state.items, action),
            serviceAccountStatus : serviceAccountReducer(state.serviceAccountStatus, action)
        }
    }
)