import { post } from '../actions'
import { BASE_URL } from '../constants'


export const LOGOUT = "@@auth/LOGOUT"
export const logout = (redirect) =>(
    {
        displayName: 'logout',
        type:LOGOUT,
        redirect : redirect,
    }
)

export const LOGIN = "@@auth/LOGIN"
export const login = () =>(
    {
        displayName: 'login',
        type:LOGIN,
    }
)

export const HANDLE_AUTHENTICATION  = "@@auth/HANDLE_AUTHENTICATION "
export const handleAuthentication = () =>(
    {
        displayName: 'handleAuthentication',
        type:HANDLE_AUTHENTICATION ,
    }
)


// e is the payload from the login provider
export const LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS"
export const loginSuccess = (e) =>(
    {
        displayName: 'login succeeded',
        type:LOGIN_SUCCESS,
        payload: e
    }
)

export const LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS'
export const logoutSuccess = (e) =>(
    {
        displayName: 'logout succeeded',
        type:LOGOUT_SUCCESS,
        payload: e
    }
)


export const VALIDATE_REQUEST = '@@auth/VALIDATE_REQUEST' 
export const VALIDATE_SUCCESS = '@@auth/VALIDATE_SUCCESS'
export const VALIDATE_FAILURE = '@@auth/VALIDATE_FAILURE'
export const validateUser = (key ) => (
    post({
        displayName: 'validate user',
        endpoint: `${BASE_URL}/dme/manage/v1/users/validate/${key}`,
        types: [
            VALIDATE_REQUEST,
            VALIDATE_SUCCESS,
            VALIDATE_FAILURE,
        ]
    })
)


export const AUTH_REQUEST = "@@auth/AUTH_REQUEST"
export const AUTH_SUCCESS = "@@auth/AUTH_SUCCESS"
export const AUTH_FAILURE = "@@auth/AUTH_FAILURE"
// actions
export const getAuthorization = () => (
    post({
        displayName: 'authorization',
        endpoint: `${BASE_URL}/dme/manage/v1/users/login`,
        types: [
            AUTH_REQUEST, 
            AUTH_SUCCESS, 
            AUTH_FAILURE,
        ]
    })
)
