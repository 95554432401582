import React from 'react'
import { FieldSelector } from './FieldSelector'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

export class FieldSelectionModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            fieldList: props.applicationDatasetFields,
            searchValue: '',
            selectedValue: '',
            selectedKey: '',
            datasource: 'application',
            editFieldRef : props.editFieldRef,
        }
    }

    handleFieldSelected=(selectedValue)=> {
        this.setState({
            selectedValue: selectedValue,
        });
    }

    showModal() {
        this.setState({
            visible: true,
            selectedValue: '',
            fieldList: this.props.applicationDatasetFields,
            datasource: 'application',
        });
    }

    handleOk =(e)=> {
        this.setState({
            visible: false,
        })
        this.props.onFieldSelected('${' + this.state.selectedValue.toLowerCase() + '}', this.state.editFieldRef)
    }

    handleCancel =()=> {
        this.setState({
            visible: false,
        });
    }

    handleDatasourceChange =(newValue) =>{
        this.setState({
            datasource : newValue.toLowerCase()
        })
    }

    render() {
        return (
            <React.Fragment>
                
                <Tooltip title='Select a data field to insert' >
                <Fab 
                color="primary"
                className={`${this.props.classes.button}`}
                onClick={e => this.showModal(e)}> <Icon>play_for_work</Icon>
                </Fab>
                </Tooltip>
                <Dialog
                    title="Field Selector"
                    open={this.state.visible}
                    onClose={this.handleCancel}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Select a field to be inserted</DialogTitle>
                    <DialogContent>
                            <FieldSelector
                                solutionId={this.props.id}
                                value={this.state.selectedValue}
                                onChange={this.handleFieldSelected}
                                onDatsourceChange={this.handleDatasourceChange}
                                datasources={this.props.datasources}
                                classes = {this.props.classes}
                                derivedFromProps
                            >
                            </FieldSelector>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                        <Button onClick={this.handleOk} color="primary" autoFocus>OK</Button>
                    </DialogActions>

                </Dialog>
            </React.Fragment>
        );
    }
}

