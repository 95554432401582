import React from 'react'
import { OperationFieldSelector } from './OperationFieldSelector'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

// rename to condition list
export class OperationFieldList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: null,
        }
    }

    handleAdd = (e) => {
        const { onChange, values } = this.props
        
        const newValues = [
            ...values,
            {
                leftHandDatasource: this.props.leftHandDatasources[0].name,
                operator : this.props.ops[0].value,
                rightHandDatasource: this.props.rightHandDatasources[0].name,

            }
        ]
        
        this.setState({
            expanded: `panel.${newValues.length - 1}`
        })
        
        onChange && onChange(newValues)
    }

    handleDelete = (index) => {
        const { onChange, values } = this.props
        const newValues = [...values]
        newValues.splice(index, 1)
        onChange && onChange(newValues)
    }

    handleExpand = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        })
    }

    handleChange = (index, value) => {
        const { onChange, values } = this.props
        const newValues = [...values]
        newValues[index]= value
        onChange && onChange(newValues)
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', marginBottom: '5px' }}>
                    {this.props.values.map((conditionField, index) => {
                        return (<OperationFieldSelector
                            key={this.props.id + '[' + index + ']'}
                            {...this.props}
                            index={index}
                            value={conditionField}
                            onDelete={this.handleDelete}
                            onExpand={this.handleExpand}
                            onChange = {this.handleChange}
                            expanded={this.state.expanded}
                        />
                        )
                    })}
                </div>
                <Fab onClick={this.handleAdd}
                    className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                    color="primary">
                    <Icon>add</Icon>
                </Fab >
            </div>
        )
    }
}

