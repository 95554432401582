import { all } from 'redux-saga/effects'
import { solutionDiagramEditorSagas } from './SolutionDiagramEditor/sagas'
import { predictionConfigEditorSagas } from './Predictions/sagas'

/**
 * Chain to the editor pane sagas
 */


export function* solutionEditorSagas() {
    yield all([
        solutionDiagramEditorSagas(),
        predictionConfigEditorSagas(),
    ])
}

