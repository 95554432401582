import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'
import { OrganisationsTable }  from './OrganisationsTable'

export class OrganisationsPage extends React.Component {

    render() {
        const {classes} = this.props
        return (
            <Paper className={classes.adminTablePaper} elevation={1}>
                <OrganisationsTable classes = {classes}/>
            </Paper>

        )
    }
}

OrganisationsPage = withStyles(styles, {withTheme: true})(OrganisationsPage)