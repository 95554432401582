import { all } from 'redux-saga/effects'

import { actionSagas } from '../actions/sagas'
import { appSagas } from '../App/sagas'
import { authSagas } from '../Auth/sagas'
import { adminSagas } from '../App/Admin/sagas'
import { datasourceSagas } from '../App/Admin/Datasources/sagas'
import { solutionEditorSagas } from '../App/SolutionEditor/sagas'

export function* sagas() {
    yield all([
        appSagas(),
        authSagas(),
        adminSagas(),
        actionSagas(),
        datasourceSagas(),
        solutionEditorSagas(),
    ])
}