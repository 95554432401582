import auth0 from 'auth0-js';
import {WEB_BASE_URL, PROJECT_ID} from  '../constants'

export default class Auth  {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: 'dicidio.auth0.com',
      audience: `https://${PROJECT_ID}.appspot.com`,
      clientID: 'ZGhHaUwHvNaiT1Vxx13no6PSJQmMKahp',
      redirectUri: `${WEB_BASE_URL}/callback`,
      responseType: 'token id_token',
      scope: 'openid profile email'
    });

  }

  getProfile = () => {
    return this.profile;
  }

  isAuthenticated = () => {
    return new Date().getTime() < this.expiresAt;
  }
  
  login = () => {
    this.auth0.authorize();
  }
  logOut = (redirect) => {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.accessToken = null;
    this.expiresAt = null;
    // this.auth0.logout()
    console.log("Redirecting to ", `${WEB_BASE_URL}${redirect||'/login'}`)
    this.auth0.logout({returnTo : `${WEB_BASE_URL}${redirect||'/login'}` })
  }

  handleAuthentication = () => {

    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          return reject("No Auth result");
        }
        this.setSession(authResult)
        return resolve(authResult);
      });
    })
  }


  setSession = (authResult) => {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    this.accessToken =  authResult.accessToken
    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  }


  renewSession = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          this.logOut();
          return reject(err);
        }
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return resolve(authResult);
        }
        this.logOut();
        return reject("No Auth result");
      });
    })
  }

}
