import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'

import { isoToPrettyDate } from '../../../functions'
import styles from '../../../styles'

export class UserProfile extends React.Component {
  render() {
    const { profile, classes } = this.props

    return profile._embedded !== undefined ? (
      <React.Fragment>
        <Avatar className={classNames(classes.avatar, classes.bigAvatar)}>
          {profile._embedded.firstName[0] + profile._embedded.lastName[0]}
        </Avatar>
        <TextField
          label="Name"
          className={classes.textField}
          disabled = {true}
          margin="normal"
          defaultValue={profile._embedded.firstName + " " + profile._embedded.lastName}
        />
        <TextField
          label="Email"
          className={classes.textField}
          
          disabled = {true}
          margin="normal"
          defaultValue={profile._embedded.emailAddress}
        />

        <TextField
          label="Last login"
          className={classes.textField}
          disabled = {true}
          margin="normal"
          defaultValue={isoToPrettyDate(profile._embedded.lastLoginDate)}
        />

      </React.Fragment>
    ) :
      null
  }
}

UserProfile = withStyles( styles, { withTheme: true})(UserProfile)
