import { firstLetterUpper } from '../functions'
import { LIST, CREATE, READ, UPDATE, DELETE} from './actions'

export const PERMISSIONS_NONE = 'PERMISSION_NONE'
/**
 * test if the user has a permission
 * @example permission( state.user.profile, EXECUTE )
 */
export const permission = (user, permission) => {
    return (permission === PERMISSIONS_NONE)
        || (
            user
            && user._embedded
            && user._embedded.permissions
            && user._embedded.permissions.includes(permission)
        )
}

/**
 * These are useful in sagas
 * @example yield select(can(EXECUTE))
 * @example yield select(links(ORGANISATION)) 
 */
export const can = perm => state => permission(state.user.profile, perm)
export const links = ref => state => state.user.profile._links[ref].href

/**
 * 
 * @param {The constant representing the entity, i.e. 'ORGANISATION'} entity 
 * @param { The action to be performed , i.e. 'Deploy'} action 
 * @returns an object with a member function that performs the named permission check 
 */

export const makePermission = (entity, action) => ({
    [`can${firstLetterUpper(action)}`]: user => permission(user, `${entity}.${action}`.toLowerCase())
})

/**
 * Create permissions based on the standard API actions
 * @param entity constant describing the entity
 * @param actions 
 * @default [LIST,CREATE,READ,UPDATE,DELETE]
 * @example makePermissions(ORGANISATION)
 * @returns { canList: (entity, action) => (user) => permission(user, LIST), ... }
 * @see ./actions.js
 */

export const makePermissions = (entity, actions = [LIST, CREATE, READ, UPDATE, DELETE]) => actions.reduce((acc, a) =>({...acc, ...makePermission(entity, a)}), {})

export const SOLUTIONS = 'solutions'
export const LIST_SOLUTIONS = `${SOLUTIONS}.${LIST}`
export const CREATE_SOLUTIONS = `${SOLUTIONS}.${CREATE}`

export const EXECUTE = 'execute'
export const SOLUTION = 'solution'
export const READ_SOLUTION = `${SOLUTION}.${READ}`
export const UPDATE_SOLUTION = `${SOLUTION}.${UPDATE}`
export const DELETE_SOLUTION = `${SOLUTION}.${DELETE}`
export const EXECUTE_SOLUTION = `${SOLUTION}.${EXECUTE}` 


export const INVITE = 'INVITE'
export const USERS = 'users'
export const LIST_USERS = `${USERS}.${LIST}`
export const CREATE_USERS = `${USERS}.${CREATE}`

export const USER = 'user'
export const READ_USER = `${USER}.${READ}`
export const UPDATE_USER = `${USER}.${UPDATE}`
export const DELETE_USER = `${USER}.${DELETE}`
export const INVITE_USER = `${USER}.${INVITE}`

export const PROFILE = 'profile'
export const UPDATE_PROFILE = `${PROFILE}.${UPDATE}`

export const ORGANISATIONS = 'organisations'
export const LIST_ORGANISATIONS = `${ORGANISATIONS}.${LIST}`
export const CREATE_ORGANISATIONS = `${ORGANISATIONS}.${CREATE}`

export const ORGANISATION = 'organisation'
export const READ_ORGANISATION = `${ORGANISATION}.${READ}`
export const UPDATE_ORGANISATION = `${ORGANISATION}.${UPDATE}`
export const DELETE_ORGANISATION = `${ORGANISATION}.${DELETE}`

export const DATASOURCES = 'datasources'
export const LIST_DATASOURCES = `${DATASOURCES}.${LIST}`
export const CREATE_DATASOURCES = `${DATASOURCES}.${CREATE}`

export const DATASOURCE = 'datasource'
export const READ_DATASOURCE = `${DATASOURCE}.${READ}`
export const UPDATE_DATASOURCE = `${DATASOURCE}.${UPDATE}`
export const DELETE_DATASOURCE = `${DATASOURCE}.${DELETE}`

export const PREDICTIONS = 'predictions'
export const LIST_PREDICTIONS = `${PREDICTIONS}.${LIST}`

export const PREDICTION = 'prediction'
export const CREATE_PREDICTION = `${PREDICTION}.${CREATE}`
export const READ_PREDICTION = `${PREDICTION}.${READ}`
export const UPDATE_PREDICTION = `${PREDICTION}.${UPDATE}`
export const DELETE_PREDICTION = `${PREDICTION}.${DELETE}`
export const DEPLOY_PREDICTION = `${PREDICTION}.deploy`
export const TRAIN_PREDICTION = `${PREDICTION}.train`

export const EXPERIMENTS = 'experiments'
export const LIST_EXPERIMENTS = `${EXPERIMENTS}.${LIST}`
export const CREATE_EXPERIMENTS = `${EXPERIMENTS}.${CREATE}`

export const EXPERIMENT = 'experiment'
export const READ_EXPERIMENT = `${EXPERIMENT}.${READ}`
export const READ_EXPERIMENT_RESULT = `${EXPERIMENT}.result.${READ}`
export const DELETE_EXPERIMENT_RESULT = `${EXPERIMENT}.result.${DELETE}`
export const UPDATE_EXPERIMENT = `${EXPERIMENT}.${UPDATE}`
export const DELETE_EXPERIMENT = `${EXPERIMENT}.${DELETE}`

export const IMPORTS = 'imports'
export const LIST_IMPORTS = `${IMPORTS}.${LIST}`
export const CREATE_IMPORTS = `${IMPORTS}.${CREATE}`

export const IMPORT = 'import'
export const READ_IMPORT = `${IMPORT}.${READ}`
export const UPDATE_IMPORT = `${IMPORT}.${UPDATE}`
export const DELETE_IMPORT = `${IMPORT}.${DELETE}`

export const ALLROLES = 'allRoles'
export const LIST_ALLROLES = `${ALLROLES}.${LIST}`
export const CREATE_ALLROLES = `${ALLROLES}.${CREATE}`
export const READ_ALLROLES = `${ALLROLES}.${READ}`
export const UPDATE_ALLROLES = `${ALLROLES}.${UPDATE}`
export const DELETE_ALLROLES = `${ALLROLES}.${DELETE}`

export const ROLES = 'roles'
export const LIST_ROLES = `${ROLES}.${LIST}`
export const CREATE_ROLES = `${ROLES}.${CREATE}`

export const ROLE = 'role'
export const READ_ROLE = `${ROLE}.${READ}`
export const UPDATE_ROLE = `${ROLE}.${UPDATE}`
export const DELETE_ROLE = `${ROLE}.${DELETE}`

export const GROUPS = 'groups'
export const LIST_GROUPS = `${GROUPS}.${LIST}`
export const CREATE_GROUPS = `${GROUPS}.${CREATE}`

export const GROUP = 'group'
export const READ_GROUP = `${GROUP}.${READ}`
export const UPDATE_GROUP = `${GROUP}.${UPDATE}`
export const DELETE_GROUP = `${GROUP}.${DELETE}`

export const EXECUTIONS = 'executions'
export const LIST_EXECUTIONS = `${EXECUTIONS}.${LIST}`

export const VERSION_EXECUTIONS = 'versionExecutions'
export const LIST_VERSION_EXECUTIONS = `${VERSION_EXECUTIONS}.${LIST}`

export const EXECUTION = 'execution'
export const READ_EXECUTION = `${EXECUTION}.${READ}`

export const VERSIONS = 'versions'
export const LIST_VERSIONS = `${VERSIONS}.${LIST}`

export const PERMISSIONS = 'permissions'
export const LIST_PERMISSIONS = `${PERMISSIONS}.${LIST}`


