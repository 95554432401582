import { get, put, post, del} from '../../../actions'

export const GET_EXPERIMENT_CONFIGS_REQUEST = '@@experiments/GET_EXPERIMENT_CONFIGS_REQUEST'
export const GET_EXPERIMENT_CONFIGS_SUCCESS = '@@experiments/GET_EXPERIMENT_CONFIGS_SUCCESS'
export const GET_EXPERIMENT_CONFIGS_FAILURE = '@@experiments/GET_EXPERIMENT_CONFIGS_FAILURE'
export const getExperimentConfigs = (endpoint) => (
    get({
        displayName: 'get experiment configurations',
        endpoint: endpoint,
        types: [
            GET_EXPERIMENT_CONFIGS_REQUEST,
            GET_EXPERIMENT_CONFIGS_SUCCESS,
            GET_EXPERIMENT_CONFIGS_FAILURE],
    })
)

export const GET_EXPERIMENT_CONFIG_REQUEST = '@@experiments/GET_EXPERIMENT_CONFIG_REQUEST'
export const GET_EXPERIMENT_CONFIG_SUCCESS = '@@experiments/GET_EXPERIMENT_CONFIG_SUCCESS'
export const GET_EXPERIMENT_CONFIG_FAILURE = '@@experiments/GET_EXPERIMENT_CONFIG_FAILURE'
export const getExperimentConfigById = (endpoint) => (
    get({
        displayName: 'get experiment configuration',
        endpoint: endpoint,
        types: [
            GET_EXPERIMENT_CONFIG_REQUEST,
            GET_EXPERIMENT_CONFIG_SUCCESS,
            GET_EXPERIMENT_CONFIGS_FAILURE],
    })
)

export const UPDATE_EXPERIMENT_CONFIG_REQUEST = '@@experiments/UPDATE_EXPERIMENT_CONFIG_REQUEST' 
export const UPDATE_EXPERIMENT_CONFIG_SUCCESS = '@@experiments/UPDATE_EXPERIMENT_CONFIG_SUCCESS'
export const UPDATE_EXPERIMENT_CONFIG_FAILURE = '@@experiments/UPDATE_EXPERIMENT_CONFIG_FAILURE'
export const updateExperimentConfig = ( endpoint, experimentConfig ) => (
    put({
        displayName: 'update experiment configuration',
        endpoint: endpoint,
        types: [
            UPDATE_EXPERIMENT_CONFIG_REQUEST,
            UPDATE_EXPERIMENT_CONFIG_SUCCESS,
            UPDATE_EXPERIMENT_CONFIG_FAILURE,
        ],
        body: JSON.stringify(experimentConfig)
    })
)

export const SAVE_GRAPH_REQUEST = '@@experiments/SAVE_GRAPH_REQUEST' 
export const SAVE_GRAPH_SUCCESS = '@@experiments/SAVE_GRAPH_SUCCESS'
export const SAVE_GRAPH_FAILURE = '@@experiments/SAVE_GRAPH_FAILURE'
export const saveGraph = ( endpoint, graph ) => (
    put({
        displayName: 'Save experiment result graph',
        endpoint: endpoint,
        types: [
            SAVE_GRAPH_REQUEST,
            SAVE_GRAPH_SUCCESS,
            SAVE_GRAPH_FAILURE,
        ],
        body: JSON.stringify(graph)
    })
)

export const CREATE_EXPERIMENT_CONFIG_REQUEST = '@@experiments/CREATE_EXPERIMENT_CONFIG_REQUEST'
export const CREATE_EXPERIMENT_CONFIG_SUCCESS = '@@experiments/CREATE_EXPERIMENT_CONFIG_SUCCESS'
export const CREATE_EXPERIMENT_CONFIG_FAILURE = '@@experiments/CREATE_EXPERIMENT_CONFIG_FAILURE'
export const createExperimentConfig = (endpoint, newExperimentConfig) => (
    post({
        displayName: 'create experiment configuration',
        endpoint: endpoint,
        types: [
            CREATE_EXPERIMENT_CONFIG_REQUEST,
            CREATE_EXPERIMENT_CONFIG_SUCCESS,
            CREATE_EXPERIMENT_CONFIG_FAILURE,
        ],
        body: JSON.stringify(newExperimentConfig)
    })
)

export const DELETE_EXPERIMENT_CONFIG_REQUEST = '@@experiments/DELETE_EXPERIMENT_CONFIG_REQUEST'
export const DELETE_EXPERIMENT_CONFIG_SUCCESS = '@@experiments/DELETE_EXPERIMENT_CONFIG_SUCCESS'
export const DELETE_EXPERIMENT_CONFIG_FAILURE = '@@experiments/DELETE_EXPERIMENT_CONFIG_FAILURE'
export const deleteExperimentConfig = ( endpoint ) => (
    del({
        displayName: 'delete experiment configuration',
        endpoint: endpoint,
        types: [
            DELETE_EXPERIMENT_CONFIG_REQUEST,
            DELETE_EXPERIMENT_CONFIG_SUCCESS,
            DELETE_EXPERIMENT_CONFIG_FAILURE,
        ],
    })
) 

export const GET_EXPERIMENT_RESULTS_REQUEST = '@@experiments/GET_EXPERIMENT_RESULTS_REQUEST'
export const GET_EXPERIMENT_RESULTS_SUCCESS = '@@experiments/GET_EXPERIMENT_RESULTS_SUCCESS'
export const GET_EXPERIMENT_RESULTS_FAILURE = '@@experiments/GET_EXPERIMENT_RESULTS_FAILURE'
export const getExperimentResults = (endpoint) => (
    get({
        displayName: 'get all experiment results for an experiment',
        endpoint: endpoint,
        types: [
            GET_EXPERIMENT_RESULTS_REQUEST,
            GET_EXPERIMENT_RESULTS_SUCCESS,
            GET_EXPERIMENT_RESULTS_FAILURE],
    })
)

export const GET_EXPERIMENT_RESULT_REQUEST = '@@experiments/GET_EXPERIMENT_RESULT_REQUEST'
export const GET_EXPERIMENT_RESULT_SUCCESS = '@@experiments/GET_EXPERIMENT_RESULT_SUCCESS'
export const GET_EXPERIMENT_RESULT_FAILURE = '@@experiments/GET_EXPERIMENT_RESULT_FAILURE'
export const getExperimentResult = (endpoint) => (
    get({
        displayName: 'get one result for an experiment',
        endpoint: endpoint,
        types: [
            GET_EXPERIMENT_RESULT_REQUEST,
            GET_EXPERIMENT_RESULT_SUCCESS,
            GET_EXPERIMENT_RESULT_FAILURE],
    })
)

export const GET_EXPERIMENT_RESULT_DETAILS_REQUEST = '@@experiments/GET_EXPERIMENT_RESULT_DETAILS_REQUEST'
export const GET_EXPERIMENT_RESULT_DETAILS_SUCCESS = '@@experiments/GET_EXPERIMENT_RESULT_DETAILS_SUCCESS'
export const GET_EXPERIMENT_RESULT_DETAILS_FAILURE = '@@experiments/GET_EXPERIMENT_RESULT_DETAILS_FAILURE'
export const getExperimentResultDetails = (endpoint) => (
    get({
        displayName: 'get result details for an experiment',
        endpoint: endpoint,
        types: [
            GET_EXPERIMENT_RESULT_DETAILS_REQUEST,
            GET_EXPERIMENT_RESULT_DETAILS_SUCCESS,
            GET_EXPERIMENT_RESULT_DETAILS_FAILURE],
    })
)

export const CREATE_EXPERIMENT_RESULT_REQUEST = '@@experiments/CREATE_EXPERIMENT_RESULT_REQUEST'
export const CREATE_EXPERIMENT_RESULT_SUCCESS = '@@experiments/CREATE_EXPERIMENT_RESULT_SUCCESS'
export const CREATE_EXPERIMENT_RESULT_FAILURE = '@@experiments/CREATE_EXPERIMENT_RESULT_FAILURE'
export const runExperiment = (endpoint) => (
    post({
        displayName: 'Run the experiment and create an experiment result',
        endpoint: endpoint,
        types: [
            CREATE_EXPERIMENT_RESULT_REQUEST,
            CREATE_EXPERIMENT_RESULT_SUCCESS,
            CREATE_EXPERIMENT_RESULT_FAILURE,
        ],
    })
)

export const DELETE_EXPERIMENT_RESULT_REQUEST = '@@experiments/DELETE_EXPERIMENT_RESULT_REQUEST'
export const DELETE_EXPERIMENT_RESULT_SUCCESS = '@@experiments/DELETE_EXPERIMENT_RESULT_SUCCESS'
export const DELETE_EXPERIMENT_RESULT_FAILURE = '@@experiments/DELETE_EXPERIMENT_RESULT_FAILURE'
export const deleteExperimentResult = (endpoint) => (
    del({
        displayName: 'delete experiment result',
        endpoint: endpoint,
        types: [
            DELETE_EXPERIMENT_RESULT_REQUEST,
            DELETE_EXPERIMENT_RESULT_SUCCESS,
            DELETE_EXPERIMENT_RESULT_FAILURE,
        ],
    })
)
