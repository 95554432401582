/**
 * 
 */
export const MESSAGE = 'info'
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'

const messageAction = (message, description, type = USER_NOTIFICATION, level = MESSAGE) => ({
    type: type,
    level: level,
    message: message,
    description: description
})

/**
 * Action creators for each of the types and levels
 * eg:
 * 
 * dialogUserMessage ()
 */

/**
 * Highest visibility
 * User profile
 * Modal dialogs
 */
export const USER_DIALOG = '@@msg/MESSAGE_USER_DIALOG'
export const dialogUserMessage = (message, description) => (messageAction(message, description, USER_DIALOG, MESSAGE))
export const dialogUserSuccess = (message, description) => (messageAction(message, description, USER_DIALOG, SUCCESS))
export const dialogUserWarning = (message, description) => (messageAction(message, description, USER_DIALOG, WARNING))
export const dialogUserError = (message, description) => (messageAction(message, description, USER_DIALOG, ERROR))

/**
 * High visibility
 * User profile
 * pop up notifications (toast)
 */
export const USER_NOTIFICATION = '@@msg/USER_NOTIFICATION'
export const notifyUserMessage = (message, description) => (messageAction(message, description, USER_NOTIFICATION, MESSAGE))
export const notifyUserSuccess = (message, description) => (messageAction(message, description, USER_NOTIFICATION, SUCCESS))
export const notifyUserWarning = (message, description) => (messageAction(message, description, USER_NOTIFICATION, WARNING))
export const notifyUserError = (message, description) => (messageAction(message, description, USER_NOTIFICATION, ERROR))

/**
 * Medium visibility
 * User profile
 * Status line messages
 */
export const USER_STATUS = '@@msg/USER_STATUS'
export const statusUserMessage = (message, description) => (messageAction(message, description, USER_STATUS, MESSAGE))
export const statusUserSuccess = (message, description) => (messageAction(message, description, USER_STATUS, SUCCESS))
export const statusUserWarning = (message, description) => (messageAction(message, description, USER_STATUS, WARNING))
export const statusUserError = (message, description) => (messageAction(message, description, USER_STATUS, ERROR))

/**
 * Low Visibility message
 * Developer profile
 * console or application log output
 */
export const APP_LOG = '@@msg/APP_LOG'
export const logAppMessage = (message, description) => (messageAction(message, description, APP_LOG, MESSAGE))
export const logAppSuccess = (message, description) => (messageAction(message, description, APP_LOG, SUCCESS))
export const logAppWarning = (message, description) => (messageAction(message, description, APP_LOG, WARNING))
export const logAppError = (message, description) => (messageAction(message, description, APP_LOG, ERROR))

