import React from 'react'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

import { KVSelectItem} from './KVSelectItem'
import { Typography } from '@material-ui/core';

export class KVSelectList extends React.Component {
    add = (e) =>{

        const newValue = this.props.vItems?this.props.vItems[0].label : ''
        let newKey = 'new';
        let i = 1;
        
        // eslint-disable-next-line
        while (this.props.values.find( item=> item.key ===newKey) !== undefined) {
            newKey = 'new' + i
            i++
        }
        const newValues = [...this.props.values, {key:newKey, value: newValue}]
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    delete = (index) =>{
        let newValues = this.props.values
        newValues.splice(index, 1)
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    onChange (index, value) {
        let newValues = this.props.values
        newValues[index]= value 
        if (this.props.onChange) {
            this.props.onChange(newValues)
        }
    }

    render() {
        return (
            <div>
            <Typography variant="body1">{this.props.label}</Typography>
            <div style = {{width: '100%', marginBottom :'5px'}}>
                {this.props.values.map((value, index) => {
                    console.log("value", value)
                return (<KVSelectItem 
                    key = {index}
                    style = {{width: '90%'}}
                    {...this.props}
                    index = {index}
                    kValue = {value.key}
                    vValue = {value.value}
                    kLabel = {this.props.kLabel}
                    kType = {this.props.kType}
                    vLabel = {this.props.vLabel}
                    vItems ={this.props.vItems }
                    delete = {this.delete}
                    onChange = {(value) =>this.onChange(index, value)}
                    />
                )  
                }) }

            </div>
                <Fab onClick={this.add} 
                className={`${this.props.classes.button} ${this.props.classes.addToListButton}`}
                color="primary">
                <Icon>add</Icon>
                </Fab >
            </div>
        )
    }
}

