import * as actions from './actions'

const InitialLoginState = {
    user:{},
    token:{}    
}

// Login reducer
function loginReducer( state=InitialLoginState, action ){
    switch ( action.type ){
        case actions.LOGIN_SUCCESS:{
            return {
                ...state,
            user: {...action.payload.idTokenPayload},
            }

        }
        case actions.LOGOUT_SUCCESS:{
            return {
                ...state,
                user:{},
                token:{}
            }
        }
        default: return state
    }
}



const initialState = {
}

function authReducer( state = initialState, action ){

    switch ( action.type ){

        case actions.AUTH_SUCCESS: {
            return {
                ...state,
                isAuthenticated:true,
                authenticationError : '',
            }
        }
        case actions.AUTH_FAILURE: {
            return {
                ...state,
                isAuthenticated:false,
                authenticationError : action.payload.status,
            }
        }
        case actions.LOGOUT_SUCCESS:{
            return {
                ...state,
                isAuthenticated:false,
                authenticationError : '',
            }
        }
        case actions.VALIDATE_FAILURE: {
            return {
                ...state,
                validationState: 'failed'
            }
        }
        case actions.VALIDATE_REQUEST: {
            return {
                ...state,
                validationState: 'inProgress'
            }
        }
        case actions.VALIDATE_SUCCESS: {
            return {
                ...state,
                validationState: 'success'
            }
        }
        default: return state
    }
}

export { loginReducer, authReducer }