import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MenuItem, TextField, FormControl, Typography, FormGroup } from '@material-ui/core'
import { isInteger}  from '../../../functions'

export class ActionScheduler extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        
            action: props.value?props.value.action : 'immediate',
            delayedHours: props.value?props.value.delayedHours : '0',
            delayedMinutes: props.value?props.value.delayedMinutes : '0',
        }
    
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
        if (this.props.onChange) {
            this.props.onChange({...this.state, [field]:value})
        }
    }

    render() {
        const { action, delayedHours, delayedMinutes } = this.state;
        const { classes } = this.props

        return (
            <div style={{ width: '100%' }}>

                <Typography variant='h5' gutterBottom>
                    Job schedule
				</Typography>
                <RadioGroup
                    aria-label="Gender"
                    name="gender1"
                    className={classes.group}
                    value={action}
                    onChange={e => this.handleChange('action', e.target.value)}
                >
                    <FormControlLabel value='immediate' control={<Radio />} label="Immediate execution" />
                    <FormControlLabel value='delayed' control={<Radio />} label="Delayed execution" />
                </RadioGroup>
                {this.state.action === 'delayed' ?
                    <div>
                        <Typography variant='h6' gutterBottom>
                            Day(s) of the week
						</Typography>

                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>


                                <TextField
                                    className={classes.formControl}
                                    label="Hours"
                                    value={delayedHours}
                                    onChange={e => {
                                        if ( isInteger(e.target.value)) {this.handleChange('delayedHours', e.target.value)}
                                    }}
                                    id="formatted-numberformat-input"
                                />
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    label="Minutes"
                                    className={classes.textField}
                                    value={delayedMinutes}
                                    onChange={e => this.handleChange('delayedMinutes', e.target.value)}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                >
                                    <MenuItem key='0' value='0'>0</MenuItem>
                                    <MenuItem key='5' value='5'>5</MenuItem>
                                    <MenuItem key='10' value='10'>10</MenuItem>
                                    <MenuItem key='15' value='15'>15</MenuItem>
                                    <MenuItem key='20' value='10'>20</MenuItem>
                                    <MenuItem key='25' value='25'>25</MenuItem>
                                    <MenuItem key='30' value='20'>30</MenuItem>
                                    <MenuItem key='35' value='35'>35</MenuItem>
                                    <MenuItem key='40' value='40'>40</MenuItem>
                                    <MenuItem key='45' value='45'>45</MenuItem>
                                    <MenuItem key='50' value='50'>50</MenuItem>
                                    <MenuItem key='55' value='55'>55</MenuItem>
                                </TextField>
                            </FormGroup>
                        </FormControl>
                    </div> :
                    <div>
                    </div>}
            </div>
        )
    }
}
