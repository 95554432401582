import { all, put, takeEvery } from 'redux-saga/effects'

// import { startPoll, stopPoll } from '../../../actions/sagas'
import * as msg from '../../../actions/messages'
import * as pcActions from '../../Admin/PredictionConfigs/actions'

function* notifyStatus(action) {
    yield put(msg.notifyUserMessage( 'Prediction', `${action.payload._embedded.name} saved`))
}

function* updateStatus(action){
    yield put(msg.statusUserMessage('Prediction Config Editor', action.payload._embedded?action.payload._embedded.statusMessage:null))
}


function* watchApiUpdates(){
    yield takeEvery( pcActions.GET_PREDICTION_CONFIG_TRAINING_STATUS_SUCCESS, updateStatus )
    yield takeEvery( pcActions.UPDATE_PREDICTION_CONFIG_SUCCESS, notifyStatus )
}

export function* predictionConfigEditorSagas(){
    yield all([
        watchApiUpdates(),
    ])
}