import { get, put, post, del} from '../../../actions'

export const GET_ORGANISATIONS_REQUEST = '@@organisations/GET_ORGANISATIONS_REQUEST'
export const GET_ORGANISATIONS_SUCCESS = '@@organisations/GET_ORGANISATIONS_SUCCESS' 
export const GET_ORGANISATIONS_FAILURE = '@@organisations/GET_ORGANISATIONS_FAILURE'
export const getOrganisations = (endpoint) =>(
    get({
        displayName: 'get organisations',
        endpoint: endpoint,
        types: [
            GET_ORGANISATIONS_REQUEST, 
            GET_ORGANISATIONS_SUCCESS, 
            GET_ORGANISATIONS_FAILURE
        ]
    })
)

export const UPDATE_ORGANISATION_REQUEST = '@@organisation/UPDATE_ORGANISATION_REQUEST' 
export const UPDATE_ORGANISATION_SUCCESS = '@@organisation/UPDATE_ORGANISATION_SUCCESS'
export const UPDATE_ORGANISATION_FAILURE = '@@organisation/UPDATE_ORGANISATION_FAILURE'
export const updateOrganisation = ( endpoint, organisation ) => (
    put({
        displayName: 'update organisation',
        endpoint: endpoint,
        types: [
            UPDATE_ORGANISATION_REQUEST,
            UPDATE_ORGANISATION_SUCCESS,
            UPDATE_ORGANISATION_FAILURE,
        ],
        body: JSON.stringify(organisation)
    })
)

const defaultOrganisation = {
    name:'New Organisation',
}

export const CREATE_ORGANISATION_REQUEST = '@@organisation/CREATE_ORGANISATION_REQUEST'
export const CREATE_ORGANISATION_SUCCESS = '@@organisation/CREATE_ORGANISATION_SUCCESS'
export const CREATE_ORGANISATION_FAILURE = '@@organisation/CREATE_ORGANISATION_FAILURE'
export const createOrganisation = (endpoint, organisation = defaultOrganisation ) => (
    post({
        displayName: 'create organisation',
        endpoint: endpoint,
        types: [
            CREATE_ORGANISATION_REQUEST,
            CREATE_ORGANISATION_SUCCESS,
            CREATE_ORGANISATION_FAILURE,
        ],
        body: JSON.stringify(organisation)
    })
)

export const DELETE_ORGANISATION_REQUEST = '@@organisation/DELETE_ORGANISATION_REQUEST'
export const DELETE_ORGANISATION_SUCCESS = '@@organisation/DELETE_ORGANISATION_SUCCESS'
export const DELETE_ORGANISATION_FAILURE = '@@organisation/DELETE_ORGANISATION_FAILURE'
export const deleteOrganisation = ( endpoint ) => (
    del({
        displayName: 'delete organisation',
        endpoint: endpoint,
        types: [
            DELETE_ORGANISATION_REQUEST,
            DELETE_ORGANISATION_SUCCESS,
            DELETE_ORGANISATION_FAILURE,
        ],
    })
) 

export const CLEAR_SERVICE_ACCOUNT_PROGRESS = '@@serviceAccount/CLEAR_SERVICE_ACCOUNT_PROGRESS'
export const clearServiceAccountRequest = () => ({
    type: CLEAR_SERVICE_ACCOUNT_PROGRESS
})


export const SET_SERVICE_ACCOUNT_REQUEST = '@@serviceAccount/SET_SERVICE_ACCOUNT_REQUEST'
export const SET_SERVICE_ACCOUNT_SUCCESS = '@@serviceAccount/SET_SERVICE_ACCOUNT_SUCCESS'
export const SET_SERVICE_ACCOUNT_FAILURE = '@@serviceAccount/SET_SERVICE_ACCOUNT_FAILURE'
export const setServiceAccount = (endpoint, useDicidio, file ) => (
    put({
        displayName: 'Set service account',
        endpoint: endpoint,
        types: [
            SET_SERVICE_ACCOUNT_REQUEST,
            SET_SERVICE_ACCOUNT_SUCCESS,
            SET_SERVICE_ACCOUNT_FAILURE,
        ],
        body: JSON.stringify({useDicidio:useDicidio, file:file})
    })
)

export const SET_SERVICE_ACCOUNT_PROJECTIDS_REQUEST = '@@serviceAccount/SET_SERVICE_ACCOUNT_PROJECTIDS_REQUEST'
export const SET_SERVICE_ACCOUNT_PROJECTIDS_SUCCESS = '@@serviceAccount/SET_SERVICE_ACCOUNT_PROJECTIDS_SUCCESS'
export const SET_SERVICE_ACCOUNT_PROJECTIDS_FAILURE = '@@serviceAccount/SET_SERVICE_ACCOUNT_PROJECTIDS_FAILURE'
export const setServiceAccountProjectIds = (endpoint, organisationIdStr) => {
    console.log("Callingf ", endpoint)
    return (
    put({
        displayName: 'Set service account GCP project IDs',
        endpoint: endpoint,
        types: [
            SET_SERVICE_ACCOUNT_PROJECTIDS_REQUEST,
            SET_SERVICE_ACCOUNT_PROJECTIDS_SUCCESS,
            SET_SERVICE_ACCOUNT_PROJECTIDS_FAILURE,
        ],
        body: JSON.stringify({organisationIdStr : organisationIdStr})
        
    })

)
}
