import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import { withStyles } from '@material-ui/core/styles'

import Table from '../../../components/table/SimpleTable'
import * as actions from './actions'
import styles from '../../../styles'
import _ from 'lodash'

const matchUUID = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/

export class ExecutionDataView extends React.PureComponent {

    translateMessage(message) {
        const nodes = this.props.solution ? this.props.solution.solutionDefinition.nodes : []
        const links = this.props.solution ? this.props.solution.solutionDefinition.links : []
        return message ? message.replace(matchUUID, id => {
            var it = nodes.find(node => node.id === id) || links.find(link => link.id === id)
            return it ? `${it.name}:${it.label}` : id
        }) : message
    }

    render() {
        const { execution, selected } = this.props
        const started = execution ? new Date(execution._embedded.started).getTime() : 0

        const events = (execution && execution._embedded.executionEvents) || []
        const errors = (this.props.solution && this.props.solution.errors) ? this.props.solution.errors : []

        const allEvents = [...errors, ...events]
        const datasource = allEvents.length > 0 ? allEvents.map((i, t) => {
            var { key: value, ...ti } = i
            return { ...ti, category: value, key: t }
        }) : []

        const selectedRowKeys = _.filter(datasource, item => { return item.nodeId in selected }).map(item => _.findIndex(datasource, item))

        const columns = [
            {
                title: 'Time',
                dataIndex: 'timestamp',
                key: 'timestamp',
                render: item => {
                    const time = started ? new Date(item) - started : ''
                    return time < 0 ? '' : time
                }
            }, {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
                render: (text) => this.translateMessage(text),
            }, {
                title: 'Component',
                dataIndex: 'id',
                key: 'id',
                render: (text) => this.translateMessage(text),
            },
        ]
        return (
            <Table
                pagination={this.props.pagination}
                className={this.props.className}
                dataSource={datasource}
                columns={columns}
                onRowSelect={(e) => {
                    if (this.props.onComponentSelect && datasource[e]) {
                        this.props.onComponentSelect(datasource[e].nodeId)
                    }
                }}
                selectedRowKeys={selectedRowKeys}
                canSelect={row => row.category === 'Node' || row.category === 'Rule' || row.eventType === 'ERROR'} />
        )
    }
}


ExecutionDataView = connect(
    (state, props) => ({
        solution: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition,
        diagram: state.solutions.present.items[props.id] && state.solutions.present.items[props.id].definition && state.solutions.present.items[props.id].definition.solutionDefinition,
        selected: state.diagrams.instances[props.id] && state.diagrams.instances[props.id].selected,

    }),
    dispatch => (
        bindActionCreators({ ...actions }, dispatch)
    ),
)(withStyles(styles, { withTheme: true })(ExecutionDataView))

