import React from 'react'
import { bindActionCreators } from 'redux'
import { isoToPrettyDate } from '../../../functions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { EditInput } from '../../../components/table'
import { Tabs, Tab, Tooltip, Icon, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Typography, IconButton } from '@material-ui/core'

import { EnhancedTable } from '../../../components/table'

import * as actions from './actions'
import * as p from '../../../actions/permissions'
import _ from 'lodash'

export class SolutionsTable extends React.Component {

    state = {
        apiDialogOpen: false,
        tabValue: 'request',
    }

    componentDidMount() {
        if (p.permission(this.props.user, p.LIST_SOLUTIONS)) {
            this.props.getSolutions(this.props.user._links[p.SOLUTIONS].href)
        }
    }

    expandVersions = (expanded, record) => {
        if (expanded) {
            this.props.getVersions(record._links.versions.href, record._embedded.webSafeString)
        }

    }

    handleApiDialogOpen = (url, version) => {
        this.setState({
            apiDialogOpen: true,
            solutionWss: version.entityWss,
            versionWss: version.webSafeString,
            apiUrl: url
        });
    }
    handleApiDialogClose = () => {
        this.setState({ apiDialogOpen: false });
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    }

    renderApiDialog = () => {
        const { fullScreen } = this.props
        const solution = (this.state.solutionWss && this.state.versionWss) ? this.props.versions[this.state.solutionWss][this.state.versionWss].solution : undefined
        const requestFields = (solution && solution._embedded) ? solution._embedded.applicationDatasetFields : [] || []
        const responseFields = (solution && solution._embedded) ? solution._embedded.runtimeDatasetFields : [] || []
        
        const inputs = _.map(requestFields, field => {
            const sampleValue = field.type === 'String' ? "'text'" : field.type === 'Integer' ? 123 : 123.45
            return `'${field.name}': ${sampleValue}, \n      `
        })
        const outputs = _.map(responseFields, field => {
            const sampleValue = field.type === 'String' ? '"text"' : field.type === 'Integer' ? 123 : 123.45
            return `"${field.name}": ${sampleValue}, \n            `
        })
        return (<Dialog
            fullScreen={fullScreen}
            open={this.state.apiDialogOpen}
            onClose={this.handleApiDialogClose}
            aria-labelledby="dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="dialog-title">{"API connection details"}</DialogTitle>
            <DialogContent>
                <Tabs value={this.state.tabValue} onChange={this.handleTabChange} variant="fullWidth">
                    <Tab value="request" label="Request" />
                    <Tab value="response" label="Response" />
                </Tabs>
                {this.state.tabValue === 'request' && <div >
                    <Typography variant="h6">
                        cURL command
                    </Typography>
                    <div style={{ overflow: 'auto' }}>
                        <span style={{ whiteSpace: "pre" }}>
                            {`curl -X POST \\\n`}
                            {`  ${this.state.apiUrl} \\\n`}
                            {`  -H "Content-Type: application/json" \\\n`}
                            {`  -H "Authorization: Bearer [your OAuth 2.0 token]" \\\n`}
                            {`  -H "cache-control: no-cache" \\\n`}
                            {inputs.length !== 0 ? `  -d {\n       ${inputs}}` : null}
                            {`\n\n`}
                        </span>
                    </div>
                </div>}
                {this.state.tabValue === 'response' && <div >
                    <Typography variant="h6">
                        Sample response
                    </Typography>

                    <div style={{ overflow: 'auto' }}>
                        <span style={{ whiteSpace: "pre" }}>
                            {`{\n`}
                            {`    "_links": {\n`}
                            {`        "self": {\n`}
                            {`            "rel": "self",\n`}
                            {`            "href": "http://localhost:8080/dme/decisioning/v1/executions/....."\n`}
                            {`        }\n`}
                            {`    },\n`}
                            {`    "_embedded": {\n`}
                            {`        "started": "2018-11-08T09:06:02.020+11:00",\n`}
                            {`        "ended": "2018-11-08T09:06:02.053+11:00",\n`}
                            {`        "versionWss": "${this.state.versionWss}",\n`}
                            {`        "solutionWss": "${this.state.solutionWss}",\n`}
                            {`        "solutionProcessId": "IO.SQPM.........-....-....-....-............",\n`}
                            {`        "runtimeData": {`}
                            {outputs.length !== 0 ? `\n            ${outputs}` : null}
                            {`},\n`}
                            {`        "durationMillis": "33",\n`}
                            {`        "webSafeString": "....."\n`}
                            {`    }\n`}
                            {`}\n`}
                        </span>
                    </div>

                </div>}
                <DialogContentText>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleApiDialogClose} color="primary">
                    Close
              </Button>
            </DialogActions>
        </Dialog>)
    }
    renderVersions = (record) => {
        const versions = _.map(this.props.versions[record._embedded.webSafeString])

        const statusName = (deploymentStatus) => {
            switch (deploymentStatus) {
                case 'notDeployed':
                    return 'Not deployed'
                case 'serving':
                    return 'Serving'
                case 'stopped':
                    return 'Stopped'
                case 'error':
                    return 'Error'
                default:
                    return 'Error'
            }
        }
        const columns = [
            { title: 'Date', dataIndex: '_embedded.createdDate', key: 'createdDate', render: (value) => <span>{isoToPrettyDate(value)}</span> },
            {
                title: 'Name', dataIndex: '_embedded.versionName', key: 'versionName',
                render: (text, record) => {
                    const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_SOLUTION)
                    return canEdit ? <EditInput
                        onChange={value => (
                            this.props.updateVersion(record._links.self.href, { ...record._embedded, versionName: value }))
                        }
                        value={text}
                        key={record._embedded.webSafeString + 'versionName'} >
                        {text}
                    </EditInput> : <React.Fragment>{text}</React.Fragment>
                }

            },
            {
                title: 'Description', dataIndex: '_embedded.versionDescription', key: 'versionDescription',
                render: (text, record) => {
                    const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_SOLUTION)
                    return canEdit ? <EditInput
                        onChange={value => (
                            this.props.updateVersion(record._links.self.href, { ...record._embedded, versionDescription: value }))
                        }
                        value={text}
                        key={record._embedded.webSafeString + 'versionDescription'} >
                        {text}
                    </EditInput> : <React.Fragment>{text}</React.Fragment>
                }


            },
            {
                title: 'API ID', dataIndex: '_embedded.webSafeString', key: 'APIEndpointCopy', render: (value, record) =>
                    <React.Fragment>
                        {value}
                        <Tooltip title='Open API details'  >
                          <IconButton aria-label="Open API details" onClick={() => {
                                this.props.getVersionSolution(record._links.entity.href, record._embedded.webSafeString)
                                this.handleApiDialogOpen(record._links.self.href + '/execute', record._embedded)
                            }
                            }>
                                <Icon>info</Icon></IconButton>
                        </Tooltip>
                    </React.Fragment>
            },
            { title: 'Status', dataIndex: '_embedded.deploymentStatus', key: 'deploymentStatus', render: (value) => <span>{statusName(value)}</span> },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '_embedded.deploymentStatus',
                render: (value, record) => (
                    <span className="table-operation">
                        {value === 'serving' ?
                            <Tooltip title="Stop serving this version" >
                            <IconButton aria-label="Stop serving this version" onClick={e => { e.preventDefault(); this.props.stopVersion(record._links.stop.href) }}>
                                <Icon>pause</Icon>
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Start serving this version" >
                                <IconButton aria-label="Start serving this version" onClick={e => { e.preventDefault(); this.props.deployVersion(record._links.deploy.href) }}><Icon>play_arrow</Icon></IconButton>
                            </Tooltip>
                        }
                        <React.Fragment>
                            <Tooltip title="Delete version" >
                                <IconButton aria-label="Delete version" onClick={e => { e.preventDefault(); this.props.deleteVersion(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                            </Tooltip>
                        </React.Fragment>
                    </span>
                ),
            },
        ];

        return (
            versions.length > 0 ?
                <EnhancedTable
                    columns={columns}
                    dataSource={versions}
                    rowKey={record => record._embedded.webSafeString}
                    pagination={false}
                />
                : null
        );
    };

    render() {
        const { items } = this.props
        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_SOLUTIONS)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_SOLUTION)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_SOLUTION)

        const columns = [{
            title: 'Name',
            dataIndex: '_embedded.name',
            key: 'name',
            className: this.props.columnClassName,
            render: (text, record) => {
                return canEdit ? <EditInput
                    onChange={value => (
                        this.props.updateSolution(record._links.self.href, { ...record._embedded, name: value }))
                    }
                    value={text}
                    key={record._embedded.webSafeString} >
                    {text}
                </EditInput> : <React.Fragment>{text}</React.Fragment>
            }

        }, {
            title: 'Description',
            dataIndex: '_embedded.description',
            key: 'description',
            className: this.props.columnClassName,
            render: (text, record) => {
                return canEdit ? <EditInput
                    onChange={value =>
                        this.props.updateSolution(record._links.self.href, { ...record._embedded, description: value })
                    }
                    value={text}
                    key={record._embedded.webSafeString} >
                    {text}
                </EditInput> : <React.Fragment>{text}</React.Fragment>
            }
        }, {
            title: 'Action',
            key: 'action',
            className: this.props.columnClassName,
            render: (text, record) => (
                <span>
                    {canEdit ? <React.Fragment>
                        <Tooltip title="Open in editor" >
                        <IconButton>
                            <Link aria-label="Open in solution editor"
                                to={{ pathname: `/solution/${record._embedded.webSafeString}` }}
                                target={record._embedded.webSafeString}>
                                <Icon>open_in_new</Icon>
                            </Link>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Create a new version" >
                            <IconButton aria-label="Create a new version" onClick={e => { e.preventDefault(); this.props.createVersion(record._links.versions.href) }}>
                                <Icon>add_circle</Icon>
                            </IconButton>
                        </Tooltip>
                    </React.Fragment> : <React.Fragment />}
                    {canDelete ? (<Tooltip title="Delete solution" >
                        <IconButton aria-label="Delete solution" onClick={e => { e.preventDefault(); this.props.deleteSolution(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                    </Tooltip>) : (<React.Fragment />)}
                </span>
            ),
        }]
        return (
            <React.Fragment>
                {this.renderApiDialog()}

                <EnhancedTable className={this.props.className}
                    dataSource={items}
                    columns={columns}
                    rowKey={record => record._links.self.href}
                    canCreate={canAdd}
                    onCreate={_ => this.props.createSolution(this.props.user._links[p.SOLUTIONS].href)}
                    canDelete={canDelete}
                    onDelete={record => this.props.deleteSolution(record._links.self.href)}
                    onExpand={this.expandVersions}
                    expandedRowRender={this.renderVersions}
                    pagination
                />
            </React.Fragment>
        )
    }
}


SolutionsTable = connect(
    state => ({
        items: Object.values(state.solutions.present.items),
        versions: state.solutions.present.versions,
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(SolutionsTable)
