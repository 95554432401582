import { arrayToObject, addUpdateItem, removeItem } from '../../../functions'

import * as actions from './actions'

const initialState = {
    items: {}
}

export const datasourcesReducer = (state = initialState, action) => {
    switch (action.type) {

        case actions.GET_BUCKETS_REQUEST: {
            return {
                ...state,
                alertMessage: 'Loading the list of buckets'
            }
        }

        case actions.GET_BUCKET_FILES_REQUEST: {
            return {
                ...state,
                alertMessage: 'Loading the list of files from the bucket'
            }
        }

        case actions.GET_BUCKET_FILE_DETAILS_REQUEST: {
            return {
                ...state,
                alertMessage: 'Retrieving the details of the file'
            }
        }

        case actions.GET_DATASOURCE_META_DATA_REQUEST: {
            return {
                ...state,
                alertMessage: 'Retrieving the details the datasource details'
            }
        }

        case actions.GET_DATASOURCES_SUCCESS: {
            return {
                ...state,
                items: { ...arrayToObject(action.payload.items) }
            }
        }

        case actions.GET_DATASOURCE_META_DATA_SUCCESS: {
            return {
                ...state,
                datasourceMetadata: action.payload
            }
        }

        case actions.GET_DATASOURCE_OBJECTS_SUCCESS: {
            return {
                ...state,
                datasourceObjects: { ...arrayToObject(action.payload.items) }
            }
        }


        case actions.CREATE_DATASOURCE_SUCCESS: {
            {
                let newItems = { ...state.items }
                newItems[action.payload._embedded.webSafeString] = action.payload
                return {
                    ...state,
                    objectsImported: action.payload['Objects created'],
                    items: newItems,
                    importCompleted: true,
                }
            }
        }

        case actions.ADD_COUNTING_WSS: {
            {
                let  newUpdating = state.updating||[]
                newUpdating.push(action.webSafeString)
                return {
                    ...state,
                    updating : newUpdating
                }
            }
        }
        case actions.REMOVE_COUNTING_WSS: {
            {
                let  newUpdating = state.updating
                const index = newUpdating.indexOf(action.webSafeString)
                if (index> -1) {
                    newUpdating.splice(index, 1)
                }
                return {
                    ...state,
                    updating : newUpdating
                }
            }
        }

        case actions.UPDATE_DATASOURCE_RECORD_COUNT_SUCCESS: 
        case actions.UPDATE_DATASOURCE_SUCCESS: {
            let newUpdating = state.updating // .splice(action.payload._embedded.webSafeString)
            return {
                ...state,
                items: { ...addUpdateItem(state.items, action) },
                updating : newUpdating
            }
        }

        case actions.DELETE_DATASOURCE_SUCCESS: {
            return {
                ...state,
                items: { ...removeItem(state.items, action) }
            }
        }
        
        case actions.GET_DATASOURCES_FAILURE: {
            return state
        }

        case actions.GET_BUCKETS_SUCCESS: {
            return {
                ...state,
                buckets: action.payload,
                alertMessage: undefined
            }
        }

        case actions.GET_BUCKET_FILES_SUCCESS: {
            return {
                ...state,
                files: action.payload,
                alertMessage: undefined
            }
        }

        case actions.GET_BUCKET_FILE_DETAILS_SUCCESS: {
            return {
                ...state,
                fileDetails: action.payload,
                alertMessage: undefined
            }
        }

        default: return state
    }
}