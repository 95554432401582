import { takeEvery, all, put } from 'redux-saga/effects'

import * as actions from './actions'

function* addRecordCounts(action){
 yield put(actions.updateDatasourceRecordCountApiRequest(action.location,))
 yield put(actions.addCountingWss(action.webSafeString))
}

function* removeRecordCounts(action){
  yield console.log("remove  records???", action.payload._embedded.webSafeString)
  yield put(actions.removeCountingWss(action.payload._embedded.webSafeString))
 }
 
function* watchRecordCounts(){
  yield takeEvery( actions.UPDATE_DATASOURCE_RECORD_COUNT, addRecordCounts )
  yield takeEvery( actions.UPDATE_DATASOURCE_RECORD_COUNT_FAILURE, removeRecordCounts )
  yield takeEvery( actions.UPDATE_DATASOURCE_RECORD_COUNT_SUCCESS, removeRecordCounts )
}


export function* datasourceSagas(){
  yield all([ watchRecordCounts()
  ])
}