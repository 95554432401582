import { createActionTypeReducer } from '../../../middleware'

import {
    INIT_SOLUTION_EDITOR,
    SELECTED_COMPONENT,
    DESELECTED_COMPONENT,
    DELETE_COMPONENT
} from './actions'

const initialState = {
    selected: {},// map of selected components
}


const getSelectedItems =(diagram) => {
    if (diagram) {
        const selectedNodes =  diagram.nodes.filter(node=> node.selected === true).reduce((map, item)  => 
        { 
            map[item.id] =  item
            return map
        }, {}) ||[]
        const selectedLinks =  diagram.links.filter(link=> link.selected === true).reduce((map, item)  => 
        { 
            map[item.id] =  item
            return map
        }, {}) ||[]
        return {...selectedLinks, ...selectedNodes}
    }
    else {
        return []
    }
}

export const solutionSelectionReducer = createActionTypeReducer(initialState, {
    [INIT_SOLUTION_EDITOR]: (state, action) => ({ ...state, ...initialState, ...action.payload }), 
    // diagram component selection - reference to actual component for serialization back to diagram
    [SELECTED_COMPONENT]: (state, action) => ({ ...state, selected: { ...state.selected, [action.component.id]: action.component } }),
    [DESELECTED_COMPONENT]: (state, action) => {
        return { ...state, selected: getSelectedItems(action.diagram) }
    }, // remove the selected component on delete
    [DELETE_COMPONENT]: (state, action) => {
        const { [action.component.id]: value, ...newState } = state.selected
        return {
            ...state,
            selected: newState,
        }

    },
})

