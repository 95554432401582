import * as React from "react";
import * as _ from "lodash";
import { BaseWidget, DefaultPortLabel } from "storm-react-diagrams";
import { connect } from 'react-redux'
import { Tooltip}  from '@material-ui/core'

export class NodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-default-node", props);
		this.state = {};
	}

	generatePort(port) {
		port.label = <i id='nodeIcon' />//className="material-icons">arrow_right</i>
		return <DefaultPortLabel model={port} key={port.id} />;
	}

	render() {

		const events = (this.props.execution && this.props.execution._embedded && this.props.execution._embedded.executionEvents) ? this.props.execution._embedded.executionEvents : [] 
		const errors = (this.props.solution && this.props.solution.errors) ? this.props.solution.errors : [] 

		const executionEvents = [...events , ...errors]
		let style = { background: this.props.node.color }
		let valueChanged = []
		if (executionEvents) {
			const myId = this.props.node.id
			if (myId) {
				const executed = _.find(executionEvents, event => { return event.id === myId && event.action === "node.triggered"})
				const errorState = _.find(executionEvents, event => { return event.id === myId && event.eventType ==='ERROR' })
				if (executed) {
					style = executed ? { background: this.props.node.color, boxShadow: '0px 0px 15px 5px rgba(00, 00, 200, 0.8)' } : { background: this.props.node.color }
					// let's find the incoming and outgoing runtime datas
					const outEvent = _.find(executionEvents, event => { return event.action === "node.left" && event.id === myId })
					if (executed && outEvent && outEvent.runtimeData) {
						_.forEach(outEvent.runtimeData, (value, key) => {
							if (!executed.runtimeData || executed.runtimeData[key] !== value) {
								// const prevValue = executed.runtimeData?executed.runtimeData[key]:" "
								valueChanged.push(key + ": " + value)

							}
						})
					}

				}
				if (errorState) {
					style = errorState ? { background: this.props.node.color, boxShadow:  '0px 0px 15px 5px rgba(255,0,0, 1.0)'} : { background: this.props.node.color }
				}
			}
		}

		const delayed = false;

		const toolTipContent = <div className={this.bem("__tooltip")}>
			<div className={this.bem("__tooltip_title")}>{this.props.node.typeLabel}</div>
			Name: {this.props.node.label}<br />
			{valueChanged.length > 0 ? '___________________' : null}
			{_.map(valueChanged, (value, index) => { return (<div key={index}>{value}</div>) })}
		</div>

		return (
			<Tooltip title={toolTipContent}><div {...this.getProps()} style={style}>
				<div className={this.bem("__title")}>

					<div className={this.bem("__name")}><i id='nodeIcon' className="material-icons">{this.props.node.icon}</i></div>
					<div className={this.bem("__delayed")}>
					{delayed? <i className="material-icons">schedule</i> : <React.Fragment/>}
					</div>

					{this.props.node.changeIcon?
					<div className={this.bem("__changed")} style= {{opacity: valueChanged.length>0? 1 : 0.4 }}>
						<i className="material-icons">{valueChanged.length > 0 ? 'flash_on' : 'flash_off'}</i>
					</div> : null}
				</div>
						<div className={this.bem("__content")}>
							{this.props.node.label}
						</div>

				<div className={this.bem("__in")} style={{borderColor:this.props.node.color}}>
						{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}
					</div>
					<div className={this.bem("__out")} style={{borderColor:this.props.node.color}}>
						{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}
					</div>
				</div>
			</Tooltip>
		);
	}
}

NodeWidget = connect(
	(state, props) => ({
		execution: state.solutions.present.items[props.solutionId] && state.solutions.present.items[props.solutionId].execution,
		solution: state.solutions.present.items[props.solutionId] && state.solutions.present.items[props.solutionId].definition,

	}),
	null
)(NodeWidget)
