import React, {lazy, Suspense} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../../styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import{Icon, Grid, Paper } from "@material-ui/core";

class Home extends React.Component {

  render() {
    const PublicLinks = lazy( ()=> import('./PublicLinks'))


    const { classes } = this.props;
    
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.layout}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              align="center"
              noWrap
              className={classes.toolbarTitle}
            >
              <div className={classes.logo} style={{fontSize: '3em', margin:30}}>DICIDIO</div>
            </Typography>
          <main>
            {/* Main featured post */}
            <Paper className={classes.mainFeaturedPost} style={{align:'center', width:'70%', marginLeft:'20%', marginRight:'20%', marginTop:50, marginBottom : 100}}>
            <Grid container style = {{width:'100%', cursor:'pointer', height:120}} onClick={e => (window.location.href = "/admin")}>
            <Grid item key='filler'  sm={false} md={2} />
            <Grid item key='welcome' xs={12} sm={8} md = {6} >
                <Typography
                      variant="h1"
                      color="inherit"
                      style={{fontFamily :"barlow, roboto, arial", fontSize:100, fontWeight:'500', textAlign:'center', lineHeight:'110%', margin:'5px'}}
                    >
                    Start
                    </Typography>
                </Grid>
                <Grid item key='icon' xs={false} sm={4} md = {4} >
                  <Icon style={{ fontSize:110, lineHeight: '110%' }}>exit_to_app</Icon>                    
          </Grid>
        </Grid>
            </Paper>
            <Suspense fallback = {<Typography  variant = 'body1'>loading...</Typography>} >
              <PublicLinks classes={classes}/>
            </Suspense>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Home);
