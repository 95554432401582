import { takeEvery, all, put, call, take, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { appStatus, clearAppStatus, startAsyncWait, finishAsyncWait } from '../App/actions'
import * as msg from './messages'

/**
 * These are our notification methods
 * Dialog - showDialog: interrupt the user's flow for a very important message
 * Status - updateStatus: display a small, probably unimportant message about the application status (Saving...)
 * Notification - notify: display a popup 'toast' message for a brief duration
 * Log - logMessage: pump a message into the application log for analysis by the hamsters
 */

/**
 * Show an application modal dialog appropriate to the level
 * info, success, warning, error
 */
/* function* showDialog(action) {
    yield Modal[action.level](action)
}
 */
/**
 * Update the status line for 5 seconds
 */
function* updateStatus(action) {
        yield put(appStatus({ message: action.message, description: action.description, code: action.level }))
        yield call(delay, 5000)
        yield put(clearAppStatus())
}

/**
 * Show a notification toast appropriate to the level
 * info, success, warning, error
 */
/* function* notify(action) {
    // yield notification[action.level](action)
} */

function* logMessage(action) {
    yield console.log(`${action.message}: ${action.description}`)
}

function* startAsync(action){
    yield put(startAsyncWait())
}

function* finishAsync(action){
    yield put(finishAsyncWait())
}


/**
 * Watch for each occurance of a type of notification
 */
function* watchMessages(){
    yield all([
        //yield takeEvery(msg.USER_DIALOG, showDialog),
        //yield takeEvery(msg.USER_NOTIFICATION, notify),
        yield takeEvery(msg.USER_STATUS, updateStatus),
        yield takeEvery(msg.APP_LOG, logMessage),
        yield takeEvery( action => action.type.includes('REQUEST'), startAsync),
        yield takeEvery( action => action.type.includes('SUCCESS'), finishAsync),
        yield takeEvery( action => action.type.includes('FAILURE'), finishAsync)
    ])
}

/**
 * API Errors - general notification when bad stuff happens
 */

/* export function* notifyAPIErrors(action) {
    // go for the specific message : fallback to generic message
    const name = action.meta.displayName
    const message = action.payload && action.payload.response && action.payload.response.error.message ? 
        action.payload.response.error.message 
        : action.payload.message
    yield put(msg.logAppMessage('API Method Error', message))
} */




/**
 * background polling
 */
export const START_POLL = '@@sagas/START_POLL'
export const startPoll = ( api, delay ) => ({
    type: START_POLL,
    api: api,
    delay: delay
})

export const STOP_POLL =  '@@sagas/STOP_POLL'
export const stopPoll = () => ({
    type: STOP_POLL,
})

function* pollApi(action){
    while(true){
        yield put(action.api)
        yield call( delay, action.delay)
    }
}

function* watchPolling() {
    while (true) {
      const action = yield take(START_POLL);
      yield race([
        call(pollApi, action),
        take(STOP_POLL)
      ]);
    }
  }

export function* actionSagas() {
    yield all([
        watchMessages(),
        watchPolling(),
    ])
}

