import React from 'react'
import MonacoEditor from 'react-monaco-editor'

export class Editor extends React.Component {

  editorDidMount = (editor, monaco) => {
    //editor.focus();
  }

  onChange = (newValue, e) => {
    this.props.onChange(newValue, e)
  }

  render() {
    // const code = this.state.code;
    const options = {
      selectOnLineNumbers: true,
      codeLens: false,
      autoClosingBrackets: true,
      autoIndent: true,
      minimap: { enabled: false }
    };

    const requireConfig = {
      url: 'https://cdnjs.cloudflare.com/ajax/libs/require.js/2.3.1/require.min.js',
      paths: {
        vs: 'https://as.alipayobjects.com/g/cicada/monaco-editor-mirror/0.10.1/min/vs'
      }
    };

    return (
          <MonacoEditor 
            language={this.props.language ? this.props.language : "javascript"}
            theme="vs"
            value={this.props.value}
            options={options}
            onChange={this.onChange}
            editorDidMount={this.editorDidMount}
            requireConfig={requireConfig}
            {...this.props}
          />
        )
  }
}
