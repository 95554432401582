import React from 'react'
import _ from 'lodash'
import {
  DiagramEngine,
  //  DiagramModel,
  DiagramWidget,
  DiagramModel,
} from "storm-react-diagrams";

import {
  MoveCanvasAction,
  MoveItemsAction,
} from 'storm-react-diagrams'

import {
  LinkFactory,
  PortFactory,
  LabelFactory,
  DecisionNodeFactory,
} from './factories'

import './SolutionDiagram.css'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../../styles'

/**
 * SolutionDiagram - React event and drag & drop layer for Storm React Diagrams
 * Event interface for the underlying diagram
 * nodes: move/delete
 * link: create/delete
 * UI: drag, drag & drop, 
 * 
 * the number of points in a link is restricted to make diagrams less messy
 * smart path routing is turned off, seems buggy in high traffic situations
 * 
 */


export class SolutionDiagram extends React.PureComponent {
  engine = new DiagramEngine()

  constructor(props) {
    super(props)
    this.engine.registerNodeFactory(new DecisionNodeFactory(props.solutionId))
    this.engine.registerLinkFactory(new LinkFactory(props.solutionId))
    this.engine.registerPortFactory(new PortFactory())
    this.engine.registerLabelFactory(new LabelFactory());
    this.engine.setDiagramModel(this.getNewModel())
  }

  getNewModel() {
    let model = new DiagramModel()
    model.addListener({
      nodesUpdated: e => this.nodeEvents(e),
      linksUpdated: e => this.linkEvents(e),
      //offsetUpdated: e => this.diagramChanged(),
      //zoomUpdated: e => this.diagramChanged(),
      //gridUpdated: e => this.diagramChanged(),
    })
    return model
  }

  diagramChanged = e => {
    const { onUpdate } = this.props
    onUpdate && onUpdate(this.engine.getDiagramModel().serializeDiagram())
  }

  selectionChanged = (e, diagram) => {
    const { onSelectionChanged } = this.props
    onSelectionChanged && onSelectionChanged(e, diagram)
  }

  itemDeleted = e => {
    const { onDelete } = this.props
    onDelete && onDelete(e)
  }

  zoomIn = () => {
    const { zoomLevel } = this.props
    zoomLevel && zoomLevel(this.engine.getDiagramModel().zoom + 10)
  }
  zoomOut = () => {
    const { zoomLevel } = this.props
    zoomLevel && zoomLevel(this.engine.getDiagramModel().zoom - 10)
  }

  nodeEvents(e) {
    e.node.addListener({
      selectionChanged: (e) => this.selectionChanged(e,this.engine.getDiagramModel().serializeDiagram()),
      entityRemoved: this.itemDeleted,
    })
  }

  selectNode = (nodeId) => {
    let nodes = this.engine.getDiagramModel().getNodes()
    _.forEach(nodes, node => 
      {
        if (node.id === nodeId) {
            node.setSelected(true)
        }
        else {
          if (node.selected) {
            node.setSelected(false)
          }
        }
        
      })
    this.engine.repaintCanvas()
  }
  getNameForLink = (linkId) => {

    const links = this.engine.getDiagramModel().getLinks()
    const myLink = _.find(links, ['id', linkId])
    if (!myLink) {
      return null;
    }
    return `${myLink.sourcePort && myLink.sourcePort.parent ? myLink.sourcePort.parent.label : ''} > ${myLink.targetPort && myLink.targetPort.parent ? myLink.targetPort.parent.label : ''}`
  }

  linkEvents(e) {
    if (e.isCreated) {
      e.link.addListener({
        // targetPortChanged: this.diagramChanged,
        // sourcePortChanged: this.diagramChanged,
        //sourcePortChanged: e => this.linkEvent(e),
        selectionChanged: this.selectionChanged,
        entityRemoved: this.itemDeleted,
      })
    }
  }


  onDrop = (e) => {
    let data = e.dataTransfer.getData("diagram-node")
    if (!data) return
    var component = JSON.parse(data)
    var factory = this.engine.getNodeFactory(component.type)
    var node = factory.getNewInstance(component)
    let points = this.engine.getRelativeMousePoint(e)
    node.x = points.x - 50
    node.y = points.y - 50
    this.engine.getDiagramModel().addNode(node)
    this.diagramChanged()
    this.engine.repaintCanvas()
  }

  getSelectedItems =()=> {
    const model = this.engine.getDiagramModel()
    const myDiagram = model.serializeDiagram()
    const selectedNodes = myDiagram ? myDiagram.nodes.filter(node => node.selected === true && node.name !== 'Start') : []
    const selectedLinks = myDiagram ? myDiagram.links.filter(link => link.selected === true) : []
		return  { nodes: selectedNodes, links: selectedLinks }
  }
  getSelectedItemCount =()=> {
    const selectedItems = this.getSelectedItems()
    return(selectedItems.nodes.length + selectedItems.links.length)
  }

  insertItems = (items) => {
    let model = this.engine.getDiagramModel()
    let myDiagram = model.serializeDiagram()
    // paste and deselect any other items
    myDiagram.nodes = [...myDiagram.nodes.map(node=> ({...node, selected:false})), ...items.nodes]
    myDiagram.links = [...myDiagram.links.map(link=> ({...link, selected:false})), ...items.links]

    model.deSerializeDiagram(myDiagram, this.engine)
    this.diagramChanged()
    this.engine.repaintCanvas()
  }

  eventHandler = (e) => {
    // marquee selection
    if (e instanceof MoveItemsAction)
      this.diagramChanged()
    if (e instanceof MoveCanvasAction)
      this.diagramChanged()
  }

  render() {
    const { datasource } = this.props
    if (datasource) {
      let model = this.getNewModel()
      model.deSerializeDiagram(datasource, this.engine)
      this.engine.setDiagramModel(model)
    }
    return (
      <React.Fragment
        >
        <div
          className="diagram-layer"
          onDrop={this.onDrop}
          onDragOver={e => { e.preventDefault() }}
        >
          <DiagramWidget
            ref = {this.props.forwardedRef }
            className='diagram-canvas'
            diagramEngine={this.engine}
            maxNumberPointsPerLink={0}
            allowLooseLinks={true}
            deleteKeys={[]}
            inverseZoom={true}
            {...this}
            actionStoppedFiring={this.eventHandler}
          />
        </div>
      </React.Fragment>
    )
  }
}

SolutionDiagram = withStyles(styles, { withTheme: true })(SolutionDiagram)

export default  React.forwardRef((props, ref) => {
  return (<SolutionDiagram {...props} forwardedRef ={ref} />)
})

