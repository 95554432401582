import React from 'react'
import { Typography, Card } from '@material-ui/core'
import VegaLite from 'react-vega-lite';
import { Handler} from 'vega-tooltip';

export class Diagram extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lastUpdate : new Date(),
            canRender : true,
        }
        this.CARD_REF = React.createRef()

    }

    componentDidMount() {
        this.countdown = setInterval(this.tick, 200);
	}

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.spec !== this.props.spec) {
            this.setState({canRender: false, lastUpdate : new Date()})
            return false
        }
        return this.state.canRender
    }

    tick = () =>{
		if (!this.state.canRender) {
			const sec = (new Date() - this.state.lastUpdate )/1000 
			if (sec> 0.7){
                this.setState({canRender: true})
                this.forceUpdate()
			}
		}
	}

   
    render() {
        const {spec, data, classes} = this.props
   
        const rect = this.CARD_REF.current?this.CARD_REF.current.getClientRects()[0]:null
        if (rect) {
            spec.height = rect.height*.75
            spec.width = rect.width*.75
        }
        return (
            <Card style={{height:'97%', }}  className={classes.axisCard} elevation={5} 
            >
            <div style={{width:'100%', height:'100%',}} ref={this.CARD_REF} >
            {data ?
                <VegaLite spec={spec} data={{values:data}} tooltip={new Handler().call} 
                autosize={{ type: "fit",   contains: "padding"}}/>
            :
                <Typography variant="h4">Loading results...</Typography>
            }
            </div>
            </Card>
        )

    }
}

