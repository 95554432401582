import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Icon, Tooltip, IconButton} from '@material-ui/core'
import { EnhancedTable } from '../../../components/table'

import * as actions from './actions'
import * as p from '../../../actions/permissions'

import { EditInput, } from '../../../components/table'
import { ItemsSelector } from '../../../components/ItemsSelector'

import _ from "lodash"


export class RolesTable extends React.Component {

    state = {
        permissionsEditorVisible: false,
        editPermissions: []
    }
    componentWillMount() {
        if (this.props.user._embedded) {
            if (p.permission(this.props.user, p.LIST_ALLROLES)) {
                this.props.getRoles(this.props.user._links[p.ALLROLES].href)
            }
            if (p.permission(this.props.user, p.LIST_PERMISSIONS)) {
                this.props.getPermissions(this.props.user._links[p.PERMISSIONS].href)
            }
        }
    }

    openPermissionsEditor = (e, permissions) => {
        this.setState({
            selectedUser: e,
            permissionsEditorVisible: true,
            editPermissions: e._embedded.permissions,
        })
    }

    closePermissionsEditor = (e) => {
        this.setState({
            permissionsEditorVisible: false,
        })
    }

    updatePermissions = (e) => {
        this.props.updateRole(this.state.selectedUser._links.self.href, { ...this.state.selectedUser._embedded, permissions: this.state.editPermissions })
        this.closePermissionsEditor()
    }

    handlePermissionsChange = (permissions) => {
        this.setState({
            editPermissions: permissions
        })
    }
    render() {
        const { items, updateRole, createRole, deleteRole } = this.props
        const canAdd = _.includes(this.props.user._embedded.permissions, p.CREATE_ALLROLES)
        const canEdit = _.includes(this.props.user._embedded.permissions, p.UPDATE_ALLROLES)
        const canDelete = _.includes(this.props.user._embedded.permissions, p.DELETE_ALLROLES)
        const canEditPermissions = _.includes(this.props.user._embedded.permissions, p.UPDATE_ALLROLES)
        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    if (record._embedded.code === 'ADMI' || !canEdit) {
                        return (<React.Fragment>
                            {text}
                        </React.Fragment>)
                    }
                    else {
                        return (
                            <EditInput
                                onChange={value =>
                                    updateRole(record._links.self.href, { ...record._embedded, name: value })
                                }
                                value={text}
                                key={record._embedded.webSafeString} >
                                {text}
                            </EditInput>)
                    }
                }
            }, {
                title: 'Description',
                dataIndex: '_embedded.description',
                key: 'description',
                render: (text, record) => {
                    if (record._embedded.code === 'ADMI' || !canEdit) {
                        return (<React.Fragment>
                            {text}
                        </React.Fragment>)
                    }
                    else {
                        return (
                            <EditInput
                                onChange={value =>
                                    updateRole(record._links.self.href, { ...record._embedded, description: value })
                                }
                                value={text}
                                key={record._embedded.webSafeString} >
                                {text}
                            </EditInput>)
                    }
                }

            }, {
                title: 'Permissions',
                key: 'permissions',
                render: (text, record) =>
                    <React.Fragment>
                        {record._embedded.code !== 'ADMI' ? (<a href={'/#'} onClick={e => { e.preventDefault(); this.openPermissionsEditor(record) }}>{_.size(record._embedded.permissions)} permissions </a>) : (<React.Fragment>{_.size(record._embedded.permissions)} permissions</React.Fragment>)}


                    </React.Fragment>
            }, {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return canDelete && record._embedded.code !== 'ADMI' ?
                            <Tooltip title="Delete role" >
                                <IconButton aria-label="Delete role" onClick={e => { e.preventDefault(); deleteRole(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                            </Tooltip> : null
                }

            }]

        return (
            <div className={this.props.className}>
                <EnhancedTable
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record._links.self.href}
                    canDelete = { canDelete }
                    onDelete = { e => deleteRole(e._links.self.href)} 
                    canCreate={ canAdd }
                    onCreate={e => createRole(this.props.user._links[p.ALLROLES].href)}
                    pagination
                />
                    <ItemsSelector
                        style={{ minWidth: '50%', maxHeight :'80%' }}
                        open={this.state.permissionsEditorVisible}
                        selectedUser={this.state.selectedUser}
                        currentItems={this.state.selectedUser ? this.state.selectedUser._embedded.permissions : []}

                        onCancel={this.closePermissionsEditor}
                        onOk={this.updatePermissions}
                        title={`Role Permissions(${this.state.selectedUser ? this.state.selectedUser._embedded.code : ''}) ${canEditPermissions ? '' : ' (Read-only)'}`}
                        onChange={this.handlePermissionsChange}
                        disabled={!canEditPermissions}
                        okButton={{ disabled: !canEditPermissions }}

                        classes = {this.props.classes}
                        items={this.props.permissions}
                    />
            </div>
        )
    }
}

RolesTable = connect(
    state => ({ // Solutions are mapped in the model, but the UI needs an array
        items: Object.values(state.roles.items),
        permissions: _.map(state.permissions ? state.permissions.items : [], permission => { return { key: permission, title: permission, } }),
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(RolesTable)