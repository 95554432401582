import React from 'react'
import { PredictionConfigsTable } from './PredictionConfigsTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import styles from '../../../styles'

export class PredictionConfigsPage extends React.Component{
    render(){
        return (
            <Paper className={this.props.classes.adminTablePaper} elevation={1}>
                <PredictionConfigsTable  />
            </Paper>
        )
    }
}

PredictionConfigsPage = withStyles(styles, {withTheme: true})(PredictionConfigsPage)