import * as React from "react";
import { AbstractNodeFactory } from 'storm-react-diagrams'

//  widget
import { NodeWidget } from '../widgets'

// model
import { DecisionNodeModel } from '../models'

export class DecisionNodeFactory extends AbstractNodeFactory {
	constructor(solutionId) {
		super("DecisionNode")
		this.solutionId =  solutionId
	}

	generateReactWidget(diagramEngine, node) {
		return <NodeWidget 
			solutionId={this.solutionId} 
			node={node} 
			diagramEngine={diagramEngine} />
	}

	getNewInstance(initialConfig) {
        return new DecisionNodeModel(initialConfig)
	}
}