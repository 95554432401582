import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import  { Select, Radio, RadioGroup, Dialog, DialogContent, DialogTitle, DialogActions, Button, FormControl, FormLabel, 
    FormControlLabel, Input, CircularProgress, Stepper, Step, StepLabel, MenuItem, Typography} from '@material-ui/core'

import * as actions from './actions'
import * as p from '../../../actions/permissions'
import * as _ from 'lodash'
import { EditSelect, EnhancedTable } from '../../../components/table'
import { isAlphaNumUnderscore } from '../../../functions'


export class NewDatasourceDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            canOk: false,
            rowSelection: [],
            importType: undefined,
            tableName: '',
            bucket : '',
            name : '',
            currentStep : 0,
            selectedFile : '',
            projectId : '',
            tableType: 'predictiveAnalytics',
        }
    }

    handleSave = (e) => {
        e.preventDefault();
        var colTypes = this.state.columnTypes
        var rows = _.toArray(this.props.fileDetails)
        var selectedRows = this.state.selectedRows
        _.map(colTypes, (type, col) => {
            const indexRows = rows.findIndex((row => row.name === col))
            if (indexRows >=0) {
                console.log("Found index rows, ", indexRows)
                rows[indexRows].type = type
            }
            const selectedIndex = selectedRows.findIndex((row => row.name === col))
            if (selectedIndex >=0) {
                console.log("Found selectedIndex rows, ", selectedIndex)
                selectedRows[selectedIndex].type = type
            }

        })
        if (this.state.importType === "storage") {
            let fileLocation = this.state.selectedFile
            if (this.state.bucket) {
                fileLocation = "gs://" + this.state.bucket + "/" + this.state.selectedFile
            }

            this.props.createLinkedDatasoureFromBucketFile(this.props.user._links[p.DATASOURCES].href, this.state.name, this.state.projectId, fileLocation, rows, selectedRows)
            this.setState({
                waiting: true
            })
        }
        else if (this.state.importType === "bigQuery") {
            this.props.createBigQueryDatasoure(this.props.user._links[p.DATASOURCES].href, this.state.name, this.state.tableName, this.state.tableType)
            this.setState({
                waiting: true
            })
        }
    }

    handleCancel = () => {
        this.clearState()
        this.props.onCancelComplete && this.props.onCancelComplete()
    }

    changeCreationProcess = (e) => {
        const { value } = e.target
        if (value === 'storage') {
            // get the list of buckets
            this.props.getBuckets(this.props.user._links[p.IMPORTS].href)
            this.setState({
                currentStep: 0
            })
        }
        this.setState({
            importType: value
        })
    }

    handeSelectBucket = (e) => {

        const bucketName = e.target.value
        const bucket = _.find(this.props.buckets,  b=>b.id === bucketName)
        const projectId = bucket?bucket.projectId:'--'

        console.log("Selected ", this.props.user._links[p.IMPORTS].href, projectId, bucketName)
        this.props.getBucketFiles(this.props.user._links[p.IMPORTS].href, projectId, bucketName)
        this.setState({
            bucket: bucketName,
            projectId : projectId,
            files: [],
            selectedFile: ''
        })
    }

    handleFileSelected = (e) => {
        const {value } =  e.target
        const selectedFile  = _.find(this.props.files, file=> file.name === value)

            this.setState({
            selectedFile: selectedFile.name,
            selectedFileLocation :  selectedFile.definition
        })
    }

    nextStep() {
        const currentStep = this.state.currentStep + 1;
        if (currentStep === 1) {

            // load file definition
            console.log("getFileDefinition", this.state.selectedFileLocation, this.state.bucket, this.state.projectId, this.state.selectedFile)
            this.props.getFileDefinition(this.state.selectedFileLocation, this.state.projectId, this.state.bucket,  this.state.selectedFile)
        }
        this.setState({ currentStep: currentStep });
    }

    prevStep() {
        const currentStep = this.state.currentStep - 1;
        this.setState({ currentStep: currentStep });
    }

    clearState() {
        this.setState({
            currentStep: 0,
            bucket: '',
            files: [],
            selectedFile :'',
            name: '',
            canOk: false,
            // importType: undefined,
            allClear: true,
            tableName: '',
        });
    }

    updateColumnType(value, more) {

        var columnTypes = this.state.columnTypes || {}
        columnTypes[value.name] = more.name
        this.setState({ columnTypes: columnTypes })
        // update the state with the new column type

    }

    render() {
        const buckets = () => {
            if (this.props.buckets) {
                return _.map(this.props.buckets, bucket => <MenuItem key={bucket.id} value={bucket.id}>{bucket.name}</MenuItem>)
            }
            else {
                return []
            }
        }


        const files = () => {
            if (this.props.files && this.state.bucket) {
                return _.map(this.props.files, file => {
                return (<MenuItem key={file.name} value={file.name}>{file.name} (${file.length})</MenuItem>)
                })
            }
            return null
        }

        const nameChanged = (e) => {
            this.setState({
                name: e.target.value
            })

        }
        const tableNameChanged = (e) => {
            if (isAlphaNumUnderscore(e.target.value)) {
                this.setState({
                    tableName: e.target.value
                })
            }

        }

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows
                })
            },
            onSelect: (record, selected, selectedRows) => {
                this.setState({
                    selectedRows
                })
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.setState({
                    selectedRows
                })
            },
        }
        const currentStep = this.state.currentStep
        if (this.props.importCompleted === true && this.state.waiting === true) {
            this.setState({
                waiting: false,
            })
            this.clearState()

            this.props.onSaveComplete()
        }

        const showSpinner = (this.props.alertMessage && this.state.importType !== undefined)
        const okButtonDisabled = ((this.state.importType === 'bigQuery' && (this.state.name === '' || this.state.tableName === '')) ||
            (this.state.importType === 'storage' && (this.state.selectedRows === undefined || this.state.selectedRows.length === 0 || this.state.name === '')))
        const {classes} =  this.props

        const columns = [
            {   
                title:'Column name',
                dataIndex :'name',
                key:'name'},
            {
                title:'Data type',
                dataIndex:'type',
                key :'type',
                render: (text, record, index) => (
                    <EditSelect
                        onChange={value => (
                            this.updateColumnType(record, { name: value, description: record }))
                    }
                    value={text}
                    values={[{ value: "string", label: "String" }, { value: "integer", label: "Integer" }, { value: "real", label: "Float" }]}
                    mkey={"type." + index}
                    />
                )
            }]
        return (
            <Dialog
                onClose={this.handleCancel}
                open = {this.props.open}
                aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title">{this.props.title || 'New datasource'}</DialogTitle>
            <DialogContent aria-busy = {showSpinner}>
                <CircularProgress  className={classes.spinBlock} style={{ visibility: showSpinner ? '' : 'hidden' }}/>
                <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                    <FormLabel component="legend">Name</FormLabel>
                <Input
                    value={this.state.name}
                    onChange={nameChanged}
                />
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                    <RadioGroup aria-label="Datasource type"
                        name="gender1"
                        className={classes.group}
                        value={this.state.importType}
                        onChange={this.changeCreationProcess}>
                        <FormControlLabel value={'bigQuery'} control={<Radio />} label="Google BigQuery table" />
                        <FormControlLabel value={'storage'} control={<Radio />} label="Create a new linked Google Storage datasource" />
                    </RadioGroup>
                </FormControl>
                <div style={{ display: (this.state.importType === 'bigQuery') ? '' : 'none',}} >
                    <br />
                    <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Table name</FormLabel>
                        <Input
                            value={this.state.tableName}
                            onChange={tableNameChanged}
                        />
                    </FormControl>
                    <Typography variant='caption' >Table name consists of letters (upper and lowercase), numbers and underscores only.</Typography>  
                    <br />
                    <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                    <FormLabel component="legend">Dataset definition</FormLabel>
                    <RadioGroup value={this.state.tableType}>
                        <FormControlLabel value={'predictiveAnalytics'} control={<Radio />} label="Preditive analytics template" />
                        <FormControlLabel disabled value={'custom'} control={<Radio />} label="Custom dataset format" />
                    </RadioGroup>
                    </FormControl>
                </div>
                <div style={{display: (this.state.importType === 'storage') ? '' : 'none', }} >
                    <br />
                        <Stepper activeStep={this.state.currentStep}>
                            <Step key="1">
                               <StepLabel>Select a file from a Google storage bucket</StepLabel>
                            </Step>
                            <Step key="2">
                                <StepLabel>Configure the fields to be imported</StepLabel>
                            </Step>

                        </Stepper>
                    <div style={{display: currentStep === 0 ? '' : 'none' }} >
                        <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Select a Google storage bucket</FormLabel>
                        <Select
                            onChange={this.handeSelectBucket}
                            value={this.state.bucket}
                        >
                            {buckets()}
                        </Select>
                        </FormControl>
                        <br />
                        <br/>
                        <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Select a file</FormLabel>

                        <Select
                             onChange={this.handleFileSelected}
                                value={this.state.selectedFile}
                            >
                                {files()}
                                </Select>
                        </FormControl>
                        <br />
                    </div>
                    <div style={{display: currentStep === 1 ? '' : 'none' }} >
                    <Typography variant="body1">Please select the fields that you would like to be part of the new datasource</Typography>

                            {this.props.fileDetails ? (
                                <EnhancedTable
                                    pagination = {true}
                                    rowSelection={rowSelection}
                                    rowKey={ record => record.key}
                                    dataSource={_.toArray(this.props.fileDetails)}
                                    columns = {columns} />
                            ) : (<Typography variant="body1">... loading file column information ... </Typography>)
                            }
                    </div>
                    <div className="steps-action">
                    </div>
                </div>
                </DialogContent>
                    <DialogActions>
                    {this.state.importType === 'storage'?
                    currentStep === 0? 
                            <Button disabled={(this.state.selectedFile === undefined || this.state.name === undefined || this.state.name === '')} type="primary" onClick={() => this.nextStep()}>Next</Button> :
                            <Button style={{ marginLeft: 8 }} onClick={() => this.prevStep()}>Previous</Button> :null}
                    <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                    {currentStep === 1 || this.state.importType === 'bigQuery' ?<Button onClick={this.handleSave} disabled = {okButtonDisabled} color="primary" autoFocus>Create</Button>:null}
                    </DialogActions>
                </Dialog>
            )
    }
}



NewDatasourceDialog = connect(
    (state, props) => ({
        ...state.datasources,
        fileDetails: state.datasources.fileDetails ? state.datasources.fileDetails.properties : undefined,
        user: state.user.profile,
    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(NewDatasourceDialog)

