import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'

import {Tooltip , Icon}  from '@material-ui/core'

import styles from '../../styles'


let EnhancedTableToolbar = props => {
    const { numSelected, classes, title } = props
    const { canDelete, onDelete } = props
    return (
      <Toolbar
        className={classNames(classes.toolbarRoot, {
          [classes.toolBarHighlight]: numSelected > 0,
        })}
      >
        <div className={classes.toolBarTitle}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
              <Typography variant="h6" id="tableTitle">
                {title}
              </Typography>
            )}
        </div>
        <div className={classes.toolBarSpacer} />
        <div className={classes.toolBarActions}>
          {numSelected > 0 ?
            (
              canDelete ?
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" onClick={onDelete}>
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip>
                : null
            ) : (null)}
        </div>
      </Toolbar>
    )
  }
  
  EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
  }
  
  export default withStyles(styles, { withTheme: true })(EnhancedTableToolbar)