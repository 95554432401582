
import React from 'react'
import { Dialog, DialogContent, Typography, DialogActions, Button, FormControl, FormLabel, Input, CircularProgress, Divider} from '@material-ui/core';
import { isValidProjectId } from '../../../functions';
export class GcpDialog extends React.Component {

    state = {
        dicidioSelecting : false,
        clientSelecting : false,
        projectIds : '',
        webSafeString: null,
    }

    static getDerivedStateFromProps (props, state) {
        if (props.organisation && props.organisation._embedded && props.organisation._embedded.webSafeString !== state.webSafeString) {
            let projectIds = props.organisation._embedded.gcpProjectIds
            if (Array.isArray(projectIds)) {
                projectIds = projectIds.join()
            }
    
            state = {
                ...state, 
                projectIds : projectIds,
                webSafeString : props.organisation._embedded.webSafeString}
        }
        return state;
    }
   
    handleClose =() => {
        this.props.onClose && this.props.onClose()
    }

    handleGcpProjectIdsChange =(e) => {

        if (e.target.value !==null) { 
            var fields = e.target.value.split(',');
            const reducer = (isValid, item) => {
                if (item!=='' && isValidProjectId(item) === false) {
                    return false
                }
                else {
                    return isValid
                }
            }
            if (fields.reduce(reducer, true)) {
                this.setState({projectIds : e.target.value})
            }
        }
    }

    handleGcpProjectIdsSave =(e) => {

        if (this.state.projectIds!==null  && this.props.onProjectIdsChange) {
            this.setState({projectIdsSaving: true})
            this.props.onProjectIdsChange(this.state.projectIds)
        }
    }

    handleEnableDicidioAccount= ()=> {
        this.props.onAccountSelect && this.props.onAccountSelect(true,null)
        this.setState({dicidioSelecting: true})
    }

    handleFileContenRead = e =>{
        this.props.onAccountSelect && this.props.onAccountSelect(false,e.target.result)
        this.setState({clientSelecting: true})

    }
    handleEnableClientAccount= e => {
        if (e.target.files[0]) {
            let fr = new FileReader()
            fr.onloadend = this.handleFileContenRead;
            fr.readAsText(e.target.files[0])
        }
    }

    render() {
        const {serviceAccountStatus, organisation} = this.props
        const dicidioSelecting = this.state.dicidioSelecting && serviceAccountStatus.inProgress
        const clientSelecting  = this.state.clientSelecting && serviceAccountStatus.inProgress
        const projectIdsSaving = this.state.projectIdsSaving && serviceAccountStatus.inProgress
        const {classes } = this.props
        const accountType = organisation?organisation._embedded.serviceAccountType : 'none' // can be 'none', 'dicidio' or 'client'

        const dicidioSelect = <div style={{display:'flex'}}>
            <Button disabled = {dicidioSelecting} variant="contained" color="primary" onClick={this.handleEnableDicidioAccount}>Enable Dicidio Service Account</Button>
            <div style={{display :dicidioSelecting?null:'none', margin:'5px'}}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    size={24}
                    thickness={4}
                />
                </div>
        </div>

        const jsonKeyFileSelect = <div style={{display:'flex'}}>
            <input
                disabled  = {clientSelecting}
                accept="application/json"
                style={{ display: 'none'}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={this.handleEnableClientAccount}
            />
            <label htmlFor="contained-button-file">
                <Button disabled = {clientSelecting} variant="contained"  component="span"  color="primary" >Upload your own JSON Private Key file</Button>
            </label>
            <div style={{display :clientSelecting?null:'none', margin:'5px'}}>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={24}
                        thickness={4}/>
            </div>
        </div>
        return( 
            <Dialog
                onClose={this.handleClose}
                open = {this.props.open}
                // aria-labelledby="dialog-title"
                >
                {/* <DialogTitle id="dialog-title">GCP Service Account and Project configuration</DialogTitle> */}
                <DialogContent >

                    {serviceAccountStatus.message ? <Typography color ='error' variant="h6">{serviceAccountStatus.message}</Typography> :null}
                    <Typography variant="h5">Google Cloud Project ID</Typography>
                    <Typography variant="body1">Enter the project ID for any Google Cloud Platform projects that this service account will access. Project IDs need to be separated with a comma.</Typography>
                    <br/>
                    <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">GCP project IDs</FormLabel>
                            <Input
                                value={this.state.projectIds || ''}
                                onChange={this.handleGcpProjectIdsChange}
                            />
                            <Typography variant="caption">(Only lowercase letters, numbers and hyphens)</Typography>        
                            <br/>
                        <div style={{display:'flex'}}>
                            <Button onClick={this.handleGcpProjectIdsSave} variant="contained"  component="span"  color="primary" >Save changes</Button>
                            <div style={{display :projectIdsSaving?null:'none', margin:'5px'}}>
                                <CircularProgress
                                    variant="indeterminate"
                                    disableShrink
                                    size={24}
                                    thickness={4}
                                /> 
                            </div>
                        </div> 
                    </FormControl>
                    <br/>
                    <br/>
                    <Divider/>
                    <br/>
                    <Typography variant="h5">Service accounts</Typography>

                    {accountType ==='dicidio' && organisation && organisation._embedded.serviceAccountName? <React.Fragment>
                    <Typography variant="body1">You are currently using a Dicidio Service Account to access Google Storage buckets. You have to grant this service account, using the service account name below, List and Read access to your Storage Buckets. Make sure that the GCP Project IDs for these buckets are included in the Project ID section above.</Typography>
                    <br/>
                    <Typography variant="body1" >Active Service account:</Typography>
                    <br/>
                    <Typography variant="body1" classes={{body1:classes.typoBoldCentered}}>{organisation && organisation._embedded.serviceAccountName}</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1">Alternatively you can use your own Google Service Account, you will have to upload the account's JSON Private Key file. Make sure that the service account has the appropriate Bucket list and read access and that the GCP Project IDs are included in the section above. The Dicidio service account will not be used once you've uploaded a valid JSON Private Key file </Typography>
                    {jsonKeyFileSelect}
                    </React.Fragment>:null}

                    {accountType ==='client' && organisation && organisation._embedded.serviceAccountName? <React.Fragment>
                    <Typography variant="body1">You are currently your own Google Service Account to access Google Storage buckets. You have to grant this service account, using the service account name below, List and Read access to your Storage Buckets. Make sure that the GCP Project IDs for these buckets are included in the Project ID section above.</Typography>
                    <Typography variant="body1" classes={{body1:classes.typoBoldCentered}}>Active Service account: {organisation && organisation._embedded.serviceAccountName}</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1">Alternatively you can use a Dicidio Service Account. You will then have to grant this service account Bucket list and read access and make sure that the GCP Project IDs are included in the section above. Your Private Key details will be deleted from Dicidio when you select to use a Dicido Service Account, the Service Account itself will still exist in your own Google Cloud Project(s). </Typography>
                    {dicidioSelect}
                    </React.Fragment>:null}

                    {accountType ==='none'? <React.Fragment>
                        <Typography variant="body1">A service account will be used to access Google Storage Buckets. You can either use a service account created by Dicidio, or use your own service account.</Typography>
                        <br/>

                        <Typography variant="h6">Use a service account created by Dicidio</Typography>
                        <Typography variant="body1">Dicidio can generate a service account for you. You will then have to grant this service account List and Read access to your Storage Buckets. Make sure that the GCP Project IDs for these buckets are included in the Project ID section above.</Typography>
                        {dicidioSelect}
                        <br/>
                        <br/>
                        <Typography variant="h6">Use your own service account</Typography>
                        <Typography variant="body1">To use an existing Google Service Account that you have access to you will have to upload the account's JSON Private Key file. Make sure that the service account has the appropriate Bucket list and read access and that the GCP Project IDs are included in the section above.</Typography>
                        {jsonKeyFileSelect}
                        </React.Fragment> : null}
                </DialogContent>
                <DialogActions>
                        <Button type="primary" onClick={this.handleClose}>Close</Button> 
                </DialogActions>

            </Dialog>

        )
    }

}


