import { takeEvery, race, take, all, put, select } from 'redux-saga/effects'
import * as msg from '../../../actions/messages'
import * as p from '../../../actions/permissions'
import _ from  'lodash'

import {
    DELETE_COMPONENT, 
    UPDATE_COMPONENT,
    ADD_COMPONENT,
    INIT_SOLUTION_EDITOR,
    SAVE_DIAGRAM,
    EXECUTE_DIAGRAM,
} from './actions'

import {
    UPDATE_SOLUTION_DEF_SUCCESS,
    UPDATE_SOLUTION_DEF_FAILURE, 
    updateSolutionDefinition,
    getSolution,
    getSolutionDefinition,
    executeSolution,
    clearExecution,
    deleteLink,
    deleteNode,
    updateLink,
    updateNode,
    addLink,
    addNode

} from '../../Admin/Solutions'

const instance = id => state => state.solutions.present.items[id]
const links = permission => state => state.user.profile._links[permission].href

function* notifyStatus(action, message) {
    yield put(msg.notifyUserMessage('Solution ' , message))
}

function* watchApiUpdates() {
    yield takeEvery(UPDATE_SOLUTION_DEF_SUCCESS, action=>notifyStatus(action,"saved"))
}

function* solutionUpdateDefinition(action) {
    const solution = yield select(instance(action.documentId))
    yield put(clearExecution(action.documentId))
    yield put(updateSolutionDefinition(solution._links.definition.href, solution.definition))
}

function* startupEditor(action) {
    const solutions = yield select(links(p.SOLUTIONS))
    if (solutions)
        {
            yield put(getSolution( `${solutions}/${action.documentId}`))
            yield put(getSolutionDefinition( `${solutions}/${action.documentId}/definition`))
        }
        else {
            yield put(msg.notifyUserError('User', 'No user reference to solutions found'))
        }
}

function* execSolution(action){
    const solution = yield select(instance(action.documentId))
    yield put(clearExecution(action.documentId))
    yield put(updateSolutionDefinition(solution._links.definition.href, solution.definition))
    const { success, fail } = yield race({
        success: take(UPDATE_SOLUTION_DEF_SUCCESS),
        fail: take(UPDATE_SOLUTION_DEF_FAILURE)
    })

    if( success ){
        // map the application test data to KVPs
        let  appData = {}
        _.forEach(solution.definition.applicationTestData, item=> {appData[item.name] = item.value})
        yield put(executeSolution(solution._links.execute.href, appData))     
    } else {
        yield put(msg.notifyUserError('Execute', fail.payload.response.error.message))
    }
}

function* watchsolutionEditorActions() {
    yield takeEvery(INIT_SOLUTION_EDITOR, startupEditor)
    yield takeEvery(SAVE_DIAGRAM, solutionUpdateDefinition)
    yield takeEvery(EXECUTE_DIAGRAM, execSolution)
}
// need both delete component and delete node/link actions in the queue
function* deleteComponentSaga(action) {
    action.component.type === 'links' ?
        yield put(deleteLink(action.documentId, action.component))
        : yield put(deleteNode(action.documentId, action.component))
}

function* updateComponentSaga(action) {
    action.component.type === 'links' ?
        yield put(updateLink(action.documentId, action.component))
        : yield put(updateNode(action.documentId, action.component))
}

function* addComponentSaga(action) {
    action.component.type === 'links' ?
        yield put(addLink(action.documentId, action.component))
        : yield put(addNode(action.documentId, action.component))
}

function* watchDiagramActions() {
    yield takeEvery(DELETE_COMPONENT, deleteComponentSaga)
    yield takeEvery(UPDATE_COMPONENT, updateComponentSaga)
    yield takeEvery(ADD_COMPONENT, addComponentSaga)
}

export function* solutionDiagramEditorSagas() {
    yield all([
        watchDiagramActions(),
        watchsolutionEditorActions(),
        watchApiUpdates(),
    ])
}