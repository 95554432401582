import { combineReducers } from 'redux'
import { arrayToObject, addUpdateItem, removeItem, versionsResponseToObject } from '../../../functions'
import * as actions from './actions'
import { createActionTypeReducer } from '../../../middleware';


/**
 * Solutions Reducer 
 * 
 * items: { wss: payload }
 * 
 */


/**
 * Solution Reducer
 * 
 * [wss] : payload
 * 
 */


// state = state.diagrams.items
const solutionsReducer = createActionTypeReducer({}, {
    [actions.GET_SOLUTIONS_SUCCESS]: (state, action) => ({ ...arrayToObject(action.payload.items) }),
    [actions.GET_SOLUTION_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.CREATE_SOLUTION_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.UPDATE_SOLUTION_SUCCESS]: (state, action) => ({ ...state, ...addUpdateItem(state, action) }),
    [actions.DELETE_SOLUTION_SUCCESS]: (state, action) => ({ ...removeItem(state, action) }),
    [actions.UPDATE_SOLUTION_DEF_SUCCESS]: (state, action) => 
    {
        return {
        ...state,
        [action.payload._embedded.solutionWss]: {
            ...state[action.payload._embedded.solutionWss],
            definition : { ...action.payload._embedded}
        }
        }
    },
    [actions.GET_SOLUTION_DEF_SUCCESS]: (state, action) => 
    {
        return {
        ...state,
        [action.payload._embedded.solutionWss]: {
            ...state[action.payload._embedded.solutionWss],
            definition : { ...action.payload._embedded}
        }
        }
    },
    [actions.EXECUTE_SOLUTION_SUCCESS]: (state, action) => ({
        ...state,
        [action.payload._embedded.solutionWss]: {
            ...state[action.payload._embedded.solutionWss],
            execution: { ...action.payload }
        }
    }),
    [actions.CLEAR_EXECUTION]: (state, action) => ({
        ...state, [action.solutionId]: {
            ...state[action.solutionId],
            execution: null
        }
    })
},
    (state, action) => {
        return action.solutionId ? {
            ...state,
            [action.solutionId]: {
                ...state[action.solutionId],
                definition: {
                    ...solutionReducer(state[action.solutionId].definition, action)
                },
            }
        } : state
    }
)

export const solutionReducer = createActionTypeReducer({}, {
    [actions.UPDATE_SOLUTION_APPLICATION_FIELDS]: (state, action) => ({ ...state, applicationDatasetFields: [...action.fields] }),
    [actions.UPDATE_SOLUTION_RUNTIME_FIELDS]: (state, action) => ({ ...state, runtimeDatasetFields: [...action.fields] }),
    [actions.UPDATE_SOLUTION_APPDATA_FIELDS]: (state, action) => ({ ...state, applicationTestData: { ...action.fields } }),
    [actions.UPDATE_SOLUTION_DIAGRAM]: (state, action) => ({...state, solutionDefinition: { ...action.diagram } }),


}, (state, action) => {
    return {
        ...state,
        solutionDefinition: {
            ...solutionDefinitionReducer(state.solutionDefinition, action)
        }
    }
}
)

/* solutionDefinition {
    links: [],
    nodes: [],
}
 */
// links: state.links.filter( o => state.nodes.find( n => n.id === action.component.id ).ports.map( p => p.links.reduce((acc, l) => l, [])).indexOf( o.id ) === -1), //phew ':)
export const solutionDefinitionReducer = createActionTypeReducer({}, {
    [actions.ADD_NODE]: (state, action) => ({ ...state, nodes: [...state.nodes, { ...action.component }] }),
    [actions.UPDATE_NODE]: (state, action) => ({ ...state, nodes: state.nodes.map(item => { return item.id === action.node.id ? { ...item, ...action.node } : item }) }),
    // this is special because removing a node leaves dangling links - need to remove the links before the diagram renders again
    [actions.DELETE_NODE]: (state, action) => ({
        ...state,
        links: state.links.filter(link=> link.target !==action.node.id && link.source !== action.node.id),
        nodes: state.nodes.filter(item => item.id !== action.node.id),
        }),
    [actions.ADD_LINK]: (state, action) => ({ ...state, links: [...state.links, { ...action.component }] }),
    [actions.UPDATE_LINK]: (state, action) => ({ ...state, links: state.links.map(item => { return item.id === action.link.id ? { ...item, ...action.link } : item }) }),
    [actions.DELETE_LINK]: (state, action) => ({ ...state, links: state.links.filter(item => item.id !== action.link.id) }),
    [actions.ZOOM_LEVEL]: (state, action) => ({ ...state, zoom: action.zoom })
}) // default fallback - return state




export const addUpdateVersionItem = (state, action) => {
    const updatedOrNewItem = action.payload
    const solutionWss = updatedOrNewItem ? updatedOrNewItem._embedded.entityWss : undefined

    let allVersionsForWss = state[solutionWss] || []
    allVersionsForWss[updatedOrNewItem._embedded.webSafeString] = updatedOrNewItem
    state[solutionWss] = allVersionsForWss

    return state
}

export const addSolutionToVersionItem = (state, action) => {
    const solution = action.payload
    const solutionWss = solution ? solution._embedded.webSafeString : undefined
    const versionWss = action.meta.correlation.versionWss
    state[solutionWss][versionWss].solution = {...solution}
    return state
}


export const removeVersionItem = (state, action) => {
    const deletedItem = action.payload
    const solutionWss = deletedItem ? deletedItem._embedded.entityWss : undefined
    let allVersionsForWss = state[solutionWss]
    allVersionsForWss = removeItem(allVersionsForWss, action)
    state[solutionWss] = allVersionsForWss

    return state
}


export const versionsReducer = createActionTypeReducer({}, {
    [actions.GET_SOLUTION_VERSIONS_SUCCESS]: (state, action) => ({ ...versionsResponseToObject(state, action.payload.items) }),
    // [actions.GET_DEPLOYED_VERSIONS_SUCCESS]: (state, action) => ({ ...versionsResponseToObject(state, action.payload.items) }),
    [actions.GET_DEPLOYED_VERSIONS_SUCCESS]: (state, action) => ({ ...state, deployedVersions : action.payload.items}) ,
    [actions.CREATE_SOLUTION_VERSION_SUCCESS]: (state, action) => ({ ...addUpdateVersionItem(state, action) }),
    [actions.DELETE_SOLUTION_VERSION_SUCCESS]: (state, action) => ({ ...removeVersionItem(state, action) }),
    [actions.STOP_SOLUTION_VERSION_SUCCESS]: (state, action) => ({ ...addUpdateVersionItem(state, action) }),
    [actions.DEPLOY_SOLUTION_VERSION_SUCCESS]: (state, action) => ({ ...addUpdateVersionItem(state, action) }),
    [actions.GET_SOLUTION_VERSION_SOLUTION_SUCCESS] :(state, action) =>({ ...addSolutionToVersionItem(state, action) }),
})

export const solutionReducers = combineReducers({
    items: solutionsReducer,
    versions: versionsReducer,
})
