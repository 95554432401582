import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { EnhancedTable } from '../../../components/table'
import {Icon, Tooltip, IconButton } from '@material-ui/core'

import * as actions from './actions'
import {
    permission,
    ORGANISATIONS,
    UPDATE_ORGANISATION,
    CREATE_ORGANISATIONS,
    LIST_ORGANISATIONS,
    DELETE_ORGANISATION,
} from '../../../actions/permissions'

import _ from 'lodash'

import { EditInput } from '../../../components/table'
import { GcpDialog } from './GcpDialog';

export class OrganisationsTable extends React.Component {

    state = {
        showGcpDialog : false,
        gcpConfig : {}
    }

    componentWillMount() {
        if (permission(this.props.user, LIST_ORGANISATIONS)) {
            this.props.getOrganisations(this.props.user._links[ORGANISATIONS].href)
        }
    }
    
    handleUpdateGcpDetails = (record)=>{
        this.setState({
            showGcpDialog : true,
            accountUrl:record._links['serviceAccount']?record._links['serviceAccount'].href:null,
            gcpConfigWss : record._embedded.webSafeString,
        })
    }

    handleAccountSelect = (url, useDicidio, file)=>{
        this.props.setServiceAccount(url, useDicidio, file)
    }

    handleCloseGcpDetails = () => {
        this.setState({showGcpDialog : false})
        this.props.clearServiceAccountRequest && this.props.clearServiceAccountRequest()
    }

    render() {
        const {classes, items, updateOrganisation, createOrganisation, deleteOrganisation } = this.props
        const canAdd = _.includes(this.props.user._embedded.permissions, CREATE_ORGANISATIONS)
        const canEdit = _.includes(this.props.user._embedded.permissions, UPDATE_ORGANISATION)
        const canDelete = _.includes(this.props.user._embedded.permissions, DELETE_ORGANISATION)
        
        const columns = [
            {
                title: 'Name',
                dataIndex: '_embedded.name',
                key: 'name',
                render: (text, record) => {
                    return canEdit && record._embedded.code ?
                        <EditInput
                            onChange={value =>
                                updateOrganisation(record._links.self.href, { ...record._embedded, name: value })
                            }
                            value={text}
                            key={record._embedded.webSafeString}
                        >{text}</EditInput>
                        : text
                }
            },
            {
                title: 'Code',
                dataIndex: '_embedded.code',
                key: 'code',
            },
            {
                title: 'Description',
                dataIndex: '_embedded.description',
                key: 'description',
                render: (text, record) => {
                    return canEdit && record._embedded.code ?
                        <EditInput
                            onChange={value =>
                                updateOrganisation(record._links.self.href, { ...record._embedded, description: value })
                            }
                            value={text}
                            key={record._embedded.webSafeString}
                        >{text}</EditInput>
                        : text
                }
            }, {
                title: 'GCP Projects and Service Account ',
                dataIndex: '_embedded.serviceAccountName',
                key: 'serviceAccount',
                render: (text, record) => {
                    let accountType = 'No account configured'
                    if (record._embedded.serviceAccountType ==='dicidio'){
                        accountType = 'Dicidio account'
                    }
                    if (record._embedded.serviceAccountType ==='client'){
                        accountType = 'Client provided account'
                    }
                    return canEdit ?
                            <Tooltip title="Review or configure the service account and Google Cloud Projects">
                            <React.Fragment>{accountType}
                            <IconButton aria-label="Edit GCP service account" onClick={()=>this.handleUpdateGcpDetails(record)}><Icon>lock_open</Icon></IconButton>
                            </React.Fragment>
                            </Tooltip> : accountType
                }
            }
            ,{
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return canDelete && record._embedded.code ?
                            <Tooltip title="Delete organisation" >
                            <IconButton aria-label="Delete organisation" onClick={e => { e.preventDefault(); deleteOrganisation(record._links.self.href) }}><Icon>delete</Icon></IconButton>
                            </Tooltip> : null
                }
            }
        ]

        return (
            <React.Fragment>
                <EnhancedTable
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record._links.self.href}
                    canCreate={ canAdd }
                    onCreate={ _ => createOrganisation(this.props.user._links[ORGANISATIONS].href)}
                    canDelete={ canDelete }
                    onDelete={ e => deleteOrganisation(e._links.self.href)}
                    pagination
                />
                <GcpDialog 
                    open ={this.state.showGcpDialog} 
                    onClose = {this.handleCloseGcpDetails} 
                    classes = {classes}
                    onProjectIdsChange = { (projectIds)=>this.props.setServiceAccountProjectIds(this.state.accountUrl, projectIds) }
                    onAccountSelect = {(useDicidio, file)=>this.handleAccountSelect(this.state.accountUrl, useDicidio, file)}
                    gcpConfig= {this.state.gcpConfig} 
                    organisation = {this.props.items.find(item=> item._embedded.webSafeString === this.state.gcpConfigWss)}
                    serviceAccountStatus = {this.props.serviceAccountStatus}

                    />
            </React.Fragment>
        )
    }
}

OrganisationsTable = connect(
    state => ({
        items: Object.values(state.organisations.items),
        user: state.user.profile,
        serviceAccountStatus : state.organisations.serviceAccountStatus,


    }),
    dispatch => (
        bindActionCreators(actions, dispatch)
    )
)(OrganisationsTable)