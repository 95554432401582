import { put, all, takeEvery } from 'redux-saga/effects'

import { getAuthorization } from '../Auth/actions'
import { APP_INIT, showNotification, APP_NEW_VERSION } from './actions'
import { USER_NOTIFICATION } from '../actions/messages'
 
function* initializeApplication(){
    yield put( getAuthorization() )
}

/**
 * watch for application startup, perform init actions
 */
function* watchAppState(){
    yield takeEvery( APP_INIT, initializeApplication )
}

function* notify(action){
    yield put(showNotification(action))
}

function* watchNotification(){
    yield takeEvery(USER_NOTIFICATION, notify)
    yield takeEvery(APP_NEW_VERSION, notify)
}

export function* appSagas(){
    yield all([
        watchAppState(),
        watchNotification(),
    ])
}