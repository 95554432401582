import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'

import styles from '../../styles'

import EnhancedTableToolbar from './EnhancedToolbar'

function desc(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { rows, onSelectAllClick, order, orderBy, selection, numSelected, rowCount, expandedRow } = this.props

    return (
      <TableHead>
        <TableRow>
          {selection ?
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell> : null
          }
          {
            expandedRow ?
              <TableCell />
              : null
          }
          {rows.map(row => {
            return (
              <TableCell
                key={row.key}
                align={row.numeric?'right':'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.key ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.key}
                    direction={order}
                    onClick={this.createSortHandler(row.dataIndex)}
                  >
                    {row.title}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class EnhancedTable extends React.Component {
  
  constructor(props) {
    super(props)
    const selected = props.rowSelection?props.rowSelection.selectedRowKeys||[]:[]
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: selected,
      expanded: [],
      page: 0,
      rowsPerPage: 10,
    }
  
  }

  

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  updateSelection(selection) {
    const { dataSource, rowSelection, rowKey } = this.props
    rowSelection.onChange && rowSelection.onChange(selection, dataSource.filter(n => selection.includes(rowKey(n))))
  }

  handleSelectAllClick = event => {
    const { rowKey, dataSource } = this.props
    if (event.target.checked) {
      const allSelected = dataSource.map(n => rowKey(n))
      this.setState(state => ({ selected: allSelected }))
      this.updateSelection( allSelected )
      return
    }
    this.setState({ selected: [] })
    this.updateSelection([])
  }

  handleSelectedClick = (event, row) => {
    const { selected } = this.state
    const { rowKey } = this.props
    const id = rowKey(row)
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    this.setState({ selected: newSelected })
    this.updateSelection(newSelected)
  }

  handleExpandedClick = (event, row) => {
    const { expanded } = this.state
    const { rowKey, onExpand } = this.props
    const id = rowKey(row)
    const index = expanded.indexOf(id)
    let newExpanded = []

    if (index === -1) {
      newExpanded = newExpanded.concat(expanded, id)
    } else if (index === 0) {
      newExpanded = newExpanded.concat(expanded.slice(1))
    } else if (index === expanded.length - 1) {
      newExpanded = newExpanded.concat(expanded.slice(0, -1))
    } else if (index > 0) {
      newExpanded = newExpanded.concat(
        expanded.slice(0, index),
        expanded.slice(index + 1),
      )
    }

    this.setState({ expanded: newExpanded })

    onExpand(newExpanded, row)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1
  isExpanded = id => this.state.expanded.indexOf(id) !== -1

  onDelete = selected => {
    const { dataSource, rowKey } = this.props
    selected.forEach(s =>
      dataSource
        .filter(el => rowKey(el) === s)
        .forEach(f => this.props.onDelete(f))
    )
    this.setState({ selected: [] })
  }

  onFilter = _ => { }

  rowKey = (n) => n.key

  render() {
    const { classes } = this.props
    const { dataSource, columns, title, onExpand, expandedRowRender, rowSelection } = this.props
    const { canCreate, onCreate, canDelete } = this.props
    const { order, orderBy, selected, rowsPerPage, page } = this.state
    const selection = rowSelection !== undefined
    const numSelected = selected.length
    const rowKey  = this.props.rowKey ? this.props.rowKey : this.rowKey
    return (
      <div>
        { selection ?
          <EnhancedTableToolbar numSelected={selected.length} title={title} canDelete={canDelete} onDelete={_ => this.onDelete(selected)} onFilter={this.onFilter} /> 
          : null
        }
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              rows={columns}
              selection={selection}
              numSelected={numSelected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={dataSource.length}
              expandedRow={onExpand}
            />
            <TableBody>
              {
                stableSort(dataSource, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n, index) => {
                    const rowId = rowKey(n)
                    const isSelected = this.isSelected(rowId)
                    const isExpanded = this.isExpanded(rowId)
                    return ([
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={rowId}
                        selected={isSelected}
                      >{selection ?
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} onClick={event => this.handleSelectedClick(event, n)} />
                        </TableCell>
                        : null
                        }
                        {
                          onExpand ?
                            <TableCell >
                              <IconButton aria-label="Expand" onClick={e => this.handleExpandedClick(e, n)}>
                                {
                                  isExpanded ?
                                    <Icon>expand_less</Icon> 
                                    : <Icon>expand_more</Icon>
                                }
                              </IconButton>
                            </TableCell>
                            : null
                        }
                        {
                          columns.map((c, i) => {
                            return (
                              <TableCell component="th" scope="row" padding="none" key={i}>
                                {c.render ? c.render(get(n, c.dataIndex), n, index) : get(n, c.dataIndex)}
                              </TableCell>
                            )
                          })
                        }

                      </TableRow>,
                      onExpand && isExpanded ?
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={rowId + 1}
                          selected={isSelected}>
                          <TableCell />
                          <TableCell colSpan={columns.length + 1} component="th" scope="row" padding="none" key={rowId + 1}>
                            {expandedRowRender(n)}
                          </TableCell>
                        </TableRow>
                        : null
                    ])
                  }
                  )
              }
            </TableBody>
          </Table>
          {
            canCreate ? (
              <Tooltip title="Add">
                <Fab
                  color="primary"
                  className={this.props.classes.adminTableAddButton}
                  aria-label="Add"
                  onClick={e => onCreate()}>
                  <Icon>add</Icon>
                </Fab>
              </Tooltip>)
              : (<React.Fragment />)
          }
        </div>
        {this.props.pagination?
        <TablePagination
          component="div"
          count={dataSource.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> : null}
      </div>
    )
  }
}

EnhancedTable.div = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(EnhancedTable)


