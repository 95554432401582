import { createActionTypeReducer } from '../middleware'
import {
    APP_INIT,
    APP_LOGIN,
    APP_CLOSE,
    APP_UPDATE_STATUS,
    APP_CLEAR_STATUS,
    APP_SHOW_NOTIFICATION,
    APP_HIDE_NOTIFICATION,
    APP_ASYNC_WAIT,
    APP_ASYNC_DONE
} from './actions'
import * as Constants from '../constants'


const initialState = {
    version: Constants.VERSION,
    running: true,
    state: APP_CLOSE,
    status: {
        description: '',
        message: '',
        code: 0,
    },
    notification: {
        description: '',
        message: '',
        code: 0
    },
    cursor: 'default'
}


export const appReducer = createActionTypeReducer(initialState, {
    [APP_INIT]: (state, action) => ({ ...state, state: action.type }),
    [APP_LOGIN]: (state, action) => ({ ...state, state: action.type }),
    [APP_CLOSE]: (state, action) => ({ ...state, state: action.type }),
    [APP_UPDATE_STATUS]: (state, action) => ({ ...state, status: { message: action.message ? `${action.message}: ${action.description}` : action.description, code: action.code, visible: true } }),
    [APP_CLEAR_STATUS]: (state, action) => ({ ...state, status: { visible: false } }),
    [APP_SHOW_NOTIFICATION]: (state, action) => ({ ...state, notification: { ...action, visible: true}}),
    [APP_HIDE_NOTIFICATION]: (state, action) => ({ ...state, notification: { visible: false}}),
    [APP_ASYNC_WAIT]:(state, action) => ({ ...state, cursor: 'wait'}),
    [APP_ASYNC_DONE]: (state, action) => ({ ...state, cursor: 'default'})
})

