import { arrayToObject, addUpdateItem, removeItem } from "../../../functions";
import * as actions from "./actions";

const initialState = {
  items: {}
};

export function experimentConfigsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_EXPERIMENT_CONFIGS_SUCCESS: {
      return {
        ...state,
        items: arrayToObject(action.payload.items)
      };
    }
    case actions.UPDATE_EXPERIMENT_CONFIG_SUCCESS:
    case actions.CREATE_EXPERIMENT_CONFIG_SUCCESS: {
    return {
        ...state,
        items: addUpdateItem(state.items, action)
      };
    }
    case actions.DELETE_EXPERIMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        items: removeItem(state.items, action)
      };
    }
    case actions.GET_EXPERIMENT_CONFIG_SUCCESS: {
        return {
          ...state,
          items: addUpdateItem(state.items, action)
        };
    }
    case actions.GET_EXPERIMENT_RESULTS_SUCCESS: {
      let items = state.items;
      if (action.payload.items) {
        action.payload.items.forEach(item => {
          if (items[item._embedded.experimentConfigWss]) {
            if (!items[item._embedded.experimentConfigWss].results) {
              items[item._embedded.experimentConfigWss].results = {};
            }
            items[item._embedded.experimentConfigWss].results[item._embedded.webSafeString] = item;
          }
        });
      }
      return {
        ...state,
        items: items
      };
    }
    case actions.GET_EXPERIMENT_RESULT_SUCCESS: 
    case actions.CREATE_EXPERIMENT_RESULT_SUCCESS: {
        let items = state.items;
        if (!items[action.payload._embedded.experimentConfigWss]) {
          items[action.payload._embedded.experimentConfigWss] ={}
        }
        items[action.payload._embedded.experimentConfigWss].results = addUpdateItem(items[action.payload._embedded.experimentConfigWss].results || [], action)
        return {
          ...state,
          items: items
        };

    }
    case actions.GET_EXPERIMENT_RESULT_DETAILS_SUCCESS: {
      let { items}  = state;
      const { experimentConfigWss, experimentResultWss } = action.payload._embedded
      if (!items[experimentConfigWss]) {
        items[experimentConfigWss] ={}
      }
      if (!items[experimentConfigWss].results) {
        items[experimentConfigWss].results = []
      }
      if (!items[experimentConfigWss].results[experimentResultWss]) {
        items[experimentConfigWss].results[experimentResultWss] = {}
      }
      items[experimentConfigWss].results[experimentResultWss].details = action.payload
      return {
        ...state,
        items: items
      };

  }
  case actions.DELETE_EXPERIMENT_RESULT_SUCCESS: {
        let items = state.items;
        items[action.payload._embedded.experimentConfigWss].results = removeItem(items[action.payload._embedded.experimentConfigWss].results, action)
        return {
          ...state,
          items: items
        };
    }
  
    default:
      return state;
  }
}
