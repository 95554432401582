import React from 'react'
import memoize from "memoize-one";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Diagram} from './Diagram'

import {Button, Grid, Paper, Icon, Chip,  } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import {getExperimentResult, getExperimentResultDetails, saveGraph } from '../Admin/Experiments/actions'
import {AxisController} from './AxisController'
import _ from 'lodash'
import styles from '../../styles'
import { DicidioAppBar } from '../DicidioAppBar';
import { isoToPrettyDate } from '../../functions'

export class ExperimentVisualiser extends React.Component {


	constructor (props) {
		super(props)
		const { theme } = props
		const height = this.calcHeight(theme)

		this.state = {
			hasChanged : false,
			autoSave : true,
			height: height,
			box2: {height: height / 2,},
			selected: this.props.location.pathname,
			detailsView: this.COMPONENT_DETAILS,
			detailsDrawerOpen: true,
			spec : undefined,
		}	
	}

	componentDidMount() {
		const link = this.props.user && this.props.user._links.experimentResult && this.props.user._links.experimentResult.href
		const detailsLink = this.props.user && this.props.user._links.experimentResultDetails && this.props.user._links.experimentResultDetails.href
		
		this.props.getExperimentResult(`${link}/${this.props.match.params.id}`)
		this.props.getExperimentResultDetails(`${detailsLink}/${this.props.match.params.id}`)
		this.countdown = setInterval(this.tick, 5000);

	}

	componentWillUnmount() {
		clearInterval(this.countdown);
	}

	componentDidUpdate(prevProps) {
		if (this.props.experimentResultDetails && !this.state.spec) {
			this.updateSpec()
			this.setState({hasChanged : false})
		}
	}
	
	emptySpec = {
		"description": "A simple bar chart with embedded data.",
		"mark": {type:"square", "tooltip": {"content": "data"}},
		"width" : 250,
		"height" :250,
		"selection": {"grid": {"type": "interval", "bind": "scales"}},
		 "encoding": {
			tooltip : [],
		}
	}

	tick = () =>{
		if (this.state.hasChanged && this.state.autoSave) {
			this.handleSave()
		}
	}

	calcHeight(theme) {
		return window.innerHeight - theme.spacing.unit * 20
	}

	handleWindowResize() {
		const { theme } = this.props
		// dimensions of furniture
		const height = this.calcHeight(theme)
		const diffHeight = height - this.state.height

		this.setState({
			height: height,
			// only set box 2 - diagram wxh
			box2: {
				height: this.state.box2.height + diffHeight,
				buttonMenuVisible: false,
			}
		})
	}

	handleClear =() => {
		this.setState({spec:this.emptySpec})
	}

	handleAutoSaveChange=() => {
		this.setState({autoSave : !this.state.autoSave})
	}

	handleSave=()=> {
		const graphLink = this.props.experimentResultDetails && this.props.experimentResultDetails._links.graph && this.props.experimentResultDetails._links.graph.href
		const {spec} = this.state
		this.props.saveGraph(graphLink, spec)
		this.setState({hasChanged : false})
	}


	handleItemClick = (e, path) => {
		e.preventDefault()
		this.setState({
			selected: path
		})
		this.props.push(path)
	}

	detailsDrawerToggle = () => {
		this.setState({
			detailsDrawerOpen: !this.state.detailsDrawerOpen,
			open: false,
		})
	}

	handleDetailsChange = (newViewRef, newView) => {
		this.setState({
			detailsView: newView,
			detailsDrawerOpen: true,
			open: false,
			detailsMarker: newViewRef.current.getClientRects()[0],
		})
	}

	handleStartChipDrag (event, chipName, source) {
		event.dataTransfer.setData("id", JSON.stringify({name:chipName, source:source}));
		return event
	}

	handleChipDragOver(event) {
		event.preventDefault() 
	}
	
	handleEndChipDrag=(event, target) =>{
		event.preventDefault() 
		const data = event.dataTransfer.getData("id")
		if (data) {
			const chip = JSON.parse(data)
			const {source} = chip
			if (source!=='ALL') {
				this.updateSpec(`encoding.${source}`, {...this.state.spec.encoding[source], field:''})
			}
		}

	}

	handleAxisChange=(values)=> {
		if (values && values.axis) {
			this.updateSpec(`encoding.${values.axis}`, values)
		}
	}
	
	
	calcRange =  memoize((fieldName, data) => {
		const value = _.map(data, item=> {
			return _.get(item,fieldName)
		})
		const max = _.max(value)
		const min =  _.min(value)
		let length = (max-min).toString().length  -3
		if (length <0) {
			length = 0
		}
		const type = (min !=='' && !isNaN(min)) ? 'quantitative': 'nominal'
		return  {max: max, min: min, step :Math.pow(10,length), type :type}
	})

	updateSpec = (path, value)=>{
		let spec 
		if (!this.state.spec && this.props.experimentResultDetails && this.props.experimentResultDetails._embedded.graph) {
			spec = this.props.experimentResultDetails._embedded.graph
		} 
		else {
			spec =  this.state.spec ||  this.emptySpec
			
		}
		if (spec.encoding.tooltip.length===0) {
			const data = this.props.experimentResultDetails?this.props.experimentResultDetails._embedded.results : []
			const allValues = data[0]?Object.keys(data[0]) : []
			spec.encoding.tooltip =  _.map(allValues, value=> {
				return {"field": value, "type": "ordinal"}
		})


		}
		if (path && value) {
			spec = _.set(spec, path, value)
			if (value.field){
				const data = this.props.experimentResultDetails?this.props.experimentResultDetails._embedded.results : []
				spec = _.set(spec, `${path}.type`, this.calcRange(value.field, data).type )
				
			}
		}
		this.setState({spec:spec, hasChanged : true})
	}

	render() {
		const classes = this.props.classes
		const {spec} = this.state
		const x = spec ? {...spec.encoding.x, axis:'x'}  :{}
		const y = spec ? {...spec.encoding.y, axis:'y'}  :{}
		const color = spec ? {...spec.encoding.color, axis:'color'}  :{}
		const size  = spec ? {...spec.encoding.size, axis:'size'}  :{}
		const data = this.props.experimentResultDetails?this.props.experimentResultDetails._embedded.results : []
		const xRange = this.calcRange(x.field, data)
		const yRange = this.calcRange(y.field, data)
		const colorRange = this.calcRange(color.field, data)
		const rangeSize = this.calcRange(size.field, data)

		const allValues = _.pull(data[0]?Object.keys(data[0]) : [] , x.axisValue, y.axisValue)

		const chips =(values, source) =>(
			values.map( value=> (<Chip draggable={true} onDragStart={e=> {this.handleStartChipDrag(e, value, source)}} clickable color={_.startsWith(value, "application")?'primary':'secondary'} key ={value} label={value} className={classes.chip} />)) 
		)

		return (
			<div className={classes.root}>
			<DicidioAppBar title = {`Experiment result - ${this.props.experimentResult && this.props.experimentResult._embedded ?isoToPrettyDate(this.props.experimentResult._embedded.created):''} `} hideMenuIcon menuOpen = {false} />
				<main className={classes.adminContent} style={{height: 'unset'}}>

					<div className={classes.appBarSpacer} />

          <Paper className={this.props.classes.adminTablePaper} style={{maxHeight:'87%'}} elevation={1} >
						<React.Fragment>
						<Button 
						  className={this.props.classes.button}
          		color="primary"
							onClick={e=>this.handleClear()}>
							<Icon>clear</Icon>clear
						</Button>
						<Button 
						  className={this.props.classes.button}
          		color="primary"
							disabled = {!this.state.hasChanged}
							onClick={e=>this.handleSave()}>
							<Icon>save</Icon>SAVE
						</Button>
						<Button 
						  className={this.props.classes.button}
          		color="primary"
							onClick={this.handleAutoSaveChange}>
							<Icon>{this.state.autoSave?'checkBox':''}</Icon>Auto save
							</Button>
						<Grid container  >
							<Grid item sm={12} md={12} lg={6} xl={8} style={{maxHeight:'100%'}}>
							{spec?<Diagram data={data} spec={spec} classes ={classes}/> : null}
							</Grid>
							<Grid container item sm={12}  md={12} lg={6} xl={4}>
							<Grid item xs={12} md={6} >
								<AxisController 
									style={{height:'calc(50% - 12px)'}} 
									range={xRange} 
									title="X axis" values={x} onChange={this.handleAxisChange} canDrop classes = {this.props.classes}/>
								<AxisController 
									style={{height:'calc(50% - 12px)'}} 
									range={yRange} 
									title="Y axis" values={y} onChange={this.handleAxisChange} canDrop classes = {this.props.classes}/>
							</Grid>
							<Grid item xs={12} md={6} >
								<AxisController 
									style={{height:'calc(50% - 12px)'}} 
									range={colorRange} 
									title="Tick colour" 
									values={color} 
									onChange={this.handleAxisChange} 
									canDrop 
									classes = {this.props.classes}/>
								<AxisController 
									style={{height:'calc(50% - 12px)'}} 
									range={rangeSize} 
									title="Size" 
									values={size} 
									onChange={this.handleAxisChange} 
									canDrop 
									classes = {this.props.classes}/>
								</Grid>
								</Grid>
              <Grid item xs={12}>
							<div className={this.props.classes.chipLandingPlace} style={{minWidth : '100%'}} 
						  onDrop={e=> this.handleEndChipDrag(e, 'ALL')}
          		onDragOver={this.handleChipDragOver}>
								{chips(allValues, 'ALL')}
							</div>
							</Grid>

							</Grid>
						</React.Fragment>
				</Paper>
					
				</main>
			</div>
		)
	}
}

ExperimentVisualiser = withRouter(connect(
    (state, props) =>  {
		const experiment = (state.experiments && _.find(state.experiments.items, item=> item.results && item.results[props.match.params.id]!=null)) || []
		const experimentResult = experiment && experiment.results ? experiment.results[props.match.params.id]: undefined
		const experimentResultDetails = experimentResult  ? experimentResult.details : undefined
		return {
			user: state.user.profile,
			experimentResult : experimentResult,
			experimentResultDetails : experimentResultDetails,
		}
	},
	dispatch => (
		bindActionCreators({getExperimentResult, getExperimentResultDetails, saveGraph
		}, dispatch)
	)
)(withStyles(styles, { withTheme: true })(ExperimentVisualiser)))



