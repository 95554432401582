import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { EnhancedTable } from '../../../components/table'

import * as usersActions from './actions'
import * as rolesActions from '../Roles/actions'
import * as organisationsActions from '../Organisations/actions'
import { isoToPrettyDate, isValidEmail} from '../../../functions'
import { EditInput, EditSelect } from '../../../components/table'
import { ItemsSelector } from '../../../components/ItemsSelector'

import _ from 'lodash'
import {Switch, Select, Input, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, MenuItem, Typography } from '@material-ui/core';

export class UsersTable extends React.Component{

    state = {
        newUserModalVisible : false,
        rolesEditorVisible : false,
        editRoles : [],
        isSystemUser:false,
        newUserName :'',
        newUserDescription :'',
        newUserFirstName :'',
        newUserEmail : '',
        newUserOrgWss : '',
        isValidated : false,
    }

    componentWillMount(){
        if (this.props.user._links['users']) {
            this.props.getUsers(this.props.user._links['users'].href)
        }
        if (this.props.user._links['allRoles']) {
            this.props.getRoles(this.props.user._links['allRoles'].href)
        }
        if (this.props.user._links['organisations']) {
            this.props.getOrganisations(this.props.user._links['organisations'].href)
        }
    }

    showNewUserModal = ()=>{
        this.setState({
            newUserModalVisible: true
        })
    }
    handleChange = (e, field)=> {
        const {value} = e.target
         const newState = {...this.state, [field]:value}
         let isValidated = (newState.newUserEmail !==''&& newState.newUserOrgWss !=='' && newState.newUserName !== '')
         isValidated = isValidated && isValidEmail(newState.newUserEmail)
          if (!this.state.isSystemUser) {
            isValidated = isValidated && newState.newUserFirstName !== '' 
          }
          this.setState({...newState, isValidated: isValidated})
    }

    openRolesEditor= (e, roles) => {
        this.setState({
            selectedUser : e,
            rolesEditorVisible : true,
            editRoles :e._embedded.roles
        })
    }

    closeRolesEditor= (e) => {
        this.setState({
            rolesEditorVisible : false,
        })
    }

    updateRoles = (e) => {
        // we need to remove any roles that aren't in the new roles list and
        // add any roles that are in the list but the user doesn't have yet
        
        const currentRoles = this.state.selectedUser._embedded.roles
        const newRoles = this.state.editRoles
        const changes = _.xor(currentRoles , newRoles)
        _.forEach(changes, changedRole => {
            if (_.includes(currentRoles, changedRole)) {
                // we have this role so we remove it
                this.props.removeRoleFromUser(this.state.selectedUser._links.roles.href, changedRole)
            }
            else { 
                // we don't have this role so we add it
                this.props.addRoleToUser(this.state.selectedUser._links.roles.href, changedRole)
            }
        })
        this.closeRolesEditor()
    }

    handleRolesChange = (roles) => {
        this.setState ({
            editRoles :roles
        })
    }

    createNewUser = (e)=>{
        e.preventDefault();
        const type = this.state.isSystemUser?'System account':'User'
        const newUser = {
            firstName : this.state.newUserFirstName,
            name: this.state.newUserName,
            type : type,
            description : this.state.newUserDescription,
            emailAddress: this.state.newUserEmail,
            organisationWss : this.state.newUserOrgWss,
            requestUrl : e.target.baseURI.replace('/admin/users', ''),
        }
        if (this.state.isSystemUser) {
            this.props.createNewUser(this.props.user._links['users'].href, newUser)
        }
        else {
            this.props.createNewUser(this.props.user._links['user.invite'].href, newUser)
        }
        this.closeNewUserModal()
    }
    
    closeNewUserModal = ()=>{
        this.setState({
            newUserModalVisible: false,
            isSystemUser : false,
            newUserName :'',
            newUserDescription :'',
            newUserFirstName :'',
            newUserEmail : '',
            newUserOrgWss : '',
            isValidated : false,

        })
    }


    render(){
        const { classes, items, updateUser, deleteUser, reinviteUser } = this.props

        const canAdd =  _.includes(this.props.user._embedded.permissions, 'users.create')
        const canEdit =  _.includes(this.props.user._embedded.permissions, 'user.update')
        const canDelete =  _.includes(this.props.user._embedded.permissions, 'user.delete')
        const canReinvite = _.includes(this.props.user._embedded.permissions, 'user.invite')
        const columns = [
            {
            title: 'First name',
            dataIndex: '_embedded.firstName',
            key: 'firstName',
            render: (text, record) =>  
            {return canEdit?<EditInput 
                onChange={ value => 
                    updateUser( record._links.self.href, { ...record._embedded, firstName : value })
                } 
                value={text} 
                key={record._embedded.webSafeString} >
                {text}
            </EditInput> : <React.Fragment>{text}</React.Fragment>}
        }, 
        {
            title: 'Last name',
            dataIndex: '_embedded.name',
            key: 'name',
            render: (text, record) =>  
            {return canEdit?<EditInput 
                onChange={ value => 
                    updateUser( record._links.self.href, { ...record._embedded, name : value })
                } 
                value={text} 
                key={record._embedded.webSafeString} >
                {text}
            </EditInput> : <React.Fragment>{text}</React.Fragment>}
       },
        {
            title: 'Roles',
            dataIndex: '_embedded.roles',
            key: 'roles',
            render: (text, record) => 
            <React.Fragment>
                 <a href={'/#'} onClick={ e => { e.preventDefault(); this.openRolesEditor(record) }}>{_.size(record._embedded.roles) } roles </a>
            </React.Fragment>

        },
        {
            title: 'Email',
            dataIndex: '_embedded.emailAddress',
            key: 'emailAddress',
            render: (text, record) =>  
            {return canEdit?<EditInput 
                onChange={ value => 
                    updateUser( record._links.self.href, { ...record._embedded, emailAddress : value })
                } 
                value={text} 
                key={record._embedded.webSafeString} >
                {text}
            </EditInput> : <React.Fragment>{text}</React.Fragment>}
        },
        {
            title: 'Auth0 Id',
            dataIndex: '_embedded.userId',
            key: 'id',
        },
        {
            title: 'Status',
            dataIndex: '_embedded.userStatus',
            key: 'userStatus',
        },
        {
            title: 'Account type',
            dataIndex: '_embedded.type',
            key: 'type',
        },
        {
            title: 'Organisation',
            dataIndex: '_embedded.organisationWss',
            key: 'organisationWss',
            render: (text, record) =>  
            {
                if (canEdit) {
                return <EditSelect
                    style={{ width: '100%' }}
                    values = {_.map(this.props.organisations, (value, key)=> {
                        return {value:value._embedded.webSafeString,
                        label : value._embedded.name}
                    })
                    }   
                    value = {text}
                onChange={ value => 
                    updateUser( record._links.self.href, { ...record._embedded, organisationWss : value })
                } 
                >
                {/* {_.map(this.props.organisations, (value, key)=>{
                    return (<Option key={value._embedded.webSafeString}>{value._embedded.name} ({value._embedded.code})</Option> )  
                })} */}

                </EditSelect>
                }
                else {
                const org = _.find(this.props.organisations, org => {return org._embedded.webSafeString=== text})
                if (org) {
                    return (<React.Fragment>{org._embedded.name}</React.Fragment> )
                }
                }
               
            }
        },
        {
            title:'Last login',
            dataIndex :'_embedded.lastLoginDate',
            key :'lastLoginDate',
            render: (text) =>  
            <React.Fragment>{text?isoToPrettyDate(text):'-'}</React.Fragment> 
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => ( 
                <span>
                    {canDelete?(<a href={record._links.self.href} onClick={ e => { e.preventDefault(); deleteUser(e.target.href) }}>Delete</a>):(<div/>)}
                  
                    { (record._links.invite && canReinvite)?
                    (<a href={record._links.invite.href} onClick={ e => { e.preventDefault(); reinviteUser(e.target.href) }}>Resend invite</a>)
                    :(<div/>)}
                </span>
            ),
        }] 

        return (
            <React.Fragment>
               <Dialog
                onClose={this.closeNewUserModal}
                open = {this.state.newUserModalVisible}
                aria-labelledby="dialog-title">
                <DialogTitle id="dialog-title">Set up a new user</DialogTitle>
                <DialogContent >
                <Typography variant="body1">
                        The new user will be sent an invite email, which 
                        contains an activation link. Please ensure 
                        that you use the correct email address.
                    </Typography>
                    <div>
                        <Typography variant="body1">System account <Switch checked={this.state.isSystemUser}
                            onChange={e=>{
                            this.setState({isSystemUser: e.target.checked})
                            }}
                            />
                        </Typography>
                    </div>
                    <br/>

                    {this.state.isSystemUser?
                    <React.Fragment>
                    <FormControl required={true} component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Name</FormLabel>
                        <Input
                            value={this.state.newUserName}
                            onChange={e=>this.handleChange(e, 'newUserName')}
                        />
                    </FormControl>
                    <br/>
                    <br/>

                    <FormControl component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Description</FormLabel>
                        <Input
                            value={this.state.newUserDescription}
                            onChange={e=>this.handleChange(e, 'newUserDescription')}
                        />
                    </FormControl>
                    </React.Fragment>
                    :   
                    <React.Fragment>
                    <FormControl required={true} component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">First name</FormLabel>
                        <Input
                            value={this.state.newUserFirstName}
                            onChange={e=>this.handleChange(e, 'newUserFirstName')}
                        />
                    </FormControl>
                    <br/>
                    <br/>
                    <FormControl required={true} component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Last name</FormLabel>
                        <Input
                            value={this.state.newUserName}
                            onChange={e=>this.handleChange(e, 'newUserName')}
                        />
                    </FormControl>
                    </React.Fragment>
                    }
                    <br/>
                    <br/>

                    <FormControl required={true} component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Email address</FormLabel>
                        <Input
                            value={this.state.newUserEmail}
                            onChange={e=>this.handleChange(e, 'newUserEmail')}
                        />
                    </FormControl>
                    <br/>
                    <br/>
                    <FormControl required={true} component="fieldset" className={classes.formControl} fullWidth ={true}>
                        <FormLabel component="legend">Organisation this user will belong to</FormLabel>
                            <Select
                            value={this.state.newUserOrgWss}
                            onChange={e=>this.handleChange(e, 'newUserOrgWss')}
                            >
                            {_.map(this.props.organisations, (value, key)=> <MenuItem key={value._embedded.webSafeString} value={value._embedded.webSafeString}>{value._embedded.name} ({value._embedded.code})</MenuItem> )  
                            }
                            </Select>
                                          
                       </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNewUserModal} color="primary">Cancel</Button>
                        <Button onClick={this.createNewUser} disabled ={!this.state.isValidated} color="primary" autoFocus>{this.state.isSystemUser?'Create new system account':'Create new user and send invite email'}</Button>
                    </DialogActions>
                    </Dialog>
                    <ItemsSelector 
                        style={{ minWidth: '50%', maxHeight :'80%',maxWidth : '100%' }}
                        open={this.state.rolesEditorVisible}
                        selectedUser={this.state.selectedUser}
                        currentItems = {this.state.selectedUser?this.state.selectedUser._embedded.roles:[]}

                        onCancel = {this.closeRolesEditor}
                        onOk = {this.updateRoles}
                        title={`Roles (${this.state.selectedUser?this.state.selectedUser._embedded.code:''})`}
                        onChange = {this.handleRolesChange}
                        disabled={!canEdit}
                        okButton={{ disabled: !canEdit }}

                        classes = {this.props.classes}
                        items = {this.props.roles}

                    /> 
                    <EnhancedTable 
                        columns={columns} 
                        dataSource={items} 
                        rowKey={ record => record._links.self.href }
                        canCreate={ canAdd }
                        onCreate={ this.showNewUserModal } 
                        pagination
                    />
            </React.Fragment>
        )
    }
} 

UsersTable = connect(
    state => ({ 
        items: Object.values(state.users.items).sort( (a,b) => {
            return ( a._embedded.name ? b._embedded.name ? a._embedded.name.localeCompare(b._embedded.name) : a._embedded.name : b._embedded.name )
        }),
        organisations : state.organisations?state.organisations.items:[],
        roles : _.map(state.roles?state.roles.items:[], role => {return {key : role._embedded.webSafeString, title :role._embedded.name}}),
        user: state.user.profile,
    }),
    dispatch=>(
        bindActionCreators( { ...organisationsActions, ...usersActions, ...rolesActions }, dispatch )
    )
)(UsersTable)