import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import {DeployedUsageStats} from './../../App/Admin/Home/DeployedUsageStats';
import { Paper, Icon, IconButton, Typography } from "@material-ui/core";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class AddRemoveLayout extends React.Component {

  componentRefs = {}
  state = {fullScreen : null}
  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    })
  }

  handleRefresh(componentId) {
    const ref = this.componentRefs[componentId]
    if (ref && ref.current) {
      ref.current.refresh()
    }
  }

  handleResize = (e,f) => {
    console.log("handle me", e, f)
  }
  handleFullScreen(componentId) {
    if (this.state.fullScreen === componentId) {
      this.setState({
        fullScreen : null
      })
    }
    else {
      this.setState({
        fullScreen : componentId
      })
    }
  }

  createElement(el, onChange, fullScreen) {
    const Component = el.component || 'div'
    const { classes} =  this.props

    if (!this.componentRefs[el.i]) {
      this.componentRefs[el.i] = React.createRef()
    }
   
    return (
      <Paper key={el.i} 
      data-grid={el}
       elevation={1} className= {classes.itemPaper} >
        <div 
          style={{position:'absolute', zIndex:1, display: fullScreen?'unset':'none'}}
          >fullScreen</div>

        <div className={classes.itemHeader} >
          <Typography align="center" style={{width:'100%'}} variant='h6'> {`${el.config.title}`}</Typography>
          <IconButton classes={{root:classes.itemHeaderButton}} aria-label="Display dashboard item full screen" onClick={()=> this.handleFullScreen(el.i)}><Icon>{fullScreen?'fullscreen_exit':'fullscreen'}</Icon></IconButton>
          <IconButton classes={{root:classes.itemHeaderButton}} aria-label="Refresh dashboard item" onClick={()=> this.handleRefresh(el.i)}><Icon>refresh</Icon></IconButton>
          <IconButton classes={{root:classes.itemHeaderButton}} aria-label="Remove this dashboard item" onClick={_ => this.handleRemoveItem(el.i)}><Icon>close</Icon></IconButton>
        </div>
        <Component innerRef={this.componentRefs[el.i]} onTitleChange={e=>{this.handleTitleChange(e, el.i)}} onChange={onChange} config ={el.config} id={el.i} fullScreen = {fullScreen}/>
      </Paper>
    )
  }
  handleRemoveItem( i ) {
    const items =  _.reject(this.state.items, { i: i })
    this.setState({
      items: items
    })
    this.props.onChange && this.props.onChange(items);
  }


  handleTitleChange=(newValue, i)=>{
    let items  = this.props.layout || {}
    if (i){
      const myIndex = _.findIndex(items, {"i":i})
      if (myIndex >= 0) {
        if (items[myIndex].config) {
          items[myIndex].config.title = newValue;
          this.props.onChange && this.props.onChange(items);
          this.setState({items:items})
        }
      }

    }
  }

  handleConfigChange=(newValue, i)=> {
    let items  = this.props.layout || {}
    if (i){
      const myIndex = _.findIndex(items, {"i":i})
      if (myIndex >= 0) {
        items[myIndex].config = newValue;
        // this.props.onLayoutChange && this.props.onLayoutChange(items);
        this.props.onChange && this.props.onChange(items);
        this.setState({items:items})
      }

      const ref = this.componentRefs[i]
      if (ref && ref.current) {
        ref.current.resize()
      }
  
    }
    else {
      // it's a regular update by the ResponsiveReactGridLayout
      this.props.onLayoutChange && this.props.onLayoutChange(newValue);
      // this.props.onChange && this.props.onChange(newValue);
      this.setState({items:newValue})
      
    }
  }

  render() {
    let layout = this.props.layout
    if (this.state.fullScreen) {
      
        console.log("Big  one", layout, this.state.fullScreen)

        const myIndex = _.findIndex(layout, {"i":this.state.fullScreen})
        if (myIndex >= 0) {
          const layout0 = {...layout[0] , x :0, y:0, w:8, h:8}
          layout = [ layout0 ]
        // layout[1].x =0 
        // layout[1].y =0
        // layout[1].w =0
        // layout[1].h =0
        // layout= layout.splice(1)

        }
      
      console.log("We're going full screen", layout)
    }
      return (
        <ResponsiveReactGridLayout
          onLayoutChange={this.handleConfigChange}
          // onBreakpointChange={(bp, cols) => this.onBreakpointChange(bp, cols)}
          // {...this.props} 
          layout = {layout}
          onResize = {this.handleResize}
        >
          {_.map(layout, el => {
            if ((el.config && el.config.type ==='DeployedUsageStats') || el.component==='') {
              el.component = DeployedUsageStats
              if (!el.y ) {
                el.y =0
              }
            }

            return this.createElement(el, e=>this.handleConfigChange(e, el.i), this.state.fullScreen && this.state.fullScreen ===el.i )

          })}
        </ResponsiveReactGridLayout>
     
      )
    
  }
}

export { AddRemoveLayout }
