import React  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch, Link } from 'react-router-dom'
import { push } from 'connected-react-router'

import { PrivateRoute } from '../../Auth'
import { logout } from '../../Auth/actions'


import {
	permission,
	PERMISSIONS_NONE,
	LIST_SOLUTIONS, SOLUTIONS,
	LIST_ORGANISATIONS, ORGANISATIONS,
	LIST_USERS, USERS,
	LIST_DATASOURCES, DATASOURCES,
	LIST_ROLES, ROLES,
	LIST_PREDICTIONS, PREDICTIONS, 
	READ_PREDICTION, PREDICTION, 
	LIST_EXECUTIONS, EXECUTIONS, READ_EXPERIMENT, EXPERIMENTS,
	LIST_EXPERIMENTS, EXPERIMENT,
	
	// LIST_EXPERIMENTS, EXPERIMENT,
	// LIST_GROUPS, GROUPS,
	// LIST_EXECUTIONS, EXECUTIONS, READ_EXPERIMENT, EXPERIMENTS,

} from '../../actions/permissions'

import { Home } from './Home'
import { RolesPage } from './Roles'
import { SolutionsPage } from './Solutions'
import { UserProfilePage, UsersAdminPage } from './User'
import { PredictionConfigsPage } from './PredictionConfigs'
import { PredictionConfigEditor } from '../SolutionEditor/Predictions/PredictionConfigEditor'
import { ExperimentConfigEditor } from '../Admin/Experiments/ExperimentConfigEditor'
import { OrganisationsPage } from './Organisations'
import { ExecutionsPage } from './Executions'
import { DatasourcesPage } from './Datasources'
import { ExperimentsPage } from './Experiments/ExperimentsPage';

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import {  CssBaseline, Divider, List, IconButton, Drawer, ListItem, ListItemIcon, ListItemText, Icon} from '@material-ui/core'

import styles from '../../styles'
import { DicidioAppBar } from '../DicidioAppBar';

const privateRoutes = [
	{ name: 'Dashboard', path: '/admin', urlMatcher :'^/admin$', exact: true, component: Home, icon: 'dashboard', permission: PERMISSIONS_NONE },
	{ name: 'Profile', path: '/admin/profile', component: UserProfilePage,  icon: 'account_circle', permission: PERMISSIONS_NONE },
	{ name: 'Organisations', path: '/admin/organisations', component: OrganisationsPage, icon: 'domain', permission: LIST_ORGANISATIONS, link: ORGANISATIONS },
	// { name: 'Groups', path: '/admin/groups', component: GroupsPage,  icon: 'group', permission: LIST_GROUPS, link: GROUPS },
	{ name: 'Roles', path: '/admin/roles', component: RolesPage, icon: 'group_work', permission: LIST_ROLES, link: ROLES },
	{ name: 'Users', path: '/admin/users', component: UsersAdminPage, icon: 'person', permission: LIST_USERS, link: USERS },
	{ name: 'Datasources', path: '/admin/datasources', component: DatasourcesPage,  icon: 'folder', permission: LIST_DATASOURCES, link: DATASOURCES },
	{ name: 'Solutions', path: '/admin/solutions', component: SolutionsPage,  icon: 'widgets', permission: LIST_SOLUTIONS, link: SOLUTIONS },
	{ name: '', path: '/admin/predictions/configs/:id', urlMatcher : '^/admin/predictions/configs/.*$',component: PredictionConfigEditor,  permission: READ_PREDICTION, link: PREDICTIONS },
	{ name: 'ExperimentConfigEditor', path: '/admin/experiments/configs/:id', urlMatcher : '^/admin/experiments/configs/.*$',component: ExperimentConfigEditor,  permission: READ_EXPERIMENT, link: EXPERIMENTS },
	{ name: 'Predictions', path: '/admin/predictions/configs', exact: true, component: PredictionConfigsPage,  icon: 'play_circle_outline', permission: LIST_PREDICTIONS, link: PREDICTION },
	{ name: 'Experiments', path: '/admin/experiments/configs', component: ExperimentsPage,  icon: 'playlist_play', permission: LIST_EXPERIMENTS, link: EXPERIMENT },
	{ name: 'Executions', path: '/admin/executions', component: ExecutionsPage,  icon: 'flag', permission: LIST_EXECUTIONS, link: EXECUTIONS },
]


export class AdminPage extends React.Component {
	state = {
		selected: this.props.location.pathname,
		open: true
	};

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	handleItemClick = (e, path) => {
		e.preventDefault()
		this.setState({
			selected: path
		})
		this.props.push(path)
	}

	currentRoute = () => {
		return privateRoutes.find(i => (this.props.location.pathname.match(new RegExp(i.urlMatcher || i.path))))
	}

	render() {
		const { classes,  } = this.props
		const currentRoute =  this.currentRoute()
		return (
			<React.Fragment>
				<CssBaseline />
				<div className={classes.root}>
				<DicidioAppBar menuOpen = {this.state.open}  onDrawerOpen = {this.handleDrawerOpen} title ={this.props.title || currentRoute.name }/>
					<Drawer
						variant="permanent"
						classes={{
							paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
						}}
						open={this.state.open}
					>
						<div className={classes.toolbarIcon}>
						<div className= {classes.logo} >DICIDIO</div>
							<IconButton onClick={this.handleDrawerClose}>
								<Icon>chevron_left</Icon>
							</IconButton>
						</div>
						<Divider />
						<List>
							<div className={classes.drawerMenu}>
								{
									privateRoutes.map(i => (
										(permission(this.props.user, i.permission) && i.icon)?
											< ListItem key={i.name} button
												component={Link} to={i.path}
												onClick={e => this.handleItemClick(e, i.path)}
												selected={this.state.selected.match(new RegExp(i.urlMatcher || i.path)) !== null }>
												<ListItemIcon>
													<Icon>{i.icon}</Icon>
												</ListItemIcon>
												<ListItemText primary={i.name} />
											</ListItem>
											: null
									))
								}</div>
						</List>
						<Divider />
						<List>{/* secondaryListItems */}</List>
					</Drawer>
					<main className={classes.adminContent}>
						<div className={classes.appBarSpacer} />
						<Switch>
							{
								privateRoutes.map(item => <PrivateRoute key={item.path} {...item} />)
							}

						</Switch>
					</main>
				</div>

			</React.Fragment>
		);
	}
}


AdminPage = connect(
	state => ({
		user: state.user.profile,
		title :state.adminPage.title,
		
	}),
	dispatch => (
		bindActionCreators({ push, logout }, dispatch)
	)
)(withStyles(styles, { withTheme: true })(AdminPage))

