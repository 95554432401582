import React from 'react'

import {FormControlLabel, Checkbox, Chip, Typography, Card, CardHeader, CardContent } from '@material-ui/core'
import { Slider} from '@material-ui/lab';

import _ from 'lodash'

export class AxisController extends React.Component {

    handleChipDragOver= (event) => {
        if (this.props.canDrop) {
            event.preventDefault() 
        }
    }
	handleStartChipDrag (event, chipName, source) {
		event.dataTransfer.setData("id", JSON.stringify({name:chipName, source:source}));
		return event
    }

    handleLogScaleChange = () => {
        const {values, onChange} = this.props
        if (values.scale && values.scale.type==='log') {
            onChange && values && onChange({...values, scale : undefined})
        }
        else {
            onChange && values && onChange({...values, scale : {type:'log'}})
        }
    }

    handleBinChange = (event, step, type) => {
        const {values, onChange} = this.props
        if (event.target.checked === true) {
            onChange && values && onChange({...values, bin : {nice: true, "step": step}, type:'ordinal'})
        }
        else {
            onChange && values && onChange({...values, bin : undefined, type:type})
        }
    }
    handleBinSizeChange = (event, step) => {
        const {values, onChange} = this.props
        if (step) {
            onChange && values && onChange({...values, bin : {nice: true, "step": step}})
        }
    }

    handleEndChipDrag=(event, target) =>{
		event.preventDefault() 
		const data = event.dataTransfer.getData("id")
		if (data) {
            const chip = JSON.parse(data)
            const {values, onChange} = this.props
            chip && onChange && values && onChange({...values, field : chip.name, bin:undefined, scale:undefined})
		}
	}

    render() {
        const {range, classes, title } = this.props
        const values = this.props.values || {}
        const {axis,  bin, field, log, } = values
        
        const type = range?range.type :'nominal'
        const useBin = (bin && bin.nice) || false
        const step = (bin && bin.step) || (range && Math.round((range.max-range.min)/4)) || 0
        return  <Card 
                    className={classes.axisCard} 
                    style={this.props.style}
                    elevation={5}
        		    onDrop={e=> this.handleEndChipDrag(e, axis)}
          		    onDragOver={this.handleChipDragOver}>
                <CardHeader classes={{root:classes.axisCardHeader, title: classes.axisCardHeaderText}} title={title || "Drop place"} />
                <CardContent >
        	<div 
                className={classes.chipLandingPlace} 
            >
            {field?
            <Chip 
                draggable={true} 
                onDragStart={e=> {this.handleStartChipDrag(e, field, axis)}} 
                clickable color={_.startsWith(field, "application")?'primary':'secondary'} 
                key ={field} 
                label={field} 
                className={classes.chip} />   
                : 
                <Typography variant="body1" classes={{root:classes.chipLandingPlaceText}}>Drop field here</Typography>
                }
			</div>	
            {type==='quantitative'?
            <FormControlLabel
              control={
                <Checkbox
                  checked={log}
                  onChange={this.handleLogScaleChange}
                />
              }
              label="Use logaritmic scale"
            /> : null}
            {(type==='quantitative' || type==='ordinal') ?
            <React.Fragment>
            <FormControlLabel style = {{width:'100%'}}
              control={
                <Checkbox
                  checked={useBin}
                  onChange={e=>this.handleBinChange(e, step, type)}
                />
              }
              label="Group values"
            />
            {useBin?
                <React.Fragment>
            <Typography id="slider-label">Step size {bin? step: ''}</Typography>
               <Slider
                    aria-labelledby="slider-label"
                    {...range}
                    value={step}
                    onChange={this.handleBinSizeChange}
                    />
                    </React.Fragment>
                : null}
            </React.Fragment>: null}

			</CardContent>

        </Card>
    }
}