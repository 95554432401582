const diagram = {   "id": "882cffe0-a6d8-437a-ab31-1c3006e2fc58",
        "offsetX": 120,
        "offsetY": 20,
        "zoom": 38.888892618815106,
        "gridSize": 25,
        "links": [
          {
            "id": "d2666246-fda1-4614-b497-51ad767352ee",
            "type": "links",
            "selected": false,
            "source": "a78fc5d7-9900-4c11-b80f-29b549eadb36",
            "sourcePort": "f2120f7b-8ae1-4881-bfd5-c9203b54b7ca",
            "target": "8048d46b-71fd-41b3-03db-43eeed96f838",
            "targetPort": "2cf9b500-b8f2-4ae1-16e0-7e99e790bd08",
            "points": [
              {
                "id": "545bfdf1-4a38-409b-b050-0acbc0c15273",
                "selected": false,
                "x": 212.4999576767255,
                "y": 410.5000355339571
              },
              {
                "id": "5b6bbed5-9129-4e76-bd1a-9b0446dba5d5",
                "selected": true,
                "x": 287.50000279914025,
                "y": 410.5000355339571
              }
            ],
            "extras": {},
            "labels": [],
            "width": 3,
            "color": "rgba(255,255,255,0.5)",
            "curvyness": 50,
            "nodeProps": [
              {
                "field": "conditionFields",
                "label": "Condition",
                "type": "linkCondition"
              }
            ]
          },
          {
            "id": "10139b14-b9fe-4b63-b306-5eed0bfd9c0d",
            "type": "links",
            "selected": false,
            "source": "0bed32c9-3e75-4d48-3cb8-b8484a8cf1ea",
            "sourcePort": "01357b98-b4f4-4ade-0583-3657d66cbeb3",
            "target": "bc9e05cf-903d-4874-be77-506b266508d1",
            "targetPort": "3e158f56-56c6-487b-ae02-439c8fc52fd6",
            "points": [
              {
                "id": "30b4c511-f7d6-4157-bc28-35361dbb48d9",
                "selected": false,
                "x": 1787.499806614728,
                "y": 410.5000355339571
              },
              {
                "id": "418901d6-ebd6-4b88-825c-60fd5e12c2a7",
                "selected": true,
                "x": 1887.4998667779478,
                "y": 435.500050574762
              }
            ],
            "extras": {},
            "labels": [],
            "width": 3,
            "color": "rgba(255,255,255,0.5)",
            "curvyness": 50,
            "nodeProps": [
              {
                "field": "conditionFields",
                "label": "Condition",
                "type": "linkCondition"
              }
            ]
          }
        ],
        "nodes": [
          {
            "id": "a78fc5d7-9900-4c11-b80f-29b549eadb36",
            "type": "DecisionNode",
            "selected": false,
            "x": 125,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "f2120f7b-8ae1-4881-bfd5-c9203b54b7ca",
                "type": "links",
                "selected": false,
                "name": "818ce98d-01b5-4add-815e-c8c6182d330f",
                "parentNode": "a78fc5d7-9900-4c11-b80f-29b549eadb36",
                "links": ["d2666246-fda1-4614-b497-51ad767352ee"],
                "in": false
              }
            ],
            "name": "Start",
            "color": "rgb(0,192,255)",
            "label": "Start",
            "icon": "forward",
            "typeLabel": "Start"
          },
          {
            "id": "82fd26ff-cab0-4a44-b3bd-7ddbf605c91a",
            "type": "DecisionNode",
            "selected": false,
            "x": 1050,
            "y": -25,
            "extras": {},
            "ports": [
              {
                "id": "4d62f941-2c8e-46de-8e4f-fa7fcfe624d5",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "82fd26ff-cab0-4a44-b3bd-7ddbf605c91a",
                "links": [],
                "in": true
              },
              {
                "id": "fe9a68fa-510b-4aed-94d8-7df463bc27b9",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "82fd26ff-cab0-4a44-b3bd-7ddbf605c91a",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "61f242e8-14ef-438c-8154-6bff30463978",
            "type": "DecisionNode",
            "selected": false,
            "x": 325,
            "y": 100,
            "extras": {},
            "ports": [
              {
                "id": "cf1de0bb-768d-49c1-b704-b57579fec715",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "61f242e8-14ef-438c-8154-6bff30463978",
                "links": [],
                "in": true
              },
              {
                "id": "6c10200d-9a11-43bc-a809-6f742659db00",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "61f242e8-14ef-438c-8154-6bff30463978",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "bc9e05cf-903d-4874-be77-506b266508d1",
            "type": "DecisionNode",
            "selected": false,
            "x": 1875,
            "y": 375,
            "extras": {},
            "ports": [
              {
                "id": "3e158f56-56c6-487b-ae02-439c8fc52fd6",
                "type": "links",
                "selected": false,
                "name": "a1b18bb5-5758-4579-30c6-20c064038b04",
                "parentNode": "bc9e05cf-903d-4874-be77-506b266508d1",
                "links": ["10139b14-b9fe-4b63-b306-5eed0bfd9c0d"],
                "in": true
              }
            ],
            "name": "End",
            "color": "rgb(255,192,0)",
            "label": "End",
            "icon": "done",
            "changeIcon": false,
            "typeLabel": "End",
            "nodeProps": [{ "field": "label", "label": "Name", "type": "text" }]
          },
          {
            "id": "7f8fa84d-036c-4f02-8438-87cdf0f1fdd9",
            "type": "DecisionNode",
            "selected": false,
            "x": 925,
            "y": -25,
            "extras": {},
            "ports": [
              {
                "id": "e9231747-4b31-429d-a73c-88eeb48d4d49",
                "type": "links",
                "selected": false,
                "name": "f92794c1-7c2d-4420-20cb-f803b0f0908b",
                "parentNode": "7f8fa84d-036c-4f02-8438-87cdf0f1fdd9",
                "links": [],
                "in": true
              },
              {
                "id": "6e79d1c9-a86a-4137-b9e8-83048af19ffd",
                "type": "links",
                "selected": false,
                "name": "7c62cffc-0444-4d40-0728-02277c2bcd9b",
                "parentNode": "7f8fa84d-036c-4f02-8438-87cdf0f1fdd9",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "f5d03f76-2933-4374-2781-1c3a6e41f804",
            "type": "DecisionNode",
            "selected": false,
            "x": 300,
            "y": 225,
            "extras": {},
            "ports": [
              {
                "id": "cc326434-6c1d-4ba3-1c01-53cccd8c4828",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "f5d03f76-2933-4374-2781-1c3a6e41f804",
                "links": [],
                "in": true
              },
              {
                "id": "3cb6319e-666d-4c37-3052-8d33a41dbf90",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "f5d03f76-2933-4374-2781-1c3a6e41f804",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "8048d46b-71fd-41b3-03db-43eeed96f838",
            "type": "DecisionNode",
            "selected": false,
            "x": 275,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "2cf9b500-b8f2-4ae1-16e0-7e99e790bd08",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "8048d46b-71fd-41b3-03db-43eeed96f838",
                "links": ["d2666246-fda1-4614-b497-51ad767352ee"],
                "in": true
              },
              {
                "id": "fe101977-dd84-4049-1c6f-210c529e5940",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "8048d46b-71fd-41b3-03db-43eeed96f838",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "a8408da1-1337-4efe-23d4-bbff5f538a63",
            "type": "DecisionNode",
            "selected": false,
            "x": 400,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "84af5a74-edfe-4595-38f2-bfcd8c37b446",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "a8408da1-1337-4efe-23d4-bbff5f538a63",
                "links": [],
                "in": true
              },
              {
                "id": "7f773d49-ff1d-4dab-03a9-307c471f77ef",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "a8408da1-1337-4efe-23d4-bbff5f538a63",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "646e6e92-062e-4149-10cc-c5cdd53c3348",
            "type": "DecisionNode",
            "selected": false,
            "x": 525,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "5f59e2ff-d413-435b-101b-89d14e42fc32",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "646e6e92-062e-4149-10cc-c5cdd53c3348",
                "links": [],
                "in": true
              },
              {
                "id": "4e64227b-f9ce-4b40-2bec-090a9231e941",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "646e6e92-062e-4149-10cc-c5cdd53c3348",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "80754540-5eae-4ca2-0593-28eefcbc3778",
            "type": "DecisionNode",
            "selected": false,
            "x": 650,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "c05d0e8c-08d1-4dae-0ddb-e77d844a4949",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "80754540-5eae-4ca2-0593-28eefcbc3778",
                "links": [],
                "in": true
              },
              {
                "id": "7272f3a5-3f77-4ab4-35d0-a73b6bbf232a",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "80754540-5eae-4ca2-0593-28eefcbc3778",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "2e7776fb-9190-499c-1838-dbe63cf2e6ac",
            "type": "DecisionNode",
            "selected": false,
            "x": 525,
            "y": 225,
            "extras": {},
            "ports": [
              {
                "id": "a5862fbd-a6f4-4742-1bc1-a208def04f26",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "2e7776fb-9190-499c-1838-dbe63cf2e6ac",
                "links": [],
                "in": true
              },
              {
                "id": "2df9b466-f4a1-4d69-15b9-1bb47ae0b8a5",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "2e7776fb-9190-499c-1838-dbe63cf2e6ac",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "a66fa5eb-b516-4033-0afb-a8a282abe83a",
            "type": "DecisionNode",
            "selected": false,
            "x": 525,
            "y": 475,
            "extras": {},
            "ports": [
              {
                "id": "6fb54896-a5d5-42f6-2457-30db01d1e123",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "a66fa5eb-b516-4033-0afb-a8a282abe83a",
                "links": [],
                "in": true
              },
              {
                "id": "64813ec4-e1ee-45a4-38d2-abbf58d27ab7",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "a66fa5eb-b516-4033-0afb-a8a282abe83a",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "e247d885-ec5b-4af8-1bc6-0c52826b4de1",
            "type": "DecisionNode",
            "selected": false,
            "x": 350,
            "y": -25,
            "extras": {},
            "ports": [
              {
                "id": "b8f3eb72-50f2-420e-3258-3fe49bd91f01",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "e247d885-ec5b-4af8-1bc6-0c52826b4de1",
                "links": [],
                "in": true
              },
              {
                "id": "80dfa94b-e196-4c2f-2dd0-427da629efbc",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "e247d885-ec5b-4af8-1bc6-0c52826b4de1",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "6b849e45-39b0-4f0f-2c91-00b36a4312d4",
            "type": "DecisionNode",
            "selected": false,
            "x": 1050,
            "y": 475,
            "extras": {},
            "ports": [
              {
                "id": "33fe1a13-c1e9-4beb-3d7c-4edd839f1564",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "6b849e45-39b0-4f0f-2c91-00b36a4312d4",
                "links": [],
                "in": true
              },
              {
                "id": "4e05c503-02aa-4687-0aba-41e2f0352141",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "6b849e45-39b0-4f0f-2c91-00b36a4312d4",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "5bf690fe-2b58-40bd-2181-49be6048fe4f",
            "type": "DecisionNode",
            "selected": false,
            "x": 925,
            "y": 475,
            "extras": {},
            "ports": [
              {
                "id": "020eb31c-3ff0-44b0-22cd-6743e5d4fe0a",
                "type": "links",
                "selected": false,
                "name": "f92794c1-7c2d-4420-20cb-f803b0f0908b",
                "parentNode": "5bf690fe-2b58-40bd-2181-49be6048fe4f",
                "links": [],
                "in": true
              },
              {
                "id": "d41ccefd-27ce-4ea1-0ba0-b527ca9d7284",
                "type": "links",
                "selected": false,
                "name": "7c62cffc-0444-4d40-0728-02277c2bcd9b",
                "parentNode": "5bf690fe-2b58-40bd-2181-49be6048fe4f",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "803b509e-719b-4c5a-11dd-bef4909e522e",
            "type": "DecisionNode",
            "selected": false,
            "x": 1175,
            "y": 25,
            "extras": {},
            "ports": [
              {
                "id": "cc757b62-38a7-4229-0f2c-5a8baba9d5ed",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "803b509e-719b-4c5a-11dd-bef4909e522e",
                "links": [],
                "in": true
              },
              {
                "id": "c455988c-4663-49e3-35ca-32b12576a15d",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "803b509e-719b-4c5a-11dd-bef4909e522e",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "865b0192-6a31-475b-1751-886be93de5eb",
            "type": "DecisionNode",
            "selected": false,
            "x": 1175,
            "y": 150,
            "extras": {},
            "ports": [
              {
                "id": "d20e1d92-a1d0-454a-0538-a27bd166394e",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "865b0192-6a31-475b-1751-886be93de5eb",
                "links": [],
                "in": true
              },
              {
                "id": "8f7aab4a-d6d8-4b59-1c9b-428b04db8d88",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "865b0192-6a31-475b-1751-886be93de5eb",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "14c0b3b0-0a07-4a6b-0a50-daf5e8149435",
            "type": "DecisionNode",
            "selected": false,
            "x": 1175,
            "y": 275,
            "extras": {},
            "ports": [
              {
                "id": "b59811e2-3bdb-4239-248e-8a875459140a",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "14c0b3b0-0a07-4a6b-0a50-daf5e8149435",
                "links": [],
                "in": true
              },
              {
                "id": "c175aabc-1d7b-498c-27a2-fa47665bea5f",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "14c0b3b0-0a07-4a6b-0a50-daf5e8149435",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "af6ee81b-2621-4397-1e29-1d73b088c054",
            "type": "DecisionNode",
            "selected": false,
            "x": 800,
            "y": 25,
            "extras": {},
            "ports": [
              {
                "id": "bef0061b-9151-4cc3-2be2-4976495aaa3a",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "af6ee81b-2621-4397-1e29-1d73b088c054",
                "links": [],
                "in": true
              },
              {
                "id": "25f528a5-7573-4f7d-327e-fabf8c39e75f",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "af6ee81b-2621-4397-1e29-1d73b088c054",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "9139c52f-5940-43f1-1c84-aa9d2b7879ff",
            "type": "DecisionNode",
            "selected": false,
            "x": 800,
            "y": 150,
            "extras": {},
            "ports": [
              {
                "id": "392a928b-b0f0-458f-3446-332259fbe578",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "9139c52f-5940-43f1-1c84-aa9d2b7879ff",
                "links": [],
                "in": true
              },
              {
                "id": "5c75bd09-9d7a-45a6-0df0-23992b261eb3",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "9139c52f-5940-43f1-1c84-aa9d2b7879ff",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "0ca8f25e-e5ff-4bc5-2121-121ebd8705a8",
            "type": "DecisionNode",
            "selected": false,
            "x": 800,
            "y": 275,
            "extras": {},
            "ports": [
              {
                "id": "1d7f691a-f97e-4136-3120-e0fa1a5894fb",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "0ca8f25e-e5ff-4bc5-2121-121ebd8705a8",
                "links": [],
                "in": true
              },
              {
                "id": "b7ce2ecd-8b26-42fc-1b87-859963d5a3f8",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "0ca8f25e-e5ff-4bc5-2121-121ebd8705a8",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "8ff1987f-b0c0-40bc-1526-be4a4ce99c2c",
            "type": "DecisionNode",
            "selected": false,
            "x": 1175,
            "y": 400,
            "extras": {},
            "ports": [
              {
                "id": "ce5def6e-829c-4a4f-0721-47da543162f0",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "8ff1987f-b0c0-40bc-1526-be4a4ce99c2c",
                "links": [],
                "in": true
              },
              {
                "id": "ec0cc947-f103-4490-0499-7da41d7910e6",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "8ff1987f-b0c0-40bc-1526-be4a4ce99c2c",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "fd08f1ce-d345-47d1-0a4b-2f1e8ae1985a",
            "type": "DecisionNode",
            "selected": false,
            "x": 800,
            "y": 400,
            "extras": {},
            "ports": [
              {
                "id": "b1a58a68-dd73-4888-0f53-d24d089dd0cd",
                "type": "links",
                "selected": false,
                "name": "75c60c8b-550b-440e-3ee6-54d00943c2d1",
                "parentNode": "fd08f1ce-d345-47d1-0a4b-2f1e8ae1985a",
                "links": [],
                "in": true
              },
              {
                "id": "8e0d8fdc-51c6-48ea-0060-a50d6458766f",
                "type": "links",
                "selected": false,
                "name": "6900d1fc-e297-48ca-3771-015acfe1da0a",
                "parentNode": "fd08f1ce-d345-47d1-0a4b-2f1e8ae1985a",
                "links": [],
                "in": false
              }
            ],
            "name": "EasyRule",
            "color": "rgb(204, 0, 204)",
            "label": "Easy Rule",
            "icon": "check_circle",
            "changeIcon": true,
            "typeLabel": "Easy Rule",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "conditionFields",
                "label": "If",
                "type": "linkCondition"
              },
              { "type": "divider" },
              {
                "field": "thenFields",
                "label": "Then",
                "type": "runtimeFieldSetter"
              }
            ]
          },
          {
            "id": "bc2d98ef-c820-44b4-2d32-d3e372bea299",
            "type": "DecisionNode",
            "selected": false,
            "x": 1375,
            "y": 100,
            "extras": {},
            "ports": [
              {
                "id": "7d396310-f93c-442e-28bc-cb7763d1afd4",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "bc2d98ef-c820-44b4-2d32-d3e372bea299",
                "links": [],
                "in": true
              },
              {
                "id": "30f5bc56-261f-4eb2-2610-57ca2ccb063a",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "bc2d98ef-c820-44b4-2d32-d3e372bea299",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "3b7ec6f0-c125-4f5f-22a5-5bd9eceabdc2",
            "type": "DecisionNode",
            "selected": false,
            "x": 1350,
            "y": 225,
            "extras": {},
            "ports": [
              {
                "id": "88d8eb05-83b7-48c4-3f0c-1cba614c6f0a",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "3b7ec6f0-c125-4f5f-22a5-5bd9eceabdc2",
                "links": [],
                "in": true
              },
              {
                "id": "eaa224d7-c4d0-4a42-0c89-2bc419267fab",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "3b7ec6f0-c125-4f5f-22a5-5bd9eceabdc2",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "788a0a5d-4124-40e3-0f0b-bde336200499",
            "type": "DecisionNode",
            "selected": false,
            "x": 1325,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "f7079f6d-9ba9-4aab-0d0e-924b4c3dbe93",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "788a0a5d-4124-40e3-0f0b-bde336200499",
                "links": [],
                "in": true
              },
              {
                "id": "827f8ce9-1dab-42a4-010d-2c803c8138f7",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "788a0a5d-4124-40e3-0f0b-bde336200499",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "909ebda9-23f9-4b7e-3dee-5a2fbca838da",
            "type": "DecisionNode",
            "selected": false,
            "x": 1450,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "9bc113cc-64b9-4498-30f7-af8408511360",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "909ebda9-23f9-4b7e-3dee-5a2fbca838da",
                "links": [],
                "in": true
              },
              {
                "id": "abef72e1-b8e1-4808-01f0-6642e749fad2",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "909ebda9-23f9-4b7e-3dee-5a2fbca838da",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "5c29a392-d607-4810-011c-6165254cac39",
            "type": "DecisionNode",
            "selected": false,
            "x": 1575,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "a075e161-3daa-4fe0-0d23-e4146958670d",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "5c29a392-d607-4810-011c-6165254cac39",
                "links": [],
                "in": true
              },
              {
                "id": "beaf0efa-7c23-4054-1fcf-0535d9ddba01",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "5c29a392-d607-4810-011c-6165254cac39",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "0bed32c9-3e75-4d48-3cb8-b8484a8cf1ea",
            "type": "DecisionNode",
            "selected": false,
            "x": 1700,
            "y": 350,
            "extras": {},
            "ports": [
              {
                "id": "f68c0524-379c-45d8-2197-6e20918fef03",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "0bed32c9-3e75-4d48-3cb8-b8484a8cf1ea",
                "links": [],
                "in": true
              },
              {
                "id": "01357b98-b4f4-4ade-0583-3657d66cbeb3",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "0bed32c9-3e75-4d48-3cb8-b8484a8cf1ea",
                "links": ["10139b14-b9fe-4b63-b306-5eed0bfd9c0d"],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "1ecbce78-6c9c-4259-3ae1-0081ea5db06f",
            "type": "DecisionNode",
            "selected": false,
            "x": 1575,
            "y": 225,
            "extras": {},
            "ports": [
              {
                "id": "bd621610-62d1-4a60-03d3-353e9a6f3108",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "1ecbce78-6c9c-4259-3ae1-0081ea5db06f",
                "links": [],
                "in": true
              },
              {
                "id": "9d2dabf3-ddcb-4d54-2580-ede6c783a73e",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "1ecbce78-6c9c-4259-3ae1-0081ea5db06f",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "81929c6c-c2d1-4fca-2d18-c4e02c22a281",
            "type": "DecisionNode",
            "selected": false,
            "x": 1575,
            "y": 475,
            "extras": {},
            "ports": [
              {
                "id": "8e37b5f6-3c3d-425b-2abe-fd80d24dea65",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "81929c6c-c2d1-4fca-2d18-c4e02c22a281",
                "links": [],
                "in": true
              },
              {
                "id": "975ae179-e63f-4cc9-0bb4-24d20aa0ff1d",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "81929c6c-c2d1-4fca-2d18-c4e02c22a281",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          },
          {
            "id": "73fb3ac5-e6fb-4fb5-1644-853aa9a6b699",
            "type": "DecisionNode",
            "selected": false,
            "x": 1400,
            "y": -25,
            "extras": {},
            "ports": [
              {
                "id": "e83d97eb-18b8-4159-2d10-5b04c1fc1417",
                "type": "links",
                "selected": false,
                "name": "3384944b-0411-4aa6-1ede-e969c19e7f34",
                "parentNode": "73fb3ac5-e6fb-4fb5-1644-853aa9a6b699",
                "links": [],
                "in": true
              },
              {
                "id": "e6b4fae9-4bc4-4ece-0e6e-de43d1cf7c76",
                "type": "links",
                "selected": false,
                "name": "63df0f75-f8a9-411c-2217-6f03ab9d2731",
                "parentNode": "73fb3ac5-e6fb-4fb5-1644-853aa9a6b699",
                "links": [],
                "in": false
              }
            ],
            "name": "Calculation",
            "color": "rgb(124, 122, 155)",
            "label": "Calculation",
            "icon": "ballot",
            "changeIcon": true,
            "typeLabel": "Calculation",
            "nodeProps": [
              { "field": "label", "label": "Name", "type": "text" },
              {
                "field": "info1Label0",
                "label": "Only Integer and Float fields can be used in calculations",
                "type": "infoText"
              },
              {
                "field": "calculations",
                "label": "Calculation",
                "type": "calculator",
                "required": true
              },
              {
                "field": "resultField",
                "label": "Result field",
                "type": "runtimeFieldSelector"
              }
            ]
          }
        ]
      }

export default diagram